import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import { useNavigate, useLocation } from "react-router-dom";

const SubCategoryManager = () => {
    const location = useLocation();
    const id = location?.state?.id;
    const name = location?.state?.name;
	const whichCategory = location?.state?.which;
    const navigate = useNavigate();

    const [allData, setAllData] = useState([]);
	const {postOrGet}  = useAxios();

	const handleActivateDeactivate = async (id,action) => {
		postOrGet(`subcategory/activate/deactivate/${whichCategory}`,'post',{id,action}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.subcategoryID === id ? {...data,subcategoryActive: action} : data;
				});
				setAllData(newAllData);
			}
		});
	}

	const loadData = async () => {
		postOrGet(`subcategory/${whichCategory}/${id}`,'get',{}).then((data) =>{
			if(data?.results){
				setAllData(data.results);
			}
		});
	}

	useEffect(()=>{
		//import('../../../assets/admin/js/material-dashboard-inside').then((mod) => {}).catch(err => {});
		loadData();	
	},[]);

	return <div className="row mt-4 p-4">
					<div className="col-12">

					    <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                            <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                            <span className="btn-inner--text">GO BACK</span>
                        </button>

						<div className="card mb-4">
							<div className="card-header pb-0">
								<h6>Sub categories for {name} </h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_CATEGORY_VIEW">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														S/N
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Sub Category
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Status
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.subcategoryID}`}>
															<td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.subcategoryName }
																		</h6>
																	</div>
																</div>
															</td>
															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.subcategoryActive ? "success" : "primary"}`}>
																	{s.subcategoryActive ? "ACTIVE" : "DISABLED"}
																</span>
																</p>
															</td>
															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		<AuthorizedAreas permission="CAN_SUB_CATEGORY_EDIT">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={'/admin/categories/subedit'}
																					state={{ 
																						id: s.subcategoryID,
																						name: s.subcategoryName,
																						which: whichCategory
																					}}
																				>
																					EDIT SUBCATEGORY
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		<AuthorizedAreas permission="CAN_SUB_CATEGORY_ACTIVATE_DEACTIVATE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleActivateDeactivate(s.subcategoryID,s.subcategoryActive ? false : true)}
																				>
																					{s.subcategoryActive ? "DE-ACTIVATE" : "ACTIVATE"} SUB CATEGORY
																				</Link>
																			</li>
																		</AuthorizedAreas>
																	</ul>
																</div>
															</td>
														</tr>
													))
												) : (
													<h6 className="p-3">No entries created yet</h6>
												)}
											</tbody>
										</table>
									</div>
								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>

}

export default SubCategoryManager