import {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import {loginSchema} from '../../utils/validations/LoginValidation';
import {registerSchema} from '../../utils/validations/RegisterValidation';
import {useDispatch} from "react-redux";
import {SET_ACTIVE_USER} from "../../redux/slice/authSlice";
import useGlobalNotification from "../../utils/hooks/useGlobalNotification";
import useAxios from "../../utils/hooks/useAxios";

const AuthWall = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const linkAction = location?.state?.action;
    const {dispatchNotification} = useGlobalNotification();
	const {postOrGet}  = useAxios();

    const [resetToken, setResetToken] = useState('');//password reset
    const [action, setAction] = useState('login');//login or register
    const [resetPassword, setResetPassword] = useState(false);//login or register
    const defaultLoginForm = {
		username: "",
		password: "",
	}
    const defaultResetForm = {
		password: "",
		passwordConfirm: "",
	}
    const defaultRegisterForm = {
		firstName: "",
		lastName: "",
		userType: "",
		email: "",
		username: "",
		password: "",
		passwordConfirm: "",
	}
    const [loginForm, setLoginForm] = useState(defaultLoginForm);
    const [registerForm, setRegisterForm] = useState(defaultRegisterForm );
    const [resetForm, setResetForm] = useState(defaultResetForm);

    const handleLoginChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLoginForm(prev=>({ ...prev, [name]: value })); 
	}
    const handleRegisterChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setRegisterForm(prev=>({ ...prev, [name]: value })); 
	}                    
    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        if(resetPassword){
            //RESET PASSWORD
            if(loginForm.username){

                await postOrGet('auth/reset/password','post',{username: loginForm.username})
                .then((data) =>{
                    if(data){
                       dispatchNotification('success',"Password reset email sent");
                    }
                });
            }else
            dispatchNotification('error',"Please enter email address");

        }else{
            //LOGIN
            loginSchema.validate(loginForm, { abortEarly: false })
            .then(async (values)=>{

                await postOrGet('auth/login','post',values)
                .then((data) =>{
                    if(data){
                        dispatch(SET_ACTIVE_USER(data));
                        setLoginForm(defaultLoginForm);
                        if(data.userType==1)
                            navigate("/");
                        else
                            navigate("/admin/dashboard");
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });

        }

        
        
    }
    const handleRegisterSubmit = async (e) => {
        e.preventDefault();

        //validation
        registerSchema.validate(registerForm, { abortEarly: false })
        .then(async (values)=>{

            await postOrGet('auth/user/create','post',values)
            .then((data) =>{
                if(data){
                   dispatchNotification('success',"Account created successfully. Kindly login to access your account"); 
                   setRegisterForm(defaultRegisterForm);
                   setAction("login");
                }
            });
            
        })
        .catch(err=>{
            let message = "<p>You have the following errors:</p>";
            message += err.errors.join("<br/>");
            dispatchNotification('error',message);
        });
    }

    const handleResetSubmit = async (e) => {
        e.preventDefault();

        if(resetForm.password==""  || resetForm.passwordConfirm==""){
            dispatchNotification('error',"Please enter password and password confirm fields");
            return;
        }

        if(resetForm.password  != resetForm.passwordConfirm){
            dispatchNotification('error',"Password fields must match");
            return;
        }
            

        await postOrGet('auth/reset/password/live','post',{...resetForm,resetToken })
            .then((data) =>{
                if(data){
                    if(data.error){
                        dispatchNotification('error',data.error);
                        return;
                    }

                    dispatchNotification('success',"Password reset email sent");
                    setResetToken("");
                }
        });
           
    }

    useEffect(() => {
        if(linkAction && linkAction=="register"){
            setAction("register");
            setRegisterForm({ ...registerForm, userType:2}); 
        }

        if(location?.pathname){
            if(location.pathname.includes("/authwall/reset/")){
                const rc = location.pathname.split('/authwall/reset/')[1];
                setResetToken(rc);
            }
            
        }
    },[])

  return (
    <div className="user-area">
        <div className="container-fluid m-0">
            <div className="row">
                <div className="col-lg-6 col-xl-6 p-0 login_h">
                    <div className="user-img">
                        <img src={require("../../assets/frontend/img/loginbg.jpg")} alt="Images" />
                    </div>
                </div>
                <div className="col-lg-6 col-xl-6 p-5">
                    <div className="user-section text-center">
                    <div className="user-content">
                        <img src={require("../../assets/frontend/img/logo/logo1.png")} alt />
                        <h2>Welcome to <b>KadTech</b></h2>
                    </div>
                    <div className="tab user-tab">
                        <div className="row">
                            {resetToken == "" &&
                            <div className="col-lg-12 col-md-12">
                                <ul className="tabs-influence">
                                    <li className={action=="login" ? "active" : ""}>
                                        <a onClick={()=>setAction("login")} href="#"> <i className="flaticon-contact" /> Login</a>
                                    </li>
                                    <li className={action=="register" ? "active" : ""}>
                                        <a onClick={()=>setAction("register")} href="#"> <i className="flaticon-verify" /> Register</a>
                                    </li>
                                </ul>
                            </div>
                            }
                            <div className="col-lg-12 col-md-12">
                                <div className="tab_content current">
                                    <div style={{ display : action=="login" ? "block": "none"}} className="tabs_item">
                                        {/* <div className="pt-3">
                                                <button type="button" className="btn btn-sm btn-danger">
                                                    <i style={{ color: 'white' }} className="bx bx-gmail"></i>
                                                    CONTINUE WITH GMAIL
                                                </button>
                                                <button type="button" className="btn btn-sm btn-info">
                                                    <i className="bx bx-facebook"></i>
                                                    CONTINUE WITH FACEBOOK
                                                </button>
                                        </div> */}
                                        {
                                        resetToken == "" ?
                                        <div className="user-all-form">
                                            <div className="contact-form">               
                                                    <div className="row">
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <i className="bx bx-user" />
                                                                <input type="text" name="username" onChange={e=>handleLoginChange(e)} value={loginForm.username} className="form-control" autoComplete="off" required data-error="Please enter your Username or Email" placeholder="Username or Email" />
                                                            </div>
                                                        </div>
                                                        {
                                                        !resetPassword &&
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <i className="bx bx-lock-alt" />
                                                                <input type="password" name="password" onChange={e=>handleLoginChange(e)} onKeyDown={(e) => e.key === 'Enter' && handleLoginSubmit(e)} value={loginForm.password} className="form-control" autoComplete="off"  placeholder="Password" />
                                                            </div>
                                                        </div>
                                                        }
                                                        <div className="col-lg-12 col-md-12 text-center">
                                                        <button type="button" onClick={e=>handleLoginSubmit(e)}  className="default-btn  user-all-btn">{resetPassword ? 'RESET':'LOGIN'}</button>
                                                        </div>
                                                        <div className="col-lg-6 col-sm-6 form-condition">
                                                        {/* <div className="agree-label">
                                                            <input type="checkbox" id="chb1" />
                                                            <label htmlFor="chb1">
                                                            Remember Me
                                                            </label>
                                                        </div> */}
                                                        </div>
                                                        <div className="col-lg-6 col-sm-6">
                                                        {!resetPassword ?  
                                                         <Link onClick={()=>setResetPassword(true)} className="forget" to="#">Forgot my password?</Link> :
                                                         <Link onClick={()=>setResetPassword(false)} className="forget" to="#">I have my credentials?</Link>
                                                        }
                                                        </div>
                                                    </div>     
                                            </div>
                                        </div>
                                        :
                                        <div className="user-all-form">
                                            <div className="contact-form"> 
                                                <h3>Password Reset</h3> 
                                                <div className="row">
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <i className="bx bx-user" />
                                                                <input type="password" name="password" onChange={e=>{
                                                                 setResetForm(prev=>({ ...prev, "password": e.target.value }));    
                                                                }} value={resetForm.password} className="form-control" autoComplete="off" required data-error="Please enter your Username or Email" placeholder="Enter New Password" />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <i className="bx bx-user" />
                                                                <input type="password" name="passwordConfirm" 
                                                                onChange={e=>{
                                                                 setResetForm(prev=>({ ...prev, "passwordConfirm": e.target.value }));    
                                                                }} 
                                                                value={resetForm.passwordConfirm} className="form-control" autoComplete="off" required data-error="Please enter your Username or Email" placeholder="Password Confirmation" />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12 col-md-12 text-center">
                                                            <button type="button" onClick={e=>handleResetSubmit(e)}  className="default-btn  user-all-btn">Reset Password</button>
                                                        </div>
                                                </div> 
                                            </div>       
                                        </div>
                                        }
                                    </div>
                                    <div style={{ display : action=="register" ? "block": "none"}} className="tabs_item">
                                        <div className="user-all-form">
                                            <div className="contact-form">
                                               
                                                    <div className="row">
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <i className="flaticon-writing" />
                                                                <input type="text" name="firstName" onChange={e=>handleRegisterChange(e)} value={registerForm.firstName} className="form-control" autoComplete="off" required data-error="Please enter your First Name" placeholder="First Name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <i className="flaticon-writing" />
                                                                <input type="text" name="lastName" onChange={e=>handleRegisterChange(e)} value={registerForm.lastName} className="form-control" autoComplete="off" required data-error="Please enter your Last Name" placeholder="Last Name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <i className="flaticon-category" />
                                                                    <select 
                                                                    className="form-control"
                                                                    name="userType"
                                                                    autoComplete="off"
                                                                    onChange={e=>handleRegisterChange(e)}
                                                                    defaultValue={""}
                                                                    value={registerForm.userType}
                                                                    >
                                                                        <option value="">Select Account Type</option>
                                                                        <option value={1}>User Account</option>
                                                                        <option value={2}>Business Account</option>
                                                                    </select>	
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <i className="flaticon-email-2" />
                                                                <input type="email" name="email" onChange={e=>handleRegisterChange(e)} value={registerForm.email} className="form-control" autoComplete="off" required data-error="Please enter email" placeholder="Email" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <i className="bx bx-user" />
                                                                <input type="text" name="username" onChange={e=>handleRegisterChange(e)} value={registerForm.username} className="form-control" autoComplete="off" required data-error="Please enter your Username" placeholder="Username" />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <i className="bx bx-lock-alt" />
                                                                <input className="form-control" name="password" onChange={e=>handleRegisterChange(e)} value={registerForm.password} type="password" autoComplete="off" placeholder="Password" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <i className="bx bx-lock-alt" />
                                                                <input className="form-control"  name="passwordConfirm" onChange={e=>handleRegisterChange(e)} onKeyDown={(e) => e.key === 'Enter' && handleRegisterSubmit(e)}  value={registerForm.passwordConfirm} type="password" placeholder="Confirm Password" />
                                                            </div>
                                                        </div>
                                                      
                                                        <div className="col-lg-12 col-md-12 text-center">
                                                            <button type="button" onClick={e=>handleRegisterSubmit(e)} className="default-btn  user-all-btn"> Register </button>
                                                        </div>
                                                        <div className="col-12">
                                                        <p className="account-desc">
                                                            Already have an account? &nbsp;&nbsp;
                                                            <Link className="btn btn-sm btn-primary" style={{ color: 'white' }} onClick={()=>setAction('login')} to="#">Login</Link>
                                                        </p>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default AuthWall