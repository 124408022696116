import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/Store";
import {disableReactDevTools} from '@fvilers/disable-react-devtools';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ReactPWAInstallProvider from "react-pwa-install";

if(process.env.NODE_ENV == 'production'){
    disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ReactPWAInstallProvider enableLogging>
      <Provider store={store}>
              <BrowserRouter>
                  <App />
              </BrowserRouter>
          </Provider>
    </ReactPWAInstallProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
