import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";


const EditListings = () => {
    const [allData, setAllData] = useState([]);
	const {postOrGet}  = useAxios();
    const location = useLocation();
    const navigate = useNavigate();

	const loadData = async () => {
        if(location?.state?.id){
            postOrGet(`listings/view/${location.state.id}`,'get').then((data) =>{
                if(data?.results){
                    setAllData(data.results);
                }
            });
        }
	}

    const handleActivateDeactivate = async (id,action) => {
		postOrGet('listing/activate/deactivate','post',{id,action}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.listingID === id ? {...data,listingActive: action} : data;
				});
				setAllData(newAllData);
			}
		});
	}

	useEffect(()=>{
		window.scrollTo({top: 0, left: 0});
		loadData();	
	},[]);

	return <div className="row mt-4 p-4">
                
            <div className="col-12">
                <div className="card mb-4">

                <div className="col-4 p-4">
                <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                  <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                  <span className="btn-inner--text">GO BACK</span>
                </button>
                </div>

							{/* <div className="card-header pb-0">
								<h6>Listings for {location?.state?.name}</h6>
							</div> */}
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_BUSINESS_VIEW">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														S/N
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Listed Name / Address
													</th>
													<th className="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
														Amount
													</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Status
													</th>
											
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.listingID}`}>
															<td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.listingName }
																		</h6>
                                                                        <p className="mb-0 text-sm">
																			{s.listingAddress }
																		</p>                  
																	</div>
																</div>
															</td>
															<td>
                                                                 <p className="mb-0 text-sm text-center">
                                                                        &#163;{s.listingAmount }
                                                                </p>  
															</td>
															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.listingActive ? "success" : "primary"}`}>
																	{s.listingActive ? "ACTIVE" : "INACTIVE"}
																    </span>
																</p>           
															</td>
															
										
															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		<AuthorizedAreas permission="CAN_BUSINESS_VIEW">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`/admin/business/edit/listing`}
																					state={{ 
																						id: s.listingID
																					}}
																				>
																					EDIT LISTING
																				</Link>
																			</li>
																		</AuthorizedAreas>
																	
                                                                    <AuthorizedAreas permission="CAN_BUSINESS_VIEW">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleActivateDeactivate(s.listingID,s.listingActive ? false : true)}
																				>
																					{s.listingActive ? "DE-ACTIVATE" : "ACTIVATE"} LISTING
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		
																	</ul>
																</div>
															</td>
														</tr>
													))
												) : (
													<h6 className="p-3">No entries created yet</h6>
												)}
											</tbody>
										</table>
									</div>
								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>

}

export default EditListings