import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate, useLocation } from "react-router-dom";
import { StaffCreateValidation } from "../../../utils/validations/StaffCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const EditStaff = () => {
        const defaultForm = {
                firstName: "",
                lastName: "",
                email: "",
                username: "",
                designationId: "",
        };
        const location = useLocation();
        const uuid = location?.state?.uuid;

        const [form, setForm] = useState(defaultForm);
        const [staffPrivileges,setStaffPrivileges] = useState([]);
        const [designations,setDesignations] = useState([]);
        const [permissions,setPermissions] = useState([]);
        
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const {dispatchNotification} = useGlobalNotification();
        const {postOrGet}  = useAxios();

        const getInitializationData = async () =>{
            await postOrGet('staff/edit','post',{uuid})
                    .then((data) =>{
                        if(data){
                            setPermissions(data.permissions);
                            setDesignations(data.designations);
                            const userPrivileges = data?.user?.privileges?.rights;
                            const userObject = data.user;
                            delete userObject.privileges;
                            setForm(userObject);
                            setStaffPrivileges(userPrivileges);
                        }
            }); 
        }

        useEffect(() => {
                getInitializationData();
        },[]);

        const handleChange = (e) => {
                const name = e.target.name;
                const value = e.target.value;
                setForm(prev=>({ ...prev, [name]: value })); 
        };
        
        const amIChecked = (id) =>{ return staffPrivileges.includes(id)}

        const handleCheckbox = (id) =>{
                if(staffPrivileges.includes(id))
                setStaffPrivileges(prev=>(prev.filter(e=>e!=id)));
                else
                setStaffPrivileges(prev=>([...prev,id]));	
        }

        const handleDesignationChange = async (e) =>{
                if (e.target.value=="") return;

                handleChange(e);
                dispatchNotification('success','Fetching associated privileges...');
                await postOrGet('staff/fetch/role/related/permissions','post',{designationId:e.target.value})
                    .then((data) =>{
                        if(data){
                            const obj = JSON.parse(data?.result);
                            if(obj?.permissions?.rights?.length){
                                setStaffPrivileges(obj.permissions.rights);
                            }
                        }
                });
        }
        
        const handleSubmit = async (e) => {
                e.preventDefault();
                //validation
                StaffCreateValidation.validate(form, { abortEarly: false })
                .then(async (values)=>{
                    await postOrGet('staff/edit/save','post',{...values,uuid,privileges : JSON.stringify(staffPrivileges)})
                    .then((data) =>{
                        if(data){
                            dispatchNotification('success',"Operation completed successfully"); 
                        }
                    });   
                })
                .catch(err=>{
                    let message = "<p>You have the following errors:</p>";
                    message  += err.errors.join("<br/>");
                    dispatchNotification('error',message);
                });
            };
            
	
	return <AuthorizedAreas permission="CAN_STAFF_EDIT">
	<div className="card-body px-0 pt-0 pb-2">
					
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div style={{ padding: 20 }}>
				<button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
						<span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
						<span className="btn-inner--text">GO BACK</span>
					</button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h5>Staff Information</h5>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="form-control"
                          placeholder="First Name"
                          value={form.firstName}
						  onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
					 <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="lastName"
                          id="lastName"
                          className="form-control"
                          placeholder="Last Name"
                          value={form.lastName}
						  onChange={(e)=>handleChange(e)}						
                        />
                      </div>
				   </div>
                </div>

				<div className="row mt-4">
					<div className="col-6">
                      <div className="input-group input-group-static">
                        <label>Email</label>
                        <input
                          autoComplete="off"
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          value={form.email}
						  onChange={e=>handleChange(e)}						
                        />
                      </div>
                    </div>
					<div className="col-6">
                      <div className="input-group input-group-static">
                        <label>Username</label>
                        <input
                          autoComplete="off"
                          type="text"
                          id="username"
                          name="username"
                          className="form-control"
                          placeholder="Enter username"
                          value={form.username}
						  onChange={e=>handleChange(e)}						
                        />
                      </div>
                    </div>
				</div>
          
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="designationId">Designation</label>
                        <select
                          className="form-control"
                          name="designationId"
                          id="designationId"
                          autoComplete="off"
                          defaultValue={""}
						  value={form.designationId}
						  onChange={e=>handleDesignationChange(e)}
                        >
                          <option value=""> --Select Designation--</option>
                          { designations?.length && designations.map((e) => (
							<option value={e.designationID}>{e.designationName}</option>
							))
						  }
                        </select>
                      </div>
                    </div>
                  </div>
                  <h6 className="roleNamePermissionShow">Permissions
                    <span className="roleNamePermission text-primary" />
                  </h6>
                  <div className="row mt-4 permissions_here">
                    {permissions?.length && permissions.map((c) => (
						<div key={c.permissionID} className="col-sm-12 col-md-6 col-lg-12">
							<div className="form-check form-switch">
								<input value={c.permissionID} 
								checked={amIChecked(c.permissionID)} onChange={()=>handleCheckbox(c.permissionID)}
								id={`idHere${c.permissionID}`} className="form-check-input" name="courses[]" type="checkbox" />
								<label htmlFor={`idHere${c.permissionID}`} className="form-check-label">{c.permissionName}</label>
							</div>
						</div>
					))}
                  </div>
                 <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
					<span className="btn-inner--icon"> <i className="material-icons">save</i></span>
					<span className="btn-inner--text">SAVE EDITS</span>
				</button>
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default EditStaff