import * as yup from "yup";

export const EventsCreateValidation = yup.object().shape({
	eventsFullName: yup.string().max(100,'Event Name should not exceed 100 characters').required('Event Name is required'),
	eventsDescription: yup.string().required('Event Description is required'),
	eventsAddress: yup.string().max(200,'Event Address cannot exceed 200 characters').required('Event Address field is required'),
	eventsLatitude: yup.string().max(100).required('Event Latitude field is required'),
	eventsLongitude: yup.string().max(100).required('Event Longitude field is required'),
	eventsCategory: yup.string().max(2).required('Event Category field is required'),
	eventsSubcategory: yup.string().max(2),
	bannerMainPicture:yup.string().required('Event Main Picture is required'),
	eventsWebsite: yup.string().nullable().notRequired().when('eventsWebsite', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.(\w+)\.(\w+).(\w+)?$/,'Website is not in acceptable format'),
   }),	
	
},
    [
        // Add Cyclic deps here because when in businessWebsite require itself
        ['eventsWebsite', 'eventsWebsite'],
    ]

);