import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate, useLocation } from "react-router-dom";
import { SubCategoryEditValidation } from "../../../utils/validations/SubCategoryEditValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const EditSubCategory = () => {
        const location = useLocation();
        const subcategoryID = location?.state?.id;
        const subcategoryName = location?.state?.name;
        const whichCategory = location?.state?.which;

        const defaultForm = {
                subcategoryID,
                subcategoryName
        };

        const [form, setForm] = useState(defaultForm);
        
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const {dispatchNotification} = useGlobalNotification();
        const {postOrGet}  = useAxios();

        useEffect(() => {
        },[]);

        const handleChange = (e) => {
                const name = e.target.name;
                const value = e.target.value;
                setForm(prev=>({ ...prev, [name]: value })); 
        };
        
        const handleSubmit = async (e) => {
                e.preventDefault();
                //validation
                SubCategoryEditValidation.validate(form, { abortEarly: false })
                .then(async (values)=>{
                    await postOrGet(`subcategory/edit/save/${whichCategory}`,'post',form)
                    .then((data) =>{
                        if(data){
                            dispatchNotification('success',"Operation completed successfully"); 
                        }
                    });   
                })
                .catch(err=>{
                    let message = "<p>You have the following errors:</p>";
                    message  += err.errors.join("<br/>");
                    dispatchNotification('error',message);
                });
            };
            
	
	return <AuthorizedAreas permission="CAN_SUB_CATEGORY_EDIT">
	<div className="card-body px-0 pt-0 pb-2">
					
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div style={{ padding: 20 }}>
				  <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
						<span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
						<span className="btn-inner--text">GO BACK</span>
					</button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h5>Edit Sub Category</h5>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="subcategoryName">Sub Category Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="subcategoryName"
                          id="subcategoryName"
                          className="form-control"
                          placeholder="Sub Category Name"
                          value={form.subcategoryName}
						  onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
                </div>

                  <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                    <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                    <span className="btn-inner--text">SAVE EDITS</span>
                  </button>
               </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default EditSubCategory