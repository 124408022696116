import useGlobalNotification from "./useGlobalNotification";
import useRefreshToken from "./useRefreshToken";
import axios from '../../api/axios';
import {CHANGE_LOADING_STATE} from "../../redux/slice/authSlice";
import { useDispatch, useSelector} from "react-redux";
import { useLocation} from "react-router-dom";


const useAxios = () => {
    const {accessToken} = useSelector((state)=>state.auth);
    const {dispatchNotification} = useGlobalNotification();
    const dispatch = useDispatch();
    const refresh = useRefreshToken();
    const location = useLocation();

    const postOrGet = async (url,method,values={},additionalHeaders={'content-type': 'application/json'}) =>{
        dispatch(CHANGE_LOADING_STATE(true));
        const options = { authorization: `Bearer ${accessToken}`,...additionalHeaders };
        try {
            const response = await axios({
                method,
                url,
                headers: options,
                 data: values
            });
            return response.data;
        } catch (err) {
            if(err?.response?.status == 403){
                //token is invalid
				//refresh token here
                 const newAccessToken = await refresh();
                 if(newAccessToken){
                    try{
                        const response = await axios({
                            method,
                            url,
                            headers: { authorization: `Bearer ${newAccessToken}` },
                            data: {...values}
                        });
                        return response.data;
                    }catch(err){
                        dispatchNotification('An error','Error occurred');  
                    }
                    finally{
                         dispatch(CHANGE_LOADING_STATE(false));
                    }
             }else{
                //dispatchNotification('error','An error occurred, Try again');
             }
			}
            else if(err?.response?.status == 402){
                dispatchNotification('error','You do not have permission to this resource');
            }
            else if (!err?.response) {
                dispatchNotification('error','No response from server');
			}else if (err.response.data?.error) {
                dispatchNotification('error',err.response.data.error);
			}else{
                //dispatchNotification('error','There was a general error');
			}
        }finally{
            dispatch(CHANGE_LOADING_STATE(false));
        }
       
		
    }
    
    return {postOrGet}
}

export default useAxios