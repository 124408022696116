import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { EventsEditValidation } from "../../../utils/validations/EventsEditValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import Datetime from "react-datetime";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
const imagePath = `${serverPath}/api/frontend/images/`;
const documentPath = `${serverPath}/api/frontend/docs/`;

  const ViewEventS = () => {
      const defaultForm = {
        eventsFullName: "",
        eventsDescription: "",
        eventStartDate: "",
        eventEndDate: "",
        eventStartTime: "",
        eventEndTime: "",
        eventsPhone: "",
        eventsWebsite: "",
        eventsAddress: "",
        eventsLatitude: "",
        eventsLongitude: "",
        eventsCategory: "",
        eventsSubcategory: "",
        bannerMainPicture: "",
        bannerOthersPicture:"",
        removeMainBanner: false,
        removeMainBannerName: "",
        removeOtherImages:[],
      };

      const location = useLocation();
      const id = location?.state?.id;
      const name = location?.state?.name;
      const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;

      const [form, setForm] = useState(defaultForm);
      const [categories,setCategories] = useState([]);
      const [subcategories,setSubcategories] = useState([]);
      const [status,setStatus] = useState('');

      const [mainImage,setMainImage] = useState("");
      const [additionalImages,setAdditionalImages] = useState([]);

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();
      const maxSize = 600;//KB

      const getInitializationData = async () =>{
        await postOrGet(`newevents/edit?id=${id}`,'get')
            .then((data) =>{
                    if(data){
                        setCategories(data.categories);
                        setSubcategories(data.subcategories);
                        let biz = data.events;
                        
                        setStatus(biz.eventsApprovedStatus);
                        delete biz.eventsApprovedStatus;
 
                        if(biz.bannerMainPicture){
                          setMainImage(biz.bannerMainPicture);
                        }
                        delete biz.bannerMainPicture;

                        if(biz.bannerOthersPicture){
                          setAdditionalImages(biz.bannerOthersPicture.split(","));
                        }
                        delete biz.bannerOthersPicture;
                        
                        setForm(prev=>({ ...prev,...biz}));
                    }
            }); 
      }

      useEffect(() => {
        getInitializationData();
      },[]);

	
	return <AuthorizedAreas permission="CAN_EVENTS_VIEW">
	      <div className="card-body px-0 pt-0 pb-2">
					
          <form>
            <div style={{ padding: 20 }}>
               <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                  <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                  <span className="btn-inner--text">GO BACK</span>
                </button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h6 className="text-primary">Event Information for <span className="text-bold text-primary">{name}</span></h6></div>
                <div className="card-body pt-0">

                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsFullName">Event Name</label>
                        <input
                          readOnly={true}
                          autoComplete="off"
                          type="text"
                          name="eventsFullName"
                          id="eventsFullName"
                          className="form-control"
                          placeholder="Enter Event Full Name"
                          value={form.eventsFullName}            						
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsAddress">Event Address</label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="eventsAddress"
                                  id="eventsAddress"
                                  className="form-control"
                                  placeholder=""
                                  value={form.eventsAddress}					
                                />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsPhone">Phone</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="eventsPhone"
                          id="eventsPhone"
                          className="form-control"
                          value={form.eventsPhone}
						               readOnly={true}					
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsWebsite">Website</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="eventsWebsite"
                          id="eventsWebsite"
                          className="form-control"
                          value={form.eventsWebsite}
						              readOnly={true}						
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">      
                            <h6 className="font-weight-bold text-primary">Dates</h6>
                            <div className="col-12">
                                <div className="row">

                                    <div className="col-6">
                                        <div className="input-group input-group-static">
                                            <label htmlFor="eventStartDate">Start Date</label>
                                            <Datetime
                                                readOnly={true}
                                                open={false}
                                                className="input-group input-group-static"
                                                closeOnSelect={true}
                                                dateFormat={"YYYY-MM-DD"}
                                                timeFormat={false}
                                                inputProps={{
                                                name: "eventStartDate",
                                                value: form.eventStartDate,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="input-group input-group-static">
                                            <label htmlFor="eventEndDate">End Date</label>
                                            <Datetime
                                                readOnly={true}
                                                open={false}
                                                className="input-group input-group-static"
                                                closeOnSelect={true}
                                                dateFormat={"YYYY-MM-DD"}
                                                timeFormat={false}
                                                inputProps={{
                                                name: "eventEndDate",
                                                value: form.eventEndDate,
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                  </div>  

                  <div className="row mt-4">      
                      <h6 className="font-weight-bold text-primary">Times</h6>
                      <div className="col-12">
                          <div className="row">

                              <div className="col-6">
                                  <div className="input-group input-group-static">
                                  <label htmlFor="eventStartTime">Start Time</label>
                                  <Datetime
                                      readOnly={true}
                                      open={false}
                                      className="input-group input-group-static"
                                      inputProps={{
                                      name: "eventStartTime",
                                      value: form.eventStartTime,
                                      }}
                                      dateFormat={false}
                                      timeFormat={"HH:mm"}
                                  />
                                  </div>
                              </div>

                              <div className="col-6">
                                  <div className="input-group input-group-static">
                                  <label htmlFor="eventEndTime">End Time</label>
                                  <Datetime
                                      readOnly={true}
                                      open={false}
                                      className="input-group input-group-static"
                                      inputProps={{
                                      name: "eventEndTime",
                                      value: form.eventEndTime,
                                      }}
                                      dateFormat={false}
                                      timeFormat={"HH:mm"}
                                  />
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div> 
                  
                  <div className="row mt-4">
                    {mainImage && 
                     <div className="col-12 text-center">
                          <img src={`${imagePath}${mainImage}`} alt="img-blur-shadow" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                     </div>}
                  </div>


                   <div className="row mt-4">

                    <div className="row">
                        {additionalImages.length > 0 && additionalImages.map(img=>
                          (
                          <div key={img} className="col-3 text-center">
                            <img src={`${imagePath}${img}`} alt="Additional Event Images" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                          </div>
                          ))}
                    </div>

                  </div>

                 
          
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsCategory">Event Category</label>
                        <select
                          className="form-control"
                          name="eventsCategory"
                          id="eventsCategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.eventsCategory}
                          readOnly={true}
                        >
                          { categories?.length && categories.map((e) => (
                              <option key={e.categoryName} value={e.categoryID}>{e.categoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsSubcategory">Event Sub Category</label>
                        <select
                          className="form-control"
                          name="eventsSubcategory"
                          id="eventsSubcategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.eventsSubcategory}
                          readOnly={true}	
                        >
                          <option value="">--Select Sub Category--</option>
                          { subcategories?.length && subcategories.map((e) => (
                              <option value={e.subcategoryID}>{e.subcategoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 mb-5">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsDescription">Event Description</label>
                        
                          <ReactQuill
                          readOnly={true}
                          style={{ width: '100%', height: '200px', marginBottom: '10px'}} 
                          theme="snow" name="eventsDescription" value={form.eventsDescription}  
                          />

                      </div>
                    </div>
                  </div>
     
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default ViewEventS