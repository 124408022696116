import * as yup from "yup";

export const BusinessEditValidation = yup.object().shape({
	businessID: yup.string().required('Business ID is required'),
	businessFullName: yup.string().max(100,'Business Name should not exceed 100 characters'),
	businessURLName: yup.string().max(100).required('Business URL Name is required'),
	businessDescription: yup.string().required('Business Description is required'),
	businessPhone: yup.string().max(16, 'Phone Number should not be more than 16 characters').required('Phone field is required'),
	businessWebsite: yup.string().nullable().notRequired().when('businessWebsite', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.([a-z0-9_-]+)\.([a-z0-9_-]+).(\w+)?$/,'Website is not in acceptable format'),
           	 		}),
	businessAddress: yup.string().max(200,'Business Address cannot exceed 200 characters').required('Business Address field is required'),
	businessLatitude: yup.string().max(100).required('Business Latitude field is required'),
	businessLongitude: yup.string().max(100).required('Business Longitude field is required'),
	businessCategory: yup.string().max(2).required('Business Category field is required'),
	businessSubcategory: yup.string().max(2),
	removeMainBanner: yup.boolean().required('Required field'),
	businessSocialMediaHandles: yup.object().shape({

		facebook: yup.string().nullable().notRequired().when('facebook', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.facebook.com\/(\w+)$/,'Facebook Handle is not in acceptable format'),
           	 		}),
		
		twitter: yup.string().nullable().notRequired().when('twitter', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.twitter.com\/(\w+)$/,'Twitter Handle is not in acceptable format'),
           	 		}),
		instagram: yup.string().nullable().notRequired().when('instagram', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.instagram.com\/(\w+)$/,'Instagram Handle is not in acceptable format'),
           	 		}),
	},
	[
        // Add Cyclic deps here because when in businessWebsite require itself
        ['facebook', 'facebook'],
        ['twitter', 'twitter'],
        ['instagram', 'instagram'],
    ]
	),
},
    [
        // Add Cyclic deps here because when in businessWebsite require itself
        ['businessWebsite', 'businessWebsite']
    ]);