import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

const MyTickets = () => {
	const defaultDetails = {
        loading:true,
        qty: 0,
        kidsQty: 0,
        userName:"",
        ticketCode:"",
        amount:0,
        kidsAmount:0,
        discount:0,
        datePurchased:"" 
    }
	const [ticketDetails, setTicketDetails] = useState(defaultDetails);
    const [allData, setAllData] = useState([]);
	const [showTicketModal,setShowTicketModal] = useState(false);
	const {postOrGet}  = useAxios();

	const loadMyTickets = async () => { 
		postOrGet(`newevents/tickets/mytickets`,'get').then((data) =>{
			if(data?.results){
				setAllData(data.results);
			}
		});
	}

	const handleFetchTicketDetails = async (pid) =>{
        if(pid){
            setTicketDetails(prev=>({ ...prev,...defaultDetails}));
            postOrGet(`newevents/tickets/specific/${pid}`,'get',{}).then((data) =>{
                if(data){
                    setTicketDetails(prev=>({ ...prev,loading:false,...data.ticketDetails[0]}));
                    setShowTicketModal(true);
                }
            });
        }
    }


	useEffect(()=>{
		//import('../../../assets/admin/js/material-dashboard-inside').then((mod) => {}).catch(err => {});
		loadMyTickets();
		window.scrollTo({top: 0, left: 0});
	},[]);

	return <div className="row mt-4 p-4">  
				<div className="col-12">

					<div className="card mb-4">
						
						<div className="card-header pb-0">
							<h6>My Tickets</h6>
						</div>
						

						<div className="card-body px-0 pt-0 pb-2">
							<AuthorizedAreas permission="CAN_MENU_MY_TICKETS">

							{
							allData.length > 0 ? 
								<div className="table-responsive p-0">
									<table className="table align-items-center mb-0">
										<thead>
											<tr>
												<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													S/N
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Ticket No.
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Event Details
												</th>
												
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Status
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Adults Qty/Amount
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Kids Qty/Amount
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Discount/Amount
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Total Amount
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Payment Confirmed
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Date Purchased
												</th>
												{/* <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th> */}
											</tr>
										</thead>
										<tbody>
											{
												allData.map((s, index) => (
													<tr key={`${s.purchasedID}`}>
														<td className="text-center">
															<p className="text-sm font-weight-normal mb-0">
																{index + 1}
															</p>
														</td>
														<td className="text-center">
															<Link to={"#"} onClick={()=>handleFetchTicketDetails(s.purchasedID)}>
															<p className="mb-0 text-sm">
																<button className="btn btn-sm btn-info"><i style={{ fontSize: "15px" }} class="material-icons-round">visibility</i>&nbsp;<br/>{s.ticketCode }</button>
															</p>
															</Link>
														</td>
														<td className="text-left">
															<div className="d-flex px-3 py-1">
																<div className="d-flex flex-column justify-content-center">
																	<Link
                                                                                        
																		to={"#"}
																		onClick={()=>handleFetchTicketDetails(s.purchasedID)}
																	>
																	<p className="mb-0 text-sm">
																		<h6>{s.eventsFullName }</h6>
																		<h6 style={{ fontWeight: '400' }}>{s.eventsAddress}</h6>
																		{moment(s.eventStartDate).format("LL") }  - {moment(s.eventEndDate).format("LL") }<br/>
																		{moment(s.eventStartTime, "HH:mm").format("HH:mm") } - {moment(s.eventEndTime, "HH:mm").format("HH:mm") }

																	</p>
																	</Link>
																</div>
															</div>
														</td>
														
														<td>
															<p className="text-sm font-weight-normal mb-0">
																{
																s.paymentConfirmed!=1 ?
																<span className="btn btn-sm btn-warning">----</span>:
																<span className={`btn btn-sm btn-${moment().diff(moment(s.eventEndDate),"days") > 0 ? "primary" : s.ticketUsed == 0 ? "success" : "primary"}`}>
																{moment().diff(moment(s.eventEndDate),"days") > 0 ? "EXPIRED" : s.ticketUsed == 0 ? "OPEN" : "USED"}
																</span>
																}
															</p>
														</td>
														<td className="text-center">
																<p className="mb-0 text-sm">
																	{s.qty > 0 ? `£ ${s.amount} (${s.qty})` : `----` }
																</p>
														</td>
														<td className="text-center">
																<p className="mb-0 text-sm">
																	{s.kidsQty > 0 ? `£ ${s.kidsAmount} (${s.kidsQty})` : `----` }
																</p>
														</td>
														
														<td className="text-center">
																<p className="mb-0 text-sm">
																	 &nbsp;{s.discount > 0 ? `(£${ (((s.qty * s.amount) + (s.kidsQty * s.kidsAmount)) * (s.discount/100)).toFixed(2)})` : "---" }
																</p>
														</td>
														<td className="text-center">
																<p className="mb-0 text-sm">
																	&pound; {
                                                            s.discount > 0 ? 
                                                            ((s.qty * s.amount) + (s.kidsQty * s.kidsAmount) - (((s.qty * s.amount) + (s.kidsQty * s.kidsAmount)) * (s.discount/100))).toFixed(2) : 
                                                            (s.qty * s.amount) + (s.kidsQty * s.kidsAmount)
                                                            }
																</p>
														</td>

														<td className="text-center">
																<span className={`btn btn-sm btn-${s.paymentConfirmed==1 ? "success" :  "warning"}`}>
																	{s.paymentConfirmed==1 ? "APPROVED" : "PENDING"}
																</span>
														</td>
														<td className="text-center">
															<p className="mb-0 text-sm ">
																	{moment(s.datePurchased).format("LL HH:mm") }
																</p>
														</td>
									
													</tr>
												))
											}
										</tbody>
									</table>
								</div> :
							<p className="p-3">No Purchased Tickets</p>
							}
							</AuthorizedAreas>
						</div>
					</div>
				</div>

				{/* View Ticket */}
            <Modal 
                show={showTicketModal} 
                style={{ zIndex: "99999999999999999999999999999" }}
                /* fullscreen={FontFaceSetLoadEvent} */
                size="xl"
                scrollable={true}
                centered
                backdrop="static"
                keyboard={false} 
                onHide={()=>setShowTicketModal(false)}>
                    <Modal.Body>

                            <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowTicketModal(false)} className="fa fa-circle-xmark"></i>

                            <div className="row">
                                <div className="col-12">
                                    <h5 className="title text-center" style={{ marginBottom: 0 }}> <i className="flaticon-filter" />&nbsp;Purchased Ticket Details</h5>
                                
                                    <div className="row">
                                        <div className="row p-5 col-12">

                                            <div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="ticketCode">Ticket Code</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="ticketCode"
                                                    id="ticketCode"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={ticketDetails.ticketCode}						
                                                    />
                                                </div>
                                            </div>

											<div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="qtyPurchased">Quantity Purchased</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="qtyPurchased"
                                                    id="qtyPurchased"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={parseInt(ticketDetails.qty) + parseInt(ticketDetails.kidsQty)}						
                                                    />
                                                </div>
                                            </div>

											<div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="datePurchased">Date Purchased</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="datePurchased"
                                                    id="datePurchased"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={moment(ticketDetails.datePurchased).format("LL HH:mm")}				
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="amount">Amount (Adult)</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="amount"
                                                    id="amount"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={`${ticketDetails.amount} x (${ticketDetails.qty})`}				
                                                    />
                                                </div>
                                            </div>

                                             <div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="kidsAmount">Amount (Kids)</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="kidsAmount"
                                                    id="kidsAmount"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={`${ticketDetails.kidsAmount} x (${ticketDetails.kidsQty})`}						
                                                    />
                                                </div>
                                            </div>

										{ticketDetails.discount > 0 ?
											<div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="discount">Discount</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="discount"
                                                    id="discount"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={`(${ (((ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount)) * (ticketDetails.discount/100)).toFixed(2)})`}			
                                                    />
                                                </div>
                                            </div> : null}

											<div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="totalAmount">Total Amount</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="totalAmount"
                                                    id="totalAmount"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={
                                                            ticketDetails.discount > 0 ? 
                                                            ((ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount) - (((ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount)) * (ticketDetails.discount/100))).toFixed(2) : 
                                                           (ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount)
                                                            }		
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            
                                </div>

                            </div>
                    </Modal.Body>
             </Modal>
		   </div>
}

export default MyTickets