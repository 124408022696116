import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/authSlice';


const store = configureStore({
	reducer: {
		auth: authReducer,
	},
	devTools: process.env.NODE_ENV == "production" ? false: true
});

export default store;

