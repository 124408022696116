import React,{useEffect} from "react";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingLines from "../src/assets/lottie/loading_lines.json";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
//import $ from 'jquery';
//import 'jquery/src/jquery';


//#region 
import FrontendLayout from './layout/frontend/FrontendLayout';
import Home from './pages/frontend/Home';
import AuthWall from './pages/frontend/AuthWall';
import Explore from './pages/frontend/Explore';
import Business from './pages/frontend/Business';
import Event from './pages/frontend/Event';
import AllEvents from './pages/frontend/AllEvents';
import Listing from './pages/frontend/Listing';
import Places from './pages/frontend/Places';
import Community from './pages/frontend/Community';
import NewFrontendEvent from './pages/frontend/NewFrontendEvent';
//#endregion

//#region AdminLayout
import AdminLayout from './layout/admin/AdminLayout';
import Login from './pages/admin/login/Login';
import Dashboard from './pages/admin/Dashboard';
import NotFound from './pages/admin/NotFound';
//#endregion

//#region Staff Management
import StaffManager from './pages/admin/staff/StaffManager';
import NewStaff from './pages/admin/staff/NewStaff';
import EditStaff from './pages/admin/staff/EditStaff';
import ViewStaff from './pages/admin/staff/ViewStaff';
//#endregion Staff Management

//#region User Management
import UserManager from './pages/admin/users/UserManager';
import ViewUser from './pages/admin/users/ViewUser';
//#endregion User Management

//#region Category Management
import CategoryManager from './pages/admin/categories/CategoryManager';
import NewCategory from './pages/admin/categories/NewCategory';
import EditCategory from './pages/admin/categories/EditCategory';
import EditSubCategory from './pages/admin/categories/EditSubCategory';
import NewSubCategory from './pages/admin/categories/NewSubCategory';
import SubCategoryManager from './pages/admin/categories/SubCategoryManager';
//#endregion Category Management

//#region Designation Management
import DesignationManager from './pages/admin/designation/DesignationManager';
import NewDesignation from './pages/admin/designation/NewDesignation';
import EditDesignation from './pages/admin/designation/EditDesignation';
import ViewDesignation from './pages/admin/designation/ViewDesignation';
//#endregion Designation Management

//#region Business Management
import BusinessManager from './pages/admin/business/BusinessManager';
import NewBusiness from './pages/admin/business/NewBusiness';
import EditBusiness from './pages/admin/business/EditBusiness';
import ViewBusiness from './pages/admin/business/ViewBusiness';
import BusinessReviewApprove from './pages/admin/business/BusinessReviewApprove';
import NewListing from './pages/admin/business/NewListing';
import EditListings from './pages/admin/business/EditListings';
import EditListing from './pages/admin/business/EditListing';
//#endregion Business Management

//#region Event/Deals Management
import EventManager from './pages/admin/business/EventManager';
import NewEvent from './pages/admin/business/NewEvent';
import EditEvent from './pages/admin/business/EditEvent';
//#endregion Event/Deals Management


//#region Deals Management
import DealManager from './pages/admin/business/DealManager';
import NewDeal from './pages/admin/business/NewDeal';
import EditDeal from './pages/admin/business/EditDeal';
//#endregion Deals Management

//#region Places Management
import PlacesManager from './pages/admin/places/PlacesManager';
import NewPlace from './pages/admin/places/NewPlace';
import ViewPlace from './pages/admin/places/ViewPlace';
import EditPlace from './pages/admin/places/EditPlace';
//#endregion

//#region Community Management
import CommunityManager from './pages/admin/community/CommunityManager';
import NewCommunity from './pages/admin/community/NewCommunity';
import ViewCommunity from './pages/admin/community/ViewCommunity';
import EditCommunity from './pages/admin/community/EditCommunity';
//#endregion

//#region Event Management
import EventsManager from './pages/admin/events/EventsManager';
import NewEventS from './pages/admin/events/NewEventS';
import ViewEventS from './pages/admin/events/ViewEventS';
import EditEventS from './pages/admin/events/EditEventS';
import TicketsManager from './pages/admin/events/TicketsManager';
//#endregion

//#region My Tickets
import MyTickets from './pages/admin/mytickets/MyTickets';
//#region End My Tickets

//#region My Tickets
import MyFollows from './pages/admin/myfollows/MyFollows';
//#region End My Tickets

import PrivateRoutes from "./utils/PrivateRoutes";
import PersistSession from "./utils/PersistSession";
import {SET_TRIGGER_ALERT} from "./redux/slice/authSlice";


function App() {

	const location = useLocation();

//window.jQuery = window.$ = $;
  const {isLoading,triggerAlert, triggerAlertType, triggerAlertMessage} = useSelector((state)=>state.auth);
	const dispatch = useDispatch();
	let clearIT;
	useEffect(()=>{
    if(triggerAlert == true){
      let Msg = () => {
        return (
          <>
           {parse(DOMPurify.sanitize(triggerAlertMessage))}
          </>
        );
      };

        if(triggerAlertType=='error'){
          toast.error(<Msg/>);
        }
          
        if(triggerAlertType=='success'){
          toast.success(<Msg/>);	
        }

        clearIT = setTimeout(()=>{
            dispatch(SET_TRIGGER_ALERT(false));
        },2000);
    }
    return ()=>{
      clearTimeout(clearIT);
    }
	},
	 [triggerAlert]
	);

	//autoscroll back to top
	useEffect(() => {
		if(document.querySelector("#anchorScrollToElement"))
		document.querySelector("#anchorScrollToElement").scrollIntoView();
	}, [location]);

    return (
			<>
			<ToastContainer position="top-center" limit={2} autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme="dark"/>
				<div className="preloader" style={{ display: isLoading ? "" : "none" }}>
					<Player
						autoplay
						loop
						style={{ height: "100vh", width: "400px", position: "relative" }}
						src={loadingLines}
					/>
				</div>
				<Routes>	
					<Route element={<FrontendLayout />}>
						<Route element={<PersistSession />} >
							<Route path="/" element={<Home />} />
							<Route path="/authwall" element={<AuthWall />} />
							<Route path="/authwall/reset/:resetToken" element={<AuthWall />} />
							<Route path="/deals" element={<Explore  />} />
							<Route path="/explore" element={<Explore  />} />
							<Route path="/explore/:cat" element={<Explore  />} />
							<Route path="/explore/:cat/:subcat" element={<Explore  />} />

							{/* places */}
							<Route path="/places/:cat" element={<Explore  />} />
							<Route path="/places/:cat/:subcat" element={<Explore  />} />
							{/* Actual Places Page */}
							<Route path="/place/:placeID" element={<Places  />} />

							{/* community */}
							<Route path="/community/:cat" element={<Explore  />} />
							<Route path="/community/:cat/:subcat" element={<Explore  />} />
							{/* Actual Community Page */}
							<Route path="/comm/:communityID" element={<Community  />} />

							{/* events */}
							<Route path="/events/:cat" element={<Explore  />} />
							<Route path="/events/:cat/:subcat" element={<Explore  />} />
							{/* Actual Event Page */}
							<Route path="/ev/:eventID" element={<NewFrontendEvent  />} />

							<Route path="/business/:name" element={<Business />} />
							<Route path="/business/event" element={<Event />} />
							<Route path="/business/listing" element={<Listing />} />
						</Route>
					</Route>			
					<Route path="/admin/login" element={<Login />} />
					<Route element={<PersistSession />} >
						<Route element={<PrivateRoutes />}>
							<Route path="admin" element={<AdminLayout />}>
								<Route path="dashboard" element={<Dashboard />} />

								<Route path="staff">
									<Route index element={<StaffManager />} />
									<Route path="new" element={<NewStaff />} />
									<Route path="edit" element={<EditStaff />} />
									<Route path="view" element={<ViewStaff />} />
								</Route>

								<Route path="users">
									<Route index element={<UserManager />} />
									<Route path="view" element={<ViewUser />} />
								</Route>

								<Route path="categories">
									<Route index element={<CategoryManager />} />
									<Route path="edit" element={<EditCategory />} />
									<Route path="view" element={<SubCategoryManager />} />
									<Route path="subedit" element={<EditSubCategory />} />
									<Route path="new" element={<NewCategory />} />
									<Route path="subcategory/new" element={<NewSubCategory />} />
								</Route>

								<Route path="designation">
									<Route index element={<DesignationManager />} />
									<Route path="new" element={<NewDesignation />} />
									<Route path="edit" element={<EditDesignation />} />
									<Route path="view" element={<ViewDesignation />} />
								</Route>

								<Route path="business">
									<Route index element={<BusinessManager />} />
									<Route path="new" element={<NewBusiness />} />
									<Route path="edit" element={<EditBusiness />} />
									<Route path="view" element={<ViewBusiness />} />
									<Route path="approve/single" element={<BusinessReviewApprove />} />
									{/* <Route path="new/event" element={<NewEvent />} /> */}
									<Route path="new/deal" element={<NewDeal />} />
									<Route path="deals/manage" element={<DealManager />} />
									<Route path="deals/manage/edit" element={<EditDeal />} />
									
									<Route path="new/listing" element={<NewListing />} />
									<Route path="edit/listings" element={<EditListings />} />
									<Route path="edit/listing" element={<EditListing />} />
								</Route>

								<Route path="places">
									<Route index element={<PlacesManager />} />
									<Route path="new" element={<NewPlace />} />
									<Route path="view" element={<ViewPlace />} />
									<Route path="edit" element={<EditPlace />} />
								</Route>

								<Route path="community">
									<Route index element={<CommunityManager />} />
									<Route path="new" element={<NewCommunity />} />
									<Route path="view" element={<ViewCommunity />} />
									<Route path="edit" element={<EditCommunity />} />
								</Route>

								<Route path="events">
									<Route index element={<EventsManager />} />
									<Route path="new" element={<NewEventS />} />
									<Route path="view" element={<ViewEventS />} />
									<Route path="edit" element={<EditEventS />} />
									<Route path="manage_ticket" element={<TicketsManager />} />
								</Route>

							
								<Route path="tickets" element={<MyTickets  />} />

								<Route path="follows" element={<MyFollows  />} />

								{/* <Route path="deals">
									<Route index element={<EventManager />} />
									<Route path="new" element={<NewEvent />} />
									<Route path="edit" element={<EditEvent />} />
								</Route> */}
		
							</Route>
						</Route>
					</Route>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</>
		);
}

export default App
