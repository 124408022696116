import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import moment from 'moment';

const DealManager = () => {
    const [allData, setAllData] = useState([]);
	const {postOrGet}  = useAxios();
    const location = useLocation();
    const id = location?.state?.id;
    const bizName = location?.state?.name;
    const navigate = useNavigate();

	const fetchDeals = async (id) => { 
		postOrGet(`events/view/${id}`,'get').then((data) =>{
			if(data?.results){
				setAllData(data.results);
			}
		});
	}

	const handleActivateDeactivate = async (id,action) => {
		postOrGet('event/activate/deactivate','post',{id,action}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.eventID === id ? {...data,eventActive: action} : data;
				});
				setAllData(newAllData);
			}
		});
	}


	useEffect(()=>{
		//import('../../../assets/admin/js/material-dashboard-inside').then((mod) => {}).catch(err => {});
		fetchDeals(id);
		window.scrollTo({top: 0, left: 0});
	},[]);

	return <div className="row mt-4 p-4">  
				<div className="col-12">

                    <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                        <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                        <span className="btn-inner--text">GO BACK</span>
                    </button>


					<div className="card mb-4">
						{bizName &&
						<div className="card-header pb-0">
							<h6>Deals for {bizName}</h6>
						</div>
						}

						<div className="card-body px-0 pt-0 pb-2">
							<AuthorizedAreas permission="CAN_DEALS_CREATE">

							{
							allData.length > 0 ? 
								<div className="table-responsive p-0">
									<table className="table align-items-center mb-0">
										<thead>
											<tr>
												<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													S/N
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Deal/Offer Name
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Start Day
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													End Day
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Start Time
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													End Time
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Status
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
											</tr>
										</thead>
										<tbody>
											{
												allData.map((s, index) => (
													<tr key={`${s.eventID}`}>
														<td className="text-center">
															<p className="text-sm font-weight-normal mb-0">
																{index + 1}
															</p>
														</td>
														<td>
															<div className="d-flex px-3 py-1">
																<div className="d-flex flex-column justify-content-center">
																	<p className="mb-0 text-sm">
																		{s.eventName }
																	</p>
																</div>
															</div>
														</td>
														<td>
																<p className="mb-0 text-sm">
																	{moment(s.eventStartDate).format("LL") }
																</p>
														</td>
														<td>
																<p className="mb-0 text-sm">
																	{moment(s.eventEndDate).format("LL") }
																</p>
														</td>
														<td>
																
																<p className="mb-0 text-sm">
																	{moment(s.eventStartTime, "HH:mm").format("HH:mm") }
																</p>
														</td>
														<td>
															<p className="mb-0 text-sm ">
																	{moment(s.eventEndTime, "HH:mm").format("HH:mm") }
																</p>
														</td>

														<td>
														
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.eventActive ? "success" : "primary"}`}>
																	{s.eventActive ? "ACTIVE" : "DISABLED"}
																	</span>
																</p>
														</td>
									
														<td className="align-middle text-center text-sm">
															<div className="btn-group dropstart">
																<button
																	className="btn btn-secondary dropdown-toggle"
																	type="button"
																	id="dropdownMenuButton2"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																>
																	Actions
																</button>
																<ul className="dropdown-menu dropdown-menu-dark">
															
																	<AuthorizedAreas permission="CAN_DEALS_EDIT">
																		<li>
																			<Link
																				className="dropdown-item"
																				to={`edit`}
																				state={{ 
																					id: s.eventID
																				}}
																			>
																				EDIT DEAL
																			</Link>
																		</li>
																	</AuthorizedAreas>

																	<AuthorizedAreas permission="CAN_DEALS_EDIT">
																		<li>
																			<Link
																				className="dropdown-item"
																				to="#"
																				onClick={()=>handleActivateDeactivate(s.eventID,s.eventActive ? false : true)}
																			>
																				{s.eventActive ? "DE-ACTIVATE" : "ACTIVATE"} DEAL
																			</Link>
																		</li>
																	</AuthorizedAreas>
																	
																</ul>
															</div>
														</td>
													</tr>
												))
											}
										</tbody>
									</table>
								</div> :
							<p className="p-3">No deals exists for {bizName}</p>
							}
							</AuthorizedAreas>
						</div>
					</div>
				</div>
		   </div>
}

export default DealManager