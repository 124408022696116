import * as yup from "yup";

export const EventCreateValidation = yup.object().shape({
	businessId: yup.number().required('Deals must belong to a business'),
	eventName: yup.string().max(200,'Offer Name should not exceed 200 characters').required('Offer Name is required'),
	eventDetails: yup.string().required('Offer details are required'),
	//eventAddress: yup.string().required('Event Address is required'),
	eventMainPicture:yup.string().required('Offer Main Image is required'),
	eventStartDate: yup.string().required('Start Date is required'),
	eventEndDate: yup.string().required('End Date is required'),
	eventStartTime: yup.string().required('Start Time is required'),
	eventEndTime: yup.string().required('End Time is required'),

}
);