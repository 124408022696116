import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import useGlobalNotification from '../../../utils/hooks/useGlobalNotification';
import { TicketSettingsCreateValidation } from "../../../utils/validations/TicketSettingsCreateValidation";

const TicketsManager = () => {
    const defaultSettings = {
        ticketEnabled: 0,
        ticketAmount: 0.0,
        kidsAmount: 0.0,
        ticketAvailableQty: 0,
        ticketDiscountPercentage: 0.0,
        ticketDiscountQuantity: 0
      };

      const defaultDetails = {
        loading:true,
        qty: 0,
        kidsQty: 0,
        userName:"",
        ticketCode:"",
        amount:0,
        kidsAmount:0,
        discount:0,
        datePurchased:"" 
    }
    const [loggedInUserID, setLoggedInUserID] = useState(null);
    const [ticketDetails, setTicketDetails] = useState(defaultDetails);
    const [ticketsSettings, setTicketsSettings] = useState(defaultSettings);
    const [ticketsSettingsFrontend, setTicketsSettingsFrontend] = useState(defaultSettings);
    const [totalTicketQtySold, setTotalTicketQtySold] = useState(0);
    const [totalTicketAmountSold, setTotalTicketAmountSold] = useState(0);
    const [tickets, setTickets] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const id = location?.state?.id;
    const endDate = location?.state?.endDate;
    const name = location?.state?.name;
	const {postOrGet}  = useAxios();
	
	const [showModal,setShowModal] = useState(false);
	const [showTicketModal,setShowTicketModal] = useState(false);
	const {dispatchNotification} = useGlobalNotification();


    const handleSubmit = async () => {
        TicketSettingsCreateValidation.validate(ticketsSettings, { abortEarly: false }).then(async (values)=>{
                    await postOrGet('newevents/tickets/settings/save/edit','post',{id,...ticketsSettings})
                    .then((data) =>{
                        if(data){
                            setTicketsSettingsFrontend(prev=>({ ...prev,...ticketsSettings}));
                            setShowModal(false);
                            dispatchNotification('success',"Operation completed successfully"); 
                        }
                    });   
                }).catch(err=>{
                    let message = "<p>You have the following errors:</p>";
                    message  += err?.errors.join("<br/>");
                    dispatchNotification('error',message);
        });
	}

    const handleFetchTicketDetails = async (pid) =>{
        if(pid){
            setTicketDetails(prev=>({ ...prev,...defaultDetails}));
            postOrGet(`newevents/tickets/specific/${pid}`,'get',{}).then((data) =>{
                if(data){
                    setTicketDetails(prev=>({ ...prev,loading:false,...data.ticketDetails[0]}));
                    setShowTicketModal(true);
                }
            });
        }
    }

    const markTicketAsUsed = async (pid) =>{
        if(pid){
            
            postOrGet(`newevents/tickets/mark/used/${pid}`,'get',{}).then((data) =>{

                const newAllData = tickets.map((data) =>{
					return data.purchasedID === pid ? {...data,ticketUsed: 1} : data;
				});
				setTickets(newAllData);
                dispatchNotification('success',"Ticket as been marked as used"); 
            });
        }
    }


	const loadData = async () => { 
		postOrGet(`newevents/tickets/all/${id}`,'get',{}).then((data) =>{
			if(data){
                setTicketsSettings(prev=>({ ...prev,...data.ticketsSettings[0]}));
                setTicketsSettingsFrontend(prev=>({ ...prev,...data.ticketsSettings[0]}));
                setTickets(data.tickets);
                setLoggedInUserID(data.userDetails.userId);
              
                setTotalTicketQtySold(data?.ticketsQty[0]?.totalSoldQty);
                setTotalTicketAmountSold(data?.ticketsSum[0]?.totalSoldAmt);
			}
		});
	}

	useEffect(()=>{
		window.scrollTo({top: 0, left: 0});
		loadData();	
	},[]);

	return <AuthorizedAreas permission="CAN_EVENTS_MANAGE_TICKETS">

        <div className="card-header pb-0">
            <h6>Tickets Manager for "{name}"</h6>
        </div>
                <div className="row mt-4 p-4">
                        <div className="col-12">

                            <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                                <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                                <span className="btn-inner--text">GO BACK</span>
                            </button>
                

                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={() => {
                                if(moment().diff(moment(endDate),"days") > 0)
                                   dispatchNotification('error',"Event has ended"); 
                                else    
                                    setShowModal(true);
                                }}  type="button" className="btn btn-icon btn-sm btn-warning">
                                <span className="btn-inner--icon"><i className="material-icons">tune</i></span>
                                <span className="btn-inner--text">CHANGE TICKETS SETTINGS</span>
                            </button>

                            <section>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 mb-4">
                                        <div className="card text-center">
                                            <div className="card-body">
                                            <p className="text-uppercase small mb-2">
                                                <strong>TICKET ENABLED <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="Can customers purchase tickets"></i> </strong>
                                            </p>
                                            <h5 className="mb-0">
                                                {
                                                moment().diff(moment(endDate),"days") > 0 ?
                                                <strong className="text-danger ms-2">EVENT ENDED</strong>
                                                :
                                                <strong className={` ${ticketsSettingsFrontend.ticketEnabled ? "text-success":"text-danger"} ms-2`}>{ticketsSettingsFrontend.ticketEnabled ? "ENABLED":"DISABLED"}</strong>
                                                }
                                            </h5>
                                            <hr />
                                            <p className="text-uppercase small mb-2">
                                                <strong>AVAILABLE QTY <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="How many tickets can customers purchase"></i></strong>
                                            </p>
                                            <h5 className="mb-0">
                                                <strong className="ms-2">{ticketsSettingsFrontend.ticketAvailableQty}</strong>
                                            </h5>
                                            </div>
                                        </div>  
                                    </div>

                                    <div className="col-lg-3 col-md-6 mb-4">
                                        <div className="card text-center">
                                            <div className="card-body">
                                            <p className="text-uppercase small mb-2">
                                                <strong>TICKET AMOUNT (ADULT) <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="Ticket Amount for Adults"></i></strong>
                                            </p>
                                            <h5 className="mb-0">
                                                <strong className="ms-2">£{ticketsSettingsFrontend.ticketAmount}</strong>
                                            </h5>
                                            <hr />
                                            <p className="text-uppercase small mb-2">
                                                <strong>TICKET AMOUNT (KIDS) <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="Ticket Amount for Kids"></i></strong>
                                            </p>
                                            <h5 className="mb-0">
                                                <strong className="ms-2">£{ticketsSettingsFrontend.kidsAmount}</strong>
                                            </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 mb-4">
                                        <div className="card text-center">
                                            <div className="card-body">
                                            <p className="text-uppercase small mb-2">
                                                <strong>DISCOUNT <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="Discount percentage on ticket"></i></strong>
                                            </p>
                                            <h5 className="mb-0">
                                                <strong className="ms-2">{ticketsSettingsFrontend.ticketDiscountPercentage}%</strong>
                                            </h5>
                                            <hr />
                                            <p className="text-uppercase small mb-2">
                                                <strong>MINIMUM DISCOUNT QTY <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="How many tickets for Discount"></i></strong>
                                            </p>
                                            <h5 className="mb-0">
                                                <strong className="ms-2">{ticketsSettingsFrontend.ticketDiscountQuantity}</strong>
                                            </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 mb-4">
                                        <div className="card text-center">
                                            <div className="card-body">
                                            <p className="text-uppercase small mb-2">
                                                <strong>TOTAL QTY SOLD <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="Discount percentage on ticket"></i></strong>
                                            </p>
                                            <h5 className="mb-0">
                                                <strong className="ms-2 text-success">{totalTicketQtySold || 0 }</strong>
                                            </h5>
                                            <hr />
                                            <p className="text-uppercase small mb-2">
                                                <strong>TOTAL AMOUNT SOLD <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="How many tickets for Discount"></i></strong>
                                            </p>
                                            <h5 className="mb-0">
                                                <strong className="ms-2 text-success">£{ totalTicketAmountSold ? parseFloat(totalTicketAmountSold).toFixed(2) : 0}</strong>
                                            </h5>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </section>

                            <div className="card mb-4">
                                <div className="card-header pb-0">
                                    <h6>Purchased Tickets</h6>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                   
                                        <div className="table-responsive p-0">
                                            <table className="table align-items-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            S/N
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            Ticket Code
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            Status
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            Adult Qty
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            Amount
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            Kids Qty
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                            Amount
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                            Date Purchased
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tickets.length > 0 ? (
                                                        tickets.map((s, index) => (
                                                            <tr key={`${s.purchasedID}`}>
                                                                <td className="text-center">
                                                                    <p className="text-sm font-weight-normal mb-0">
                                                                        {index + 1}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex px-3 py-1">
                                                    
                                                                        <div className="d-flex flex-column justify-content-center">
                                                                            <h6 className="mb-0 text-sm">
                                                                               <code>{s.ticketCode }</code> 
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <p className="text-sm font-weight-normal mb-0">
                                                                        <span className={`btn btn-sm btn-${moment().diff(moment(endDate),"days") > 0 ? "primary" : s.ticketUsed == 0 ? "success" : "primary"}`}>
                                                                        {moment().diff(moment(endDate),"days") > 0 ? "EXPIRED" : s.ticketUsed == 0 ? "OPEN" : "USED"}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex px-3 py-1">
                                                    
                                                                        <div className="d-flex flex-column justify-content-center">
                                                                            <h6 className="mb-0 text-sm">
                                                                                {s.qty }
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="d-flex px-3 py-1">
                                                    
                                                                        <div className="d-flex flex-column justify-content-center">
                                                                            <h6 className="mb-0 text-sm">
                                                                                {s.amount }
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="d-flex px-3 py-1">
                                                    
                                                                        <div className="d-flex flex-column justify-content-center">
                                                                            <h6 className="mb-0 text-sm">
                                                                                {s.kidsQty }
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="d-flex px-3 py-1">
                                                    
                                                                        <div className="d-flex flex-column justify-content-center">
                                                                            <h6 className="mb-0 text-sm">
                                                                                {s.kidsAmount }
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="d-flex px-3 py-1">
                                                                        <div className="d-flex flex-column justify-content-center">
                                                                            <h6 className="mb-0 text-sm">
                                                                                { moment(s.datePurchased).format('LLL') }
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle text-center text-sm">
                                                                    <div className="btn-group dropstart">
                                                                        <button
                                                                            className="btn btn-secondary dropdown-toggle"
                                                                            type="button"
                                                                            id="dropdownMenuButton2"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            Actions 
                                                                        </button>
                                                                        <ul className="dropdown-menu dropdown-menu-dark">
                                                                            <AuthorizedAreas permission="CAN_EVENTS_MANAGE_TICKETS">
                                                                                <li>
                                                                                    <Link
                                                                                        className="dropdown-item"
                                                                                        to={"#"}
                                                                                        onClick={()=>handleFetchTicketDetails(s.purchasedID)}
                                                                                    >
                                                                                        VIEW TICKET DETAILS
                                                                                    </Link>
                                                                                </li>
                                                                            </AuthorizedAreas>
                                                                            {loggedInUserID && loggedInUserID == s.userId && s.ticketUsed == 0 ?
                                                                            <AuthorizedAreas permission="CAN_EVENTS_TICKETS_MARKED_USED">
                                                                                <li>
                                                                                    <Link
                                                                                        className="dropdown-item"
                                                                                        to={"#"}
                                                                                        onClick={()=>markTicketAsUsed(s.purchasedID)}
                                                                                    >
                                                                                        MARK TICKET AS USED
                                                                                    </Link>
                                                                                </li>
                                                                            </AuthorizedAreas> :null}
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>		
            </div>

            {/* Ticket Settings */}
            <Modal 
                show={showModal} 
                style={{ zIndex: "999999999999999999999999999" }}
                /* fullscreen={FontFaceSetLoadEvent} */
                size="xl"
                scrollable={true}
                centered
                backdrop="static"
                keyboard={false} 
                onHide={()=>setShowModal(false)}>
               <Modal.Body>

                    <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowModal(false)} className="fa fa-circle-xmark"></i>

                    <div className="row">
                        <div className="col-12">
                            <h5 className="title text-center" style={{ marginBottom: 0 }}> <i className="flaticon-filter" /> Tickets Settings for Event</h5>
                        
        
                                <form>
                                    <div className="row">

                                        <div className="center">
                                            <div>
                                                <div className="form-check form-switch">
                                                <input value={id} 
                                                checked={ticketsSettings.ticketEnabled} 
                                                onChange={(e)=>{
                                                        const value = ticketsSettings.ticketEnabled==0? 1: 0;
                                                        setTicketsSettings(prev=>({ ...prev, ticketEnabled: value})); 
                                                }}
                                                id="ticketEnabled" className="form-check-input" name="ticketEnabled" type="checkbox" />
                                                <label htmlFor="ticketEnabled" className="form-check-label">Ticket is {setTicketsSettings.ticketEnabled == 0 ? "Disabled": "Enabled"}</label>
                                            </div> 
                                            </div>
                                            
                                        </div>

                                        <div className="row p-5">
                                            <div className="col-4">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="ticketAvailableQty">Ticket Quantity</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="ticketAvailableQty"
                                                    id="ticketAvailableQty"
                                                    className="form-control"
                                                    placeholder="Enter Ticket Quantity"
                                                    value={ticketsSettings.ticketAvailableQty}
                                                            onChange={(e)=>{
                                                            const name = e.target.name;
                                                            const value = e.target.value;
                                                            setTicketsSettings(prev=>({ ...prev, [name]: value})); 
                                                    }}						
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="ticketAmount">Adult Ticket Amount</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="ticketAmount"
                                                    id="ticketAmount"
                                                    className="form-control"
                                                    placeholder="Enter Ticket Amount"
                                                    value={ticketsSettings.ticketAmount}
                                                                onChange={(e)=>{
                                                                const name = e.target.name;
                                                                const value = e.target.value;
                                                                setTicketsSettings(prev=>({ ...prev, [name]: value})); 
                                                         }}						
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="kidsAmount">Kids Ticket Amount</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="kidsAmount"
                                                    id="kidsAmount"
                                                    className="form-control"
                                                    placeholder="Enter Kids Amount"
                                                    value={ticketsSettings.kidsAmount}
                                                                onChange={(e)=>{
                                                                const name = e.target.name;
                                                                const value = e.target.value;
                                                                setTicketsSettings(prev=>({ ...prev, [name]: value})); 
                                                         }}						
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row p-5">
                                            <div className="col-6">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="ticketDiscountPercentage">Discount Percent (%)</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="ticketDiscountPercentage"
                                                    id="ticketDiscountPercentage"
                                                    className="form-control"
                                                    placeholder="Enter Discount Percent e.g 15"
                                                    value={ticketsSettings.ticketDiscountPercentage}
                                                            onChange={(e)=>{
                                                            const name = e.target.name;
                                                            const value = e.target.value;
                                                            setTicketsSettings(prev=>({ ...prev, [name]: value})); 
                                                    }}						
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="ticketDiscountQuantity">How many tickets must be purchased to activate discount</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="ticketDiscountQuantity"
                                                    id="ticketDiscountQuantity"
                                                    className="form-control"
                                                    placeholder="Ticket Discount Quantity"
                                                    value={ticketsSettings.ticketDiscountQuantity}
                                                                onChange={(e)=>{
                                                                const name = e.target.name;
                                                                const value = e.target.value;
                                                                setTicketsSettings(prev=>({ ...prev, [name]: value})); 
                                                         }}						
                                                    />
                                                </div>
                                            </div>
                                        </div>


                    
                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button onClick={()=>handleSubmit()} type="button" className="default-btn">SAVE SETTINGS</button>
                                        </div>
                                    </div>
                                </form>
                                    
                            </div>

                    </div>
                     
              </Modal.Body>
           </Modal>

            {/* View Ticket */}
            <Modal 
                show={showTicketModal} 
                style={{ zIndex: "99999999999999999999999999999" }}
                /* fullscreen={FontFaceSetLoadEvent} */
                size="xl"
                scrollable={true}
                centered
                backdrop="static"
                keyboard={false} 
                onHide={()=>setShowTicketModal(false)}>
                    <Modal.Body>

                            <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowTicketModal(false)} className="fa fa-circle-xmark"></i>

                            <div className="row">
                                <div className="col-12">
                                    <h5 className="title text-center" style={{ marginBottom: 0 }}> <i className="flaticon-filter" />&nbsp;Purchased Ticket Details</h5>
                                
                                    <div className="row">

                                        <div className="row p-5 col-8">

                                            <div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="ticketCode">Ticket Code</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="ticketCode"
                                                    id="ticketCode"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={ticketDetails.ticketCode}						
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="userName">User</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="userName"
                                                    id="userName"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={ticketDetails.userName}						
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="amount">Amount (Adult)</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="amount"
                                                    id="amount"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={ticketDetails.amount}						
                                                    />
                                                </div>
                                            </div>

                                             <div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="kidsAmount">Amount (Kids)</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="kidsAmount"
                                                    id="kidsAmount"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={ticketDetails.kidsAmount}						
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 m-3">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="qty">Adult Quantity</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="qty"
                                                    id="qty"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={ticketDetails.qty}						
                                                    />
                                                </div>
                                            </div>

                                            {ticketDetails.kidsQty > 0?
                                                <div className="col-12 m-3">
                                                    <div className="input-group input-group-static">
                                                        <label htmlFor="kidsQty">Kids Quantity</label>
                                                        <input
                                                        autoComplete="off"
                                                        type="text"
                                                        name="kidsQty"
                                                        id="kidsQty"
                                                        className="form-control"
                                                        readOnly={true}
                                                        value={ticketDetails.kidsQty}						
                                                        />
                                                    </div>
                                                </div>
                                            :
                                            null}
                                        </div>


                                        <div className="col-4 p-5 ps-lg-4">
                                            <div className="row m-0">
                                                <div className="col-12 px-4">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <p className="textmuted">Qty</p>
                                                        <p className="fs-14 fw-bold">{parseInt(ticketDetails.qty) + parseInt(ticketDetails.kidsQty)}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <p className="textmuted">Subtotal</p>
                                                        <p className="fs-14 fw-bold">- £&nbsp;{ (ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount)}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <p className="textmuted">Discount</p>
                                                        <p className="fs-14 fw-bold text-danger">- £&nbsp;{ticketDetails.discount > 0 ? `(${ (((ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount)) * (ticketDetails.discount/100)).toFixed(2)})` : 0 }</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-3">
                                                        <p className="textmuted fw-bold">Total</p>
                                                        <div className="d-flex align-text-top">
                                                        £&nbsp;<span className="h4 text-success">
                                                            {
                                                            ticketDetails.discount > 0 ? 
                                                            ((ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount) - (((ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount)) * (ticketDetails.discount/100))).toFixed(2) : 
                                                            (ticketDetails.qty * ticketDetails.amount) + (ticketDetails.kidsQty * ticketDetails.kidsAmount)
                                                            }
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                            
                                </div>

                            </div>:    
                    </Modal.Body>
             </Modal>

           </AuthorizedAreas>

}

export default TicketsManager