import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate } from "react-router-dom";
import { PlacesCreateValidation } from "../../../utils/validations/PlacesCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import {
  GoogleMap,
	useJsApiLoader,
	MarkerF,
	Autocomplete
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-bootstrap/Modal';

const  modules  = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script:  "sub" }, { script:  "super" }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        [/* "link", */ "image", /* "video" */],
        ["clean"],
    ],
};


  const NewPlace = () => {
      const defaultForm = {
        placesFullName: "",
        placesDescription: "",
        placesAddress: "",
        placesLatitude: process.env.REACT_APP_DEFAULT_LAT,
        placesLongitude: process.env.REACT_APP_DEFAULT_LNG ,
        placesCategory: "",
        placesSubcategory: "",
        operatingHours: {
          monday : { 
            startTime : '',
            endTime : ''
          },
          tuesday : { 
            startTime : '',
            endTime : ''
          },
          wednesday : { 
            startTime : '',
            endTime : ''
          },
          thursday : { 
            startTime : '',
            endTime : ''
          },
          friday : { 
            startTime : '',
            endTime : ''
          },
          saturday : { 
            startTime : '',
            endTime : ''
          },
          sunday : { 
            startTime : '',
            endTime : ''
          }
        },
        placesPhone: "",
        placesWebsite: "",
        bannerMainPicture: "",
        bannerOthersPicture:""
      };
      const [form, setForm] = useState(defaultForm);
      const [showModal,setShowModal] = useState(false);
      const [categories,setCategories] = useState([]);
      const [subcategories,setSubcategories] = useState([]);

      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY);
      const [map, setMap] = useState(/** @type google.maps.Map */ (null));
      const [autocomplete, setAutocomplete] = useState(/** @type google.maps.Map.Autocomplete */ (null));
      const [autocompleteModal, setAutocompleteModal] = useState((null));
      const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: ["places"],
      region: "uk",
      });

      const placeHasChanged = () => {
          const res = autocomplete.getPlace();
          const lat = res?.geometry?.location?.lat?.();
          const lng = res?.geometry?.location?.lng?.();
          Geocode.fromLatLng(lat, lng).then(
              (response) => {
                 setForm(prev=>({ 
                    ...prev, 
                    'placesAddress': response.results[0].formatted_address ,
                    'placesLatitude': lat,
                    'placesLongitude': lng,
                  })); 
              },
              (error) => {
                  dispatchNotification('error',error);
              }
          ); 
    };

    const placeHasChangedModal = () => {
          const res = autocompleteModal.getPlace();
          const lat = res?.geometry?.location?.lat?.();
          const lng = res?.geometry?.location?.lng?.();
          Geocode.fromLatLng(lat, lng).then(
              (response) => {
                 setForm(prev=>({ 
                    ...prev, 
                    'placesAddress': response.results[0].formatted_address ,
                    'placesLatitude': lat,
                    'placesLongitude': lng,
                  })); 
              },
              (error) => {
                  dispatchNotification('error',error);
              }
          ); 
    };

      const mapIsClicked = (e) => {
      const lat = e?.latLng?.lat?.();
      const lng = e?.latLng?.lng?.();
      const newPosition = { lat, lng };
          Geocode.fromLatLng(lat, lng).then(
              (response) => {
                  setForm(prev=>({ 
                    ...prev, 
                    'placesAddress': response.results[0].formatted_address ,
                    'placesLatitude': lat,
                    'placesLongitude': lng,
                  }));
              },
              (error) => {
                  dispatchNotification('error',error);
              }
          ); 
      map.panTo(newPosition);
     };

      
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();
      const maxSize = 600;//KB

      const getInitializationData = async () =>{
      await postOrGet('places/fetch_creation_data','get')
          .then((data) =>{
                if(data){
                  setCategories(data.categories);
                }
          }); 
      }

      useEffect(() => {
        getInitializationData();
      },[]);

     

      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      };

      const handleCategoryChange = async (e) =>{
        if (e.target.value=="") return;
        handleChange(e);
        //dispatchNotification('success','Fetching associated subcategories...');
            await postOrGet(`places/fetch/subcategories?placesCategory=${e.target.value}`,'get',{})
                .then((data) =>{
                    if(data){
                      setSubcategories(data.subcategories);
                    }
            });
      }
      
      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            PlacesCreateValidation.validate(form, { abortEarly: false })
            .then(async (values)=>{
                const form_data = new FormData();
                for ( var key in form ) {
                      if(key=="bannerOthersPicture"  && form.bannerOthersPicture?.length){
                        let a=1;
                          Object.keys(form.bannerOthersPicture).forEach((key)=>{
                            form_data.append(`otherPictures${a}`, form.bannerOthersPicture.item(key))
                            a++;
                          }); 
                      }
                      else if(key=="operatingHours"){
                        form_data.append(key, JSON.stringify(form[key]));
                      }
                    else{
                        form_data.append(key, form[key]);
                      }
                }

                await postOrGet('places/create','post',form_data,{"Content-Type": "multipart/form-data" })
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Created Successfully."); 
                        setForm(defaultForm);
                       // setTimeout(() => navigate(-1),2000);
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
        };
      
      const handleBannerMainPictureUpload = (e) =>{
        /* if(e.target?.files[0]?.size/1024 > maxSize){
          e.target.value = null;
          dispatchNotification('error',`Banner Image should be less than ${maxSize}KB`);
        }
        else */
            setForm(prev=>({ ...prev, 'bannerMainPicture': e.target.files[0] }))
        
      }

      const handleMultipleImages = (e) =>{
        /* for(let key in e.target.files){
            if(e.target.files[key]?.size/1024 > maxSize){
              e.target.value = null;
              dispatchNotification('error',`Images cannot be more than ${maxSize}KB`);
              break;
            }
        } */
        setForm(prev=>({ ...prev, 'bannerOthersPicture': e.target.files }))
      }

	
	
	return <AuthorizedAreas permission="CAN_PLACES_CREATE">
        <Modal 
        show={showModal} 
        /* fullscreen={true} */
        size="xl"
        scrollable={false}
        centered
        backdrop="static"
        keyboard={false} 
        onHide={()=>setShowModal(false)}>
            <Modal.Header closeButton={false}> 
                <Modal.Title>Specify Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {
               isLoaded?
               <>
               <GoogleMap
                    mapContainerStyle={{
                        width: "100%",
                        height: "400px",
                        marginTop: "5px",
                    }}
                    center={{ lat : form.placesLatitude , lng: form.placesLongitude }}
                    zoom={18}
                    onLoad={(map) => setMap(map)}
                    onClick={(e) => mapIsClicked(e)}
                >
                    <MarkerF position={{ lat: form.placesLatitude , lng:form.placesLongitude  }} />
                </GoogleMap>  
                <button onClick={() =>setShowModal(false)}type="button" className="btn btn-icon btn-danger btn-lg w-100 mt-3">
                    <i className="flaticon-place" /> <span className="btn-inner--text">Use Location</span>
                </button>
                </>                  
               :
               null}
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="danger" onClick={()=>setShowModal(false)}>Close</Button> */}
            </Modal.Footer>
        </Modal>
	      <div className="card-body px-0 pt-0 pb-2">
					
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div style={{ padding: 20 }}>
               <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                  <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                  <span className="btn-inner--text">GO BACK</span>
                </button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h6 className="text-primary">Place Information</h6></div>
                <div className="card-body pt-0">

                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="placesFullName">Place Name</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="placesFullName"
                          id="placesFullName"
                          className="form-control"
                          placeholder="Enter Place Full Name"
                          value={form.placesFullName}
						              onChange={(e)=>{
                            const name = e.target.name;
                            const value = e.target.value;
                            setForm(prev=>({ ...prev, [name]: value })); 
                          }}						
                        />
                      </div>
                    </div>
                  </div>

                

                  {isLoaded && (
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="placesAddress">
                          Places Address&nbsp;&nbsp;
                           <span onClick={() =>setShowModal(true)} className="btn-outline-danger" style={{ cursor: 'pointer' }}>
                            <i className="flaticon-place" /> 
                                &nbsp;Click to use Map
                            </span>
                          </label>
                        <Autocomplete
                            onLoad={(a) => setAutocomplete(a)}
                            onPlaceChanged={(e) => placeHasChanged(e)}
                             className="form-control"
                                >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="placesAddress"
                                  id="placesAddress"
                                  className="form-control"
                                  placeholder="Search Places Address"
                                  value={form.placesAddress}
                                  onChange={(e)=>handleChange(e)}						
                                />
                        </Autocomplete>
                      </div>
                    </div>
                  </div>
                  )}

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="bannerMainPicture">Place Picture <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                        <input
                          autoComplete="off"
                          type="file"
                          name="bannerMainPicture"
                          id="bannerMainPicture"
                          className="form-control"
                          onChange={(e)=>handleBannerMainPictureUpload(e)}	
                          accept=".jpg, .jpeg, .png"			
                        />
                      </div>
                    </div>
                  </div>

                   <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="multipleImages">Additional Images. Hold CTR/CMD for Multiple <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                        <input
                          autoComplete="off"
                          type="file"
                          id="multipleImages"
                          className="form-control"
                          multiple={true}
                          onChange={(e)=>handleMultipleImages(e)}		
                          accept=".jpg, .jpeg, .png"		
                        />
                      </div>
                    </div>
                  </div>


                  <div className="row mt-4">
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="placesPhone">Phone No.</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="placesPhone"
                          id="placesPhone"
                          className="form-control"
                          placeholder="Enter Phone Number"
                          value={form.placesPhone}
						              onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="placesWebsite">Website <i style={{ cursor:"pointer" }} className="fa fa-circle-info"  data-bs-toggle="tooltip" data-bs-placement="top" title="Must begin with www"></i></label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="placesWebsite"
                          id="placesWebsite"
                          className="form-control"
                          placeholder="e.g www.website.com"
                          value={form.placesWebsite}
						              onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
                  </div>

                  
          
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="placesCategory">Place Category</label>
                        <select
                          className="form-control"
                          name="placesCategory"
                          id="placesCategory"
                          autoComplete="off"
                          value={form.placesCategory}
                          onChange={e=>handleCategoryChange(e)}
                        >
                          <option value=""> --Select Places Category--</option>
                          { categories?.length && categories.map((e) => (
                              <option key={e.categoryName} value={e.categoryID}>{e.categoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="placesSubcategory">Place Sub Category</label>
                        <select
                          className="form-control"
                          name="placesSubcategory"
                          id="placesSubcategory"
                          autoComplete="off"
                          value={form.placesSubcategory}
                          onChange={(e)=>handleChange(e)}	
                        >
                          <option value=""> --Select Sub Category--</option>
                          { subcategories?.length && subcategories.map((e) => (
                              <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 mb-5">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="placesDescription">Place Description</label>
                        {/* <textarea 
                          className="form-control" 
                          name="businessDescription"
                          id="businessDescription" 
                          rows="3"
                          onChange={(e)=>handleChange(e)}	
                          value={form.businessDescription}
                          />
                        */}
                          <ReactQuill
                          modules={modules}
                          style={{ width: '100%', height: '200px', marginBottom: '10px'}} 
                          theme="snow" name="placesDescription" value={form.placesDescription} 
                          onChange={(e)=>{
                            setForm(prev=>({ ...prev, 'placesDescription': e }))
                          }}	 
                          />
                      </div>
                    </div>
                  </div>

                  <div className="row dynamic_operating_times">      
                      <h6 className="font-weight-bold text-primary">Operating Days and Hours</h6>

                      <div className="col-6">
                        <h6 className="text-sm">Mondays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.monday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.monday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.monday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.monday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Tuesdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.tuesday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.tuesday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.tuesday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.tuesday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Wednesdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.wednesday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.wednesday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.wednesday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.wednesday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Thursdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.thursday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.thursday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.thursday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.thursday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Fridays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.friday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.friday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.friday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.friday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Saturdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.saturday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.saturday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.saturday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.saturday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Sundays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.sunday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.sunday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.sunday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.sunday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                  </div> 
     
                  
                 <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                  <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                  <span className="btn-inner--text">CREATE</span>
                </button>
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default NewPlace