import React, { useRef, useEffect} from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import timeLottie from "../../../assets/lottie/timemanagement.json";
import {loginSchema} from '../../../utils/validations/LoginValidation';
import { useFormik } from "formik";
//import "./login.scss";
import { useNavigate, useLocation } from "react-router-dom";
import {useDispatch} from "react-redux";
import {SET_ACTIVE_USER} from "../../../redux/slice/authSlice";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import "../../../assets/admin/css/material-dashboard.css";


const Login = () => {
	/* const [loginForm, setLoginForm] = useState({
		username: "",
		password: "",
	}); */
	const dispatch = useDispatch();
	const navigate = useNavigate();
	//const from = useLocation().state?.from?.path || "/dashboard";
	const location = useLocation();
	const status = location?.state?.status;
	const usernameRef = useRef(null);
	const {dispatchNotification} = useGlobalNotification();
	const {postOrGet}  = useAxios();
	
    useEffect(() => {
		usernameRef.current.focus();
		if(status && status == 'loggedOut'){
			dispatchNotification('success','You have logout successfully');
		}
		if(status && status == 'expired'){
			dispatchNotification('success','You session has expired. Please re-login');
		}	
		}, []);
	/* const handleChange = (e) => {
							const name = e.target.name;
							const value = e.target.value;
							setLoginForm({ ...loginForm, [name]: value }); 
						};*/
    /* const handleSubmit = async (e) => {
                                e.preventDefault();

                                //validation
                                const isValid = await loginSchema.isValid(loginForm);

                                //clear state and redirect
                                setLoginForm({ username: "", password: "" });
                            }; */

    const onSubmit = async (values,actions) =>{
		await postOrGet('auth/login','post',values)
		.then((data) =>{
			if(data){
				dispatch(SET_ACTIVE_USER(data));
				actions.resetForm();
				/* if(data.userType==1)
                        navigate("/");
                    else */
                        navigate("/admin/dashboard");
			}
		});
    }
	const { values, handleChange,isSubmitting, handleBlur,handleSubmit,errors } = useFormik({
		initialValues: {
			username: "",
			password: "",
		},
        validationSchema:loginSchema,
        onSubmit,
	});

	return (
		<main className="main-content  mt-0">
			<section>
				<div className="page-header min-vh-100">
					<div className="container">
						<div className="row">
							<div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
								<Player
									autoplay
									loop
									style={{ height: "100vh", width: "100%" }}
									src={timeLottie}
								/>
							</div>
							<div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
								<div className="card card-plain">
									<div className="card-header text-center">
										<h4 className="font-weight-bolder">
											Kadtech Business Listing Manager
										</h4>
										<p className="mb-0">
											Enter your email and password to sign in
										</p>
									</div>
									<div className="card-body mt-2">
										<form
											autoComplete="off"
											onSubmit={handleSubmit}
											role="form"
										>
											<div
												className={`input-group input-group-outline mb-3 ${
													errors.username ? "is-focused" : "is-valid"
												} `}
											>
												<label className="form-label">Email</label>
												<input
													disabled={isSubmitting}
													name="username"
													ref={usernameRef}
													value={values.username}
													onChange={handleChange}
													onBlur={handleBlur}
													type="text"
													className="form-control"
												/>
												{errors.username ? (
													<div className="invalid-feedback">
														{errors.username}
													</div>
												) : null}
											</div>
											<div
												className={`input-group input-group-outline mb-3 ${
													errors.password ? "is-focused" : "is-valid"
												} `}
											>
												<label className="form-label">Password</label>
												<input
													disabled={isSubmitting}
													name="password"
													value={values.password}
													onChange={handleChange}
													onBlur={handleBlur}
													type="password"
													className="form-control"
												/>
												{errors.password ? (
													<div className="invalid-feedback">
														{errors.password}
													</div>
												) : null}
											</div>
											{/* <div className="form-check form-switch d-flex align-items-center mb-3">
												<input
													disabled={isSubmitting}
													className="form-check-input"
													type="checkbox"
													id="rememberMe"
												/>
												<label
													className="form-check-label mb-0 ms-3"
													htmlFor="rememberMe"
												>
													Remember me
												</label>
											</div> */}
											<div className="text-center">
												<button
													disabled={isSubmitting}
													type="submit"
													className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0"
												>
													LOGIN
												</button>
											</div>
										</form>
									</div>
									<div className="card-footer text-center pt-0 px-lg-2 px-1">
										<p className="mb-4 text-sm mx-auto">
											© 2022 , Kadtech Business Listing
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default Login;
