import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate, useLocation } from "react-router-dom";
import { EventCreateValidation } from "../../../utils/validations/EventCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const  modules  = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script:  "sub" }, { script:  "super" }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        [/* "link", */ "image", /* "video" */],
        ["clean"],
    ],
};


const NewDeal = () => {
      
      const bizID = useLocation().state.id;
      const bizName = useLocation().state.name;


      const defaultForm = {
        businessId:bizID,
        eventName: "",
        eventDetails: "",
        //eventAddress: "",
        eventStartDate: "",
        eventEndDate: "",
        eventStartTime: "",
        eventEndTime: "",
        eventMainPicture: "",
        eventOthersPicture:""
      };
      const [form, setForm] = useState(defaultForm);

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();
      const maxSize = 600;//KB


      /* const loadAuthorizedBusinesses = async () => { 
		postOrGet(`events/initialize`,'get').then((data) =>{
			if(data?.results){
				setAllBusiness(data.results);
			}
		});
	 }
 */

      useEffect(() => {
        //loadAuthorizedBusinesses();
      },[]);

      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      };

      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            EventCreateValidation.validate(form, { abortEarly: false })
            .then(async (values)=>{
                const form_data = new FormData();
                for ( var key in form ) {
                      if(key=="eventOthersPicture"  && form.eventOthersPicture?.length){
                        let a=1;
                          Object.keys(form.eventOthersPicture).forEach((key)=>{
                            form_data.append(`otherPictures${a}`, form.eventOthersPicture.item(key))
                            a++;
                          }); 
                      }
                      else{
                        form_data.append(key, form[key]);
                      }
                }

                await postOrGet('event/create','post',form_data,{"Content-Type": "multipart/form-data" })
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Created Successfully."); 
                        setForm(defaultForm);
                       // setTimeout(() => navigate(-1),2000);
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
        };
      
      const handleBannerMainPictureUpload = (e) =>{
        /* if(e.target?.files[0]?.size/1024 > maxSize){
          e.target.value = null;
          dispatchNotification('error',`Banner Image should be less than ${maxSize}KB`);
        }
        else */
            setForm(prev=>({ ...prev, 'eventMainPicture': e.target.files[0] }))
        
      }

      const handleMultipleImages = (e) =>{
        /* for(let key in e.target.files){
            if(e.target.files[key]?.size/1024 > maxSize){
              e.target.value = null;
              dispatchNotification('error',`Images cannot be more than ${maxSize}KB`);
              break;
            }
        } */
        setForm(prev=>({ ...prev, 'eventOthersPicture': e.target.files }))
      }

	
	return <AuthorizedAreas permission="CAN_DEALS_CREATE">
            <div className="card-body px-0 pt-0 pb-2">	
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <div style={{ padding: 20 }}>
                        <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                            <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                            <span className="btn-inner--text">GO BACK</span>
                        </button>


                        {form.businessId &&
                        <div className="card mt-4" id="basic-info">
                            <div className="card-header">
                            <h6 className="text-primary">Create deal/offer for {bizName}</h6></div>
                            <div className="card-body pt-0">

                            <div className="row">
                                <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="eventName">Offer Name</label>
                                    <input
                                    autoComplete="off"
                                    type="text"
                                    name="eventName"
                                    id="eventName"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    value={form.eventName}
                                    onChange={(e)=>handleChange(e)}					
                                    />
                                </div>
                                </div>
                            </div>

                            <div className="row mt-4 mb-6">
                                <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="eventDetails">Details</label>
                                    <ReactQuill
                                    modules={modules}
                                    style={{ width: '100%', height: '200px', marginBottom: '10px'}} 
                                    theme="snow" name="eventDetails" value={form.eventDetails} 
                                    onChange={(e)=>{
                                        setForm(prev=>({ ...prev, 'eventDetails': e }))
                                    }}	 
                                    />
                                </div>
                                </div>
                            </div>

                            <div className="row mt-4 dynamic_operating_times">      
                                <h6 className="font-weight-bold text-primary">Dates</h6>
                                <div className="col-12">
                                    <div className="row">

                                        <div className="col-6">
                                            <div className="input-group input-group-static">
                                                <label htmlFor="">Start Date</label>
                                                <Datetime
                                                    autoComplete="off"
                                                    className="input-group input-group-static"
                                                    closeOnSelect={true}
                                                    dateFormat={"YYYY-MM-DD"}
                                                    timeFormat={false}
                                                    inputProps={{
                                                    name: "eventDay",
                                                    value: form.eventStartDate,
                                                    }}
                                                    onChange={(e) => {
                                                    const newDay = moment(new Date(e._d)).format(
                                                        "YYYY-MM-DD"
                                                    );
                                                    setForm(prev=>({ ...prev, 'eventStartDate':newDay})); 
                                                    }}
                                                    isValidDate={(current) =>
                                                        current.isAfter(moment().subtract(1, "day"))
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-static">
                                                <label htmlFor="businessSubcategory">End Date</label>
                                                <Datetime
                                                    autoComplete="off"
                                                    className="input-group input-group-static"
                                                    closeOnSelect={true}
                                                    dateFormat={"YYYY-MM-DD"}
                                                    timeFormat={false}
                                                    inputProps={{
                                                    name: "eventDay",
                                                    value: form.eventEndDate,
                                                    }}
                                                    onChange={(e) => {
                                                    const newDay2 = moment(new Date(e._d)).format(
                                                        "YYYY-MM-DD"
                                                    );
                                                    setForm(prev=>({ ...prev, 'eventEndDate':newDay2})); 
                                                    }}
                                                    isValidDate={(current) =>
                                                        current.isAfter(moment(form.eventStartDate))
                                                    }
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> 

                            <div className="row mt-4">      
                                <h6 className="font-weight-bold text-primary">Times</h6>
                                <div className="col-12">
                                    <div className="row">

                                        <div className="col-6">
                                            <div className="input-group input-group-static">
                                            <label htmlFor="businessSubcategory">Start Time</label>
                                            <Datetime
                                                autoComplete="off"
                                                className="input-group input-group-static"
                                                inputProps={{
                                                name: "eventStartTime",
                                                value: form.eventStartTime,
                                                }}
                                                onChange={(e) => {
                                                const newTime = moment(new Date(e._d)).format(
                                                    "HH:mm"
                                                );
                                                setForm(prev=>({ ...prev, "eventStartTime":newTime})); 
                                                }}
                                                dateFormat={false}
                                                timeFormat={"HH:mm"}
                                            />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-static">
                                            <label htmlFor="businessSubcategory">End Time</label>
                                            <Datetime
                                            autoComplete="off"
                                                className="input-group input-group-static"
                                                inputProps={{
                                                name: "eventEndTime",
                                                value: form.eventEndTime,
                                                }}
                                                onChange={(e) => {
                                                const newTime = moment(new Date(e._d)).format(
                                                    "HH:mm"
                                                );
                                                setForm(prev=>({ ...prev, "eventEndTime":newTime})); 
                                                }}
                                                dateFormat={false}
                                                timeFormat={"HH:mm"}
                                            />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>   

                            {/* <div className="row mt-4">
                                <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="eventAddress">Event Address</label>
                                    <textarea 
                                    className="form-control" 
                                    name="eventAddress"
                                    id="eventAddress" 
                                    rows="1"
                                    onChange={(e)=>handleChange(e)}	
                                    value={form.eventAddress}
                                    />
                                </div>
                                </div>
                            </div> */}


                            <div className="row mt-4">
                                <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="eventMainPicture">Offer Main Banner <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                                    <input
                                    autoComplete="off"
                                    type="file"
                                    name="eventMainPicture"
                                    id="eventMainPicture"
                                    className="form-control"
                                    onChange={(e)=>handleBannerMainPictureUpload(e)}	
                                    accept=".jpg, .jpeg, .png"			
                                    />
                                </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="multipleImages">Additional Images. Hold CTR/CMD for Multiple <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                                    <input
                                    autoComplete="off"
                                    type="file"
                                    id="multipleImages"
                                    className="form-control"
                                    multiple={true}
                                    onChange={(e)=>handleMultipleImages(e)}		
                                    accept=".jpg, .jpeg, .png"		
                                    />
                                </div>
                                </div>
                            </div>

                            
                            <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                            <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                            <span className="btn-inner--text">CREATE</span>
                            </button>
                            </div>
                        </div>
                        }

                    </div>
                </form>
            </div> 
		</AuthorizedAreas>;
}

export default NewDeal