import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { CommunityEditValidation } from "../../../utils/validations/CommunityEditValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import Datetime from "react-datetime";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
const imagePath = `${serverPath}/api/frontend/images/`;
const documentPath = `${serverPath}/api/frontend/docs/`;

  const ViewCommunity = () => {
      const defaultForm = {
        communityFullName: "",
        communityDescription: "",
        communityAddress: "",
        communityLatitude: "",
        communityLongitude: "",
        communityCategory: "",
        communitySubcategory: "",
        communityPhone: "",
        communityWebsite: "",
        bannerMainPicture: "",
        bannerOthersPicture:"",
        removeMainBanner: false,
        removeMainBannerName: "",
        operatingHours: {
          monday : { 
            startTime : '',
            endTime : ''
          },
          tuesday : { 
            startTime : '',
            endTime : ''
          },
          wednesday : { 
            startTime : '',
            endTime : ''
          },
          thursday : { 
            startTime : '',
            endTime : ''
          },
          friday : { 
            startTime : '',
            endTime : ''
          },
          saturday : { 
            startTime : '',
            endTime : ''
          },
          sunday : { 
            startTime : '',
            endTime : ''
          }
        },
        removeOtherImages:[],
      };

      const location = useLocation();
      const id = location?.state?.id;
      const name = location?.state?.name;
      const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;

      const [form, setForm] = useState(defaultForm);
      const [categories,setCategories] = useState([]);
      const [subcategories,setSubcategories] = useState([]);
      const [status,setStatus] = useState('');

      const [mainImage,setMainImage] = useState("");
      const [additionalImages,setAdditionalImages] = useState([]);

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();
      const maxSize = 600;//KB

      const getInitializationData = async () =>{
        await postOrGet(`community/edit?id=${id}`,'get')
            .then((data) =>{
                    if(data){
                        setCategories(data.categories);
                        setSubcategories(data.subcategories);
                        let biz = data.community;

                        biz.operatingHours = biz.communityOperatingTimes;
                        
                        setStatus(biz.communityApprovedStatus);
                        delete biz.communityApprovedStatus;

                        delete biz.communityOperatingTimes;
 
                        if(biz.bannerMainPicture){
                          setMainImage(biz.bannerMainPicture);
                        }
                        delete biz.bannerMainPicture;

                        if(biz.bannerOthersPicture){
                          setAdditionalImages(biz.bannerOthersPicture.split(","));
                        }
                        delete biz.bannerOthersPicture;
                        
                        setForm(prev=>({ ...prev,...biz}));
                    }
            }); 
      }

      useEffect(() => {
        getInitializationData();
      },[]);

	
	return <AuthorizedAreas permission="CAN_COMMUNITY_VIEW">
	      <div className="card-body px-0 pt-0 pb-2">
					
          <form>
            <div style={{ padding: 20 }}>
               <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                  <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                  <span className="btn-inner--text">GO BACK</span>
                </button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h6 className="text-primary">Community Information for <span className="text-bold text-primary">{name}</span></h6></div>
                <div className="card-body pt-0">

                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="communityFullName">Community Name</label>
                        <input
                          readOnly={true}
                          autoComplete="off"
                          type="text"
                          name="communityFullName"
                          id="communityFullName"
                          className="form-control"
                          placeholder="Enter Community Full Name"
                          value={form.communityFullName}            						
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="communityAddress">Community Address</label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="communityAddress"
                                  id="communityAddress"
                                  className="form-control"
                                  placeholder="Search Community Address"
                                  value={form.communityAddress}					
                                />
                      </div>
                    </div>
                  </div>


            <div className="row mt-4">
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="communityPhone">Phone</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="communityPhone"
                          id="communityPhone"
                          className="form-control"
                          value={form.communityPhone}
						              readOnly={true}					
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="communityWebsite">Website</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="communityWebsite"
                          id="communityWebsite"
                          className="form-control"
                          value={form.businessWebsite}
						 readOnly={true}						
                        />
                      </div>
                    </div>
                  </div>

                  
                  <div className="row mt-4">
                    {mainImage && 
                     <div className="col-12 text-center">
                          <img src={`${imagePath}${mainImage}`} alt="img-blur-shadow" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                     </div>}
                  </div>


                   <div className="row mt-4">

                    <div className="row">
                        {additionalImages.length > 0 && additionalImages.map(img=>
                          (
                          <div key={img} className="col-3 text-center">
                            <img src={`${imagePath}${img}`} alt="Additional Community Images" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                          </div>
                          ))}
                    </div>

                  </div>

                 
          
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="communityCategory">Community Category</label>
                        <select
                          className="form-control"
                          name="communityCategory"
                          id="communityCategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.communityCategory}
                          readOnly={true}
                        >
                          { categories?.length && categories.map((e) => (
                              <option key={e.categoryName} value={e.categoryID}>{e.categoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="communitySubcategory">Community Sub Category</label>
                        <select
                          className="form-control"
                          name="communitySubcategory"
                          id="communitySubcategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.communitySubcategory}
                          readOnly={true}	
                        >
                          <option value="">--Select Sub Category--</option>
                          { subcategories?.length && subcategories.map((e) => (
                              <option value={e.subcategoryID}>{e.subcategoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 mb-5">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="communityDescription">Community Description</label>
                        
                          <ReactQuill
                          readOnly={true}
                          style={{ width: '100%', height: '200px', marginBottom: '10px'}} 
                          theme="snow" name="communityDescription" value={form.communityDescription}  
                          />

                      </div>
                    </div>
                  </div>


                  <div className="row mt-4 dynamic_operating_times">      
                      <h6 className="font-weight-bold text-primary">Operating Days and Hours</h6>

                      <div className="col-6">
                        <h6 className="text-sm">Mondays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.monday?.startTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.monday?.endTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Tuesdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.tuesday?.startTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.tuesday?.endTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Wednesdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.wednesday?.startTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.wednesday?.endTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Thursdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.thursday?.startTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.thursday?.endTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Fridays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.friday?.startTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.friday?.endTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Saturdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.saturday?.startTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.saturday?.endTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Sundays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.sunday?.startTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                              readOnly={true}
                                      open={false}
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form?.operatingHours?.sunday?.endTime,
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                  </div>  
     
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default ViewCommunity