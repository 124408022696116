import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { BusinessEditValidation } from "../../../utils/validations/BusinessEditValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import {useJsApiLoader,Autocomplete} from "@react-google-maps/api";
import Geocode from "react-geocode";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./AdminBusiness.css";

const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
const imagePath = `${serverPath}/api/frontend/images/`;
const documentPath = `${serverPath}/api/docs/docs/`;

const  modules  = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script:  "sub" }, { script:  "super" }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        [/* "link", */ "image", /* "video" */],
        ["clean"],
    ],
};



  const EditBusiness = () => {
      const defaultForm = {
        businessFullName: "",
        businessURLName: "",
        businessDescription: "",
        businessPhone: "",
        businessWebsite: "",
        businessAddress: "",
        businessLatitude: "",
        businessLongitude: "",
        businessCategory: "",
        businessSubcategory: "",
        businessSocialMediaHandles:{
         'facebook' : '',
          'twitter' :  '',
          'instagram' : ''
        },
        operatingHours: {
          monday : { 
            startTime : '',
            endTime : ''
          },
          tuesday : { 
            startTime : '',
            endTime : ''
          },
          wednesday : { 
            startTime : '',
            endTime : ''
          },
          thursday : { 
            startTime : '',
            endTime : ''
          },
          friday : { 
            startTime : '',
            endTime : ''
          },
          saturday : { 
            startTime : '',
            endTime : ''
          },
          sunday : { 
            startTime : '',
            endTime : ''
          }
        },
        bannerMainPicture: "",
        bannerOthersPicture:"",
        businessRegistrationDocuments:"",
        removeMainBanner: false,
        removeMainBannerName: "",
        removeOtherImages:[],
        removeOtherDocuments:[],
      };
      const {userType} = useSelector(state=>state.auth);
      const location = useLocation();
      const id = location?.state?.id;
      const name = location?.state?.name;
      const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;

      const [form, setForm] = useState(defaultForm);
      const [categories,setCategories] = useState([]);
      const [subcategories,setSubcategories] = useState([]);
      const [status,setStatus] = useState('');

      const [mainImage,setMainImage] = useState("");
      const [additionalImages,setAdditionalImages] = useState([]);
      const [additionalDocuments,setAdditionalDocuments] = useState([]);

      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY);
      const [autocomplete, setAutocomplete] = useState(/** @type google.maps.Map.Autocomplete */ (null));
      const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: ["places"],
      region: "uk",
      });

      const placeHasChanged = () => {
          const res = autocomplete.getPlace();
          const lat = res?.geometry?.location?.lat?.();
          const lng = res?.geometry?.location?.lng?.();
          Geocode.fromLatLng(lat, lng).then(
              (response) => {
                 setForm(prev=>({ 
                    ...prev, 
                    'businessAddress': response.results[0].formatted_address ,
                    'businessLatitude': lat,
                    'businessLongitude': lng,
                  })); 
              },
              (error) => {
                  dispatchNotification('error',error);
              }
          ); 
      }

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();
      const maxSize = 600;//KB

      const getInitializationData = async () =>{
        await postOrGet(`business/edit?id=${id}`,'get')
            .then((data) =>{
                    if(data){
                        setCategories(data.categories);
                        setSubcategories(data.subcategories);
                        let biz = data.business;
                        biz.operatingHours = biz.businessOperatingTimes;
                        setStatus(biz.businessApprovedStatus);
                        delete biz.businessApprovedStatus;

                        delete biz.businessOperatingTimes;
                        if(biz.bannerMainPicture){
                          setMainImage(biz.bannerMainPicture);
                        }
                        delete biz.bannerMainPicture;

                        if(biz.bannerOthersPicture){
                          setAdditionalImages(biz.bannerOthersPicture.split(","));
                        }
                        delete biz.bannerOthersPicture;

                        if(biz.businessRegistrationDocuments){
                          setAdditionalDocuments(biz.businessRegistrationDocuments.split(","));
                        }
                        delete biz.businessRegistrationDocuments;
                        
                        setForm(prev=>({ ...prev,...biz}));
                    }
            }); 
      }

      useEffect(() => {
        getInitializationData();
      },[]);

      const slugify = (name) => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return name.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\-]+/g, '') // Remove all non-word characters
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
      }

      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      }

      const handleCategoryChange = async (e) =>{
        if (e.target.value=="") return;
        handleChange(e);
        dispatchNotification('success','Fetching associated subcategories...');
            await postOrGet(`business/fetch/subcategories?businessCategory=${e.target.value}`,'get',{})
                .then((data) =>{
                    if(data){
                      setSubcategories(data.subcategories);
                    }
            });
      }
      
      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            BusinessEditValidation.validate(form, { abortEarly: false })
            .then(async (values)=>{
                const form_data = new FormData();
                form_data.append('additionalImages', JSON.stringify(additionalImages));
                form_data.append('additionalDocuments', JSON.stringify(additionalDocuments));
                for ( var key in form ) {
                      if(key=="bannerOthersPicture"  && form.bannerOthersPicture?.length){
                        let a=1;
                          Object.keys(form.bannerOthersPicture).forEach((key)=>{
                            form_data.append(`otherPictures${a}`, form.bannerOthersPicture.item(key))
                            a++;
                          }); 
                      }
                      else if(key=="businessRegistrationDocuments" && form.businessRegistrationDocuments?.length){
                        let a=1;  
                        Object.keys(form.businessRegistrationDocuments).forEach((key)=>{
                          form_data.append(`documents${a}`, form.businessRegistrationDocuments.item(key))
                          a++;
                        });
                      }
                      else if(key=="operatingHours" || key=="removeOtherImages" || key=="businessSocialMediaHandles" || key == "removeOtherDocuments" ){
                        form_data.append(key, JSON.stringify(form[key]));
                      }
                    else{
                        form_data.append(key, form[key]);
                      }
                }

                await postOrGet('business/save/edit','post',form_data,{"Content-Type": "multipart/form-data" })
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Edits saved successfully."); 
                       // setForm(defaultForm);
                       // setTimeout(() => navigate(-1),2000); 
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
        }
      
      const handleBannerMainPictureUpload = (e) =>{
        /* if(e.target?.files[0]?.size/1024 > maxSize){
          e.target.value = null;
          dispatchNotification('error',`Banner Image should be less than ${maxSize}KB`);
        }
        else */
            setForm(prev=>({ ...prev, 'bannerMainPicture': e.target.files[0] })) 
      }

      const handleMultipleImages = (e) =>{
        /* for(let key in e.target.files){
            if(e.target.files[key]?.size/1024 > maxSize){
              e.target.value = null;
              dispatchNotification('error',`Images cannot be more than ${maxSize}KB`);
              break;
            }
        } */
        setForm(prev=>({ ...prev, 'bannerOthersPicture': e.target.files }))
      }

       const handleDocuments = (e) =>{
        setForm(prev=>({ ...prev, 'businessRegistrationDocuments': e.target.files }))
      }

      const approveBusiness = async () =>{
        await postOrGet('business/approve','post',{businessID : id})
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Business has been approved successfully"); 
                        setStatus(1);
                    }
                }); 
      }
	
	
	return <AuthorizedAreas permission="CAN_BUSINESS_EDIT">
	      <div className="card-body px-0 pt-0 pb-2">
					
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div style={{ padding: 20 }}>
              
               <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                  <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                  <span className="btn-inner--text">GO BACK</span>
                </button>
                
              <div className="card mt-4" id="basic-info">
                <div className="card-header">

              {status!=1 && userType == 3 &&
                <button type="button" onClick={()=>{approveBusiness()}} className="mt-5 btn btn-icon btn-success btn-lg w-100">
                  <span className="btn-inner--icon"> <i className="material-icons">verified</i></span>
                  <span className="btn-inner--text">Approve Business</span>
                </button>
                }

                  <h6 className="text-primary">Business Information for <span className="text-bold text-primary">{name}</span></h6></div>
                <div className="card-body pt-0">

                  {/* {status !=1 &&
                  <> */}
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group input-group-static">
                          <label htmlFor="businessFullName">Business Name</label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="businessFullName"
                            id="businessFullName"
                            className="form-control"
                            placeholder="Enter Business Full Name"
                            value={form.businessFullName}
                            onChange={(e)=>{
                              const name = e.target.name;
                              const value = e.target.value;
                              setForm(prev=>({ ...prev, [name]: value , 'businessURLName': slugify(value)})); 
                            }}						
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="input-group input-group-static">
                          <label htmlFor="businessURLName">Business URL</label>
                          <input
                            style={{ color: '#e67e22' }}
                            readOnly={true}
                            autoComplete="off"
                            type="text"
                            name="businessURLName"
                            id="businessURLName"
                            className="form-control"
                            value={form.businessURLName}					
                          />
                        </div>
                      </div>
                    </div>
                  {/* </>
                  } */}

                  {isLoaded && (
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessAddress">Business Address</label>
                        <Autocomplete
                            onLoad={(a) => setAutocomplete(a)}
                            onPlaceChanged={(e) => placeHasChanged(e)}
                             className="form-control"
                                >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="businessAddress"
                                  id="businessAddress"
                                  className="form-control"
                                  placeholder="Search Business Address"
                                  value={form.businessAddress}
                                  onChange={(e)=>handleChange(e)}						
                                />
                        </Autocomplete>
                      </div>
                    </div>
                  </div>
                  )}

                 
                  <div className="row mt-4">
                    {mainImage && 
                     <div className="col-12 text-center">
                          <img src={`${imagePath}${mainImage}`} alt="img-blur-shadow" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                          <br/>
                          <button onClick={()=>{
                            setMainImage("");
                            setForm(prev=>({ ...prev, 'removeMainBanner': true , 'removeMainBannerName' : mainImage }))
                          }} className="btn btn btn-primary mt-2">Remove</button>
                     </div>}

                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="bannerMainPicture">Banner Picture <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                        <input
                          autoComplete="off"
                          type="file"
                          name="bannerMainPicture"
                          id="bannerMainPicture"
                          className="form-control"
                          onChange={(e)=>handleBannerMainPictureUpload(e)}	
                          accept=".jpg, .jpeg, .png"			
                        />
                      </div>
                    </div>
                  </div>


                   <div className="row mt-4">

                    <div className="row">
                        {additionalImages.length > 0 && additionalImages.map(img=>
                          (
                          <div key={img} className="col-sm-12 col-md-6 col-lg-3 text-center">
                            <img src={`${imagePath}${img}`} alt="Additional Business Images" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                            <button onClick={()=>{
                                          const newIms = additionalImages.filter(im=>im!=img);
                                          setAdditionalImages(newIms)
                                          setForm(prev=>({ ...prev, 'removeOtherImages': [...prev.removeOtherImages,img] }))
                                        }} 
                          className="btn btn btn-primary mt-2">Remove</button>
                          </div>
                          ))}
                    </div>

                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="multipleImages">Additional Images <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                        <input
                          autoComplete="off"
                          type="file"
                          id="multipleImages"
                          className="form-control"
                          multiple={true}
                          onChange={(e)=>handleMultipleImages(e)}		
                          accept=".jpg, .jpeg, .png"		
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessFullName">Business Phone</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="businessPhone"
                          id="businessPhone"
                          className="form-control"
                          placeholder="Enter Business Phone Number"
                          value={form.businessPhone}
						              onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessWebsite">Business Website</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="businessWebsite"
                          id="businessWebsite"
                          className="form-control"
                          placeholder="e.g www.business.com"
                          value={form.businessWebsite}
						              onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
                  </div>
          
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessCategory">Business Category</label>
                        <select
                          className="form-control"
                          name="businessCategory"
                          id="businessCategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.businessCategory}
                          onChange={e=>handleCategoryChange(e)}
                        >
                          <option value=""> --Select Business Category--</option>
                          { categories?.length && categories.map((e) => (
                              <option value={e.categoryID}>{e.categoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessSubcategory">Business Sub Category</label>
                        <select
                          className="form-control"
                          name="businessSubcategory"
                          id="businessSubcategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.businessSubcategory}
                          onChange={(e)=>handleChange(e)}	
                        >
                          <option value=""> --Select Sub Category--</option>
                          { subcategories?.length && subcategories.map((e) => (
                              <option value={e.subcategoryID}>{e.subcategoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 mb-5">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessDescription">Business Description</label>
                        {/* <textarea 
                          className="form-control" 
                          name="businessDescription"
                          id="businessDescription" 
                          rows="3"
                          onChange={(e)=>handleChange(e)}	
                          value={form.businessDescription}
                          /> */}

                          <ReactQuill
                          modules={modules}
                          style={{ width: '100%', height: '200px', marginBottom: '10px'}} 
                          theme="snow" name="businessDescription" value={form.businessDescription} 
                          onChange={(e)=>{
                            setForm(prev=>({ ...prev, 'businessDescription': e }))
                          }}	 
                          />

                      </div>
                    </div>
                  </div>

                 
                  <div className="row dynamic_operating_times">      
                      <h6 className="font-weight-bold text-primary">Operating Days and Hours</h6>

                      <div className="col-6">
                        <h6 className="text-sm">Mondays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.monday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.monday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.monday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.monday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Tuesdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.tuesday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.tuesday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.tuesday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.tuesday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Wednesdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.wednesday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.wednesday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.wednesday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.wednesday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Thursdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.thursday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.thursday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.thursday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.thursday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Fridays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.friday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.friday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.friday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.friday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Saturdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.saturday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.saturday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.saturday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.saturday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Sundays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.sunday.startTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.sunday.startTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.sunday.endTime,
                                }}
                                onChange={(e) => {
                                  const newTime = moment(new Date(e._d)).format(
                                    "HH:mm"
                                  );
                                  const operatingHours = form.operatingHours;
                                  operatingHours.sunday.endTime = newTime;
                                  setForm(prev=>({ ...prev, operatingHours})); 
                                }}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                  </div>  

                  <div className="row mt-4">      
                     
                          <h5 className="font-weight-bolder text-primary">Socials</h5>
                          
                          <div className="col-4"> 
                            <div className="input-group input-group-static">
                              <label>Facebook Account</label>
                              <input 
                              name="businessFacebook"
                              type="text" 
                              className="form-control" 
                              placeholder="e.g www.facebook.com/handle"
                              value={form.businessSocialMediaHandles.facebook}
                              onChange={(e)=>{
                                  const businessSocialMediaHandles = form.businessSocialMediaHandles;
                                  businessSocialMediaHandles.facebook = e.target.value;
                                  setForm(prev=>({ ...prev, businessSocialMediaHandles}));
                              }}	
                              />
                            </div>
                          </div>

                          <div className="col-4"> 
                            <div className="input-group input-group-static">
                              <label>Instagram Account</label>
                              <input 
                              name="businessInstagram"
                              type="text" 
                              className="form-control" 
                              placeholder="e.g www.instagram.com/handle"
                              value={form.businessSocialMediaHandles.instagram}
                              onChange={(e)=>{
                                const businessSocialMediaHandles = form.businessSocialMediaHandles;
                                  businessSocialMediaHandles.instagram = e.target.value;
                                  setForm(prev=>({ ...prev, businessSocialMediaHandles}));
                              }}	
                              />
                            </div> 
                          </div>

                          <div className="col-4"> 
                            <div className="input-group input-group-static">
                              <label>Twitter Account</label>
                              <input 
                              name="businessTwitter"
                              type="text" 
                              className="form-control" 
                              placeholder="e.g www.twitter.com/handle"
                              value={form.businessSocialMediaHandles.twitter}
                              onChange={(e)=>{
                                  const businessSocialMediaHandles = form.businessSocialMediaHandles;
                                  businessSocialMediaHandles.twitter = e.target.value;
                                  setForm(prev=>({ ...prev, businessSocialMediaHandles}));
                              }}	
                              />
                            </div>
                          </div>
                    
                  </div>  


                  {
                  status != 1 && 
                  <div className="row mt-4">      
                          <h5 className="font-weight-bolder text-primary">Supporting Documents</h5>
                         
                         {additionalDocuments.length > 0 &&
                            <div className="table-responsive">
                              <table className="table align-items-center mb-0">
                                <thead>
                                  <tr>
                                    <th style={{ width: '10px' }} className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">S/N</th>
                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Document</th>
                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Action</th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>

                                {
                                additionalDocuments.map((document,index)=>
                                  (
                                  <tr key={document}>
                                    <td style={{ width: '10px' }} className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                      {index+1}
                                    </td>
                                    <td>
                                      <div className="text-center">
                                        <div className="my-auto">
                                          <a href={`${documentPath}${document}`} 
                                          rel="noreferrer"
                                          target="_blank"><h6 className="mb-0 text-xs text-decoration-underline">Document {index+1}</h6></a>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <button type="button"
                                      onClick={()=>{
                                          const newIms = additionalDocuments.filter(doc=>doc!=document);
                                          setAdditionalDocuments(newIms)
                                          setForm(prev=>({ ...prev, 'removeOtherDocuments': [...prev.removeOtherDocuments,document] }))
                                        }}
                                      className="btn btn-sm btn-primary">DELETE</button>
                                    </td>
                                    
                                  </tr>)
                                  )}

                                </tbody>
                              </table>
                            </div>}
                  
                          <div className="col-12 mt-2"> 
                            <div className="input-group input-group-static">
                              <label>Business Documents</label>
                              <input
                              autoComplete="off"
                              type="file"
                              id="multipleDocuments"
                              className="form-control"
                              multiple={true}
                              onChange={(e)=>handleDocuments(e)}		
                              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"		
                            />
                            </div>
                          </div>
                    
                  </div> 
                  }      
                  
                 <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                  <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                  <span className="btn-inner--text">SAVE EDITS</span>
                </button>
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default EditBusiness