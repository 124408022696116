import * as yup from "yup";

export const PlacesCreateValidation = yup.object().shape({
	placesFullName: yup.string().max(100,'Place Name should not exceed 100 characters').required('Place Name is required'),
	placesDescription: yup.string().required('Place Description is required'),
	placesAddress: yup.string().max(200,'Place Address cannot exceed 200 characters').required('Place Address field is required'),
	placesLatitude: yup.string().max(100).required('Place Latitude field is required'),
	placesLongitude: yup.string().max(100).required('Place Longitude field is required'),
	placesCategory: yup.string().max(2).required('Place Category field is required'),
	placesSubcategory: yup.string().max(2),
	bannerMainPicture:yup.string().required('Place Main Picture is required'),
	placesWebsite: yup.string().nullable().notRequired().when('placesWebsite', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.(\w+)\.(\w+).(\w+)?$/,'Website is not in acceptable format'),
   }),
	

},
    [
        // Add Cyclic deps here because when in businessWebsite require itself
        ['placesWebsite', 'placesWebsite'],
    ]

);