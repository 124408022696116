import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CategoryCreateValidation } from "../../../utils/validations/CategoryCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const NewCategory = () => {
      const location = useLocation();
      const whichCategory = location?.state?.which;
      const defaultForm = { categoryName: "" };
      const [form, setForm] = useState(defaultForm);
      
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();

      /* const getInitializationData = async () =>{
        
          await postOrGet('staff/fetch_creation_data','get',{})
              .then((data) =>{
                if(data){
                  setPermissions(data.permissions);
                  setDesignations(data.designations);
                }
          }); 
      } */

      useEffect(() => {
        /* getInitializationData(); */
      },[]);
      
      /* const amIChecked = (id) =>{ return staffPrivileges.includes(id)} */

      /* const handleCheckbox = (id) =>{
        if(staffPrivileges.includes(id))
          setStaffPrivileges(prev=>(prev.filter(e=>e!=id)));
        else
          setStaffPrivileges(prev=>([...prev,id]));	
      } */

      /* const handleDesignationChange = async (e) =>{
        if (e.target.value=="") return;

        handleChange(e);
        dispatchNotification('success','Fetching associated privileges...');
            await postOrGet('staff/fetch/role/related/permissions','post',{designationId:e.target.value})
                .then((data) =>{
                    if(data){
                        const obj = JSON.parse(data?.result);
              if(obj?.permissions?.rights?.length){
                setStaffPrivileges(obj.permissions.rights);
              }
                    }
            });
      } */
      
      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      }; 
      
      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            CategoryCreateValidation.validate(form, { abortEarly: false })
            .then(async (values)=>{
                await postOrGet(`category/create/${whichCategory}`,'post',{...values})
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Created successfully"); 
                        setForm(defaultForm);
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
        };
      
	
      return <AuthorizedAreas permission="CAN_CATEGORY_CREATE">
      <div className="card-body px-0 pt-0 pb-2">
              
              <form onSubmit={(e)=>handleSubmit(e)}>
                <div style={{ padding: 20 }}>
            <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                <span className="btn-inner--text">GO BACK</span>
              </button>
                  <div className="card mt-4" id="basic-info">
                    <div className="card-header">
                      <h5>New {whichCategory} Category</h5>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row">
                        <div className="col-12">
                          <div className="input-group input-group-static">
                            <label htmlFor="categoryName">Category Name</label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="categoryName"
                              id="categoryName"
                              className="form-control"
                              placeholder="Enter Name"
                              value={form.categoryName}
                              onChange={(e)=>handleChange(e)}						
                            />
                          </div>
                        </div>
                    </div>
            
                    <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                        <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                        <span className="btn-inner--text">CREATE</span>
                    </button>
                    </div>
                  </div>
                </div>
              </form>
            </div> 
        </AuthorizedAreas>;
}

export default NewCategory