import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";

const StaffManager = () => {
    const [allStaff, setAllStaff] = useState([]);
	const {postOrGet}  = useAxios();

	const handleActivateDeactivate = async (uuid,action) => {
		postOrGet('staff/activate/deactivate','post',{uuid,action}).then((data) =>{
			if(data){
				const newAllStaff = allStaff.map((staff) =>{
					return staff.uuid === uuid ? {...staff,active: action} : staff;
				});
				setAllStaff(newAllStaff);
			}
		});
	}

	const loadData = async () => {
		postOrGet('staff/view','get',{}).then((data) =>{
			if(data?.results){
				setAllStaff(data.results);
			}
		});
	}

	useEffect(()=>{
		//import('../../../assets/admin/js/material-dashboard-inside').then((mod) => {}).catch(err => {});
		loadData();	
	},[]);

	return <div className="row mt-4 p-4">
					<div className="col-12">
						<AuthorizedAreas permission="CAN_STAFF_CREATE">
							<Link to="new">
								<button type="button" className="btn btn-icon btn-info">
									<span className="btn-inner--icon">
										<i className="material-icons">add_box</i>
									</span>
									<span className="btn-inner--text">CREATE STAFF</span>
								</button>
							</Link>
						</AuthorizedAreas>

						<div className="card mb-4">
							<div className="card-header pb-0">
								<h6>Staff Manager</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_MENU_STAFF_MANAGE">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														S/N
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Staff
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Designation
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Status
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allStaff.length > 0 ? (
													allStaff.map((s, index) => (
														<tr key={`${s.uuid}`}>
															<td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
																	<div>
																		{/* <img
																			src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/blue-shoe.jpg"
																			className="avatar me-3"
																			alt="image"
																		/> */}
																	</div>
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{
																				s.firstName +
																				" " +
																				s.lastName}
																		</h6>
																	</div>
																</div>
															</td>
															<td>
																<p className="text-sm font-weight-normal mb-0">
																	{s.designationName}
																</p>
															</td>
															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.active ? "success" : "primary"}`}>
																	{s.active ? "ACTIVE" : "DISABLED"}
																</span>
																</p>
															</td>
															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		<AuthorizedAreas permission="CAN_STAFF_VIEW">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`view`}
																					state={{ uuid: s.uuid }}
																				>
																					VIEW STAFF
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		<AuthorizedAreas permission="CAN_STAFF_EDIT">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`edit`}
																					state={{ uuid: s.uuid }}
																				>
																					EDIT STAFF
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		<AuthorizedAreas permission="CAN_STAFF_ACTIVATE_DEACTIVATE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleActivateDeactivate(s.uuid,s.active ? false : true)}
																				>
																					{s.active ? "DE-ACTIVATE" : "ACTIVATE"} STAFF
																				</Link>
																			</li>
																		</AuthorizedAreas>
																	</ul>
																</div>
															</td>
														</tr>
													))
												) : null}
											</tbody>
										</table>
									</div>
								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>

}

export default StaffManager