import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";

const CategoryManager = () => {
    const [allData, setAllData] = useState([]);
	const [whichCategory, setWhichCategory] = useState("Listing");
	const {postOrGet}  = useAxios();

	const setCategory = (which) =>{
		setWhichCategory(which);
	}

	const handleActivateDeactivate = async (id,action) => {
		postOrGet(`category/activate/deactivate/${whichCategory}`,'post',{id,action}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.categoryID === id ? {...data,categoryActive: action} : data;
				});
				setAllData(newAllData);
			}
		});
	}

	const loadData = async () => {
		postOrGet(`category/view/${whichCategory}`,'get',{}).then((data) =>{
			if(data?.results){
				setAllData(data.results);
			}
		});
	}

	useEffect(()=>{
		//import('../../../assets/admin/js/material-dashboard-inside').then((mod) => {}).catch(err => {});
		//loadData();	
	},[]);

	useEffect(()=>{
		//import('../../../assets/admin/js/material-dashboard-inside').then((mod) => {}).catch(err => {});
		loadData();	
	},[whichCategory]);

	return <div className="row mt-4 p-4">
					<div className="col-12">
						<AuthorizedAreas permission="CAN_CATEGORY_CREATE">
							
							<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
								<input type="radio" checked={whichCategory === "Listing"} onChange={()=>setCategory("Listing")} className="btn-check" value="original" name="btnradio" id="original" autoComplete="off" defaultChecked />
								<label className="btn btn-outline-primary" htmlFor="original">Listing Category</label>
								<input type="radio" checked={whichCategory === "Places"} onChange={()=>setCategory("Places")} className="btn-check" value="places" name="btnradio" id="places" autoComplete="off" />
								<label className="btn btn-outline-primary" htmlFor="places">Places Category</label>
								<input type="radio" checked={whichCategory === "Events"} onChange={()=>setCategory("Events")} className="btn-check" value="events" name="btnradio" id="events" autoComplete="off" />
								<label className="btn btn-outline-primary" htmlFor="events">Events Category</label>
								<input type="radio" checked={whichCategory === "Community"} onChange={()=>setCategory("Community")} className="btn-check" value="community" name="btnradio" id="community" autoComplete="off" />
								<label className="btn btn-outline-primary" htmlFor="community">Community Category</label>
							</div>


						</AuthorizedAreas>

						<div className="card mb-4">	
							<div className="card-header pb-0">
								<AuthorizedAreas permission="CAN_CATEGORY_CREATE">
								<Link 
								to="new"
								state={{
									which: whichCategory
								}}
								>
									<button type="button" className="btn btn-icon btn-info">
										<span className="btn-inner--icon">
											<i className="material-icons">add_box</i>
										</span>
										<span className="btn-inner--text">CREATE {whichCategory.toUpperCase()} CATEGORY</span>
									</button>
								</Link>
							</AuthorizedAreas>
								<h6>{whichCategory} Category Manager</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_CATEGORY_VIEW">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														S/N
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Category
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Status
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.categoryID}`}>
															<td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.categoryName }
																		</h6>
																	</div>
																</div>
															</td>
															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.categoryActive ? "success" : "primary"}`}>
																	{s.categoryActive ? "ACTIVE" : "DISABLED"}
																</span>
																</p>
															</td>
															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		<AuthorizedAreas permission="CAN_SUB_CATEGORY_EDIT">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`edit`}
																					state={{
																						id: s.categoryID,
																						name: s.categoryName,
																						which: whichCategory
																					}}
																				>
																					EDIT CATEGORY
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		<AuthorizedAreas permission="CAN_SUB_CATEGORY_VIEW">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`view`}
																					state={{ 
																						id: s.categoryID,
																						name: s.categoryName,
																						which: whichCategory
																					}}
																				>
																					VIEW SUBCATEGORY
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		<AuthorizedAreas permission="CAN_SUB_CATEGORY_CREATE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={"subcategory/new"}
																					state={{ 
																						id: s.categoryID,
																						name: s.categoryName,
																						which: whichCategory
																					}}
																				>
																					CREATE SUBCATEGORY
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		<AuthorizedAreas permission="CAN_CATEGORY_ACTIVATE_DEACTIVATE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleActivateDeactivate(s.categoryID,s.categoryActive ? false : true)}
																				>
																					{s.categoryActive ? "DE-ACTIVATE" : "ACTIVATE"} CATEGORY
																				</Link>
																			</li>
																		</AuthorizedAreas>
																	</ul>
																</div>
															</td>
														</tr>
													))
												) : (
													<h6 className="p-3">No entries created yet</h6>
												)}
											</tbody>
										</table>
									</div>
								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>

}

export default CategoryManager