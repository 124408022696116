import {useEffect,useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { SET_ACTIVE_USER } from "../redux/slice/authSlice";
import useAxios from "../utils/hooks/useAxios";

//dispatch(SET_ACTIVE_USER(response));

const PersistSession = () => {
		const [isLoading, setIsLoading] = useState(true);
	    const {postOrGet}  = useAxios();
        const location = useLocation();
		const dispatch = useDispatch();
		const { isAuthenticated, accessToken } = useSelector((state) => state.auth);
		const from = location?.pathname;
		const keepItAlive = async () =>{
			await postOrGet('auth/keep_alive','get',{})
			.then((data) =>{
				if(data){
				    dispatch(SET_ACTIVE_USER(data));
					setIsLoading(false);
				}else{
					setIsLoading(false);
				}
			});
		};
        useEffect(() =>{
			if (isAuthenticated == false && !accessToken) {
				keepItAlive();
			} else {
				setIsLoading(false);
			}
        },[]);

		return isLoading? null : <Outlet/>;
		
}

export default PersistSession