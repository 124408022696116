import {useEffect, useRef} from 'react';
import closeButton from '../assets/frontend/img/slider/close.png';
import leftButton from '../assets/frontend/img/slider/left.png';
import rightButton from '../assets/frontend/img/slider/right.png';

const ImageModal = ({img,left,right,close}) => {
  const leftCursorRef = useRef(null);
  const rightCursorRef = useRef(null);

    useEffect(()=>{ 
     const handleKeyDown = (e) => {
      switch (e.keyCode){
        case 27: //ESC
                    close(false); 
                    break;
        case 37://LEFT ARR
                    //left();
                    leftCursorRef.current.click();
                    break;
        case 39://RIGHT ARR
                    //right();
                    rightCursorRef.current.click();
                    break;           
        break;    
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
    },[]);

  return (
    <div className="overlayLayout">
      <div onClick={()=>close(false)} className="closeBtn" style={{position:"relative", alignSelf:'flex-end',cursor:"pointer" }}><img src={closeButton}/></div>
      <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: "30px" }}>   
        <img className="animated fadeIn fast" src = {img} style={{ width: '75%', height: 'auto', objectFit: 'contain' }} crossOrigin="Anonymous" /> 
      </div>
      <div ref={leftCursorRef} onClick={()=>left()} style={{ cursor:"pointer", position: "absolute", top : "50%", left: '50px' }}><img src={leftButton}/></div>
      <div ref={rightCursorRef} onClick={()=>right()} style={{ cursor:"pointer", position: "absolute", top : "50%", right: '50px' }}><img src={rightButton}/></div> 
      
    </div>
  )
}

export default ImageModal