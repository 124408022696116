import {useState,useEffect} from 'react';
import useAxios from "../../utils/hooks/useAxios";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation,useNavigate } from "react-router-dom";
import useGlobalNotification from '../../utils/hooks/useGlobalNotification';
import moment from 'moment';
import ImageModal from '../../components/ImageModal';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import list from '../../assets/frontend/img/business/list.png';
import gallery from '../../assets/frontend/img/business/gallery.png';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';


const Event = () => {
  const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
  const eventPath = `${serverPath}/api/frontend/events/`;
  const dispatch = useDispatch();
  const eventID = useLocation()?.state?.id;

  const [data, setData] = useState({});
  const {postOrGet}  = useAxios();
  const {dispatchNotification} = useGlobalNotification();
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState("");
  const [imageArray, setImageArray] = useState([]);
  

  const getData = async () => {
    await postOrGet(`frontend/business/event/${eventID}`,'get')
    .then((data) =>{
        if(data){ 
            if(data?.result[0]?.eventOtherPictures){
                setImageArray(data.result[0].eventOtherPictures.split(','));
            }
            setData(...data.result); 
        }
    });  
  }

  const moveSlideShowRight = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex + 1 >= totalLength ? 0 : currentModalIndex+1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${eventPath}${imageArray[nextIndex]}`);
  }

  const moveSlideShowLeft = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex - 1 < 0 ? totalLength-1  : currentModalIndex-1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${eventPath}${imageArray[nextIndex]}`);
  }

  const handleImage = (img,index) =>{
    setCurrentModalImage(img);
    setCurrentModalIndex(index);
    handleCloseSlideShow(true);
  }

  const handleCloseSlideShow = (action) =>{
    setShowImageModal(action);
  }

  useEffect(()=>{ 
      getData(); 
    },[]);

  return (
    <>
        <div>
            {/* Inner Banner */}
            <div style={{ position : 'relative'}} className="inner-banner">
                <img style={{ position: 'absolute', width:"100%", height:"600px", objectFit: "cover", zIndex : -2  }} src={`${eventPath}${data.eventMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                <div className="container" style={{ maxWidth:"99%" }}>
                <div className="inner-content">
                    <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="inner-banner-text">
                        <h3 style={{ marginBottom:"1px" }}>{data.eventName}</h3>
                        <ul style={{ paddingBottom:"5px" }}>
                            <li>
                            <i className="fa-regular fa-calendar"></i>
                            {data.eventStartDate == data.eventEndDate ? moment(data.eventStartDate).format('LL') : `${moment(data.eventStartDate).format('LL')} - ${moment(data.eventEndDate).format('LL')}`} 
                            </li>
                            <li>
                            <i className="fa-regular fa-clock"></i>&nbsp;
                            {moment(data.eventStartTime, "HH:mm").format("HH:mm")} -&nbsp; 
                                {moment(data.eventEndTime, "HH:mm").format("HH:mm")} 
                            </li>
                        </ul>
                        <ul>
                            <li>
                            <i className="fa fa-user"></i>
                            by {data.businessFullName}
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
               
                <div className="banner-list">

                <div className="row align-items-center">
                    {/* Profile Area */}
                                <div className="listing-details-area pb-70 p-5">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="listing-details-wrap">
                                                
                                                    <div className="listing-widget listing-widget-desc">
                                                    <h3 className="title"> <img style={{ width: "20px", height: "20px" }} src={list} /> Description</h3>
                                                    <div style={{ padding: "10px",textAlign:"justify" }}> 
                                                        {parse(DOMPurify.sanitize(data.eventDetails))}
                                                    </div>
                                                    
                                                    </div>
                                                    {imageArray.length > 0 ?
                                                    <div className="listing-widget gallery-slider p-2">
                                                        <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={gallery} /> Gallery / Photos</h3>

                                                        <ResponsiveMasonry
                                                            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                                                        >
                                                            <Masonry gutter = "10px">
                                                            {imageArray.map((image,index)=>(
                                                                
                                                                <img className="gallery" style={{ borderRadius: '10px' }} key={index} onClick={()=>handleImage(`${eventPath}${image}`,index)} src={`${eventPath}${image}`} alt="Images" crossOrigin="Anonymous"  />
                                                                
                                                            ))}
                                                            </Masonry>
                                                        </ResponsiveMasonry>
                                        
                                                    </div>:null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/* Profile Area End */}
                </div>    

                </div>
                </div>
            </div>
            {/* Inner Banner End */}
        </div>

        {
        showImageModal ?
        <ImageModal img={currentModalImage} close={handleCloseSlideShow} left={moveSlideShowLeft} right={moveSlideShowRight} />
        :
        null
        }
    </>
  )
}

export default Event