import * as yup from "yup";

export const CommunityEditValidation = yup.object().shape({
	communityID: yup.string().required('Community ID is required'),
	communityFullName: yup.string().max(100,'Community Name should not exceed 100 characters'),
	communityDescription: yup.string().required('Community Description is required'),
	communityWebsite: yup.string().nullable().notRequired().when('communityWebsite', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.(\w+)\.(\w+).(\w+)?$/,'Website is not in acceptable format'),
           	 		}),
	communityAddress: yup.string().max(200,'Community Address cannot exceed 200 characters').required('Community Address field is required'),
	communityLatitude: yup.string().max(100).required('Community Latitude field is required'),
	communityLongitude: yup.string().max(100).required('Community Longitude field is required'),
	communityCategory: yup.string().max(2).required('Community Category field is required'),
	communitySubcategory: yup.string().max(2).nullable().notRequired(),
	removeMainBanner: yup.boolean().required('Required field'),
},
    [
        // Add Cyclic deps here because when in businessWebsite require itself
        ['communityWebsite', 'communityWebsite']
    ]

);