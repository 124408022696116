import * as yup from "yup";

export const CommunityCreateValidation = yup.object().shape({
	communityFullName: yup.string().max(100,'Community Name should not exceed 100 characters').required('Community Name is required'),
	communityDescription: yup.string().required('Community Description is required'),
	communityAddress: yup.string().max(200,'Community Address cannot exceed 200 characters').required('Community Address field is required'),
	communityLatitude: yup.string().max(100).required('Community Latitude field is required'),
	communityLongitude: yup.string().max(100).required('Community Longitude field is required'),
	communityCategory: yup.string().max(2).required('Community Category field is required'),
	communitySubcategory: yup.string().max(2),
	communityWebsite: yup.string().nullable().notRequired().when('communityWebsite', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.(\w+)\.(\w+).(\w+)?$/,'Website is not in acceptable format'),
           	 		}),
	bannerMainPicture:yup.string().required('Community Main Picture is required'),
	
},
    [
        // Add Cyclic deps here because when in businessWebsite require itself
        ['communityWebsite', 'communityWebsite'],
    ]

);