import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import Datetime from "react-datetime";
import moment from 'moment';
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import useGlobalNotification from '../../../utils/hooks/useGlobalNotification';

const EventsManager = () => {
    const [allData, setAllData] = useState([]);

    const [catData, setCatData] = useState([]);
    const [reviews, setReviews] = useState({});
    const [filterOn, setFilterOn] = useState(false);
    const [filterName, setFilterName] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [paginationData, setPaginationData] = useState({
		size: 20,
		currentPage: 1,
		nextPage:0,
		previousPage: 0,
		itemsCount: 0,
		pagesCount: 0
	});

	const {postOrGet}  = useAxios();
	const {userType} = useSelector(state=>state.auth);
	const [showModal,setShowModal] = useState(false);
	const {dispatchNotification} = useGlobalNotification();

	const handleActivateDeactivate = async (id,action) => {
		postOrGet('newevents/activate/deactivate','post',{id,action}).then((data) =>{
			if(data){
				const newAllData = allData.map((data) =>{
					return data.eventsID === id ? {...data,eventsActive: action} : data;
				});
				setAllData(newAllData);
			}
		});
	}

	const loadData = async (page = paginationData.currentPage,size = paginationData.size, filterName = "", filterValue = "") => {
		postOrGet(`newevents/view?page=${page}&size=${size}&filterName=${filterName}&filterValue=${filterValue}`,'get',{}).then((data) =>{
			if(data?.results){
				setAllData(data.results);
				setCatData(data.catResult);
				setPaginationData(data.pagination);
			}
		});
	}

	const getPaginationBody = (pageCount) =>{
	    
		let count=0;
		let body = [];
	
		for(let i = paginationData.currentPage; i < pageCount; i++){
			if(i == 1) continue;//skip page 1
			if(count == 5) break;//show only 5 links at anytime
			body.push(i);
			count++;
		}
		return body;	
	}

	useEffect(()=>{
		loadData();	
	},[]);

	return <div className="row mt-4 p-4">
					<div className="col-12">
						<AuthorizedAreas permission="CAN_EVENTS_CREATE">
							<Link to="new">
								<button type="button" className="btn btn-icon btn-info">
									<span className="btn-inner--icon">
										<i className="material-icons">add_box</i>
									</span>
									<span className="btn-inner--text">CREATE NEW EVENT</span>
								</button>
							</Link>
						</AuthorizedAreas>

						<div className="card mb-4">

                <div className="row m-2">
								<div className="form-check form-switch">
									<input value={filterOn} 
									checked={filterOn} onChange={()=>{setFilterOn(!filterOn); setFilterValue(""); setStartDate(""); setEndDate("")}}
									id="search" className="form-check-input" name="filter" type="checkbox" />
									<label htmlFor="search" className="form-check-label">Filter Event</label>
								</div>


								{filterOn ?
								<>
								<div className="input-group input-group-static">
										<label htmlFor="filterBy">Filter by:</label>
										<select
										className="form-control"
										name="filterBy"
										id="filterBy"
										autoComplete="off"
										defaultValue={""}
										value={filterName}
										onChange={e=>{
											setFilterName(e.target.value);
											setFilterValue("");
										}}
										>
											<option value=""> --Select Filter Type--</option>
											<option value="name"> Event Name</option>
											<option value="category"> Event Category</option>
											<option value="date"> Date Created</option>
											<option value="approvalStatus"> Approval Status </option>
											<option value="eventStatus"> Event Status </option>
										</select>
                      			</div>

								{filterName == "name" ?
								<div className="col-12 p-3">
										<div className="input-group input-group-static">
											<label htmlFor="bName" style={{ fontWeight:"bold", fontSize:"14px" }}>Search by Name</label>
											<input
												autoComplete="off"
												type="text"
												name="bName"
												id="bName"
												className="form-control"
												placeholder="Enter Name"
												/* value={form.eventName} */
												onChange={e=>{
												setFilterValue(e.target.value);
												}}				
											/>
										</div>
								</div>:null}
                               
							   {filterName == "approvalStatus" || filterName == "eventStatus" ?
								<div className="col-12 p-3">
										<div className="input-group input-group-static">
											<label htmlFor="bStatus" style={{ fontWeight:"bold", fontSize:"14px" }}>Select Status</label>
											<select
											className="form-control"
											name="bStatus"
											id="bStatus"
											autoComplete="off"
											/* value={businessId} */
											onChange={e=>{
											setFilterValue(e.target.value);
											}}
											>
											<option value=""> --Select Status--</option>
											<option value="0"> {filterName == "approvalStatus" ? "Pending":"Inactive"} </option>
											<option value="1"> {filterName == "approvalStatus" ? "Approved":"Active"} </option>
											</select>
										</div>
								</div>:null}

								{filterName == "category" ?
								<div className="col-12 p-3">
										<div className="input-group input-group-static">
											<label htmlFor="bCat" style={{ fontWeight:"bold", fontSize:"14px" }}>Select Category</label>
											<select
											className="form-control"
											name="bCat"
											id="bCat"
											autoComplete="off"
											/* value={businessId} */
											onChange={e=>{
											setFilterValue(e.target.value);
											}}
											>
											<option value=""> --Select Category--</option>
											{ catData?.length && catData.map((e) => (
												<option key={e.categoryID} value={e.categoryID}>{e.categoryName}</option>
												))
											}
										
											</select>
										</div>
								</div>:null}

								{filterName == "date" ?
								<div className="row p-3">      
										<h6 className="font-weight-bold text-primary">Dates</h6>
										<div className="col-12">
											<div className="row">

												<div className="col-6">
													<div className="input-group input-group-static">
														<label htmlFor="">Start Date</label>
														<Datetime
															className="input-group input-group-static"
															closeOnSelect={true}
															dateFormat={"YYYY-MM-DD"}
															timeFormat={false}
															inputProps={{
															name: "eventDay",
															value: startDate,
															}}
															onChange={(e) => {
															const newDay = moment(new Date(e._d)).format(
																"YYYY-MM-DD"
															);
															setFilterValue(newDay);
															setStartDate(newDay);
															setEndDate("");
															}}
															/* isValidDate={(current) =>
																current.isAfter(moment().subtract(1, "day"))
															} */
														/>
													</div>
												</div>

												<div className="col-6">
													<div className="input-group input-group-static">
														<label htmlFor="endDate">End Date</label>
														<Datetime
															className="input-group input-group-static"
															closeOnSelect={true}
															dateFormat={"YYYY-MM-DD"}
															timeFormat={false}
															inputProps={{
															name: "eventDay",
															value: endDate,
															}}
															onChange={(e) => {
															const newDay2 = moment(new Date(e._d)).format(
																"YYYY-MM-DD"
															);
															setFilterValue(startDate+"/"+newDay2);
															setEndDate(newDay2); 
															}}
															isValidDate={(current) =>
																current.isAfter(moment(startDate))
															}
														/>
													</div>
												</div>

											</div>
										</div>
                                </div> 
							    :null}

								{filterName != "" && filterValue !=""	?							
								<button onClick={()=>loadData(1,paginationData.size,filterName,filterValue)} type="button" className="mt-2 btn btn-icon btn-primary btn-lg w-100">
									<span className="btn-inner--icon"> <i className="material-icons">search</i></span>
									<span className="btn-inner--text">FILTER</span>
								</button>:null}

								</>
								:null
								}
							</div>

							<div className="alert alert-light p-1 m-2" role="alert">
							Showing {paginationData.itemsCount < paginationData.size ? `1 - ${paginationData.itemsCount}` : `${ 1 + ( (paginationData.currentPage - 1 ) * paginationData.size)} - ${paginationData.currentPage * paginationData.size}`} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.itemsCount}</span> items
							</div>

							<div className="card-header pb-0">
								<h6>Events Manager</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_EVENTS_VIEW">
									<div className="table-responsive p-0">
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
													{/*<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														S/N
													</th>*/}
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Event Name
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Start Day
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														End Day
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Start Time
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														End Time
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Approved?
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Status
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
														Date created
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.eventsID}`}>
															{/*<td className="text-center">
																<p className="text-sm font-weight-normal mb-0">
																	{index + 1}
																</p>
															</td>*/}
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.eventsFullName }
																		</h6>
																	</div>
																</div>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.eventStartDate }
																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.eventEndDate }
																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.eventStartTime }
																		</h6>
																	</div>
																</div>
															</td>

															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.eventEndTime }
																		</h6>
																	</div>
																</div>
															</td>
															
															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.eventsApprovedStatus==1 ? "success" : s.eventsApprovedStatus==0 ? "warning" : "primary"}`}>
																	{s.eventsApprovedStatus==1 ? "APPROVED" : s.eventsApprovedStatus==0 ? "PENDING" : "REJECTED"}
																</span>
																</p>
															</td>
															<td>
																<p className="text-sm font-weight-normal mb-0">
																	<span className={`btn btn-sm btn-${s.eventsActive ? "success" : "primary"}`}>
																	{s.eventsActive ? "ACTIVE" : "DISABLED"}
																	</span>
																</p>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{ moment(s.eventsCreatedOn).format('LLL') }
																		</h6>
																	</div>
																</div>
															</td>
															<td className="align-middle text-center text-sm">
																<div className="btn-group dropstart">
																	<button
																		className="btn btn-secondary dropdown-toggle"
																		type="button"
																		id="dropdownMenuButton2"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		Actions
																	</button>
																	<ul className="dropdown-menu dropdown-menu-dark">
																		<AuthorizedAreas permission="CAN_EVENTS_VIEW">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`view`}
																					state={{ 
																						id: s.eventsID,
																						name : s.eventsFullName
																					}}
																				>
																					VIEW EVENT
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		<AuthorizedAreas permission="CAN_EVENTS_EDIT">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`edit`}
																					state={{ 
																						id: s.eventsID,
																						name : s.eventsFullName
																					}}
																				>
																					EDIT EVENT
																				</Link>
																			</li>
																		</AuthorizedAreas>
																		
																		<AuthorizedAreas permission="CAN_EVENTS_ACTIVATE_DEACTIVATE">
																			<li>
																				<Link
																					className="dropdown-item"
																					to="#"
																					onClick={()=>handleActivateDeactivate(s.eventsID,s.eventsActive ? false : true)}
																				>
																					{s.eventsActive ? "DE-ACTIVATE" : "ACTIVATE"} EVENT
																				</Link>
																			</li>
																		</AuthorizedAreas>

																		<AuthorizedAreas permission="CAN_EVENTS_MANAGE_TICKETS">
																			<li>
																				<Link
																					className="dropdown-item"
																					to={`manage_ticket`}
																					state={{ 
																						id: s.eventsID,
																						name : s.eventsFullName,
																						endDate: s.eventEndDate
																					}}	
																				>
																					MANAGE TICKETS
																				</Link>
																			</li>
																		</AuthorizedAreas>
																	
																	</ul>
																</div>
															</td>
														</tr>
													))
												) : null}
											</tbody>
											
										</table>
									</div>

									{
					paginationData.itemsCount && paginationData.pagesCount > 1 ?
							<div className="m-3">
								<nav aria-label="...">
								<ul className="pagination">
									
									<li className={`page-item ${paginationData.previousPage != 0  ? 'cursor-pointer': 'disabled'}`}>
									<span 
									className="page-link" 
									onClick={()=>loadData(paginationData.previousPage)}>Prev</span>
									</li>

									<li className={`page-item ${paginationData.currentPage == 1 ? 'active': 'cursor-pointer'}`}>
										<span 
										className="page-link" 
										onClick={()=>{ if(paginationData.currentPage == 1) return; loadData(1)}} >1</span>
									</li>
									{
									getPaginationBody(paginationData.pagesCount).map(el=> (
									     <li key={el} className={`page-item  ${el==paginationData.currentPage ? 'active':'cursor-pointer'}`} aria-current="page">
				   						      <span 
											  className="page-link"
											  onClick={()=>{ if(paginationData.currentPage == el) return; loadData(el)}} 
											  >{el}</span>
										</li>))
									}
									<li className={`page-item ${paginationData.currentPage == paginationData.pagesCount  ? 'active': 'cursor-pointer'}`}>
										<span 
										className="page-link" 
										onClick={()=>{ if(paginationData.currentPage == paginationData.pagesCount) return; loadData(paginationData.pagesCount)}}  >{paginationData.pagesCount}</span>
									</li>

									<li className={`page-item ${paginationData.nextPage <= paginationData.pagesCount  ? 'cursor-pointer': 'disabled'}`}>
										<span 
										className="page-link" 
										onClick={()=>loadData(paginationData.nextPage)}>Next</span>
									</li>
								</ul>
								</nav>
							</div> 
						:
					null
					}


								</AuthorizedAreas>
							</div>
						</div>
					</div>

					
		   </div>

}

export default EventsManager