import React from 'react';
import {Outlet, Navigate, useLocation} from 'react-router-dom';
import { useSelector} from 'react-redux';

const PrivateRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const location = useLocation();
  
   return ( isAuthenticated? <Outlet/> : <Navigate to="/admin/login" state={{ from : location?.pathname?.from ?? "/admin/login" }} replace /> )
}

export default PrivateRoutes