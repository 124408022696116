import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SubCategoryCreateValidation } from "../../../utils/validations/SubCategoryCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const NewSubCategory = () => {
          
      const location = useLocation();
      const id = location?.state?.id;
      const name = location?.state?.name;
      const whichCategory = location?.state?.which;

      const defaultForm = { categoryId: id,subcategoryName: "" };
      const [form, setForm] = useState(defaultForm);

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();

      /* const getInitializationData = async () =>{
        
          await postOrGet('staff/fetch_creation_data','get',{})
              .then((data) =>{
                if(data){
                  setPermissions(data.permissions);
                  setDesignations(data.designations);
                }
          }); 
      } */

      useEffect(() => {
        /* getInitializationData(); */
      },[]);
       
      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      }; 
      
      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            SubCategoryCreateValidation.validate(form, { abortEarly: false })
            .then(async (values)=>{
                await postOrGet(`subcategory/create/${whichCategory}`,'post',{...values})
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Created successfully"); 
                        setForm(defaultForm);
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
        };
      
	
      return <AuthorizedAreas permission="CAN_SUB_CATEGORY_CREATE">
             <div className="card-body px-0 pt-0 pb-2">
              
              <form onSubmit={(e)=>handleSubmit(e)}>
                    <div style={{ padding: 20 }}>
                        <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                            <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                            <span className="btn-inner--text">GO BACK</span>
                        </button>
                        <div className="card mt-4" id="basic-info">
                            <div className="card-header">
                            <h5>New Sub Category</h5>
                            </div>
                            <div className="card-body pt-0">

                            <div className="row mt-4">
                                <div className="col-12">
                                    <div className="input-group input-group-static">
                                        <label htmlFor="categoryId">Category</label>
                                        <select
                                            className="form-control"
                                            name="categoryId"
                                            id="categoryId"
                                            autoComplete="off"
                                            defaultValue={id}
                                            value={form.categoryId}
                                        >
                                            <option value={id}> {name}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="input-group input-group-static">
                                        <label htmlFor="subcategoryName">Sub Category Name</label>
                                        <input
                                        autoComplete="off"
                                        type="text"
                                        name="subcategoryName"
                                        id="subcategoryName"
                                        className="form-control"
                                        placeholder="Enter Sub Category"
                                        value={form.subcategoryName}
                                        onChange={(e)=>handleChange(e)}						
                                        />
                                    </div>
                                </div>
                            </div>
                    
                            <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                                <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                                <span className="btn-inner--text">CREATE</span>
                            </button>
                            </div>
                        </div>
                    </div>
              </form>
             </div> 
        </AuthorizedAreas>;
}

export default NewSubCategory