import {useState,useEffect} from 'react';
import useAxios from "../../utils/hooks/useAxios";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import useGlobalNotification from '../../utils/hooks/useGlobalNotification';
import {weekdays} from '../../utils/weekdays_months';
import moment from 'moment';
import ImageModal from '../../components/ImageModal';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Rating } from 'react-simple-star-rating';
import Modal from 'react-bootstrap/Modal';
import list from '../../assets/frontend/img/business/list.png';
import time from '../../assets/frontend/img/business/time.png';
import contact from '../../assets/frontend/img/business/contact.png';
import gallery from '../../assets/frontend/img/business/gallery.png';
import sad from '../../assets/frontend/img/business/sad.png';
import "./Business.scss";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import {
	GoogleMap,
	useJsApiLoader,
	MarkerF,
} from "@react-google-maps/api";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";


const libraries = ['places'];

const Business = () => {
  const shareURLPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_CLIENT_PRODUCTION : process.env.REACT_APP_LOCAL_SERVER;
  const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
  const imagePath = `${serverPath}/api/frontend/images/`;
  const eventPath = `${serverPath}/api/frontend/events/`;
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [position, setPosition] = useState({});
  const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries,
		region: "uk",
	});
  const format = 'HH:mm';
  const dayN = new Date().getDay();
  const day = weekdays[dayN];
  let currentTime, startTime, endTime = "";
  const dispatch = useDispatch();
  const {isAuthenticated,userType} = useSelector(state=> state.auth);
  //const bizID = useLocation()?.state?.id;

  const defaultForm = {
        rating: 0,
		review: "",
        id: ""
    };

  const [form, setForm] = useState(defaultForm);

  const name = useLocation()?.pathname.split('/business/')[1].substring(0);
  const [followedBiz,setFollowedBiz] = useState([]);
  const [data, setData] = useState({});
  const [reviewsData, setReviewsData] = useState([]);
  const [reviewsSummary, setReviewsSummary] = useState({});
  const [eventsData, setEventsData] = useState([]);
  const [status,setStatus] = useState("Closed");
  const {postOrGet}  = useAxios();
  const {dispatchNotification} = useGlobalNotification();
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [showModal,setShowModal] = useState(false);
  const [showShareModal,setShowShareModal] = useState(false);
  

  const getData = async () => {
    await postOrGet('frontend/business','post',{name})
            .then((data) =>{
                if(data){
                    currentTime = moment(moment(),format);
                    startTime = data.result.businessOperatingTimes && moment(data.result.businessOperatingTimes[day]["startTime"],format);
                    endTime = data.result.businessOperatingTimes && moment(data.result.businessOperatingTimes[day]["endTime"],format);
                    let s= currentTime.isBetween(startTime, endTime) ? "Open" : "Closed";
                    setStatus(s);
                    if(data?.result?.bannerOthersPicture){
                        setImageArray(data.result.bannerOthersPicture.split(','));
                    }

                    let d = data.result;

                    //set map position
                    const newPosition = { lat: parseFloat(d.businessLatitude), lng : parseFloat(d.businessLongitude) };
                    setPosition(newPosition);
                   

                    if(data?.result?.businessID){
                        const d_id = d.businessID;
                        setForm(prev=>({ ...prev, 'id': d_id })); 
                        delete d.businessID;
                    }
                    setData(d);
                    setReviewsSummary(...data.resultReviewSummary);
                    setReviewsData(data.resultReview);   
                    setEventsData(data.resultEvents); 
                    if(data?.followedBiz){
                            setFollowedBiz(data.followedBiz);
                    }  
                }
            });  
  }

  const handleSubmit = async () =>{

    if(form.rating==0) {
        dispatchNotification('error','You must select a rating');
        return;
    }
    if(form.review.trim()==""){ 
        dispatchNotification('error','You must enter a review');
        return;
    }

    //submit
    await postOrGet('frontend/submit/review','post',form)
            .then((data) =>{
                if(data){
                    dispatchNotification('success','You review has been submitted successfully');
                    //setForm(defaultForm);
                    setShowModal(false);   
                }
            });  
  }

  const handleRating = (rate,index=0) => {
    setForm(prev=>({ ...prev, 'rating': rate })); 
  }

  const moveSlideShowRight = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex + 1 >= totalLength ? 0 : currentModalIndex+1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${imagePath}${imageArray[nextIndex]}`);
  }

  const moveSlideShowLeft = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex - 1 < 0 ? totalLength-1  : currentModalIndex-1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${imagePath}${imageArray[nextIndex]}`);
  }

  const handleImage = (img,index) =>{
    setCurrentModalImage(img);
    setCurrentModalIndex(index);
    handleCloseSlideShow(true);
  }

  const handleCloseSlideShow = (action) =>{
    setShowImageModal(action);
  }

  const handleLikeClick = async (e,bizId) =>{
       e.preventDefault();

       let action = followedBiz.includes(bizId) ? "unfollow" : "follow";

       postOrGet('frontend/follow/unfollow','post',{bizId,action })
        .then((data) =>{
            if(data){
                if(action == "follow"){
                  setFollowedBiz(prev=>([ ...prev, bizId ])); 
                }else{
                    let newAr = followedBiz.filter(id=>id != bizId );
                    setFollowedBiz(newAr);
                }
            }
        });
    } 

  useEffect(()=>{ 
     if(!name) dispatchNotification('error','An error occurred');
     else getData(); 
    },[]);

  return (
    <>
        <div>
            {/* Inner Banner */}
            <div style={{ position : 'relative'}} className="inner-banner">
                <img style={{ position: 'absolute', width:"100%", height:"600px", objectFit: "cover", zIndex : -2  }} src={`${imagePath}${data.bannerMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                <div className="container myResponsiveContainer" style={{ maxWidth:"99%" }}>

                <div className="inner-content">

                    <div style={{ float: "right", marginTop: '20px'}}>
                        <button type="button" className="btn btn-outline-primary">
                            <i style={{ color:'white' }} className="flaticon-telephone" />&nbsp;&nbsp;
                            <a style={{ color:'white' }} href={`tel:${data.businessPhone}`}>
                            {data.businessPhone}
                            </a>
                        </button>
                    </div>

                    <div className="row align-items-center">
                    <div className="col-lg-8">
                        <div className="inner-banner-text">
                        <h3>{data.businessFullName}</h3>
                        <ul>
                            {
                            position?.lat &&
                            <li>
                            <a target="_blank" rel="noreferrer" href={`https://maps.google.com?q=${position.lat},${position.lng}`}>   
                            <i className="flaticon-cursor" />
                            {data.businessAddress}
                            </a> 
                            </li>
                            }


                            {data.businessWebsite? 
                            <li>  
                            <i className="flaticon-email-1" />
                            <a target="_blank" rel="noreferrer" href={`https://${data.businessWebsite}`} >
                                {data.businessWebsite}
                            </a>
                            </li>:null}
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        { 
                        reviewsSummary?.reviewCount ?
                            <div className="banner-rating">
                                <ul>
                                    <li className="rating-star">
                                    <Rating
                                       initialValue={reviewsSummary.ratings}
                                        readonly={true}
                                        allowFraction={true}
                                        size={15}
                                        allowHover={false}
                                        disableFillHover={true}
                                    />
                                    <span>{reviewsSummary.reviewCount} Review{reviewsSummary.reviewCount > 1 ? 's' : ''}</span>
                                    </li>
                                    <li>
                                    <Link to="#">{reviewsSummary.ratings}</Link>
                                    </li>
                                </ul>
                            </div> 
                            : 
                            null
                        }
                    </div>
                    </div>
                </div>
                <div className="banner-bottom">
                    <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="banner-tag">
                        <ul>
                            <li>
                            {
                            isAuthenticated == true && userType==1 ?   
                            <Link to="#" onClick={()=>setShowModal(true)}>
                                <i className="flaticon-writing" />
                                Add Review  
                            </Link>  :
                            null
                            }                                  
                            </li>
                            
                            {
                                    isAuthenticated ?
                            <li>
                                {/* <Link onClick={()=>setShowShareModal(true)} to="#">
                                    <i className="fa fa-share-nodes"></i>
                                    &nbsp;&nbsp;Share
                                </Link>  */}
                                <a onClick={(e)=>{handleLikeClick(e,form.id)}} className={followedBiz.includes(form.id) ? "iFollow animated heartBeat" : ""} href="#" >
                                    <i className="flaticon-like" />
                                    &nbsp;&nbsp;{followedBiz.includes(form.id) ? "Following" : "Follow"}
                                </a>
                            </li>:
                            <li>
                                <Link to="/authwall">
                                LOGIN TO FOLLOW
                            </Link>
                            </li>
                            }
                            <li>
                                <Link onClick={()=>setShowShareModal(true)} to="#">
                                    <i className="fa fa-share-nodes"></i>
                                    &nbsp;&nbsp;Share
                                </Link> 
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="banner-profile">
                            <div style={{ backgroundColor: status=='Closed'?"#f14300":"#028311"}} className="banner-status">
                                <a href="#"><h3>{status}</h3></a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="banner-list">

                <div className="row align-items-center">
                    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">

                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="home" aria-selected="true">Profile</button>
                    </li> 

                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="profile" aria-selected="false">
                        Reviews &nbsp;
                        { reviewsSummary?.reviewCount ? <span className="badge rounded-pill bg-secondary">{reviewsSummary.reviewCount}</span>:null}
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="events-tab" data-bs-toggle="tab" data-bs-target="#events" type="button" role="tab" aria-controls="contact" aria-selected="false">Deals/Offers &nbsp;
                        { eventsData.length>0 ? <span className="badge rounded-pill bg-secondary">{eventsData.length}</span>:null}
                        </button>
                    </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            {/* Profile Area */}
                                <div className="listing-details-area pb-70 p-5">
                                    <div className="container myContainer2">
                                        <div className="row">
                                            <div className="col-lg-8">
                                            <div className="listing-details-wrap">
                                            
                                                <div className="listing-widget listing-widget-desc">
                                                <h3 className="title"> <img style={{ width: "20px", height: "20px" }} src={list} /> Description</h3>
                                                <div style={{ padding: "10px", textAlign:"justify" }}> 
                                                    
                                                    {parse(DOMPurify.sanitize(data.businessDescription))}
                                                </div>
                                                
                                                {/* <div className="listing-features">
                                                    <h3 className="features-title">Listing Features</h3>
                                                    <div className="row">
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="features-card">
                                                        <i className="flaticon-coffee-cup" />
                                                        <h3>Restaurant Inside</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="features-card">
                                                        <i className="flaticon-wifi" />
                                                        <h3>Free WiFi</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="features-card">
                                                        <i className="flaticon-parking" />
                                                        <h3>Free Parking</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="features-card">
                                                        <i className="flaticon-bread" />
                                                        <h3>Breakfast</h3>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div> */}
                                                </div>
                                                {imageArray.length > 0 &&
                                                <div className="listing-widget gallery-slider p-2">
                                                    <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={gallery} /> Gallery / Photos</h3>

                                                    <ResponsiveMasonry
                                                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                                                    >
                                                        <Masonry gutter = "10px">
                                                        {imageArray.map((image,index)=>(
                                                            
                                                            <img className="gallery" style={{ borderRadius: '10px' }} key={index} onClick={()=>handleImage(`${imagePath}${image}`,index)} src={`${imagePath}${image}`} alt="Images" crossOrigin="Anonymous"  />
                                                            
                                                        ))}
                                                        </Masonry>
                                                    </ResponsiveMasonry>
                                    
                                                </div>
                                                }

                                                <div className="listing-widget gallery-slider p-2">
                                                    <h3 className="title"><i className="fa fa-map-location-dot"></i> Map View <a style={{ float: "right" }} className="btn btn-sm btn-primary" target="_blank" rel="noreferrer" href={`https://maps.google.com?q=${position.lat},${position.lng}`}>directions</a>  </h3>

                                                {isLoaded && position?.lat && (
                                                    <GoogleMap
                                                        mapContainerStyle={{
                                                            width: "100%",
                                                            height: "500px",
                                                            marginTop: "10px",
                                                        }}
                                                        center={position}
                                                        zoom={18}
                                                        onLoad={(map) => setMap(map)}
                                                    >
                                                        <MarkerF position={position} />
                                                    </GoogleMap>
                                                )}
                                    
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="listing-details-side">
                                                    <div className="listing-widget-side">
                                                    <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={time} /> Opening Hours</h3>
                                                    <ul className="listing-widget-list">
                                                        <li>Sunday <span> {!data?.businessOperatingTimes?.sunday?.startTime ? "Closed" : `${data?.businessOperatingTimes?.sunday?.startTime} - ${data?.businessOperatingTimes?.sunday?.endTime}` }</span></li>
                                                        <li>Monday <span>{!data?.businessOperatingTimes?.monday?.startTime ? "Closed" : `${data?.businessOperatingTimes?.monday?.startTime} - ${data?.businessOperatingTimes?.monday?.endTime}`}</span></li>
                                                        <li>Tuesday <span>{!data?.businessOperatingTimes?.tuesday?.startTime ? "Closed" : `${data?.businessOperatingTimes?.tuesday?.startTime} - ${data?.businessOperatingTimes?.tuesday?.endTime}` }</span></li>
                                                        <li>Wednesday <span>{!data?.businessOperatingTimes?.wednesday?.startTime ? "Closed" : `${data?.businessOperatingTimes?.wednesday?.startTime} - ${data?.businessOperatingTimes?.wednesday?.endTime}` }</span></li>
                                                        <li>Thursday <span>{!data?.businessOperatingTimes?.thursday?.startTime ? "Closed" : `${data?.businessOperatingTimes?.thursday?.startTime} - ${data?.businessOperatingTimes?.thursday?.endTime}` }</span></li>
                                                        <li>Friday <span>{!data?.businessOperatingTimes?.friday?.startTime ? "Closed" : `${data?.businessOperatingTimes?.friday?.startTime} - ${data?.businessOperatingTimes?.friday?.endTime}` }</span></li>
                                                        <li>Saturday <span>{!data?.businessOperatingTimes?.saturday?.startTime ? "Closed" : `${data?.businessOperatingTimes?.saturday?.startTime} - ${data?.businessOperatingTimes?.saturday?.endTime}` }</span></li>
                                                    </ul>
                                                    </div>
                                                    <div className="listing-widget-side">
                                                    <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={contact} /> Contact</h3>
                                                    <ul className="listing-widget-list">
                                                        
                                                        {/* <li>Website : <span> <a href="https://downtown.com">https://downtown.com</a></span></li> */}
                                                        <div className="p-4">
                                                            <span className="text-bold">Address</span> :<br/><span>{data?.businessAddress}</span>
                                                            <br/><br/>
                                                            <span className="text-bold">Phone</span> :&nbsp;<span><a href={`tel:${data?.businessPhone}`}>{data?.businessPhone}</a></span>       
                                                        </div>
                                                    </ul>
                                                    <ul className="social-link" style={{ float: "none" }}>
                                                        {data?.businessSocialMediaHandles?.facebook &&
                                                        <li><a href={`https://${data?.businessSocialMediaHandles?.facebook}`} target="_blank"><i className="bx bxl-facebook" /></a></li> }
                                                        {data?.businessSocialMediaHandles?.twitter &&
                                                        <li><a href={`https://${data?.businessSocialMediaHandles?.twitter}`} target="_blank"><i className="bx bxl-twitter" /></a></li> }
                                                        {data?.businessSocialMediaHandles?.instagram &&
                                                        <li><a href={`https://${data?.businessSocialMediaHandles?.instagram}`} target="_blank"><i className="bx bxl-instagram" /></a></li>}
                                                    </ul>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/* Profile Area End */}
                        </div>
                        <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                            {/* Reviews Area */}
                            <div className="listing-details-area pb-70 p-5">
                                <div className="container">
                                    <div className="row">

                                        {reviewsData.length == 0 ?
                                            <div style={{ display:"flex", justifyContent: "center", alignItems: "center", padding: "20px"}}>
                                                <img  src={sad} /><br/>
                                                <p>No Reviews Yet</p>
                                            </div>
                                            :


                                            <div className="listing-comment">
                                                <ul>

                                                    {reviewsData.map(review => (
                                                        <li style={{ padding:'5px' }}>
                                                            
                                                            <div className="content">
                                                                <h3>{review.firstName}</h3>
                                                                <span>{ moment(review.reviewDate).format('LLL') }</span>
                                                                <p className="comment-text" style={{ float:"none" }}>{review.reviewComment}</p>
                                                                <div className="comment-rating"> 
                                                                    {Array(Math.floor(review.reviewRating)).fill(1).map((el,i) =>
                                                                        <i key={i} className="bx bxs-star"></i>
                                                                    )}
                                                                    <span>{review.reviewRating}</span>
                                                                </div>
                                                            </div>
                                                        </li> 
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>  
                            {/* Reviews Area End*/}
                        </div>
                        <div className="tab-pane fade" id="events" role="tabpanel" aria-labelledby="events-tab">
                            {/* Events Area */}
                              <div className="listing-details-area pb-70 p-5">
                                <div className="container">
                                  <div className="row">
                                    {
                                        eventsData.length == 0 ?
                                            <div style={{ display:"flex", justifyContent: "center", alignItems: "center", padding: "20px"}}>
                                                <img  src={sad} /><br/>
                                                <p>No deals created</p>
                                            </div>
                                        :
                                        eventsData.map(event=>(
                                            <div key={event.eventMainPicture} className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                <div className="example-2 event-card">    
                                                    <div className="wrapper">
                                                        <img style={{ position: 'absolute', width:"100%", height:"350px", objectFit: "cover", zIndex : 0  }} src={`${eventPath}${event.eventMainPicture}`} alt="Event Image" crossOrigin="Anonymous" />
                                                        <div className="eventOverlay"></div>
                                                        <div className="header">
                                                            <div style={{ position:'relative' }}>
                                                                <div className="date">
                                                                    <i className="fa-regular fa-calendar"></i> { event.eventStartDate == event.eventEndDate? moment(event.eventStartDate).format('L') : `${moment(event.eventStartDate).format('L')} - ${moment(event.eventEndDate).format('L')}`}
                                                                </div>

                                                                <div className="time">
                                                                    <h6 className="show" style={{ color: 'white', fontWeight:'normal'}}> 
                                                                    <i className="fa-regular fa-clock"></i>&nbsp;
                                                                    {moment(event.eventStartTime, "HH:mm").format("HH:mm")} -&nbsp; 
                                                                    {moment(event.eventEndTime, "HH:mm").format("HH:mm")} 
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="data">
                                                        <div className="content">
                                                            <h5 className="title">
                                                            <Link state={{ id: event.eventID }}  to="/business/event">{event.eventName}</Link></h5>
                                                            <p className="text">{parse(DOMPurify.sanitize(event.eventDetails))}</p>
                                                            <Link state={{ id: event.eventID }}  to="/business/event" className="button">Read more</Link>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        ))
                                    }  
                                    </div> 
                                </div>
                             </div>
                            {/* Events Area End*/}
                        </div>
                    </div>
                </div>    

                </div>
                </div>
            </div>
            {/* Inner Banner End */}
        </div>

        {
        showImageModal &&
        <ImageModal img={currentModalImage} close={handleCloseSlideShow} left={moveSlideShowLeft} right={moveSlideShowRight} />
        }

        <Modal 
        show={showModal} 
        /* fullscreen={true} */
        size="xl"
        scrollable={false}
        centered
        backdrop="static"
        keyboard={false} 
        onHide={()=>setShowShareModal(false)}>
            <Modal.Header closeButton={false}> 
                <Modal.Title>Add a review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
                    <div className="col-12">
                        {/* Start Review */}
                            <div className="listing-widget-contact">  
                                <div className="contact-form">
                                    <h6>Rating</h6>
                                    <Rating
                                       initialValue={form.rating}
                                        onClick={handleRating}
                                        onPointerMove={handleRating}
                                        transition={true}
                                        allowFraction={true}
                                        showTooltip={true}
                                        tooltipStyle={{ marginLeft: "20px",padding: "15px",borderRadius: "60px",color: "white" }}
                                    />
                                    <div className="row pt-2">
                                        <h6>Review</h6>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <i className="bx bx-envelope" />
                                                <textarea name="review" 
                                                maxLength={500}
                                                value={form.review} 
                                                onChange={(e)=>setForm(prev=>({ ...prev, 'review': e.target.value }))} 
                                                className="form-control" 
                                                id="message" cols={30} 
                                                rows={8} 
                                                required data-error="Write your message" 
                                                placeholder="Kindly enter your review" />
                                                <div className="help-block with-errors" />
                                                <div className="p-2" style={{ float: "right" }}>
                                                <span style={{ padding: "10px", color: "white" }}className="badge bg-primary">{ 500 - form.review.split("").length}</span> characters left
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button onClick={()=>handleSubmit()} type="button" className="default-btn border-radius">
                                                Submit Review 
                                                <i className="bx bx-plus" />
                                            </button>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        {/* End Review */}
                    </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-icon btn-danger" onClick={()=>setShowModal(false)}>Close</button>
            </Modal.Footer>
        </Modal>

        <Modal 
        show={showShareModal} 
        /* fullscreen={true} */
        size="lg"
        scrollable={false}
        centered
        backdrop="static"
        keyboard={false} 
        onHide={()=>setShowModal(false)}>
            <Modal.Body>
            <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowShareModal(false)} className="fa fa-circle-xmark"></i>

            <div style={{ padding: '5px' }} className="row">

                        <div className="col-4 text-center p-5">
                            <EmailShareButton 
                                url={`${shareURLPath}/business/${name}`}
                                title={`${data.businessFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px" }} className="fa fa-envelope"></i>
                            </EmailShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <LinkedinShareButton 
                                url={`${shareURLPath}/business/${name}`}
                                title={`${data.businessFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#0072b1" }} className="fa-brands fa-linkedin"></i>
                            </LinkedinShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <FacebookShareButton 
                                url={`${shareURLPath}/business/${name}`}
                                title={`${data.businessFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#3b5998" }} className="fa-brands fa-facebook"></i>
                            </FacebookShareButton>
                        </div>


                        <div className="col-4 text-center p-5">
                            <PinterestShareButton 
                                url={`${shareURLPath}/business/${name}`}
                                title={`${data.businessFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#c8232c" }} className="fa-brands fa-pinterest"></i>
                            </PinterestShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <RedditShareButton 
                                url={`${shareURLPath}/business/${name}`}
                                title={`${data.businessFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#FF4500" }} className="fa-brands fa-reddit"></i>
                            </RedditShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <TelegramShareButton 
                                url={`${shareURLPath}/business/${name}`}
                                title={`${data.businessFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#4995be" }} className="fa-brands fa-telegram"></i>
                            </TelegramShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <TwitterShareButton
                                url={`${shareURLPath}/business/${name}`}
                                title={`${data.businessFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#1DA1F2" }} className="fa-brands fa-twitter"></i>
                            </TwitterShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <WhatsappShareButton
                                url={`${shareURLPath}/business/${name}`}
                                title={`${data.businessFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color:"#128C7E" }} className="fa-brands fa-whatsapp"></i>
                            </WhatsappShareButton>
                        </div>

                       
            </div>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default Business