import * as yup from "yup";

export const EventEditValidation = yup.object().shape({
	eventId: yup.number().required('Offer/Deal must belong to a business'),
	eventName: yup.string().max(200,'Offer/Deal Name should not exceed 200 characters').required('Deal/Offer Name is required'),
	eventDetails: yup.string().required('Details are required'),
	//eventAddress: yup.string().required('Event Address is required'),
	eventStartDate: yup.string().required('Start Date is required'),
	eventEndDate: yup.string().required('End Date is required'),
	eventStartTime: yup.string().required('Start Time is required'),
	eventEndTime: yup.string().required('End Time is required'),
    removeMainBanner: yup.boolean().required('Required field'),
}
);