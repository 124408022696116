import {useState,useEffect} from 'react';
import useAxios from "../../utils/hooks/useAxios";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation,useNavigate } from "react-router-dom";
import useGlobalNotification from '../../utils/hooks/useGlobalNotification';
import moment from 'moment';
import ImageModal from '../../components/ImageModal';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import list from '../../assets/frontend/img/business/list.png';
import gallery from '../../assets/frontend/img/business/gallery.png';
import TextareaAutosize from 'react-textarea-autosize';
import "./Listing.scss";


const Listing = () => {
  const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
  const listingPath = `${serverPath}/api/frontend/listings/`;
  const dispatch = useDispatch();
  const listingID = useLocation()?.state?.id;

  const [data, setData] = useState({});
  const {postOrGet}  = useAxios();
  const {dispatchNotification} = useGlobalNotification();
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState("");
  const [features, setFeatures] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  

  const getData = async () => {
    await postOrGet(`frontend/business/listing/${listingID}`,'get')
    .then((data) =>{
        if(data){ 
            if(data?.result[0]?.listingOthersPictures){
                setImageArray(data.result[0].listingOthersPictures.split(','));
            }
            if(data?.result[0]?.listingFeatures){
                setFeatures(data.result[0].listingFeatures.split(','));
            }

            setData(...data.result); 
        }
    });  
  }

  const moveSlideShowRight = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex + 1 >= totalLength ? 0 : currentModalIndex+1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${listingPath}${imageArray[nextIndex]}`);
  }

  const moveSlideShowLeft = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex - 1 < 0 ? totalLength-1  : currentModalIndex-1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${listingPath}${imageArray[nextIndex]}`);
  }

  const handleImage = (img,index) =>{
    setCurrentModalImage(img);
    setCurrentModalIndex(index);
    handleCloseSlideShow(true);
  }

  const handleCloseSlideShow = (action) =>{
    setShowImageModal(action);
  }

  useEffect(()=>{ 
      getData(); 
    },[]);

  return (
    <>
        <div>
            {/* Inner Banner */}
            <div style={{ position : 'relative'}} className="inner-banner">
                <img style={{ position: 'absolute', width:"100%", height:"600px", objectFit: "cover", zIndex : -2  }} src={`${listingPath}${data.listingMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                <div className="container" style={{ maxWidth:"99%" }}>
                <div className="inner-content">
                    <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="inner-banner-text" style={{ padding: 0 }}>
                        <h3 style={{ marginBottom:"1px" }}>{data.listingName}</h3>
                        <ul style={{ paddingBottom:"5px" }}>
                            <li>
                            <i class="fa fa-money-bill"></i>
                                <span>&#163; {data.listingAmount}</span> <span>per month</span>
                            </li>
                           
                        </ul>
                        <ul>
                            <li>
                            <i className="fa-solid fa-location-dot"></i>
                            {data.listingAddress}
                            </li>   

                            {data.businessPhone &&
                            <li style={{ float: "right" }}>
                            <button type="button" className="btn btn-outline-primary"><a href={`tel:${data.businessPhone}`}><span style={{ color : "white", fontSize: "15px" }}><i style={{ color: 'white'}} className="fa fa-phone"></i>{data.businessPhone}</span></a></button>
                            </li> }  
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
               
                <div className="banner-list">

                <div className="row align-items-center">
                    <div className="listing-details-area pb-70s">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">

                                    <div className="card">   
                                        <div className="card-body">
                                            <h5 className="card-title"><img style={{ width: "20px", height: "20px" }} src={list} /> Descriptions</h5>
                                           
                                           <TextareaAutosize
                                                    value={data.listingDetails}
                                                    style={{ width:'100%', border:'none', readOnly: true,textAlign:"justify" }}
                                           />
                                           
                                        </div>
                                    </div>

                                    <div className="card" style={{ marginTop: "25px" }}>   
                                        <div className="card-body">
                                            <h5 className="card-title"><i className="fa fa-list"></i> Details</h5>
                                            <ul className="listing_details">
                                                <li>
                                                    <div style={{float:'left'}}>Area</div>
                                                    <div style={{float:'right'}}>{data.listingSize}</div>
                                                </li>
                                                <li>
                                                    <div style={{float:'left'}}>Bedrooms</div>
                                                    <div style={{float:'right'}}>{data.listingBedrooms}</div>
                                                </li>
                                                <li>
                                                    <div style={{float:'left'}}>Bathrooms</div>
                                                    <div style={{float:'right'}}>{data.listingBathrooms}</div>
                                                </li>
                                                {data.listingEPC &&
                                                <li>
                                                    <div style={{float:'left'}}>EPC Rating</div>
                                                    <div style={{float:'right'}}>{data.listingEPC}</div>
                                                </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    
                                </div>

                                <div className="col-lg-6">
                                    {imageArray.length > 0 &&
                                    <div className="card" style={{ marginTop: "25px" }}>   
                                        <div className="card-body">
                                            <h5 className="card-title"><i className="fa fa-image"></i> Gallery / Photos</h5>
                                            <ResponsiveMasonry
                                                /* columnsCountBreakPoints={{350: 2, 750: 2, 900: 3}} */
                                            >
                                                <Masonry gutter = "10px">
                                                {imageArray.map((image,index)=>(
                                                    
                                                    <img className="gallery" style={{ borderRadius: '10px' }} key={index} onClick={()=>handleImage(`${listingPath}${image}`,index)} src={`${listingPath}${image}`} alt="Images" crossOrigin="Anonymous"  />
                                                    
                                                ))}
                                                </Masonry>
                                            </ResponsiveMasonry>
                                        </div>
                                    </div>
                                    }

                                    {
                                    features.length > 0 &&
                                    <div className="card" style={{ marginTop: "25px" }}>   
                                        <div className="card-body">
                                            <h5 className="card-title"><i className="fa fa-list"></i> Features</h5>
                                           {
                                           features.map(feature=>
                                           <p style={{ float: "none" }}><i style={{ fontSize: '12px' }} className="fa fa-star-of-life"></i> {feature}</p>
                                           )
                                           }
                                        </div>
                                    </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>        
                </div>    

                </div>
                </div>
            </div>
            {/* Inner Banner End */}
        </div>

        {
        showImageModal &&
        <ImageModal img={currentModalImage} close={handleCloseSlideShow} left={moveSlideShowLeft} right={moveSlideShowRight} />
        }
    </>
  )
}

export default Listing