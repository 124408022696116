import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useLocation } from "react-router-dom";
import useAxios from "../../utils/hooks/useAxios";
import {
        SET_CATEGORIES, SET_SUBCATEGORIES, 
        SET_CATEGORIES_PLACES, SET_SUBCATEGORIES_PLACES,
        SET_CATEGORIES_COMMUNITY, SET_SUBCATEGORIES_COMMUNITY,
        SET_CATEGORIES_EVENTS, SET_SUBCATEGORIES_EVENTS,
       } from '../../redux/slice/authSlice';

const NavBar = () => {
    //categories
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [subcategoriesCatId, setSubcategoriesCatId] = useState([]);

    //categories places
    const [categoriesPlaces, setCategoriesPlaces] = useState([]);
    const [subcategoriesPlaces, setSubcategoriesPlaces] = useState([]);
    const [subcategoriesPlacesCatId, setSubcategoriesPlacesCatId] = useState([]);

    //categories community
    const [categoriesCommunity, setCategoriesCommunity] = useState([]);
    const [subcategoriesCommunity, setSubcategoriesCommunity] = useState([]);
    const [subcategoriesCommunityCatId, setSubcategoriesCommunityCatId] = useState([]);
    
    //categories events
    const [categoriesEvents, setCategoriesEvents] = useState([]);
    const [subcategoriesEvents, setSubcategoriesEvents] = useState([]);
    const [subcategoriesEventsCatId, setSubcategoriesEventsCatId] = useState([]);

    const {isAuthenticated} = useSelector(state=> state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const {postOrGet}  = useAxios();

    useEffect(()=>{
        postOrGet('frontend/initialize','get')
            .then((data) =>{
                if(data){
                    //categories
                    setCategories(data.categories);
                    setSubcategories(data.subcategories);
                    setSubcategoriesCatId(data.subcategoriesCatId);
                    dispatch(SET_CATEGORIES(data.categories));
                    dispatch(SET_SUBCATEGORIES(data.subcategories));

                    //categories places
                    setCategoriesPlaces(data.categoriesPlaces);
                    setSubcategoriesPlaces(data.subcategoriesPlaces);
                    setSubcategoriesPlacesCatId(data.subcategoriesPlacesCatId);
                    dispatch(SET_CATEGORIES_PLACES(data.categoriesPlaces));
                    dispatch(SET_SUBCATEGORIES_PLACES(data.subcategoriesPlaces));

                    //categories community
                    setCategoriesCommunity(data.categoriesCommunity);
                    setSubcategoriesCommunity(data.subcategoriesCommunity);
                    setSubcategoriesCommunityCatId(data.subcategoriesCommunityCatId);
                    dispatch(SET_CATEGORIES_COMMUNITY(data.categoriesCommunity));
                    dispatch(SET_SUBCATEGORIES_COMMUNITY(data.subcategoriesCommunity));

                    //categories events
                    setCategoriesEvents(data.categoriesEvents);
                    setSubcategoriesEvents(data.subcategoriesEvents);
                    setSubcategoriesEventsCatId(data.subcategoriesEventsCatId);
                    dispatch(SET_CATEGORIES_EVENTS(data.categoriesEvents));
                    dispatch(SET_SUBCATEGORIES_EVENTS(data.subcategoriesEvents));
                }
            });
    },[]);

  return (
    <div className="navbar-area frontend-navbar">
				{/* Menu For Mobile Device */}
				<div className="mobile-nav">
					<Link to="/" className="logo">
					<img src={require("../../assets/frontend/img/logo/logo1.png")} alt="Logo" />
					</Link>
				</div>
				{/* Menu For Desktop Device */}
				<div className="nav-two main-nav">
					<div className="container-fluid">
					<nav style={{ boxShadow: 'none' }} className="container-max navbar navbar-expand-md navbar-light ">
						<Link to="/" className="navbar-brand">
						<img src={require("../../assets/frontend/img/logo/logo1.png")} alt="Logo" />
						</Link>
						<div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
						<ul className="navbar-nav m-auto">
							<li className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}> <NavLink to="/" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}> Home </NavLink>  </li>
							<li className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}><NavLink to="/explore" className="nav-link"> Explore</NavLink> </li>
							
                            {/* listing */}
                            {
                            categories.length > 0 && subcategories.length > 0 && subcategoriesCatId.length > 0 &&
                            <li id="categoryCheck" className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}> <Link to="#" className="nav-link">Listings <i className="bx bx-plus" /></Link>
                                <ul className="dropdown-menu">                                    
                                    {categories.map(category=>
                                        (
                                        <li key={category.categoryID} className="nav-item">
                                            <Link reloadDocument={true}  to={`/explore/${window.btoa(category.categoryID)}`}
                                            state={{ 
                                                categoryId: category.categoryID,
                                            }}
                                            className="nav-link">{category.categoryName} {subcategoriesCatId.includes(category.categoryName) && <i className="bx bx-plus" />}</Link> 
                                            {subcategoriesCatId.includes(category.categoryID) &&
                                                <ul className="dropdown-menu">
                                                    {subcategories.map(subcategory =>{
                                                       return category.categoryID == subcategory.categoryId ? <li key={subcategory.subcategoryName} className="nav-item">
                                                        <Link reloadDocument={true} to={`/explore/${window.btoa(category.categoryID)}/${window.btoa(subcategory.subcategoryID)}`}
                                                        state={{ 
                                                                categoryId: subcategory.categoryId,
                                                                subcategoryId : subcategory.subcategoryID
                                                            }}
                                                        className="nav-link">{subcategory.subcategoryName}</Link></li> : null;
                                                    })}
                                                </ul>
                                            }
                                        </li>
                                        )
                                    )}

                                </ul>
							</li>
                            }

                            {/* places */}
                            {
                            categoriesPlaces.length > 0 &&
                            <li className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}> <Link to="#" className="nav-link">Places <i className="bx bx-plus" /></Link>
                                <ul className="dropdown-menu">                                    
                                    {categoriesPlaces.map(category=>
                                        (
                                        <li key={category.categoryID} className="nav-item">
                                            <Link reloadDocument={true}  to={`/places/${window.btoa(category.categoryID)}`}
                                            state={{ 
                                                categoryId: category.categoryID
                                            }}
                                            className="nav-link">{category.categoryName} {subcategoriesCatId.includes(category.categoryName) && <i className="bx bx-plus" />}</Link> 
                                            {subcategoriesPlacesCatId.includes(category.categoryID) &&
                                                <ul className="dropdown-menu">
                                                    {subcategoriesPlaces.map(subcategory =>{
                                                       return category.categoryID == subcategory.categoryId ? <li key={subcategory.subcategoryName} className="nav-item">
                                                        <Link reloadDocument={true} to={`/places/${window.btoa(category.categoryID)}/${window.btoa(subcategory.subcategoryID)}`}
                                                        state={{ 
                                                                categoryId: subcategory.categoryId,
                                                                subcategoryId : subcategory.subcategoryID
                                                            }}
                                                        className="nav-link">{subcategory.subcategoryName}</Link></li> : null;
                                                    })}
                                                </ul>
                                            }
                                        </li>
                                        )
                                    )}

                                </ul>
							</li>
                            }

                            {/* community */}
                            {
                            categoriesCommunity.length > 0 &&
                            <li className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}> <Link to="#" className="nav-link">Community <i className="bx bx-plus" /></Link>
                                <ul className="dropdown-menu">                                    
                                    {categoriesCommunity.map(category=>
                                        (
                                        <li key={category.categoryID} className="nav-item">
                                            <Link reloadDocument={true}  to={`/community/${window.btoa(category.categoryID)}`}
                                            state={{ 
                                                categoryId: category.categoryID
                                            }}
                                            className="nav-link">{category.categoryName} {subcategoriesCatId.includes(category.categoryName) && <i className="bx bx-plus" />}</Link> 
                                            {subcategoriesCommunityCatId.includes(category.categoryID) &&
                                                <ul className="dropdown-menu">
                                                    {subcategoriesCommunity.map(subcategory =>{
                                                       return category.categoryID == subcategory.categoryId ? <li key={subcategory.subcategoryName} className="nav-item">
                                                        <Link reloadDocument={true} to={`/community/${window.btoa(category.categoryID)}/${window.btoa(subcategory.subcategoryID)}`}
                                                        state={{ 
                                                                categoryId: subcategory.categoryId,
                                                                subcategoryId : subcategory.subcategoryID
                                                            }}
                                                        className="nav-link">{subcategory.subcategoryName}</Link></li> : null;
                                                    })}
                                                </ul>
                                            }
                                        </li>
                                        )
                                    )}

                                </ul>
							</li>
                            }

                            {/* events */}
                            {
                            categoriesEvents.length > 0 &&
                            <li className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}> <Link to="#" className="nav-link">Events <i className="bx bx-plus" /></Link>
                                <ul className="dropdown-menu">                                    
                                    {categoriesEvents.map(category=>
                                        (
                                        <li key={category.categoryID} className="nav-item">
                                            <Link reloadDocument={true}  to={`/events/${window.btoa(category.categoryID)}`}
                                            state={{ 
                                                categoryId: category.categoryID
                                            }}
                                            className="nav-link">{category.categoryName} {subcategoriesCatId.includes(category.categoryName) && <i className="bx bx-plus" />}</Link> 
                                            {subcategoriesEventsCatId.includes(category.categoryID) &&
                                                <ul className="dropdown-menu">
                                                    {subcategoriesEvents.map(subcategory =>{
                                                       return category.categoryID == subcategory.categoryId ? <li key={subcategory.subcategoryName} className="nav-item">
                                                        <Link reloadDocument={true} to={`/events/${window.btoa(category.categoryID)}/${window.btoa(subcategory.subcategoryID)}`}
                                                        state={{ 
                                                                categoryId: subcategory.categoryId,
                                                                subcategoryId : subcategory.subcatplaces
                                                            }}
                                                        className="nav-link">{subcategory.subcategoryName}</Link></li> : null;
                                                    })}
                                                </ul>
                                            }
                                        </li>
                                        )
                                    )}

                                </ul>
							</li>
                            }

                            <li className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}><NavLink to="/deals" reloadDocument={ location?.pathname=="/deals" ? true : false} className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>Deals/Offers</NavLink></li>

                            {
							!isAuthenticated &&
							<li className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}><NavLink to="/authwall" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>Login or Register</NavLink></li>
                            }

                            {
							isAuthenticated &&
							<li className={`nav-item ${location?.pathname=="/" ? "custom-influence-nav" : ""}`}><NavLink to="/admin/dashboard" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>Account</NavLink></li>
                            }
						</ul>
						<div className="side-nav d-in-line align-items-center">
                            {
							isAuthenticated &&
							<div className="side-item">
								<div className="user-btn">
									<Link to="/admin/dashboard">
									<i className="flaticon-contact" />
									</Link>
								</div>
							</div>
                            }
                            
                            {
                                !isAuthenticated &&
                                <div className="side-item">
                                    <div className="nav-add-btn">
                                        <Link to="/authwall" state={{ action: 'register' }}  className="default-btn">
                                        Add a Listing 
                                        <i className="bx bx-plus" />
                                        </Link>
                                    </div>
                                </div>
                            }
						</div>
						</div>
					</nav>
					</div>
				</div>
	</div>
  )
}

export default NavBar