import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate } from "react-router-dom";
import { DesignationCreateValidation } from "../../../utils/validations/DesignationCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const NewDesignation = () => {
      const defaultForm = {
        designationName: "",
      };
      const [form, setForm] = useState(defaultForm);
      const [designationPrivileges,setDesignationPrivileges] = useState([]);
      const [permissions,setPermissions] = useState([]);
      
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();

      const getInitializationData = async () =>{
        await postOrGet('designation/get_creation_data','get',{})
            .then((data) =>{
              if(data){
                setPermissions(data.permissions);
              }
        }); 
      }

      useEffect(() => {
        getInitializationData();
      },[]);

      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      };
      
      const amIChecked = (id) =>{ return designationPrivileges.includes(id)}

      const handleCheckbox = (id) =>{
        if(designationPrivileges.includes(id))
          setDesignationPrivileges(prev=>(prev.filter(e=>e!=id)));
        else
          setDesignationPrivileges(prev=>([...prev,id]));	
      }

      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            DesignationCreateValidation.validate(form, { abortEarly: false }).then(async (values)=>{
                await postOrGet('designation/create','post',{...values,permissions : JSON.stringify(designationPrivileges)})
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Created successfully"); 
                        setForm(defaultForm);
                        setDesignationPrivileges([]);
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
      };
      
	
	return <AuthorizedAreas permission="CAN_DESIGNATION_CREATE">
            <div className="card-body px-0 pt-0 pb-2">      
                    <form onSubmit={(e)=>handleSubmit(e)}>
                      <div style={{ padding: 20 }}>
                  <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                      <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                      <span className="btn-inner--text">GO BACK</span>
                    </button>
                        <div className="card mt-4" id="basic-info">
                          <div className="card-header"><h5>Designation Information</h5></div>
                          <div className="card-body pt-0">
                            <div className="row">
                              <div className="col-12">
                                <div className="input-group input-group-static">
                                  <label htmlFor="designationName">Designation Name</label>
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    name="designationName"
                                    id="designationName"
                                    className="form-control"
                                    placeholder="Designation Name"
                                    value={form.designationName}
                                    onChange={(e)=>handleChange(e)}						
                                  />
                                </div>
                              </div>
                          </div>

                            <h6 className="roleNamePermissionShow">Permissions
                              <span className="roleNamePermission text-primary" />
                            </h6>
                            <div className="row mt-4 permissions_here">
                              {
                              permissions?.length && permissions.map((c) => (
                                <div key={c.permissionID} className="col-sm-12 col-md-6 col-lg-12">
                                  <div className="form-check form-switch">
                                    <input value={c.permissionID} 
                                    checked={amIChecked(c.permissionID)} onChange={()=>handleCheckbox(c.permissionID)}
                                    id={`idHere${c.permissionID}`} className="form-check-input" name="courses[]" type="checkbox" />
                                    <label htmlFor={`idHere${c.permissionID}`} className="form-check-label">{c.permissionName}</label>
                                  </div>
                                </div>
                              ))
                              }
                            </div>
                            <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                                <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                                <span className="btn-inner--text">CREATE</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> 
		</AuthorizedAreas>;
}

export default NewDesignation