import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import moment from 'moment';

const BusinessReviewApprove = () => {
    const [allData, setAllData] = useState([]);
    const [allReviewsID, setAllReviewID] = useState([]);
    const [reviewActionArray, setReviewActionArray] = useState([]);
	const {postOrGet}  = useAxios();
    const location = useLocation();
    const navigate = useNavigate();
    const {dispatchNotification} = useGlobalNotification();

    const handleCheckbox = (id) =>{
		if(reviewActionArray.includes(id))
		  setReviewActionArray(prev=>(prev.filter(e=>e!=id)));
		else
		  setReviewActionArray(prev=>([...prev,id]));	
   }

    const handleCheckAll = (e) =>{
        if(e.target.checked)
            setReviewActionArray(allReviewsID);
        else
            setReviewActionArray([]);
        
    }

    const amIChecked = (id) =>{ return reviewActionArray.includes(id)}

    const handleAction = async (action) => {

            if(reviewActionArray.length == 0){
                dispatchNotification('error','You have to select a review first');
                return;
            }

            postOrGet("business/reviews/action",'post',
            {
                ids : JSON.stringify(reviewActionArray),
                action: action
            }).then((data) =>{
                if(data?.success){
                    dispatchNotification('success','Action completed successfully');
                    //remove ids from data
                    setAllData(prev=>(prev.filter(data=>!reviewActionArray.includes(data.reviewID))));
                }
		});

    }

	const loadData = async () => {
		postOrGet(`business/reviews/view/${location?.state?.id}`,'get').then((data) =>{
			if(data?.results){
                let ids = data.results.map(rev=>rev.reviewID);
                setAllReviewID(ids);
				setAllData(data.results);
			}
		});
	}

	useEffect(()=>{
		window.scrollTo({top: 0, left: 0});
		loadData();	
	},[]);

	return <div className="row mt-4 p-4">

            <div className="col-4">
                <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                  <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                  <span className="btn-inner--text">GO BACK</span>
                </button>
            </div>
                
					<div className="col-12">
						<div className="card mb-4">
							<div className="card-header pb-0">
								<h6>Pending Reviews for {location?.state?.name}</h6>
							</div>
							<div className="card-body px-0 pt-0 pb-2">
								<AuthorizedAreas permission="CAN_BUSINESS_VIEW">
									<div className="table-responsive p-0">
                                        {allData.length > 0 &&
                                        <div className={"p-2"}>
                                            <span onClick={()=>handleAction(1)} className={`btn btn-sm btn-success m-2`}>APPROVE</span>
											<span onClick={()=>handleAction(2)} className={`btn btn-sm btn-warning m-2`}>CANCEL</span>
                                        </div>}
										<table className="table align-items-center mb-0">
											<thead>
												<tr>
												
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														<div className="form-check">
                                                            <input onChange={(e)=>handleCheckAll(e)} className="form-check-input" type="checkbox" value="" id="checkAll"/>
                                                            <label className="form-check-label text-secondary text-xxs font-weight-bolder" for="checkAll">
                                                               All
                                                            </label>
                                                        </div>
													</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														User
													</th>
													
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Review
													</th>
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
														Rating
													</th>
													
													<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
													    Date
													</th>
													
												</tr>
											</thead>
											<tbody>
												{allData.length > 0 ? (
													allData.map((s, index) => (
														<tr key={`${s.reviewID}`}>
															<td className="text-center">
																<div className="form-check">
                                                                    <input checked={amIChecked(s.reviewID)} onChange={()=>handleCheckbox(s.reviewID)} className="form-check-input" type="checkbox" value="" />
                                                                </div>
															</td>
															<td>
																<div className="d-flex px-3 py-1">
												
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{s.firstName + " " + s.lastName}
																		</h6>
																	</div>
																</div>
															</td>
															<td style={{ width : "100px" }}>
																<div className="d-flex px-3 py-1" style={{ fontSize:'12px' }}>
																	{s.reviewComment }
																</div>
															</td>
															<td className="text-center">
																{s.reviewRating }			
															</td>

                                                            <td>
																<div className="d-flex px-3 py-1">
																	<div className="d-flex flex-column justify-content-center">
																		<h6 className="mb-0 text-sm">
																			{ moment(s.reviewDate).format('LLL') }
																		</h6>
																	</div>
																</div>
															</td>
														</tr>
													))
												) : (
													<h6 className="p-3">No entries</h6>
												)}
											</tbody>
										</table>

                                        {allData.length > 0 &&
                                        <div className={"p-2"}>
                                            <span onClick={()=>handleAction(1)} className={`btn btn-sm btn-success m-2`}>APPROVE</span>
											<span onClick={()=>handleAction(2)} className={`btn btn-sm btn-warning m-2`}>CANCEL</span>
                                        </div>
                                        }
									</div>
								</AuthorizedAreas>
							</div>
						</div>
					</div>
		   </div>

}

export default BusinessReviewApprove