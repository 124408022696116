import * as yup from "yup";

export const PlacesEditValidation = yup.object().shape({
	placesID: yup.string().required('Places ID is required'),
	placesFullName: yup.string().max(100,'Places Name should not exceed 100 characters'),
	placesDescription: yup.string().required('Places Description is required'),
	placesWebsite: yup.string().nullable().notRequired().when('placesWebsite', {
                	is: (value) => value?.length,
                	then: (rule) => rule.matches(/^www.(\w+)\.(\w+).(\w+)?$/,'Website is not in acceptable format'),
           	 		}),
	placesAddress: yup.string().max(200,'Places Address cannot exceed 200 characters').required('Places Address field is required'),
	placesLatitude: yup.string().max(100).required('Places Latitude field is required'),
	placesLongitude: yup.string().max(100).required('Places Longitude field is required'),
	placesCategory: yup.string().max(2).required('Places Category field is required'),
	placesSubcategory: yup.string().max(2).nullable().notRequired(),
	removeMainBanner: yup.boolean().required('Required field'),
	
},
    [
        // Add Cyclic deps here because when in businessWebsite require itself
        ['placesWebsite', 'placesWebsite'],
    ]
);