import * as yup from "yup";

export const registerSchema = yup.object().shape({
	firstName: yup.string().max(20).required('First Name field is required'),
	lastName: yup.string().max(20).required('Last Name field is required'),
	userType: yup.string().max(1).required('User Type field is required'),
	email: yup.string().max(50, 'Email should not exceed 50 characters').email("Enter a valid email address").required('Username field is required'),
	username: yup.string().max(50, 'Username should not exceed 50 characters').required('Username field is required'),
	password: yup.string().min(6, 'Password must be a minimum of 6 characters').max(20, 'Password must be a maximum of 20 characters').required('Password is required'),
	passwordConfirm: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match'),
});