import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate, useLocation } from "react-router-dom";
import { DesignationCreateValidation } from "../../../utils/validations/DesignationCreateValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";

  const ViewDesignation = () => {
        
        const location = useLocation();
        const id = location?.state?.id;
        const name = location?.state?.name;

        const defaultForm = { designationID: id,designationName: name};

        const [form, setForm] = useState(defaultForm);
        const [designationPrivileges,setDesignationPrivileges] = useState([]);
        const [permissions,setPermissions] = useState([]);
        
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const {dispatchNotification} = useGlobalNotification();
        const {postOrGet}  = useAxios();

        const getInitializationData = async () =>{
            await postOrGet(`designation/edit?id=${id}`,'get',{})
                    .then((data) =>{
                        if(data){
                            setPermissions(data.permissions);
                            const privileges = data?.designation?.permissions?.rights;
                            setDesignationPrivileges(privileges);
                        }
            }); 
        }

        useEffect(() => {
                getInitializationData();
        },[]);

         
        const amIChecked = (id) =>{ return designationPrivileges.includes(id)}
	
	return <AuthorizedAreas permission="CAN_DESIGNATION_VIEW">
	<div className="card-body px-0 pt-0 pb-2">		
          <form>
            <div style={{ padding: 20 }}>
				<button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
						<span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
						<span className="btn-inner--text">GO BACK</span>
					</button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h5>Designation Information</h5>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="designationName">Designation Name</label>
                        <input
                          readOnly={true}
                          autoComplete="off"
                          type="text"
                          name="designationName"
                          id="designationName"
                          className="form-control"
                          placeholder="Designation Name"
                          value={form.designationName}					
                        />
                      </div>
                    </div>
                </div>

                  <h6 className="roleNamePermissionShow">Permissions
                    <span className="roleNamePermission text-primary" />
                  </h6>
                  <div className="row mt-4 permissions_here">
                    {permissions?.length && permissions.map((c) => (
                        <div key={c.permissionID} className="col-sm-12 col-md-6 col-lg-12">
                          <div className="form-check form-switch">
                            <input value={c.permissionID} 
                            checked={amIChecked(c.permissionID)} readOnly={true}
                            id={`idHere${c.permissionID}`} className="form-check-input" name="courses[]" type="checkbox" />
                            <label htmlFor={`idHere${c.permissionID}`} className="form-check-label">{c.permissionName}</label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default ViewDesignation