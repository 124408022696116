import {useState,useEffect} from 'react';
import useAxios from "../../utils/hooks/useAxios";
import { useDispatch} from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import useGlobalNotification from '../../utils/hooks/useGlobalNotification';
import {weekdays} from '../../utils/weekdays_months';
import moment from 'moment';
import ImageModal from '../../components/ImageModal';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Modal from 'react-bootstrap/Modal';
import list from '../../assets/frontend/img/business/list.png';
import time from '../../assets/frontend/img/business/time.png';
import contact from '../../assets/frontend/img/business/contact.png';
import gallery from '../../assets/frontend/img/business/gallery.png';
import "./Business.scss";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import {
	GoogleMap,
	useJsApiLoader,
	MarkerF,
} from "@react-google-maps/api";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";


const libraries = ['places'];

const Places = () => {
  const shareURLPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_CLIENT_PRODUCTION : process.env.REACT_APP_LOCAL_SERVER;
  const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
  const imagePath = `${serverPath}/api/frontend/images/`;
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [position, setPosition] = useState({});
  const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries,
		region: "uk",
	});
  const format = 'HH:mm';
  const dayN = new Date().getDay();
  const day = weekdays[dayN];
  let currentTime, startTime, endTime = "";
  const dispatch = useDispatch();
  //const bizID = useLocation()?.state?.id;

  const id = useLocation()?.pathname.split('/place/')[1].substring(0);
  const [data, setData] = useState({});
  const {postOrGet}  = useAxios();
  const {dispatchNotification} = useGlobalNotification();
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState("");
  const [status,setStatus] = useState("Closed");
  const [imageArray, setImageArray] = useState([]);
  const [showModal,setShowModal] = useState(false);
  const [showShareModal,setShowShareModal] = useState(false);
  

  const getData = async () => {
    await postOrGet('frontend/place','post',{id})
            .then((data) =>{
                if(data){
                    
                    currentTime = moment(moment(),format);
                    startTime = data?.result?.placesOperatingTimes && moment(data?.result?.placesOperatingTimes[day]["startTime"],format);
                    endTime =   data?.result?.placesOperatingTimes && moment(data?.result?.placesOperatingTimes[day]["endTime"],format);

                    let s= currentTime.isBetween(startTime, endTime) ? "Open" : "Closed";
                    setStatus(s);

                    if(data?.result?.bannerOthersPicture){
                        setImageArray(data.result.bannerOthersPicture.split(','));
                    }

                    let d = data.result;

                    //set map position
                    const newPosition = { lat: parseFloat(d.placesLatitude), lng : parseFloat(d.placesLongitude) };
                    setPosition(newPosition);
                   

                    if(data?.result?.placesID){
                        delete d.placesID;
                    }
                    setData(d); 
                }
            });  
  }

  const moveSlideShowRight = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex + 1 >= totalLength ? 0 : currentModalIndex+1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${imagePath}${imageArray[nextIndex]}`);
  }

  const moveSlideShowLeft = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex - 1 < 0 ? totalLength-1  : currentModalIndex-1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${imagePath}${imageArray[nextIndex]}`);
  }

  const handleImage = (img,index) =>{
    setCurrentModalImage(img);
    setCurrentModalIndex(index);
    handleCloseSlideShow(true);
  }

  const handleCloseSlideShow = (action) =>{
    setShowImageModal(action);
  }

  useEffect(()=>{ 
     if(!id) dispatchNotification('error','An error occurred');
     else getData(); 
    },[]);

  return (
    <>
        <div>
            {/* Inner Banner */}
            <div style={{ position : 'relative'}} className="inner-banner">
                <img style={{ position: 'absolute', width:"100%", height:"600px", objectFit: "cover", zIndex : -2  }} src={`${imagePath}${data.bannerMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                <div className="container myResponsiveContainer" style={{ maxWidth:"99%" }}>

                <div className="inner-content">

                    {data.placesPhone &&
                    <div style={{ float: "right", marginTop: '20px'}}>
                        <button type="button" className="btn btn-outline-primary">
                            <i style={{ color:'white' }} className="flaticon-telephone" />&nbsp;&nbsp;
                            <a style={{ color:'white' }} href={`tel:${data.placesPhone}`}>
                            {data.placesPhone}
                            </a>
                        </button>
                    </div>}

                    <div className="row align-items-center">
                    <div className="col-lg-8">
                        <div className="inner-banner-text">
                        <h3>{data.placesFullName}</h3>
                        <ul>
                            {
                            position?.lat &&
                            <li>
                            <a target="_blank" rel="noreferrer" href={`https://maps.google.com?q=${position.lat},${position.lng}`}>   
                            <i className="flaticon-cursor" />
                            {data.placesAddress}
                            </a> 
                            </li>
                            }


                           {data.placesWebsite &&
                           <>
                            <br/>
                            <li>  
                            <i className="flaticon-email-1" />
                            <a target="_blank" rel="noreferrer" href={`https://${data.placesWebsite}`} >
                                {data.placesWebsite}
                            </a>
                            </li>
                            </>
                            } 
                        </ul>
                        </div>
                    </div>
                    {/* <div className="col-lg-4">
                        { 
                        reviewsSummary?.reviewCount ?
                            <div className="banner-rating">
                                <ul>
                                    <li className="rating-star">
                                    <Rating
                                       initialValue={reviewsSummary.ratings}
                                        readonly={true}
                                        allowFraction={true}
                                        size={15}
                                        allowHover={false}
                                        disableFillHover={true}
                                    />
                                    <span>{reviewsSummary.reviewCount} Review{reviewsSummary.reviewCount > 1 ? 's' : ''}</span>
                                    </li>
                                    <li>
                                    <Link to="#">{reviewsSummary.ratings}</Link>
                                    </li>
                                </ul>
                            </div> 
                            : 
                            null
                        }
                    </div> */}
                    </div>
                </div>
                <div className="banner-bottom">
                    <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="banner-tag">
                        <ul>
                            {/* <li>
                            {
                            isAuthenticated == true && userType==1 &&   
                            <Link to="#" onClick={()=>setShowModal(true)}>
                                <i className="flaticon-writing" />
                                Add Review  
                            </Link>  
                            }                                  
                            </li> */}
                            <li>
                                <Link onClick={()=>setShowShareModal(true)} to="#">
                                    <i className="fa fa-share-nodes"></i>
                                    &nbsp;&nbsp;Share
                                </Link> 
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="banner-profile">
                            <div style={{ backgroundColor: status=='Closed'?"#f14300":"#028311"}} className="banner-status">
                                <a href="#"><h3>{status}</h3></a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="banner-list">

                    <div className="row align-items-center">
                        
                    <div className="listing-details-area pb-70 p-5">
                        <div className="container myContainer2">
                            <div className="row">
                                <div className="col-lg-8">
                                <div className="listing-details-wrap">
                                
                                    <div className="listing-widget listing-widget-desc">
                                    <h3 className="title"> <img style={{ width: "20px", height: "20px" }} src={list} /> Description</h3>
                                    <div style={{ padding: "10px", textAlign:"justify" }}> 
                                        
                                        {parse(DOMPurify.sanitize(data.placesDescription))}
                                    </div>
                                    
                                    
                                    </div>
                                    {imageArray.length > 0 &&
                                    <div className="listing-widget gallery-slider p-2">
                                        <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={gallery} /> Gallery / Photos</h3>

                                        <ResponsiveMasonry
                                            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                                        >
                                            <Masonry gutter = "10px">
                                            {imageArray.map((image,index)=>(
                                                
                                                <img className="gallery" style={{ borderRadius: '10px' }} key={index} onClick={()=>handleImage(`${imagePath}${image}`,index)} src={`${imagePath}${image}`} alt="Images" crossOrigin="Anonymous"  />
                                                
                                            ))}
                                            </Masonry>
                                        </ResponsiveMasonry>
                        
                                    </div>
                                    }

                                    <div className="listing-widget gallery-slider p-2">
                                        <h3 className="title"><i className="fa fa-map-location-dot"></i> Map View</h3>

                                    {isLoaded && position?.lat && (
                                        <GoogleMap
                                            mapContainerStyle={{
                                                width: "100%",
                                                height: "500px",
                                                marginTop: "10px",
                                            }}
                                            center={position}
                                            zoom={18}
                                            onLoad={(map) => setMap(map)}
                                        >
                                            <MarkerF position={position} />
                                        </GoogleMap>
                                    )}
                        
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="listing-details-side">

                                        <div className="listing-widget-side">
                                                    <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={time} /> Opening Hours</h3>
                                                    <ul className="listing-widget-list">
                                                        <li>Sunday <span> {!data?.placesOperatingTimes?.sunday?.startTime ? "Closed" : `${data?.placesOperatingTimes?.sunday?.startTime} - ${data?.placesOperatingTimes?.sunday?.endTime}` }</span></li>
                                                        <li>Monday <span>{!data?.placesOperatingTimes?.monday?.startTime ? "Closed" : `${data?.placesOperatingTimes?.monday?.startTime} - ${data?.placesOperatingTimes?.monday?.endTime}`}</span></li>
                                                        <li>Tuesday <span>{!data?.placesOperatingTimes?.tuesday?.startTime ? "Closed" : `${data?.placesOperatingTimes?.tuesday?.startTime} - ${data?.placesOperatingTimes?.tuesday?.endTime}` }</span></li>
                                                        <li>Wednesday <span>{!data?.placesOperatingTimes?.wednesday?.startTime ? "Closed" : `${data?.placesOperatingTimes?.wednesday?.startTime} - ${data?.placesOperatingTimes?.wednesday?.endTime}` }</span></li>
                                                        <li>Thursday <span>{!data?.placesOperatingTimes?.thursday?.startTime ? "Closed" : `${data?.placesOperatingTimes?.thursday?.startTime} - ${data?.placesOperatingTimes?.thursday?.endTime}` }</span></li>
                                                        <li>Friday <span>{!data?.placesOperatingTimes?.friday?.startTime ? "Closed" : `${data?.placesOperatingTimes?.friday?.startTime} - ${data?.placesOperatingTimes?.friday?.endTime}` }</span></li>
                                                        <li>Saturday <span>{!data?.placesOperatingTimes?.saturday?.startTime ? "Closed" : `${data?.placesOperatingTimes?.saturday?.startTime} - ${data?.placesOperatingTimes?.saturday?.endTime}` }</span></li>
                                                    </ul>
                                        </div>
                                       
                                        <div className="listing-widget-side">
                                            <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={contact} /> Contact</h3>
                                            <ul className="listing-widget-list">
                                                <li>Address : <span>{data?.placesAddress}</span></li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                    </div>    

                </div>
                </div>
            </div>
            {/* Inner Banner End */}
        </div>

        {
        showImageModal &&
        <ImageModal img={currentModalImage} close={handleCloseSlideShow} left={moveSlideShowLeft} right={moveSlideShowRight} />
        }

        <Modal 
        show={showShareModal} 
        /* fullscreen={true} */
        size="lg"
        scrollable={false}
        centered
        backdrop="static"
        keyboard={false} 
        onHide={()=>setShowModal(false)}>
            <Modal.Body>
            <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowShareModal(false)} className="fa fa-circle-xmark"></i>

            <div style={{ padding: '5px' }} className="row">

                        <div className="col-4 text-center p-5">
                            <EmailShareButton 
                                url={`${shareURLPath}/place/${id}`}
                                title={`${data.placesFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px" }} className="fa fa-envelope"></i>
                            </EmailShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <LinkedinShareButton 
                                url={`${shareURLPath}/place/${id}`}
                                title={`${data.placesFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#0072b1" }} className="fa-brands fa-linkedin"></i>
                            </LinkedinShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <FacebookShareButton 
                                url={`${shareURLPath}/place/${id}`}
                                title={`${data.placesFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#3b5998" }} className="fa-brands fa-facebook"></i>
                            </FacebookShareButton>
                        </div>


                        <div className="col-4 text-center p-5">
                            <PinterestShareButton 
                                url={`${shareURLPath}/place/${id}`}
                                title={`${data.placesFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#c8232c" }} className="fa-brands fa-pinterest"></i>
                            </PinterestShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <RedditShareButton 
                                url={`${shareURLPath}/place/${id}`}
                                title={`${data.placesFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#FF4500" }} className="fa-brands fa-reddit"></i>
                            </RedditShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <TelegramShareButton 
                                url={`${shareURLPath}/place/${id}`}
                                title={`${data.placesFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#4995be" }} className="fa-brands fa-telegram"></i>
                            </TelegramShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <TwitterShareButton
                                url={`${shareURLPath}/place/${id}`}
                                title={`${data.placesFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#1DA1F2" }} className="fa-brands fa-twitter"></i>
                            </TwitterShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <WhatsappShareButton
                                url={`${shareURLPath}/place/${id}`}
                                title={`${data.placesFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color:"#128C7E" }} className="fa-brands fa-whatsapp"></i>
                            </WhatsappShareButton>
                        </div>

                       
            </div>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default Places