import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { useNavigate, useLocation } from "react-router-dom";
import { ListingEditValidation } from "../../../utils/validations/ListingEditValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import {useJsApiLoader,Autocomplete} from "@react-google-maps/api";
import Geocode from "react-geocode";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
const imagePath = `${serverPath}/api/frontend/listings/`;


const EditListing = () => {

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY);
    const [autocomplete, setAutocomplete] = useState(/** @type google.maps.Map.Autocomplete */ (null));
    const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
    region: "uk",
    });

      const placeHasChanged = () => {
          const res = autocomplete.getPlace();
          const lat = res?.geometry?.location?.lat?.();
          const lng = res?.geometry?.location?.lng?.();
          Geocode.fromLatLng(lat, lng).then(
              (response) => {
                 setForm(prev=>({ 
                    ...prev, 
                    'listingAddress': response.results[0].formatted_address ,
                    'listingLat': lat,
                    'listingLng': lng,
                  })); 
              },
              (error) => {
                  dispatchNotification('error',error);
              }
          ); 
    };

      const location = useLocation();

      const defaultForm = {
            listingId: location.state.id,
            listingName: "",
            listingAmount: "",
            listingBedrooms: "",
            listingBathrooms: "",
            listingDetails: "",
            listingAddress: "",
            listingLat: "",
            listingLng: "",
            listingEPC: "",
            listingSize: "",
            listingFeatures: [],
            listingMainPicture: "",
            listingOthersPicture:"",
            removeMainBanner: false,
            removeMainBannerName: "",
            removeOtherImages:[],
      };
      const [form, setForm] = useState(defaultForm);
      const [mainImage,setMainImage] = useState("");
      const [additionalImages,setAdditionalImages] = useState([]);

    
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();
      const maxSize = 600;//KB

      const getInitializationData = async () =>{
        await postOrGet(`listing/edit/${location.state.id}`,'get')
            .then((data) =>{
                    if(data){            
                        let ev = data.listing;
                 
                        if(ev.listingFeatures && ev.listingFeatures !="" && ev.listingFeatures !=null ){
                         ev.listingFeatures=ev.listingFeatures.split(",");   
                        }else{
                           ev.listingFeatures=[]; 
                        }

                        if(ev.listingMainPicture){
                          setMainImage(ev.listingMainPicture);
                        }
                        delete ev.listingMainPicture;

                        if(ev.listingOthersPictures ){
                          setAdditionalImages(ev.listingOthersPictures.split(","));
                        }
                        delete ev.listingOthersPictures;

                        setForm(prev=>({ ...prev,...ev}));
                    }
            }); 
      }

      useEffect(() => {
        getInitializationData();
      },[]);

      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      };

      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
           ListingEditValidation.validate(form, { abortEarly: false })
            .then(async (values)=>{
                const form_data = new FormData();
                form_data.append('additionalImages', JSON.stringify(additionalImages));
                for ( var key in form ) {
                      if(key=="listingOthersPicture"  && form.listingOthersPicture?.length){
                        let a=1;
                          Object.keys(form.listingOthersPicture).forEach((key)=>{
                            form_data.append(`otherPictures${a}`, form.listingOthersPicture.item(key))
                            a++;
                          }); 
                      }
                      else if(key=="listingFeatures"){
                        form_data.append(key, JSON.stringify(form[key]));
                      }
                    else{
                        form_data.append(key, form[key]);
                      }
                }

                await postOrGet('listing/save/edit','post',form_data,{"Content-Type": "multipart/form-data" })
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Edits saved successfully."); 
                       // setForm(defaultForm);
                       // setTimeout(() => navigate(-1),2000); 
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
        }
      
      const handleBannerMainPictureUpload = (e) =>{
        /* if(e.target?.files[0]?.size/1024 > maxSize){
          e.target.value = null;
          dispatchNotification('error',`Main Image should be less than ${maxSize}KB`);
        }
        else */
            setForm(prev=>({ ...prev, 'listingMainPicture': e.target.files[0] }))
        
      }

      const handleMultipleImages = (e) =>{
        /* for(let key in e.target.files){
            if(e.target.files[key]?.size/1024 > maxSize){
              e.target.value = null;
              dispatchNotification('error',`Images cannot be more than ${maxSize}KB`);
              break;
            }
        } */
        setForm(prev=>({ ...prev, 'listingOthersPicture': e.target.files }))
      }
	
	
	return (
            <div className="card-body px-0 pt-0 pb-2">	
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <div style={{ padding: 20 }}>
                    <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                        <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                        <span className="btn-inner--text">GO BACK</span>
                        </button>
                    <div className="card mt-4" id="basic-info">
                        <div className="card-header">
                        <h6 className="text-primary">Edit Listing</h6></div>
                        <div className="card-body pt-0">

                        <div className="row">
                            <div className="col-12">
                            <div className="input-group input-group-static">
                                <label htmlFor="listingName">Listing Name</label>
                                <input
                                autoComplete="off"
                                type="text"
                                name="listingName"
                                id="listingName"
                                className="form-control"
                                placeholder="Enter Listing Name"
                                value={form.listingName}
                                onChange={(e)=>handleChange(e)}					
                                />
                            </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                            <div className="input-group input-group-static">
                                <label htmlFor="listingAmount">Listing Amount(Monthly)</label>
                                <input
                                autoComplete="off"
                                type="text"
                                name="listingAmount"
                                id="listingAmount"
                                className="form-control"
                                placeholder="Enter Listing Name"
                                value={form.listingAmount}
                                onChange={(e)=>handleChange(e)}					
                                />
                            </div>
                            </div>
                        </div>

                         <div className="row mt-4">
                            <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="listingDetails">Listing Details</label>
                                    <textarea 
                                    className="form-control" 
                                    name="listingDetails"
                                    id="listingDetails" 
                                    rows="3"
                                    onChange={(e)=>handleChange(e)}	
                                    value={form.listingDetails}
                                    />
                                </div>
                            </div>
                        </div>  

                        {isLoaded && (
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="listingAddress">Listing Address</label>
                                    <Autocomplete
                                        onLoad={(a) => setAutocomplete(a)}
                                        onPlaceChanged={(e) => placeHasChanged(e)}
                                        className="form-control"
                                            >
                                            <input
                                            autoComplete="off"
                                            type="text"
                                            name="listingAddress"
                                            id="listingAddress"
                                            className="form-control"
                                            placeholder="Search Listing Address"
                                            value={form.listingAddress}
                                            onChange={(e)=>handleChange(e)}						
                                            />
                                    </Autocomplete>
                                </div>
                            </div>
                        </div>
                        )}


                        <div className="row mt-4">
                            <div className="col-3">
                                <div className="input-group input-group-static">
                                    <label htmlFor="listingBedrooms">No. of Bedrooms</label>
                                    <select
                                        className="form-control"
                                        name="listingBedrooms"
                                        id="listingBedrooms"
                                        autoComplete="off"
                                        value={form.listingBedrooms}
                                        onChange={(e)=>handleChange(e)}	
                                        >
                                        <option value="">--Select No of Bedrooms--</option>  
                                        <option value="1">1</option>  
                                        <option value="2">2</option>  
                                        <option value="3">3</option>  
                                        <option value="4">4</option>  
                                        <option value="5">5</option>  
                                        <option value="6">6</option>  
                                        <option value="7">7</option>  
                                        <option value="8">8</option>  
                                        <option value="9">9</option>  
                                    </select>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="input-group input-group-static">

                                    <label htmlFor="listingBathrooms">No. of Bathrooms</label>
                                    <select
                                        className="form-control"
                                        name="listingBathrooms"
                                        id="listingBathrooms"
                                        autoComplete="off"
                                        value={form.listingBathrooms}
                                        onChange={(e)=>handleChange(e)}	
                                        >
                                        <option value="">--Select No of Bathrooms--</option>  
                                        <option value="1">1</option>  
                                        <option value="2">2</option>  
                                        <option value="3">3</option>  
                                        <option value="4">4</option>  
                                        <option value="5">5</option>  
                                        <option value="6">6</option>  
                                        <option value="7">7</option>  
                                        <option value="8">8</option>  
                                        <option value="9">9</option>  
                                    </select>
                                </div>
                            </div>

                            <div className="col-3">
                                    <div className="input-group input-group-static">
                                        <label htmlFor="listingEPC">EPC Rating</label>
                                        <select
                                        className="form-control"
                                        name="listingEPC"
                                        id="listingEPC"
                                        autoComplete="off"
                                        value={form.listingEPC}
                                        onChange={(e)=>handleChange(e)}	
                                        >
                                        <option value="">--Select EPC Rating--</option>  
                                        <option value="A">A</option>  
                                        <option value="B">B</option>  
                                        <option value="C">C</option>  
                                        <option value="D">D</option>  
                                        <option value="E">E</option>  
                                        <option value="F">F</option>  
                                        </select>
                                    </div>
                            </div>

                            <div className="col-3">
                                <div className="input-group input-group-static">
                                    <label htmlFor="listingSize">Listing Size</label>
                                    <input
                                    autoComplete="off"
                                    type="text"
                                    name="listingSize"
                                    id="listingSize"
                                    className="form-control"
                                    placeholder="Enter No. of Bathrooms"
                                    value={form.listingSize}
                                    onChange={(e)=>handleChange(e)}						
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">
                                <label htmlFor="">Listing Features/Amenities</label>
                                <TagsInput value={form.listingFeatures} onChange={(tag)=>{
                                    setForm(prev=>({ ...prev, 'listingFeatures': tag})); 
                                }} />
                            </div>
                        </div>    


                        <div className="row mt-4">

                            {mainImage && 
                                <div className="col-12 text-center">
                                    <img src={`${imagePath}${mainImage}`} alt="img-blur-shadow" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                                    <br/>
                                    <button onClick={()=>{
                                        setMainImage("");
                                        setForm(prev=>({ ...prev, 'removeMainBanner': true , 'removeMainBannerName' : mainImage }))
                                    }} className="btn btn btn-primary mt-2">Remove</button>
                                </div>
                            }

                            <div className="col-12">
                                <div className="input-group input-group-static">
                                    <label htmlFor="listingMainPicture">Listing Main Image <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                                    <input
                                    autoComplete="off"
                                    type="file"
                                    name="listingMainPicture"
                                    id="listingMainPicture"
                                    className="form-control"
                                    onChange={(e)=>handleBannerMainPictureUpload(e)}	
                                    accept=".jpg, .jpeg, .png"			
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="row">
                                {additionalImages.length > 0 && additionalImages.map(img=>
                                (
                                <div key={img} className="col-3 text-center">
                                    <img src={`${imagePath}${img}`} alt="Additional Images" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                                    <button onClick={()=>{
                                                const newIms = additionalImages.filter(im=>im!=img);
                                                setAdditionalImages(newIms)
                                                setForm(prev=>({ ...prev, 'removeOtherImages': [...prev.removeOtherImages,img] }))
                                                }} 
                                className="btn btn btn-primary mt-2">Remove</button>
                                </div>
                                ))}
                            </div>

                            <div className="col-12">
                            <div className="input-group input-group-static">
                                <label htmlFor="multipleImages">Additional Images <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                                <input
                                autoComplete="off"
                                type="file"
                                id="multipleImages"
                                className="form-control"
                                multiple={true}
                                onChange={(e)=>handleMultipleImages(e)}		
                                accept=".jpg, .jpeg, .png"		
                                />
                            </div>
                            </div>
                        </div>

                        
                        <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                        <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                        <span className="btn-inner--text">SAVE CHANGES</span>
                        </button>
                        </div>
                    </div>
                    </div>
                </form>
            </div> );
}

export default EditListing