import {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGlobalNotification from '../../utils/hooks/useGlobalNotification';
import { useJsApiLoader,Autocomplete} from "@react-google-maps/api";
import Geocode from "react-geocode";
import useAxios from "../../utils/hooks/useAxios";
import {SET_ADDRESS, SET_COORDINATES} from '../../redux/slice/authSlice';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import { Player } from "@lottiefiles/react-lottie-player";
import noDataLottie from "../../assets/lottie/no_data.json";
import {weekdays} from '../../utils/weekdays_months';
import { NavLink, Link, useLocation } from "react-router-dom";
import moment from 'moment';
import { Rating } from 'react-simple-star-rating';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./Explore.scss";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const libraries = ['places'];
const Explore = () => {
    const [followedBiz,setFollowedBiz] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);

    //default, properties, deals, places , community , events
    const [navCategory, setNavCategory] = useState("");

    const buttonElement = useRef();
    const buttonElementProperties = useRef();
    const buttonElementDeals = useRef();
    const buttonElementPlaces = useRef();
    const buttonElementCommunity = useRef();
    const buttonElementEvents = useRef();
    const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
    const imagePath = `${serverPath}/api/frontend/images/`;
    const eventPath = `${serverPath}/api/frontend/events/`;
    const listingPath = `${serverPath}/api/frontend/listings/`;

    const {
        address,isAuthenticated,coordinates,
        categories,subcategories,
        categoriesPlaces,subcategoriesPlaces,
        categoriesCommunity,subcategoriesCommunity,
        categoriesEvents,subcategoriesEvents} = useSelector(state=> state.auth);
    const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries,
		region: "uk",
	});
    const [autocomplete, setAutocomplete] = useState((null));
    const [subcategory,setSubcategory] = useState(subcategories);
    const [subcategoryPlaces,setSubcategoryPlaces] = useState(subcategoriesPlaces);
    const [subcategoryCommunity,setSubcategoryCommunity] = useState(subcategoriesCommunity);
    const [subcategoryEvents,setSubcategoryEvents] = useState(subcategoriesEvents);
    const [trigger,setTrigger] = useState("");

    const location = useLocation();

    const defaultForm = {
        address,
        radius: 5,//miles
        cat:"",
		sub: "",
		q: "",//query string
		ob: "proximity",//order by
    };

    const defaultPropertiesForm = {
        address,
        radius: 5,//miles
        bedrooms:"",
        bathrooms:"",
        minimumAmount:0,
        maximumAmount:0,
        ob: "amount",//proximity //order by
    }

    const defaultDealsForm = {
        address,
        radius: 5,//miles
        timeFrame:"today",
		specificDate: "",
        cat:"",
		sub: "",
    }

    const defaultPlacesForm = {
        address,
        radius: 5,//miles
        cat:"",
		sub: "",
		q: "",//query string
		ob: "proximity",//order by
    };

    const defaultCommunityForm = {
        address,
        radius: 5,//miles
        cat:"",
		sub: "",
		q: "",//query string
		ob: "proximity",//order by
    };

    const defaultEventsForm = {
        address,
        radius: 5,//miles
        timeFrame:"today",
		specificDate: "",
        cat:"",
		sub: "",
    };

    if(sessionStorage.getItem('location')){
        let location = JSON.parse(sessionStorage.getItem('location'));

        //default Form
        defaultForm.lat = location.lat;
        defaultForm.lng = location.lng;
        defaultForm.address = location.address;

        //dealsForm
        defaultDealsForm.lat = location.lat;
        defaultDealsForm.lng = location.lng;
        defaultDealsForm.address = location.address;

        //propertiesForm
        defaultPropertiesForm.lat = location.lat;
        defaultPropertiesForm.lng = location.lng;
        defaultPropertiesForm.address = location.address;

        //placesForm
        defaultPlacesForm.lat = location.lat;
        defaultPlacesForm.lng = location.lng;
        defaultPlacesForm.address = location.address;

        //communityForm
        defaultCommunityForm.lat = location.lat;
        defaultCommunityForm.lng = location.lng;
        defaultCommunityForm.address = location.address;

        //eventsForm
        defaultEventsForm.lat = location.lat;
        defaultEventsForm.lng = location.lng;
        defaultEventsForm.address = location.address;
        
    }
    else{

        //default Form
        defaultForm.lat = coordinates.lat;
        defaultForm.lng = coordinates.lng;
        defaultForm.address = address;

        //dealsForm
        defaultDealsForm.lat = location.lat;
        defaultDealsForm.lng = location.lng;
        defaultDealsForm.address = address;

        //propertiesForm
        defaultPropertiesForm.lat = location.lat;
        defaultPropertiesForm.lng = location.lng;
        defaultPropertiesForm.address = location.address;

        //placesForm
        defaultPlacesForm.lat = location.lat;
        defaultPlacesForm.lng = location.lng;
        defaultPlacesForm.address = location.address;

        //communityForm
        defaultCommunityForm.lat = location.lat;
        defaultCommunityForm.lng = location.lng;
        defaultCommunityForm.address = location.address;

        //eventsForm
        defaultEventsForm.lat = location.lat;
        defaultEventsForm.lng = location.lng;
        defaultEventsForm.address = location.address;
    }

    const [form, setForm] = useState(defaultForm);
    const [dealsForm, setDealsForm] = useState(defaultDealsForm);
    const [propertiesForm, setPropertiesForm] = useState(defaultPropertiesForm);
    const [placesForm, setPlacesForm] = useState(defaultPlacesForm);
    const [communityForm, setCommunityForm] = useState(defaultCommunityForm);
    const [eventsForm, setEventsForm] = useState(defaultEventsForm);

    const [data, setData] = useState([]);
    const [propertiesData, setPropertiesData] = useState([]);
    const [dealsData, setDealsData] = useState([]);
    const [placesData, setPlacesData] = useState([]);
    const [communityData, setCommunityData] = useState([]);
    const [eventsData, setEventsData] = useState([]);

    const [reviews, setReviews] = useState({});
    const [openClose, setOpenClose] = useState({});

    const dispatch = useDispatch();
    const {postOrGet}  = useAxios();
    const {dispatchNotification} = useGlobalNotification();

    const [paginationData, setPaginationData] = useState({
		size: 20,
		currentPage: 1,
		nextPage:0,
		previousPage: 0,
		itemsCount: 0,
		pagesCount: 0
	});

    const getPaginationBody = (pageCount) =>{
	    
		let count=0;
		let body = [];
	
		for(let i = paginationData.currentPage; i < pageCount; i++){
			if(i == 1) continue;//skip page 1
			if(count == 5) break;//show only 5 links at anytime
			body.push(i);
			count++;
		}
		return body;	
	}

    const placeHasChanged = () => {
        const res = autocomplete.getPlace();
		const lat = res?.geometry?.location?.lat?.();
		const lng = res?.geometry?.location?.lng?.();

		const newPosition = { lat, lng };
        dispatch(SET_COORDINATES(newPosition));

        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                setForm(prev=>({ ...prev, 'address': response.results[0].formatted_address, lat,lng })); 
                setDealsForm(prev=>({ ...prev, 'address': response.results[0].formatted_address, lat,lng })); 
                setPropertiesForm(prev=>({ ...prev, 'address': response.results[0].formatted_address, lat,lng })); 
                setPlacesForm(prev=>({ ...prev, 'address': response.results[0].formatted_address, lat,lng })); 
                setCommunityForm(prev=>({ ...prev, 'address': response.results[0].formatted_address, lat,lng })); 
                setEventsForm(prev=>({ ...prev, 'address': response.results[0].formatted_address, lat,lng })); 

                sessionStorage.setItem('location', JSON.stringify({ lat, lng, address:response.results[0].formatted_address })); 
                dispatch(SET_ADDRESS(response.results[0].formatted_address)); 
            },
            (error) => {
                dispatchNotification('error',error);
            }
        ); 
	};

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm(prev=>({ ...prev, [name]: value })); 
        setPropertiesForm(prev=>({ ...prev, [name]: value })); 
        setDealsForm(prev=>({ ...prev, [name]: value })); 
        setPlacesForm(prev=>({ ...prev, [name]: value })); 
        setCommunityForm(prev=>({ ...prev, [name]: value })); 
        setEventsForm(prev=>({ ...prev, [name]: value })); 
   };

   const handleCategoryChange = async (e) =>{
        if (e.target.value==""){
            handleChange(e);
            setForm(prev=>({ ...prev, "sub": "" })); 
            setDealsForm(prev=>({ ...prev, "sub": "" })); 
        }else{
            handleChange(e);
            //dispatchNotification('success','Fetching associated subcategories...');
            await postOrGet(`frontend/subcategories/fetch?businessCategory=${e.target.value}`,'get',{})
                .then((data) =>{
                    if(data){
                      setSubcategory(data.subcategories);
                    }
            });
        }  
    }

    const handleCustomCategoryChange = async (e,type) =>{

        const name = e.target.name;
        const value = e.target.value;

        if (value!=""){   
            await postOrGet(`frontend/${type}/subcategories/fetch?category=${value}`,'get',{})
                .then((data) =>{
                    if(data){
                        switch(type){
                            case 'places':
                                    setPlacesForm(prev=>({ ...prev, [name]: value })); 
                                    setSubcategoryPlaces(data.subcategories);
                                    break;
                            case 'community':
                                    setCommunityForm(prev=>({ ...prev, [name]: value })); 
                                    setSubcategoryCommunity(data.subcategories);
                                    break;
                                break;
                            case 'events':
                                    setEventsForm(prev=>({ ...prev, [name]: value })); 
                                    setSubcategoryEvents(data.subcategories);
                                    break;
                                break;    
                        }
                    }
            });
        }else{
            switch(type){
                            case 'places':
                                    setPlacesForm(prev=>({ ...prev, [name]: value })); 
                                    break;
                            case 'community':
                                    setCommunityForm(prev=>({ ...prev, [name]: value })); 
                                    break;
                                break;
                            case 'events':
                                    setEventsForm(prev=>({ ...prev, [name]: value })); 
                                    break;
                                break;    
                }
            
        }
    }

   const handleLikeClick = async (e,bizId) =>{
       e.preventDefault();

       let action = followedBiz.includes(bizId) ? "unfollow" : "follow";

       postOrGet('frontend/follow/unfollow','post',{bizId,action })
        .then((data) =>{
            if(data){
                if(action == "follow"){
                  setFollowedBiz(prev=>([ ...prev, bizId ])); 
                }else{
                    let newAr = followedBiz.filter(id=>id != bizId );
                    setFollowedBiz(newAr);
                }
            }
        });
    } 

   const handleSubmit = async (page = paginationData.currentPage,size = paginationData.size) => {
        
        await postOrGet(`frontend/search?page=${page}&size=${size}`,'post',form)
        .then((data) =>{
            if(data){

                setPaginationData(data.pagination);

                const dayN = new Date().getDay();
                const day = weekdays[dayN];
                const openOrClose = {};
                data?.results.forEach(biz=>{
                        // console.log("Day is" + day + "I am here.--->",biz.businessFullName);
                        if( !(day in biz.businessOperatingTimes)){
                                openOrClose[biz.businessID] = "closed";
                        }
                        else if(biz.businessOperatingTimes[day]['startTime']=="" || biz.businessOperatingTimes[day]['endTime']==""){
                            openOrClose[biz.businessID] = "Closed";
                        }else{
                            const format = 'HH:mm';
                            const currentTime = moment(moment(),format), startTime = moment(biz.businessOperatingTimes[day]["startTime"], format),endTime = moment(biz.businessOperatingTimes[day]["endTime"], format);
                            openOrClose[biz.businessID] = currentTime.isBetween(startTime, endTime) ? "Open" : "Closed";  
                        }
                });
                setOpenClose(openOrClose);
                if(data.resultReview.length>0){
                    let resultReview={};
                    for(let el of data.resultReview){
                        resultReview[el.businessId]=el.ratings;
                    }
                    setReviews(resultReview);
                }
                setData(data.results);
                if(data?.followedBiz){
                    setFollowedBiz(data.followedBiz);
                   }
                setShowFilterModal(false);
            }
        });  
   }

   const handlePropertiesSubmit = async(page = paginationData.currentPage,size = paginationData.size)=>{
    await postOrGet(`frontend/all/properties?page=${page}&size=${size}`,'post',propertiesForm)
            .then((data) =>{
                if(data){ 
                    setPaginationData(data.pagination);
                    setPropertiesData(data.results);  
                    setShowFilterModal(false); 
                }
        });
   }
   
   const handleDealsSubmit = async(page = paginationData.currentPage,size = paginationData.size)=>{
        await postOrGet(`frontend/all/deals?page=${page}&size=${size}`,'post',dealsForm)
            .then((data) =>{
                if(data){ 
                    setPaginationData(data.pagination);
                    setDealsData(data.results);  
                    setShowFilterModal(false) 
                }
        });

   }

   const handlePlacesSubmit = async(page = paginationData.currentPage,size = paginationData.size)=>{
        await postOrGet(`frontend/all/places?page=${page}&size=${size}`,'post',placesForm)
            .then((data) =>{
                if(data){ 
                    setPaginationData(data.pagination);
                    setPlacesData(data.results);  
                    setShowFilterModal(false) 
                }
        });

   }

   const handleCommunitySubmit = async(page = paginationData.currentPage,size = paginationData.size)=>{
        await postOrGet(`frontend/all/community?page=${page}&size=${size}`,'post',communityForm)
            .then((data) =>{
                if(data){ 
                    setPaginationData(data.pagination);
                    setCommunityData(data.results);  
                    setShowFilterModal(false) 
                }
        });

   }

   const handleEventsSubmit = async(page = paginationData.currentPage,size = paginationData.size)=>{
        await postOrGet(`frontend/all/events?page=${page}&size=${size}`,'post',eventsForm)
            .then((data) =>{
                if(data){ 
                    setPaginationData(data.pagination);
                    setEventsData(data.results);  
                    setShowFilterModal(false) 
                }
        });

   }

   useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY);
    if(location?.state){

        switch(location?.state?.type){
            case "default":
                setForm(prev=>({ ...prev, "q": location.state.searchKeyword, "cat":location.state.categoryId }));
                setNavCategory("default");
                break;
            case "properties":
                setPropertiesForm(prev=>({ ...prev,"maximumAmount": location.state.propertyPrice}));
                setNavCategory("properties");
                break;
            case "places":
                setPlacesForm(prev=>({ ...prev, "q": location.state.searchKeyword, "cat":location.state.categoryId }));
                setNavCategory("places");
                break;
            case "community":
                setCommunityForm(prev=>({ ...prev, "q": location.state.searchKeyword, "cat":location.state.categoryId }));
                setNavCategory("community");
                break;
            case "events":
                setEventsForm(prev=>({ ...prev, "timeFrame": location.state.timeFrame, "cat":location.state.categoryId }));
                setNavCategory("events");
                break;
            case "deals":
                setDealsForm(prev=>({ ...prev, "timeFrame": location.state.timeFrame, "cat":location.state.categoryId }));
                setNavCategory("deals");
                break;
        }


        //trigger
        setTrigger(Math.random() + Math.random());
        return;
    }
    else if(location?.pathname=="/explore"){
        setNavCategory("default");
        return;
    }
    else if(location?.pathname=="/deals"){
        setNavCategory("deals");
        setTrigger(Math.random() + Math.random());
        return;
    }
    else if(location?.pathname.includes("/places")){
        setNavCategory("places");
        //places cat and sub cat
        const pat = location.pathname.split('/places/')[1];
        let cat = "";
        let sub = "";
        if(pat.includes("/")){
          const arr = pat.split("/");
          cat = window.atob(arr[0]);
          sub = window.atob(arr[1]);
          setPlacesForm(prev=>({ ...prev, cat,sub }));
        }else{
          setPlacesForm(prev=>({ ...prev, 'cat': window.atob(pat) }));
        }
                
        setTrigger(Math.random() + Math.random());
        return;
    }
    else if(location?.pathname.includes("/community")){  
        setNavCategory("community");
        //community cat and sub cat
        const pat = location.pathname.split('/community/')[1];
        let cat = "";
        let sub = "";
        if(pat.includes("/")){
          const arr = pat.split("/");
          cat = window.atob(arr[0]);
          sub = window.atob(arr[1]);
          setCommunityForm(prev=>({ ...prev, cat,sub }));
        }else{
          setCommunityForm(prev=>({ ...prev, 'cat': window.atob(pat) }));
        }
                
        setTrigger(Math.random() + Math.random());
        return;
    }
    else if(location?.pathname.includes("/events")){  
        setNavCategory("events");
        //events cat and sub cat
        const pat = location.pathname.split('/events/')[1];
        let cat = "";
        let sub = "";
        if(pat.includes("/")){
          const arr = pat.split("/");
          cat = window.atob(arr[0]);
          sub = window.atob(arr[1]);
          setEventsForm(prev=>({ ...prev, cat,sub }));
        }else{
          setEventsForm(prev=>({ ...prev, 'cat': window.atob(pat) }));
        }
                
        setTrigger(Math.random() + Math.random());
        return;
    }
    else if(location?.pathname){
        setNavCategory("default");
        //listing cat and sub cat
        const pat = location.pathname.split('/explore/')[1];
        let cat = "";
        let sub = "";
        if(pat.includes("/")){
          const arr = pat.split("/");
          cat = window.atob(arr[0]);
          sub = window.atob(arr[1]);
          setForm(prev=>({ ...prev, cat,sub }));
        }else{
          setForm(prev=>({ ...prev, 'cat': window.atob(pat) }));
        }
        setTrigger(Math.random() + Math.random());
        return;
    } 
   },[]);

   useEffect(() => {
       //trigger submit
        if(navCategory=="default"){
            buttonElement.current && buttonElement.current.click();
        }
        else if(navCategory=="properties"){
            buttonElementProperties.current && buttonElementProperties.current.click();
        }
        else if(navCategory=="deals"){
            buttonElementDeals.current && buttonElementDeals.current.click();
        }
        else if(navCategory=="places"){
            buttonElementPlaces.current && buttonElementPlaces.current.click();
        }
        else if(navCategory=="community"){
            buttonElementCommunity.current && buttonElementCommunity.current.click();
        }
        else if(navCategory=="events"){
            buttonElementEvents.current && buttonElementEvents.current.click();
        }
   },[trigger]);

  return (
    <div style={{ paddingTop : 40 }} className="listing-widget-section pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 responsiveFilter">
                    <div className="listing-section-right">
                        <h3 className="title" style={{ marginBottom: 0 }}> <i className="flaticon-filter" /> Filters</h3>
                        <div className="listing-right-form">
                            

                            <div className="col-lg-12">
                                <label>Search Radius (Miles) :</label>
                                <div className="form-group">
                                    <i className="flaticon-filter" />
                                    <select 
                                    autoComplete="off"
                                    className="form-control"
                                    name="radius"
                                    value={form.radius}
                                    onChange={e=>handleChange(e)} 
                                    >
                                    {/* <option value="">Any Location</option> */}
                                    <option value="1">Within 1 Mile</option>
                                    <option value="5">Within 5 Miles</option>
                                    <option value="10">Within 10 Miles</option>
                                    <option value="20">Within 20 Miles</option>
                                    <option value="50">Within 50 Miles</option>
                                    <option value="100">Within 100 Miles</option>
                                    </select>	
                                </div>
                            </div> 

                            {navCategory === "default" &&
                            <form>
                                <div className="row">
                                
                                    <div className="col-lg-12">
                                        <label>Location: </label>
                                        <div className="form-group">
                                            <i className="flaticon-place" />
                                            {isLoaded && (
                                                <Autocomplete
                                                onLoad={(a) => setAutocomplete(a)}
                                                onPlaceChanged={(e) => placeHasChanged(e)}
                                                /* className="form-control form-group" */
                                                >
                                                <input name="address" value={form.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                </Autocomplete>)
                                            }
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="cat"
                                            value={form.cat}
                                            onChange={e=>handleCategoryChange(e)}
                                            >
                                            <option value="">All Listing Type</option>
                                            {
                                            categories.length > 0 && categories.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                            }
                                            </select>	
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Sub Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="sub"
                                            value={form.sub}
                                            onChange={e=>{
                                                setForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                setDealsForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                            }}
                                            >
                                            <option value="">All Sub Category</option>
                                            { subcategory?.length && subcategory.map((e) => (
                                                <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                ))
                                            }
                                            </select>	
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Search String: </label>
                                        <div className="form-group">
                                            <i className="flaticon-loupe" />
                                            <input name="q" value={form.q} onChange={e=>{
                                                setForm(prev=>({ ...prev, 'q': e.target.value })); 
                                            }} type="text" className="form-control" placeholder="What Are Searching*" autoComplete="off" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <label>Sort Criteria: </label>
                                        <div className="form-group">
                                            <i className="fa fa-sort"></i>
                                            <select 
                                            autoComplete="off"
                                            name="ob"
                                            value={form.ob}
                                            onChange={e=>{
                                                setForm(prev=>({ ...prev, 'ob': e.target.value })); 
                                            }}
                                            className="form-control">
                                            <option value="proximity">Proximity</option>
                                            <option value="latest">Latest</option>
                                            </select>	
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 text-center">
                                        <button onClick={()=>{handleSubmit()}} ref={buttonElement} type="button" className="default-btn">Apply Filter</button>
                                    </div>
                                </div>
                            </form>
                            }

                            {navCategory === "properties" &&
                            <form>
                                <div className="row">
                                
                                    <div className="col-lg-12">
                                        <label>Location: </label>
                                        <div className="form-group">
                                            <i className="flaticon-place" />
                                            {isLoaded && (
                                                <Autocomplete
                                                onLoad={(a) => setAutocomplete(a)}
                                                onPlaceChanged={(e) => placeHasChanged(e)}
                                                /* className="form-control form-group" */
                                                >
                                                <input name="address" value={form.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                </Autocomplete>)
                                            }
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label> <i style={{ color:"#f14300" }} className="fa fa-money-bill"></i>&nbsp;Price Range (&#163;): </label>
                                        <div className="form-group">
                                        
                                            <span style={{ fontSize:'11px' }}>Min</span>
                                            <input name="minimumAmount" value={propertiesForm.minimumAmount} 
                                            onChange={e=>{
                                                setPropertiesForm(prev=>({ ...prev, 'minimumAmount': e.target.value })); 
                                            }} 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Min" />	
                                            <span style={{ fontSize:'11px' }}>Max</span>
                                            <input name="maximumAmount" value={propertiesForm.maximumAmount} 
                                            onChange={e=>{
                                                setPropertiesForm(prev=>({ ...prev, 'maximumAmount': e.target.value })); 
                                            }} 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Max" />	
                                        </div>
                                    </div>

                                    
                                    <div className="col-lg-12">
                                        <label>No of Bedrooms: </label>
                                        <div className="form-group">
                                            <i className="fa fa-bed"></i>
                                            <select 
                                            autoComplete="off"
                                            name="bedrooms"
                                            value={propertiesForm.bedrooms}
                                            onChange={e=>{
                                                setPropertiesForm(prev=>({ ...prev, 'bedrooms': e.target.value })); 
                                            }}
                                            className="form-control">
                                            <option value="">Any:</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            </select>	
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>No of Bathrooms: </label>
                                        <div className="form-group">
                                            <i className="fa fa-bath"></i>
                                            <select 
                                            autoComplete="off"
                                            name="bathrooms"
                                            value={propertiesForm.bathrooms}
                                            onChange={e=>{
                                                setPropertiesForm(prev=>({ ...prev, 'bathrooms': e.target.value })); 
                                            }}
                                            className="form-control">
                                            <option value="">Any:</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            </select>	
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Sort Criteria: </label>
                                        <div className="form-group">
                                            <i className="fa fa-sort"></i>
                                            <select 
                                            autoComplete="off"
                                            name="ob"
                                            value={propertiesForm.ob}
                                            onChange={e=>{
                                                setPropertiesForm(prev=>({ ...prev, 'ob': e.target.value })); 
                                            }}
                                            className="form-control">
                                            <option value="amount">Amount</option>
                                            <option value="proximity">Proximity</option>
                                            </select>	
                                        </div>
                                    </div>


                                    <div className="col-lg-12 col-md-12 text-center">
                                        <button onClick={()=>handlePropertiesSubmit()} ref={buttonElementProperties} type="button" className="default-btn">Apply Filter</button>
                                    </div>
                                </div>
                            </form>
                            }

                            {navCategory === "deals" &&
                            <form>
                                <div className="row">

                                    <div style={{paddingTop: "20px"}}>
                                        <div className="row">
                                            {/* <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "anytime" }))}} className={`btn ${dealsForm.timeFrame == "anytime" ?  "btn-warning btn-sm" : "btn-sm btn-outline-warning" }`}>Anytime</button></div> */}
                                            <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "today" }))}} className={`btn ${dealsForm.timeFrame == "today" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Today</button></div>
                                            <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "this week" }))}} className={`btn ${dealsForm.timeFrame == "this week" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>This Week</button></div>
                                            <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "this weekend" }))}} className={`btn ${dealsForm.timeFrame == "this weekend" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>This Weekend</button></div>
                                            <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "next week" }))}} className={`btn ${dealsForm.timeFrame == "next week" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Next Week</button></div>
                                            <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "custom" }))}} className={`btn ${dealsForm.timeFrame == "custom" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Specific Date</button></div>
                                        </div>
                                    </div>

                                    {dealsForm.timeFrame == "custom" &&
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="input-group input-group-static">
                                                <label htmlFor=""><i className="fa fa-calendar"></i> Date</label>
                                                <Datetime
                                                    className="input-group input-group-static text-center"
                                                    closeOnSelect={true}
                                                    dateFormat={"YYYY-MM-DD"}
                                                    timeFormat={false}
                                                    inputProps={{
                                                    name: "specificDate",
                                                    value:dealsForm.specificDate
                                                    }}
                                                    onChange={(e) => {
                                                    const newDay = moment(new Date(e._d)).format("YYYY-MM-DD");
                                                    setDealsForm(prev=>({ ...prev, 'specificDate': newDay}));
                                                    }}
                                                    isValidDate={(current) => current.isAfter(moment().subtract(1, "day"))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                
                                    
                                    <div className="col-lg-12">
                                        <label>Location: </label>
                                        <div className="form-group">
                                            <i className="flaticon-place" />
                                            {isLoaded && (
                                                <Autocomplete
                                                onLoad={(a) => setAutocomplete(a)}
                                                onPlaceChanged={(e) => placeHasChanged(e)}
                                                /* className="form-control form-group" */
                                                >
                                                <input name="address" value={form.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                </Autocomplete>)
                                            }
                                        </div>
                                    </div>

                                

                                    <div className="col-lg-12">
                                        <label>Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="cat"
                                            value={form.cat}
                                            onChange={e=>handleCategoryChange(e)}
                                            >
                                            <option value="">All Listing Type</option>
                                            {
                                            categories.length > 0 && categories.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                            }
                                            </select>	
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Sub Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="sub"
                                            value={form.sub}
                                            onChange={e=>{
                                                setForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                setDealsForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                            }}
                                            >
                                            <option value="">All Sub Category</option>
                                            { subcategory?.length && subcategory.map((e) => (
                                                <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                ))
                                            }
                                            </select>	
                                        </div>
                                    </div>


                                    <div className="col-lg-12 col-md-12 text-center">
                                        <button onClick={()=>handleDealsSubmit()} ref={buttonElementDeals} type="button" className="default-btn">Apply Filter</button>
                                    </div>
                                </div>
                            </form>
                            }

                            {navCategory === "places" &&
                            <form>
                                <div className="row">
                                
                                    <div className="col-lg-12">
                                        <label>Location: </label>
                                        <div className="form-group">
                                            <i className="flaticon-place" />
                                            {isLoaded && (
                                                <Autocomplete
                                                onLoad={(a) => setAutocomplete(a)}
                                                onPlaceChanged={(e) => placeHasChanged(e)}
                                                /* className="form-control form-group" */
                                                >
                                                <input name="address" value={placesForm.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                </Autocomplete>)
                                            }
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="cat"
                                            value={placesForm.cat}
                                            onChange={e=>handleCustomCategoryChange(e,"places")}
                                            >
                                            <option value="">All Places Categories</option>
                                            {
                                            categoriesPlaces.length > 0 && categoriesPlaces.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                            }
                                            </select>	
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Sub Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="sub"
                                            value={placesForm.sub}
                                            onChange={e=>{ 
                                                setPlacesForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                            }}
                                            >
                                            <option value="">All Sub Category</option>
                                            { subcategoryPlaces?.length && subcategoryPlaces.map((e) => (
                                                <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                ))
                                            }
                                            </select>	
                                        </div>
                                    </div>


                                    <div className="col-lg-12">
                                        <label>Search String: </label>
                                        <div className="form-group">
                                            <i className="flaticon-loupe" />
                                            <input name="q" value={placesForm.q} onChange={e=>{
                                                setPlacesForm(prev=>({ ...prev, 'q': e.target.value })); 
                                            }} type="text" className="form-control" placeholder="What are you searching for?*" autoComplete="off" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <label>Sort Criteria: </label>
                                        <div className="form-group">
                                            <i className="fa fa-sort"></i>
                                            <select 
                                            autoComplete="off"
                                            name="ob"
                                            value={form.ob}
                                            onChange={e=>{
                                                setForm(prev=>({ ...prev, 'ob': e.target.value })); 
                                            }}
                                            className="form-control">
                                            <option value="proximity">Proximity</option>
                                            <option value="latest">Latest</option>
                                            </select>	
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 text-center">
                                        <button onClick={()=>handlePlacesSubmit()} ref={buttonElementPlaces} type="button" className="default-btn">Apply Filter</button>
                                    </div>
                                </div>
                            </form>
                            }

                            {navCategory === "community" &&
                            <form>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label>Location: </label>
                                        <div className="form-group">
                                            <i className="flaticon-place" />
                                            {isLoaded && (
                                                <Autocomplete
                                                onLoad={(a) => setAutocomplete(a)}
                                                onPlaceChanged={(e) => placeHasChanged(e)}
                                                /* className="form-control form-group" */
                                                >
                                                <input name="address" value={communityForm.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                </Autocomplete>)
                                            }
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="cat"
                                            value={communityForm.cat}
                                            onChange={e=>handleCustomCategoryChange(e,"community")}
                                            >
                                            <option value="">All Community Categories</option>
                                            {
                                            categoriesCommunity.length > 0 && categoriesCommunity.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                            }
                                            </select>	
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Sub Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="sub"
                                            value={communityForm.sub}
                                            onChange={e=>{ 
                                                setCommunityForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                            }}
                                            >
                                            <option value="">All Sub Category</option>
                                            { subcategoryCommunity?.length && subcategoryCommunity.map((e) => (
                                                <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                ))
                                            }
                                            </select>	
                                        </div>
                                    </div>


                                    <div className="col-lg-12">
                                        <label>Search String: </label>
                                        <div className="form-group">
                                            <i className="flaticon-loupe" />
                                            <input name="q" value={communityForm.q} onChange={e=>{
                                                setCommunityForm(prev=>({ ...prev, 'q': e.target.value })); 
                                            }} type="text" className="form-control" placeholder="What are you searching for?" autoComplete="off" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <label>Sort Criteria: </label>
                                        <div className="form-group">
                                            <i className="fa fa-sort"></i>
                                            <select 
                                            autoComplete="off"
                                            name="ob"
                                            value={form.ob}
                                            onChange={e=>{
                                                setForm(prev=>({ ...prev, 'ob': e.target.value })); 
                                            }}
                                            className="form-control">
                                            <option value="proximity">Proximity</option>
                                            <option value="latest">Latest</option>
                                            </select>	
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 text-center">
                                        <button onClick={()=>handleCommunitySubmit()} ref={buttonElementCommunity} type="button" className="default-btn">Apply Filter</button>
                                    </div>
                                </div>
                            </form>
                            }

                            {navCategory === "events" &&
                            <form>
                                <div className="row">

                                    <div style={{paddingTop: "20px"}}>
                                        <div className="row">
                                            {/* <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "anytime" }))}} className={`btn ${eventsForm.timeFrame == "anytime" ?  "btn-warning btn-sm" : "btn-sm btn-outline-warning" }`}>Anytime</button></div> */}
                                            <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "today" }))}} className={`btn ${eventsForm.timeFrame == "today" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Today</button></div>
                                            <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "this week" }))}} className={`btn ${eventsForm.timeFrame == "this week" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>This Week</button></div>
                                            <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "this weekend" }))}} className={`btn ${eventsForm.timeFrame == "this weekend" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>This Weekend</button></div>
                                            <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "next week" }))}} className={`btn ${eventsForm.timeFrame == "next week" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Next Week</button></div>
                                            <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "custom" }))}} className={`btn ${eventsForm.timeFrame == "custom" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Specific Date</button></div>
                                        </div>
                                    </div>

                                    {eventsForm.timeFrame === "custom" &&
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="input-group input-group-static">
                                                <label htmlFor=""><i className="fa fa-calendar"></i> Date</label>
                                                <Datetime
                                                    className="input-group input-group-static text-center"
                                                    closeOnSelect={true}
                                                    dateFormat={"YYYY-MM-DD"}
                                                    timeFormat={false}
                                                    inputProps={{
                                                    name: "specificDate",
                                                    value:eventsForm.specificDate
                                                    }}
                                                    onChange={(e) => {
                                                    const newDay = moment(new Date(e._d)).format("YYYY-MM-DD");
                                                    setEventsForm(prev=>({ ...prev, 'specificDate': newDay}));
                                                    }}
                                                    isValidDate={(current) => current.isAfter(moment().subtract(1, "day"))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                        
                                    <div className="col-lg-12">
                                        <label>Location: </label>
                                        <div className="form-group">
                                            <i className="flaticon-place" />
                                            {isLoaded && (
                                                <Autocomplete
                                                onLoad={(a) => setAutocomplete(a)}
                                                onPlaceChanged={(e) => placeHasChanged(e)}
                                                /* className="form-control form-group" */
                                                >
                                                <input name="address" value={eventsForm.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                </Autocomplete>)
                                            }
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="cat"
                                            value={eventsForm.cat}
                                            onChange={e=>handleCustomCategoryChange(e,"events")}
                                            >
                                            <option value="">All Event Categories</option>
                                            {
                                            categoriesEvents.length > 0 && categoriesEvents.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                            }
                                            </select>	
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Sub Category: </label>
                                        <div className="form-group">
                                            <i className="flaticon-filter" />
                                            <select 
                                            autoComplete="off"
                                            className="form-control"
                                            name="sub"
                                            value={eventsForm.sub}
                                            onChange={e=>{ 
                                                setEventsForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                            }}
                                            >
                                            <option value="">All Sub Category</option>
                                            { subcategoryEvents?.length && subcategoryEvents.map((e) => (
                                                <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                ))
                                            }
                                            </select>	
                                        </div>
                                    </div>


                                    <div className="col-lg-12 col-md-12 text-center">
                                        <button onClick={()=>handleEventsSubmit()} ref={buttonElementEvents} type="button" className="default-btn">Apply Filter</button>
                                    </div>
                                </div>
                            </form>
                            }

                        </div> 
                    </div>
                </div>
                <div className="col-lg-9 responsivePadding">
                    <Navbar bg="primary" variant="dark" className="responsiveNoPadding responsiveNavBrand">
                        <Container fluid>
                            
                        <Navbar.Brand  href="#" style={{ color:"white", fontSize:"17px"}}>Searching for?</Navbar.Brand>
                        <Nav className="me-auto explore_area">
                            <Link to="#" onClick={()=>setNavCategory("default")} className={`nav-link responsiveLink ${navCategory == 'default' && 'exploreActive'}`}><i className="fa fa-briefcase"></i> Businesses</Link>
                            <Link to="#" onClick={()=>setNavCategory("properties")} className={`nav-link responsiveLink ${navCategory == 'properties' && 'exploreActive'}`}><i className="fa fa-house"></i> Properties</Link>
                            <Link to="#" onClick={()=>setNavCategory("places")} className={`nav-link responsiveLink ${navCategory == 'places' && 'exploreActive'}`}><i className="fa fa-location-dot"></i> Places</Link>
                            <Link to="#" onClick={()=>setNavCategory("community")} className={`nav-link responsiveLink ${navCategory == 'community' && 'exploreActive'}`}><i className="fa fa-users"></i> Community</Link>
                            <Link to="#" onClick={()=>setNavCategory("events")} className={`nav-link responsiveLink ${navCategory == 'events' && 'exploreActive'}`}><i className="fa fa-calendar"></i> Events</Link>
                            <Link to="#" onClick={()=>setNavCategory("deals")} className={`nav-link responsiveLink ${navCategory == 'deals' && 'exploreActive'}`}><i className="fa fa-gift"></i> Deals/Offers</Link>
                        </Nav>
                        </Container>
                    </Navbar>

                    <div className="listing-widget-into">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                        <div className="listing-right-form">
                            <div className="row">

                            <button onClick={()=>{setShowFilterModal(!showFilterModal)}} style={{ display: "none" }} type="button" className="responsiveFilterButton btn btn-warning btn-sm m-3"><i style={{ fontSize: "14px"}} className="fa fa-filter"></i>&nbsp;&nbsp; Filter </button>

                                <div className="form-group" style={{ textAlign: "center" }}>
                                    {
                                    navCategory === "default" &&
                                    <>
                                        {
                                        data.length > 0 ? 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Showing {paginationData.itemsCount < paginationData.size ? `1 - ${paginationData.itemsCount}` : `${ 1 + ( (paginationData.currentPage - 1 ) * paginationData.size)} - ${paginationData.currentPage * paginationData.size}`} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.itemsCount}</span> business result(s)
                                        </div>: 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Your query returned no results for Businesses
                                        </div>
                                        }
                                       
                                    </>
                                    }

                                    {
                                    navCategory === "properties" &&
                                    <>
                                    
                                        {
                                        propertiesData.length > 0 ? 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Showing {paginationData.itemsCount < paginationData.size ? `1 - ${paginationData.itemsCount}` : `${ 1 + ( (paginationData.currentPage - 1 ) * paginationData.size)} - ${paginationData.currentPage * paginationData.size}`} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.itemsCount}</span> Properties
                                        </div>: 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Your query returned no results for Properties
                                        </div>
                                        }
                                       
                                    </>
                                    }

                                    {
                                    navCategory === "deals" &&
                                    <>
                                    
                                        {
                                        dealsData.length > 0 ? 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Showing {paginationData.itemsCount < paginationData.size ? `1 - ${paginationData.itemsCount}` : `${ 1 + ( (paginationData.currentPage - 1 ) * paginationData.size)} - ${paginationData.currentPage * paginationData.size}`} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.itemsCount}</span> Deal(s)
                                        </div>: 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Your query returned no results for Deals
                                        </div>
                                        }
                                       
                                    </>
                                    }

                                     {
                                    navCategory === "places" &&
                                    <>
                                    
                                        {
                                        placesData.length > 0 ? 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Showing {paginationData.itemsCount < paginationData.size ? `1 - ${paginationData.itemsCount}` : `${ 1 + ( (paginationData.currentPage - 1 ) * paginationData.size)} - ${paginationData.currentPage * paginationData.size}`} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.itemsCount}</span> Place(s)
                                        </div>: 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Your query returned no results for Places
                                        </div>
                                        }
                                       
                                    </>
                                    }

                                    {
                                    navCategory === "community" &&
                                    <>
                                    
                                        {
                                        communityData.length > 0 ? 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Showing {paginationData.itemsCount < paginationData.size ? `1 - ${paginationData.itemsCount}` : `${ 1 + ( (paginationData.currentPage - 1 ) * paginationData.size)} - ${paginationData.currentPage * paginationData.size}`} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.itemsCount}</span> Community
                                        </div>: 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Your query returned no results for Community
                                        </div>
                                        }
                                       
                                    </>
                                    }

                                    {
                                    navCategory === "events" &&
                                    <>
                                        {
                                        eventsData.length > 0 ? 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Showing {paginationData.itemsCount < paginationData.size ? `1 - ${paginationData.itemsCount}` : `${ 1 + ( (paginationData.currentPage - 1 ) * paginationData.size)} - ${paginationData.currentPage * paginationData.size}`} of <span className="badge text-bold rounded-pill bg-secondary">{paginationData.itemsCount}</span> Event(s)
                                        </div>: 
                                        <div className="alert alert-light p-1 m-2" role="alert">
                                            Your query returned no results for Events
                                        </div>
                                        }  
                                    </>
                                    }

                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                            {
                            navCategory === "default" && data.length > 0 &&
                            data.map((listing,index)=>(<div key={listing.businessID} className="col-lg-4 col-md-4">
                                <div className="place-list-item">
                                    <div className="images">
                                    <Link to={`/business/${listing.businessURLName}`} state={{ id: listing.businessID }} className="images-list">
                                        <img style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${imagePath}${listing.bannerMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                                    </Link>
                                    {/* 
                                    LOGO
                                    <div className="place-profile">
                                        <img src={require("../../assets/frontend/img/place-list/place-profile.png")} alt="Images" crossOrigin="Anonymous" />
                                    </div> 
                                    */}
                                    <div className={`place-status ${openClose[listing.businessID]=='Closed'?"bg-dark-orange":"bg-color-green"}`}>
                                        <a href="#"><h3>{ openClose[listing.businessID]}</h3></a>
                                    </div>
                                    <div style={{ width : '90%' }} className="place-tag">
                                        {
                                        isAuthenticated &&
                                        <ul>
                                            <li><a onClick={(e)=>{handleLikeClick(e,listing.businessID)}} className={followedBiz.includes(listing.businessID) ? "iFollow animated heartBeat" : ""} href="#" ><i className="flaticon-like" /></a></li>
                                        </ul>
                                        }
                                        <h3 className="title"><Link to={`/business/${listing.businessURLName}`}>{listing.categoryName}</Link></h3>
                                        <span style={{ float: 'right', color : 'white' }}>{parseFloat(listing.distance).toFixed(2)} miles</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <Link to={`/business/${listing.businessURLName}`} state={{ id: listing.businessID }} >
                                        <h3 style={{ fontSize: '20px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0 }}>{listing.businessFullName}</h3>
                                    </Link> 
                                    <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0}}>
                                        <i style={{ paddingRight:'5px' }} className="flaticon-cursor" />
                                        {listing.businessAddress}
                                    </p>
                                    <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0, paddingBottom:'5px !important'}}>
                                        <a style={{ display: "none", fontSize: "15px" }} href={`tel:${listing.businessPhone}`} className="responsivePhone">
                                        <span style={{ paddingRight:'5px', color: 'grey' }} className="fa fa-phone"></span>{listing.businessPhone}
                                        </a>
                                    </p>
                                    {
                                        listing.businessID in reviews ? 
                                        <ul className="place-rating">
                                            <li>
                                            <Link to="#">{reviews[listing.businessID]}</Link>
                                            </li>
                                            <li>
                                            <Rating
                                            initialValue={reviews[listing.businessID]}
                                                readonly={true}
                                                allowFraction={true}
                                                size={20}
                                                allowHover={false}
                                                disableFillHover={true}
                                            />
                                            </li>
                                        </ul> :
                                        <ul className="place-rating">
                                            <li>
                                            {/* <Link to="#">&nbsp;</Link> */}
                                            No Reviews
                                            </li>
                                            <li>
                                
                                            </li>
                                        </ul>
                                    }
                
                                    </div>
                                </div>                
                            </div>))
                            }

                            {/* Pagination Link for default business search*/}
                            {
                             navCategory === "default" && paginationData.itemsCount && paginationData.pagesCount > 1 ?
                                        <div className="m-3">
                                            <nav aria-label="...">
                                            <ul className="pagination">
                                                
                                                <li className={`page-item ${paginationData.previousPage != 0  ? 'cursor-pointer': 'disabled'}`}>
                                                <span 
                                                className="page-link" 
                                                onClick={()=>handleSubmit(paginationData.previousPage)}>Prev</span>
                                                </li>

                                                <li className={`page-item ${paginationData.currentPage == 1 ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == 1) return; handleSubmit(1)}} >1</span>
                                                </li>
                                                {
                                                getPaginationBody(paginationData.pagesCount).map(el=> (
                                                    <li key={el} className={`page-item  ${el==paginationData.currentPage ? 'active':'cursor-pointer'}`} aria-current="page">
                                                        <span 
                                                        className="page-link"
                                                        onClick={()=>{ if(paginationData.currentPage == el) return; handleSubmit(el)}} 
                                                        >{el}</span>
                                                    </li>))
                                                }
                                                <li className={`page-item ${paginationData.currentPage == paginationData.pagesCount  ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == paginationData.pagesCount) return; handleSubmit(paginationData.pagesCount)}}  >{paginationData.pagesCount}</span>
                                                </li>

                                                <li className={`page-item ${paginationData.nextPage <= paginationData.pagesCount  ? 'cursor-pointer': 'disabled'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>handleSubmit(paginationData.nextPage)}>Next</span>
                                                </li>
                                            </ul>
                                            </nav>
                                        </div> 
                                    :
                                null
                            }

                            {
                            navCategory === "properties" && propertiesData.length > 0 &&
                                propertiesData.map((d,index)=>(
                            <div key={d.listingName} className="col-lg-4 col-md-4">     
                                <div className="place-list-item" style={{ padding: '15px' }}>
                                    <div className="images">
                                        
                                        <Link to={"/business/listing"} state={{ id: d.listingID }} className="images-list">
                                            <img className="imageHover" style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${listingPath}${d.listingMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                                        </Link>
                                        <div className="eventOverlayHomePage"></div>

                                        <div className="headerHome">
                                            <div style={{ position:'relative' }}>
                                                <div className="date dateListing">
                                                <span style={{ fontWeight:800 }}>&#163;{d.listingAmount}</span> 
                                                <span style={{ fontWeight:300 }}> PER MONTH</span>
                                                </div>
                                            </div>
                                        </div>      
                                    
                                    <div className="place-tag">      
                                        <p> 
                                            {/* <ul>
                                            <li><Link to="#"><i className="flaticon-like" /></Link></li>
                                            </ul> */}
                                            <Link to={"/business/listing"} state={{ id: d.listingID }}>
                                            <span className="oneLine" style={{ color: 'white', fontWeight: 'bold', marginLeft:'3px' , fontSize: '15px'}}>{d.listingName}</span>
                                            </Link>
                                        </p>
                                    </div>
                                    </div>
                                    <div className="row" style={{ padding: '0px', position: 'relative' }}>  
                                        {d.listingSize &&                 
                                        <row className="col-4" style={{ padding: '0px' }}>
                                            <p><i className="fa fa-box listingIcon"></i><span style={{ fontSize:'13px' }}>{d.listingSize}</span></p>
                                        </row>
                                        }
                                        <row className="col-4" style={{ padding: '0px' }}>
                                            <p><i className="fa fa-bed listingIcon"></i>&nbsp;<span style={{ fontSize:'13px' }}>{d.listingBedrooms}</span></p>
                                        </row>
                                        <row className="col-4" style={{ padding: '0px' }}>
                                            <p><i className="fa fa-bath listingIcon"></i>&nbsp;<span style={{ fontSize:'13px' }}>{d.listingBathrooms}</span></p>
                                        </row>
                                    </div>
                                    {/* <a style={{ display: "none", fontSize: "15px" }} href={`tel:${d.businessPhone}`} className="btn btn-lg btn-success responsivePhone"><i class="fa fa-phone"></i>&nbsp;&nbsp; {d.businessPhone}</a> */}
                                </div>
                                </div>
                                ))
                            }

                            {/* Pagination Link for properties*/}
                            {
                             navCategory === "properties" && paginationData.itemsCount && paginationData.pagesCount > 1 ?
                                        <div className="m-3">
                                            <nav aria-label="...">
                                            <ul className="pagination">
                                                
                                                <li className={`page-item ${paginationData.previousPage != 0  ? 'cursor-pointer': 'disabled'}`}>
                                                <span 
                                                className="page-link" 
                                                onClick={()=>handlePropertiesSubmit(paginationData.previousPage)}>Prev</span>
                                                </li>

                                                <li className={`page-item ${paginationData.currentPage == 1 ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == 1) return; handlePropertiesSubmit(1)}} >1</span>
                                                </li>
                                                {
                                                getPaginationBody(paginationData.pagesCount).map(el=> (
                                                    <li key={el} className={`page-item  ${el==paginationData.currentPage ? 'active':'cursor-pointer'}`} aria-current="page">
                                                        <span 
                                                        className="page-link"
                                                        onClick={()=>{ if(paginationData.currentPage == el) return; handlePropertiesSubmit(el)}} 
                                                        >{el}</span>
                                                    </li>))
                                                }
                                                <li className={`page-item ${paginationData.currentPage == paginationData.pagesCount  ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == paginationData.pagesCount) return; handlePropertiesSubmit(paginationData.pagesCount)}}  >{paginationData.pagesCount}</span>
                                                </li>

                                                <li className={`page-item ${paginationData.nextPage <= paginationData.pagesCount  ? 'cursor-pointer': 'disabled'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>handlePropertiesSubmit(paginationData.nextPage)}>Next</span>
                                                </li>
                                            </ul>
                                            </nav>
                                        </div> 
                                    :
                                null
                            }
                            
                            {
                            navCategory == "deals" && dealsData.length > 0 &&
                                dealsData.map((listing,index)=>(
                
                            <div style={{ marginBottom : "20px" }} key={listing.eventMainPicture} className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="example-2 event-card">    
                                        <div className="wrapper">
                                            <img style={{ position: 'absolute', width:"100%", height:"350px", objectFit: "cover", zIndex : 0  }} src={`${eventPath}${listing.eventMainPicture}`} alt="Event Image" crossOrigin="Anonymous" />
                                            <div className="eventOverlay"></div>
                                            <div className="header">
                                                <div style={{ position:'relative' }}>
                                                    <div className="date">
                                                    <i className="fa-regular fa-calendar"></i> {moment(listing.eventStartDate).format('LL')}
                                                    </div>

                                                    <div className="time">
                                                        <h6 className="show" style={{ color: 'white', fontWeight:'normal'}}> 
                                                        <i className="fa-regular fa-clock"></i>&nbsp;&nbsp;
                                                        {moment(listing.eventStartTime, "HH:mm").format("HH:mm")} -&nbsp; 
                                                            {moment(listing.eventEndTime, "HH:mm").format("HH:mm")} 
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="data">
                                                <div className="content">
                                                    <h5 className="title">
                                                    <Link state={{ id: listing.eventID }}  to="/business/event">{listing.eventName}</Link></h5>
                                                    <p className="text">by {listing.businessFullName}</p>
                                                    <Link state={{ id: listing.eventID }}  to="/business/event" className="button">Read more</Link>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            
                                ))
                            }

                            {/* Pagination Link for deals*/}
                            {
                             navCategory === "deals" && paginationData.itemsCount && paginationData.pagesCount > 1 ?
                                        <div className="m-3">
                                            <nav aria-label="...">
                                            <ul className="pagination">
                                                
                                                <li className={`page-item ${paginationData.previousPage != 0  ? 'cursor-pointer': 'disabled'}`}>
                                                <span 
                                                className="page-link" 
                                                onClick={()=>handleDealsSubmit(paginationData.previousPage)}>Prev</span>
                                                </li>

                                                <li className={`page-item ${paginationData.currentPage == 1 ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == 1) return; handleDealsSubmit(1)}} >1</span>
                                                </li>
                                                {
                                                getPaginationBody(paginationData.pagesCount).map(el=> (
                                                    <li key={el} className={`page-item  ${el==paginationData.currentPage ? 'active':'cursor-pointer'}`} aria-current="page">
                                                        <span 
                                                        className="page-link"
                                                        onClick={()=>{ if(paginationData.currentPage == el) return; handleDealsSubmit(el)}} 
                                                        >{el}</span>
                                                    </li>))
                                                }
                                                <li className={`page-item ${paginationData.currentPage == paginationData.pagesCount  ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == paginationData.pagesCount) return; handleDealsSubmit(paginationData.pagesCount)}}  >{paginationData.pagesCount}</span>
                                                </li>

                                                <li className={`page-item ${paginationData.nextPage <= paginationData.pagesCount  ? 'cursor-pointer': 'disabled'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>handleDealsSubmit(paginationData.nextPage)}>Next</span>
                                                </li>
                                            </ul>
                                            </nav>
                                        </div> 
                                    :
                                null
                            }

                            {
                            navCategory === "places" && placesData.length > 0 &&
                            placesData.map((listing,index)=>(<div key={listing.placesID} className="col-lg-4 col-md-4">
                                <div className="place-list-item">
                                    <div className="images">
                                    <Link to={`/place/${listing.placesID}`} state={{ id: listing.placesID }} className="images-list">
                                        <img style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${imagePath}${listing.bannerMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                                    </Link>
                                    {/* 
                                    LOGO
                                    <div className="place-profile">
                                        <img src={require("../../assets/frontend/img/place-list/place-profile.png")} alt="Images" crossOrigin="Anonymous" />
                                    </div> 
                                    */}
                                    {/* <div className={`place-status ${openClose[listing.businessID]=='Closed'?"bg-dark-orange":"bg-color-green"}`}>
                                        <a href="#"><h3>{ openClose[listing.businessID]}</h3></a>
                                    </div> */}
                                    <div style={{ width : '90%' }} className="place-tag">
                                        {/* {
                                        isAuthenticated &&
                                        <ul>
                                            <li><a onClick={(e)=>{handleLikeClick(e,listing.businessID)}} className={followedBiz.includes(listing.businessID) ? "iFollow animated heartBeat" : ""} href="#" ><i className="flaticon-like" /></a></li>
                                        </ul>
                                        } */}
                                        <h3 className="title"><Link to={`/place/${listing.placesID}`}>{listing.categoryName}</Link></h3>
                                        <span style={{ float: 'right', color : 'white' }}>{parseFloat(listing.distance).toFixed(2)} miles</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <Link to={`/place/${listing.placesID}`} state={{ id: listing.placesID }} >
                                        <h3 style={{ fontSize: '20px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0 }}>{listing.placesFullName}</h3>
                                    </Link> 
                                    <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0}}>
                                        <i style={{ paddingRight:'5px' }} className="flaticon-cursor" />
                                        {listing.placesAddress}
                                    </p>
                                    {/* <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0, paddingBottom:'5px !important'}}>
                                        <a style={{ display: "none", fontSize: "15px" }} href={`tel:${listing.businessPhone}`} className="responsivePhone">
                                        <span style={{ paddingRight:'5px', color: 'grey' }} className="fa fa-phone"></span>{listing.businessPhone}
                                        </a>
                                    </p> */}
                                    </div>
                                </div>                
                            </div>))
                            }

                            {/* Pagination Link for places*/}
                            {
                             navCategory === "places" && paginationData.itemsCount && paginationData.pagesCount > 1 ?
                                        <div className="m-3">
                                            <nav aria-label="...">
                                            <ul className="pagination">
                                                
                                                <li className={`page-item ${paginationData.previousPage != 0  ? 'cursor-pointer': 'disabled'}`}>
                                                <span 
                                                className="page-link" 
                                                onClick={()=>handlePlacesSubmit(paginationData.previousPage)}>Prev</span>
                                                </li>

                                                <li className={`page-item ${paginationData.currentPage == 1 ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == 1) return; handlePlacesSubmit(1)}} >1</span>
                                                </li>
                                                {
                                                getPaginationBody(paginationData.pagesCount).map(el=> (
                                                    <li key={el} className={`page-item  ${el==paginationData.currentPage ? 'active':'cursor-pointer'}`} aria-current="page">
                                                        <span 
                                                        className="page-link"
                                                        onClick={()=>{ if(paginationData.currentPage == el) return; handlePlacesSubmit(el)}} 
                                                        >{el}</span>
                                                    </li>))
                                                }
                                                <li className={`page-item ${paginationData.currentPage == paginationData.pagesCount  ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == paginationData.pagesCount) return; handlePlacesSubmit(paginationData.pagesCount)}}  >{paginationData.pagesCount}</span>
                                                </li>

                                                <li className={`page-item ${paginationData.nextPage <= paginationData.pagesCount  ? 'cursor-pointer': 'disabled'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>handlePlacesSubmit(paginationData.nextPage)}>Next</span>
                                                </li>
                                            </ul>
                                            </nav>
                                        </div> 
                                    :
                                null
                            }

                            {
                            navCategory === "community" && communityData.length > 0 &&
                            communityData.map((listing,index)=>(<div key={listing.communityID} className="col-lg-4 col-md-4">
                                <div className="place-list-item">
                                    <div className="images">
                                    <Link to={`/comm/${listing.communityID}`} state={{ id: listing.communityID }} className="images-list">
                                        <img style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${imagePath}${listing.bannerMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                                    </Link>
                                    {/* 
                                    LOGO
                                    <div className="place-profile">
                                        <img src={require("../../assets/frontend/img/place-list/place-profile.png")} alt="Images" crossOrigin="Anonymous" />
                                    </div> 
                                    */}
                                    {/* <div className={`place-status ${openClose[listing.businessID]=='Closed'?"bg-dark-orange":"bg-color-green"}`}>
                                        <a href="#"><h3>{ openClose[listing.businessID]}</h3></a>
                                    </div> */}
                                    <div style={{ width : '90%' }} className="place-tag">
                                        {/* {
                                        isAuthenticated &&
                                        <ul>
                                            <li><a onClick={(e)=>{handleLikeClick(e,listing.businessID)}} className={followedBiz.includes(listing.businessID) ? "iFollow animated heartBeat" : ""} href="#" ><i className="flaticon-like" /></a></li>
                                        </ul>
                                        } */}
                                        <h3 className="title"><Link to={`/comm/${listing.communityID}`}>{listing.categoryName}</Link></h3>
                                        <span style={{ float: 'right', color : 'white' }}>{parseFloat(listing.distance).toFixed(2)} miles</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <Link to={`/comm/${listing.communityID}`} state={{ id: listing.communityID }} >
                                        <h3 style={{ fontSize: '20px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0 }}>{listing.communityFullName}</h3>
                                    </Link> 
                                    <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0}}>
                                        <i style={{ paddingRight:'5px' }} className="flaticon-cursor" />
                                        {listing.communityAddress}
                                    </p>
                                    {/* <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 0, paddingBottom:'5px !important'}}>
                                        <a style={{ display: "none", fontSize: "15px" }} href={`tel:${listing.businessPhone}`} className="responsivePhone">
                                        <span style={{ paddingRight:'5px', color: 'grey' }} className="fa fa-phone"></span>{listing.businessPhone}
                                        </a>
                                    </p> */}
                                    </div>
                                </div>                
                            </div>))
                            }

                            {/* Pagination Link for community*/}
                            {
                             navCategory === "community" && paginationData.itemsCount && paginationData.pagesCount > 1 ?
                                        <div className="m-3">
                                            <nav aria-label="...">
                                            <ul className="pagination">
                                                
                                                <li className={`page-item ${paginationData.previousPage != 0  ? 'cursor-pointer': 'disabled'}`}>
                                                <span 
                                                className="page-link" 
                                                onClick={()=>handleCommunitySubmit(paginationData.previousPage)}>Prev</span>
                                                </li>

                                                <li className={`page-item ${paginationData.currentPage == 1 ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == 1) return; handleCommunitySubmit(1)}} >1</span>
                                                </li>
                                                {
                                                getPaginationBody(paginationData.pagesCount).map(el=> (
                                                    <li key={el} className={`page-item  ${el==paginationData.currentPage ? 'active':'cursor-pointer'}`} aria-current="page">
                                                        <span 
                                                        className="page-link"
                                                        onClick={()=>{ if(paginationData.currentPage == el) return; handleCommunitySubmit(el)}} 
                                                        >{el}</span>
                                                    </li>))
                                                }
                                                <li className={`page-item ${paginationData.currentPage == paginationData.pagesCount  ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == paginationData.pagesCount) return; handleCommunitySubmit(paginationData.pagesCount)}}  >{paginationData.pagesCount}</span>
                                                </li>

                                                <li className={`page-item ${paginationData.nextPage <= paginationData.pagesCount  ? 'cursor-pointer': 'disabled'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>handleCommunitySubmit(paginationData.nextPage)}>Next</span>
                                                </li>
                                            </ul>
                                            </nav>
                                        </div> 
                                    :
                                null
                            }

                            {
                            navCategory == "events" && eventsData.length > 0 &&
                                eventsData.map((listing,index)=>(
                
                            <div style={{ marginBottom : "20px" }} key={listing.bannerMainPicture} className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="example-2 event-card">    
                                        <div className="wrapper">
                                            <img style={{ position: 'absolute', width:"100%", height:"350px", objectFit: "cover", zIndex : 0  }} src={`${imagePath}${listing.bannerMainPicture}`} alt="Event Image" crossOrigin="Anonymous" />
                                            <div className="eventOverlay"></div>
                                            <div className="header">
                                                <div style={{ position:'relative' }}>
                                                    <div className="date">
                                                    <i className="fa-regular fa-calendar"></i> {moment(listing.eventStartDate).format('LL')}
                                                    </div>

                                                    <div className="time">
                                                        <h6 className="show" style={{ color: 'white', fontWeight:'normal'}}> 
                                                        <i className="fa-regular fa-clock"></i>&nbsp;&nbsp;
                                                        {moment(listing.eventStartTime, "HH:mm").format("HH:mm")} -&nbsp; 
                                                            {moment(listing.eventEndTime, "HH:mm").format("HH:mm")} 
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="data">
                                                <div className="content">
                                                    <h5 className="title">
                                                    <Link to={`/ev/${listing.eventsID}`}>{listing.eventsFullName}</Link></h5>
                                                    <Link to={`/ev/${listing.eventsID}`} className="button">Read more</Link>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            
                                ))
                            }
                            {
                             navCategory === "events" && paginationData.itemsCount && paginationData.pagesCount > 1 ?
                                        <div className="m-3">
                                            <nav aria-label="...">
                                            <ul className="pagination">
                                                
                                                <li className={`page-item ${paginationData.previousPage != 0  ? 'cursor-pointer': 'disabled'}`}>
                                                <span 
                                                className="page-link" 
                                                onClick={()=>handleEventsSubmit(paginationData.previousPage)}>Prev</span>
                                                </li>

                                                <li className={`page-item ${paginationData.currentPage == 1 ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == 1) return; handleEventsSubmit(1)}} >1</span>
                                                </li>
                                                {
                                                getPaginationBody(paginationData.pagesCount).map(el=> (
                                                    <li key={el} className={`page-item  ${el==paginationData.currentPage ? 'active':'cursor-pointer'}`} aria-current="page">
                                                        <span 
                                                        className="page-link"
                                                        onClick={()=>{ if(paginationData.currentPage == el) return; handleEventsSubmit(el)}} 
                                                        >{el}</span>
                                                    </li>))
                                                }
                                                <li className={`page-item ${paginationData.currentPage == paginationData.pagesCount  ? 'active': 'cursor-pointer'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>{ if(paginationData.currentPage == paginationData.pagesCount) return; handleEventsSubmit(paginationData.pagesCount)}}  >{paginationData.pagesCount}</span>
                                                </li>

                                                <li className={`page-item ${paginationData.nextPage <= paginationData.pagesCount  ? 'cursor-pointer': 'disabled'}`}>
                                                    <span 
                                                    className="page-link" 
                                                    onClick={()=>handleEventsSubmit(paginationData.nextPage)}>Next</span>
                                                </li>
                                            </ul>
                                            </nav>
                                        </div> 
                                    :
                                null
                            }

                            {/* No Result Animation */}
                            {
                            data.length==0 &&  propertiesData.length == 0 && dealsData.length == 0 &&
                            placesData.length == 0 && communityData.length == 0 && eventsData.length == 0 &&
                                <div className="text-center">
                                    <div>
                                                <Player
                                                autoplay
                                                loop = {true}
                                                style={{ height: "300px", width: "100%" }}
                                                src={noDataLottie}
                                            />
                                    </div>
                                    <p> No matches</p>
                                </div>
                            } 

                    {/* {data.length > 0 &&
                        <div className="col-lg-12 text-center"> 
                            <a href="#" className="default-btn border-radius">
                                Load More 
                                <i className="bx bx-plus" />
                            </a>
                        </div>
                        } */}

                    </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal 
        show={showFilterModal} 
        //fullscreen={true}
        //size="xl"
        //scrollable={true}
        centered
        backdrop="static"
        keyboard={false} 
        onHide={()=>setShowFilterModal(false)}>
            <Modal.Body>

                <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowFilterModal(false)} className="fa fa-circle-xmark"></i>

                <div className="row">

                <Navbar bg="primary" variant="dark" className="responsiveNoPadding" style={{ marginTop: '20px' }}>
                    <Container>
                    <Nav className="me-auto explore_area">
                        <Link to="#" onClick={()=>setNavCategory("default")} className={`nav-link responsiveLink ${navCategory == 'default' && 'exploreActive'}`}><i className="fa fa-briefcase"></i> Businesses</Link>
                        <Link to="#" onClick={()=>setNavCategory("properties")} className={`nav-link responsiveLink ${navCategory == 'properties' && 'exploreActive'}`}><i className="fa fa-house"></i> Properties</Link>
                        <Link to="#" onClick={()=>setNavCategory("places")} className={`nav-link responsiveLink ${navCategory == 'places' && 'exploreActive'}`}><i className="fa fa-location-dot"></i> Places</Link>
                        <Link to="#" onClick={()=>setNavCategory("community")} className={`nav-link responsiveLink ${navCategory == 'community' && 'exploreActive'}`}><i className="fa fa-users"></i> Community</Link>
                        <Link to="#" onClick={()=>setNavCategory("events")} className={`nav-link responsiveLink ${navCategory == 'events' && 'exploreActive'}`}><i className="fa fa-calendar"></i> Events</Link>
                        <Link to="#" onClick={()=>setNavCategory("deals")} className={`nav-link responsiveLink ${navCategory == 'deals' && 'exploreActive'}`}><i className="fa fa-gift"></i> Deals/Offers</Link>
                    </Nav>
                    </Container>
                </Navbar>

                    <div className="col-12">
                        <div className="listing-section-right">
                            <h3 className="title" style={{ marginBottom: 0 }}> <i className="flaticon-filter" /> Filters</h3>
                            <div className="listing-right-form">
                                

                                <div className="col-lg-12">
                                    <label>Search Radius (Miles) :</label>
                                    <div className="form-group">
                                        <i className="flaticon-filter" />
                                        <select 
                                        autoComplete="off"
                                        className="form-control"
                                        name="radius"
                                        value={form.radius}
                                        onChange={e=>handleChange(e)} 
                                        >
                                        {/* <option value="">Any Location</option> */}
                                        <option value="1">Within 1 Mile</option>
                                        <option value="5">Within 5 Miles</option>
                                        <option value="10">Within 10 Miles</option>
                                        <option value="20">Within 20 Miles</option>
                                        <option value="50">Within 50 Miles</option>
                                        <option value="100">Within 100 Miles</option>
                                        </select>	
                                    </div>
                                </div> 

                                {navCategory === "default" &&
                                <form>
                                    <div className="row">
                                    
                                        <div className="col-lg-12">
                                            <label>Location: </label>
                                            <div className="form-group">
                                                <i className="flaticon-place" />
                                                {isLoaded && (
                                                    <Autocomplete
                                                    onLoad={(a) => setAutocomplete(a)}
                                                    onPlaceChanged={(e) => placeHasChanged(e)}
                                                    /* className="form-control form-group" */
                                                    >
                                                    <input name="address" value={form.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                    </Autocomplete>)
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="cat"
                                                value={form.cat}
                                                onChange={e=>handleCategoryChange(e)}
                                                >
                                                <option value="">All Listing Type</option>
                                                {
                                                categories.length > 0 && categories.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                                }
                                                </select>	
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Sub Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="sub"
                                                value={form.sub}
                                                onChange={e=>{
                                                    setForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                    setDealsForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                }}
                                                >
                                                <option value="">All Sub Category</option>
                                                { subcategory?.length && subcategory.map((e) => (
                                                    <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                    ))
                                                }
                                                </select>	
                                            </div>
                                        </div>


                                        <div className="col-lg-12">
                                            <label>Search String: </label>
                                            <div className="form-group">
                                                <i className="flaticon-loupe" />
                                                <input name="q" value={form.q} onChange={e=>{
                                                    setForm(prev=>({ ...prev, 'q': e.target.value })); 
                                                }} type="text" className="form-control" placeholder="What Are Searching*" autoComplete="off" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12">
                                            <label>Sort Criteria: </label>
                                            <div className="form-group">
                                                <i className="fa fa-sort"></i>
                                                <select 
                                                autoComplete="off"
                                                name="ob"
                                                value={form.ob}
                                                onChange={e=>{
                                                    setForm(prev=>({ ...prev, 'ob': e.target.value })); 
                                                }}
                                                className="form-control">
                                                <option value="proximity">Proximity</option>
                                                <option value="latest">Latest</option>
                                                </select>	
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button onClick={()=>{handleSubmit()}} ref={buttonElement} type="button" className="default-btn">Apply Filter</button>
                                        </div>
                                    </div>
                                </form>
                                }

                                {navCategory === "properties" &&
                                <form>
                                    <div className="row">
                                    
                                        <div className="col-lg-12">
                                            <label>Location: </label>
                                            <div className="form-group">
                                                <i className="flaticon-place" />
                                                {isLoaded && (
                                                    <Autocomplete
                                                    onLoad={(a) => setAutocomplete(a)}
                                                    onPlaceChanged={(e) => placeHasChanged(e)}
                                                    /* className="form-control form-group" */
                                                    >
                                                    <input name="address" value={form.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                    </Autocomplete>)
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label> <i style={{ color:"#f14300" }} className="fa fa-money-bill"></i>&nbsp;Price Range (&#163;): </label>
                                            <div className="form-group">
                                            
                                                <span style={{ fontSize:'11px' }}>Min</span>
                                                <input name="minimumAmount" value={propertiesForm.minimumAmount} 
                                                onChange={e=>{
                                                    setPropertiesForm(prev=>({ ...prev, 'minimumAmount': e.target.value })); 
                                                }} 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Min" />	
                                                <span style={{ fontSize:'11px' }}>Max</span>
                                                <input name="maximumAmount" value={propertiesForm.maximumAmount} 
                                                onChange={e=>{
                                                    setPropertiesForm(prev=>({ ...prev, 'maximumAmount': e.target.value })); 
                                                }} 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Max" />	
                                            </div>
                                        </div>

                                        
                                        <div className="col-lg-12">
                                            <label>No of Bedrooms: </label>
                                            <div className="form-group">
                                                <i className="fa fa-bed"></i>
                                                <select 
                                                autoComplete="off"
                                                name="bedrooms"
                                                value={propertiesForm.bedrooms}
                                                onChange={e=>{
                                                    setPropertiesForm(prev=>({ ...prev, 'bedrooms': e.target.value })); 
                                                }}
                                                className="form-control">
                                                <option value="">Any:</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                </select>	
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>No of Bathrooms: </label>
                                            <div className="form-group">
                                                <i className="fa fa-bath"></i>
                                                <select 
                                                autoComplete="off"
                                                name="bathrooms"
                                                value={propertiesForm.bathrooms}
                                                onChange={e=>{
                                                    setPropertiesForm(prev=>({ ...prev, 'bathrooms': e.target.value })); 
                                                }}
                                                className="form-control">
                                                <option value="">Any:</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                </select>	
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Sort Criteria: </label>
                                            <div className="form-group">
                                                <i className="fa fa-sort"></i>
                                                <select 
                                                autoComplete="off"
                                                name="ob"
                                                value={propertiesForm.ob}
                                                onChange={e=>{
                                                    setPropertiesForm(prev=>({ ...prev, 'ob': e.target.value })); 
                                                }}
                                                className="form-control">
                                                <option value="amount">Amount</option>
                                                <option value="proximity">Proximity</option>
                                                </select>	
                                            </div>
                                        </div>


                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button onClick={()=>handlePropertiesSubmit()} ref={buttonElementProperties} type="button" className="default-btn">Apply Filter</button>
                                        </div>
                                    </div>
                                </form>
                                }

                                {navCategory === "deals" &&
                                <form>
                                    <div className="row">

                                        <div style={{paddingTop: "20px"}}>
                                            <div className="row">
                                                {/* <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "anytime" }))}} className={`btn ${dealsForm.timeFrame == "anytime" ?  "btn-warning btn-sm" : "btn-sm btn-outline-warning" }`}>Anytime</button></div> */}
                                                <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "today" }))}} className={`btn ${dealsForm.timeFrame == "today" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Today</button></div>
                                                <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "this week" }))}} className={`btn ${dealsForm.timeFrame == "this week" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>This Week</button></div>
                                                <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "this weekend" }))}} className={`btn ${dealsForm.timeFrame == "this weekend" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>This Weekend</button></div>
                                                <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "next week" }))}} className={`btn ${dealsForm.timeFrame == "next week" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Next Week</button></div>
                                                <div className="col-6"><button type="button" onClick={()=>{setDealsForm(prev=>({ ...prev, "timeFrame": "custom" }))}} className={`btn ${dealsForm.timeFrame == "custom" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Specific Date</button></div>
                                            </div>
                                        </div>

                                        {dealsForm.timeFrame === "custom" &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor=""><i className="fa fa-calendar"></i> Date</label>
                                                    <Datetime
                                                        className="input-group input-group-static text-center"
                                                        closeOnSelect={true}
                                                        dateFormat={"YYYY-MM-DD"}
                                                        timeFormat={false}
                                                        inputProps={{
                                                        name: "specificDate",
                                                        value:dealsForm.specificDate
                                                        }}
                                                        onChange={(e) => {
                                                        const newDay = moment(new Date(e._d)).format("YYYY-MM-DD");
                                                        setDealsForm(prev=>({ ...prev, 'specificDate': newDay}));
                                                        }}
                                                        isValidDate={(current) => current.isAfter(moment().subtract(1, "day"))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    
                                        
                                        <div className="col-lg-12">
                                            <label>Location: </label>
                                            <div className="form-group">
                                                <i className="flaticon-place" />
                                                {isLoaded && (
                                                    <Autocomplete
                                                    onLoad={(a) => setAutocomplete(a)}
                                                    onPlaceChanged={(e) => placeHasChanged(e)}
                                                    /* className="form-control form-group" */
                                                    >
                                                    <input name="address" value={form.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                    </Autocomplete>)
                                                }
                                            </div>
                                        </div>

                                    

                                        <div className="col-lg-12">
                                            <label>Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="cat"
                                                value={form.cat}
                                                onChange={e=>handleCategoryChange(e)}
                                                >
                                                <option value="">All Listing Type</option>
                                                {
                                                categories.length > 0 && categories.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                                }
                                                </select>	
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Sub Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="sub"
                                                value={form.sub}
                                                onChange={e=>{
                                                    setForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                    setDealsForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                }}
                                                >
                                                <option value="">All Sub Category</option>
                                                { subcategory?.length && subcategory.map((e) => (
                                                    <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                    ))
                                                }
                                                </select>	
                                            </div>
                                        </div>


                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button onClick={()=>handleDealsSubmit()} ref={buttonElementDeals} type="button" className="default-btn">Apply Filter</button>
                                        </div>
                                    </div>
                                </form>
                                }

                                {navCategory === "places" &&
                                <form>
                                    <div className="row">
                                    
                                        <div className="col-lg-12">
                                            <label>Location: </label>
                                            <div className="form-group">
                                                <i className="flaticon-place" />
                                                {isLoaded && (
                                                    <Autocomplete
                                                    onLoad={(a) => setAutocomplete(a)}
                                                    onPlaceChanged={(e) => placeHasChanged(e)}
                                                    /* className="form-control form-group" */
                                                    >
                                                    <input name="address" value={placesForm.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                    </Autocomplete>)
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="cat"
                                                value={placesForm.cat}
                                                onChange={e=>handleCustomCategoryChange(e,"places")}
                                                >
                                                <option value="">All Places Categories</option>
                                                {
                                                categoriesPlaces.length > 0 && categoriesPlaces.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                                }
                                                </select>	
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Sub Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="sub"
                                                value={placesForm.sub}
                                                onChange={e=>{ 
                                                    setPlacesForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                }}
                                                >
                                                <option value="">All Sub Category</option>
                                                { subcategoryPlaces?.length && subcategoryPlaces.map((e) => (
                                                    <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                    ))
                                                }
                                                </select>	
                                            </div>
                                        </div>


                                        <div className="col-lg-12">
                                            <label>Search String: </label>
                                            <div className="form-group">
                                                <i className="flaticon-loupe" />
                                                <input name="q" value={placesForm.q} onChange={e=>{
                                                    setPlacesForm(prev=>({ ...prev, 'q': e.target.value })); 
                                                }} type="text" className="form-control" placeholder="What are you searching for?*" autoComplete="off" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12">
                                            <label>Sort Criteria: </label>
                                            <div className="form-group">
                                                <i className="fa fa-sort"></i>
                                                <select 
                                                autoComplete="off"
                                                name="ob"
                                                value={form.ob}
                                                onChange={e=>{
                                                    setForm(prev=>({ ...prev, 'ob': e.target.value })); 
                                                }}
                                                className="form-control">
                                                <option value="proximity">Proximity</option>
                                                <option value="latest">Latest</option>
                                                </select>	
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button onClick={()=>handlePlacesSubmit()} ref={buttonElementPlaces} type="button" className="default-btn">Apply Filter</button>
                                        </div>
                                    </div>
                                </form>
                                }

                                {navCategory === "community" &&
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label>Location: </label>
                                            <div className="form-group">
                                                <i className="flaticon-place" />
                                                {isLoaded && (
                                                    <Autocomplete
                                                    onLoad={(a) => setAutocomplete(a)}
                                                    onPlaceChanged={(e) => placeHasChanged(e)}
                                                    /* className="form-control form-group" */
                                                    >
                                                    <input name="address" value={communityForm.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                    </Autocomplete>)
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="cat"
                                                value={communityForm.cat}
                                                onChange={e=>handleCustomCategoryChange(e,"community")}
                                                >
                                                <option value="">All Community Categories</option>
                                                {
                                                categoriesCommunity.length > 0 && categoriesCommunity.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                                }
                                                </select>	
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Sub Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="sub"
                                                value={communityForm.sub}
                                                onChange={e=>{ 
                                                    setCommunityForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                }}
                                                >
                                                <option value="">All Sub Category</option>
                                                { subcategoryCommunity?.length && subcategoryCommunity.map((e) => (
                                                    <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                    ))
                                                }
                                                </select>	
                                            </div>
                                        </div>


                                        <div className="col-lg-12">
                                            <label>Search String: </label>
                                            <div className="form-group">
                                                <i className="flaticon-loupe" />
                                                <input name="q" value={communityForm.q} onChange={e=>{
                                                    setCommunityForm(prev=>({ ...prev, 'q': e.target.value })); 
                                                }} type="text" className="form-control" placeholder="What are you searching for?" autoComplete="off" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-12">
                                            <label>Sort Criteria: </label>
                                            <div className="form-group">
                                                <i className="fa fa-sort"></i>
                                                <select 
                                                autoComplete="off"
                                                name="ob"
                                                value={form.ob}
                                                onChange={e=>{
                                                    setForm(prev=>({ ...prev, 'ob': e.target.value })); 
                                                }}
                                                className="form-control">
                                                <option value="proximity">Proximity</option>
                                                <option value="latest">Latest</option>
                                                </select>	
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button onClick={()=>handleCommunitySubmit()} ref={buttonElementCommunity} type="button" className="default-btn">Apply Filter</button>
                                        </div>
                                    </div>
                                </form>
                                }

                                {navCategory === "events" &&
                                <form>
                                    <div className="row">

                                        <div style={{paddingTop: "20px"}}>
                                            <div className="row">
                                                {/* <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "anytime" }))}} className={`btn ${eventsForm.timeFrame === "anytime" ?  "btn-warning btn-sm" : "btn-sm btn-outline-warning" }`}>Anytime</button></div> */}
                                                <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "today" }))}} className={`btn ${eventsForm.timeFrame === "today" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Today</button></div>
                                                <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "this week" }))}} className={`btn ${eventsForm.timeFrame === "this week" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>This Week</button></div>
                                                <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "this weekend" }))}} className={`btn ${eventsForm.timeFrame === "this weekend" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>This Weekend</button></div>
                                                <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "next week" }))}} className={`btn ${eventsForm.timeFrame === "next week" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Next Week</button></div>
                                                <div className="col-6"><button type="button" onClick={()=>{setEventsForm(prev=>({ ...prev, "timeFrame": "custom" }))}} className={`btn ${eventsForm.timeFrame === "custom" ? "btn-warning btn-sm" : "btn-sm btn-outline-warning"}`}>Specific Date</button></div>
                                            </div>
                                        </div>

                                        {eventsForm.timeFrame === "custom" &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor=""><i className="fa fa-calendar"></i> Date</label>
                                                    <Datetime
                                                        className="input-group input-group-static text-center"
                                                        closeOnSelect={true}
                                                        dateFormat={"YYYY-MM-DD"}
                                                        timeFormat={false}
                                                        inputProps={{
                                                        name: "specificDate",
                                                        value:eventsForm.specificDate
                                                        }}
                                                        onChange={(e) => {
                                                        const newDay = moment(new Date(e._d)).format("YYYY-MM-DD");
                                                        setEventsForm(prev=>({ ...prev, 'specificDate': newDay}));
                                                        }}
                                                        isValidDate={(current) => current.isAfter(moment().subtract(1, "day"))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        }
                                            
                                        <div className="col-lg-12">
                                            <label>Location: </label>
                                            <div className="form-group">
                                                <i className="flaticon-place" />
                                                {isLoaded && (
                                                    <Autocomplete
                                                    onLoad={(a) => setAutocomplete(a)}
                                                    onPlaceChanged={(e) => placeHasChanged(e)}
                                                    /* className="form-control form-group" */
                                                    >
                                                    <input name="address" value={eventsForm.address} onChange={e=>handleChange(e)} type="text" className="form-control" placeholder="Location" />
                                                    </Autocomplete>)
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="cat"
                                                value={eventsForm.cat}
                                                onChange={e=>handleCustomCategoryChange(e,"events")}
                                                >
                                                <option value="">All Event Categories</option>
                                                {
                                                categoriesEvents.length > 0 && categoriesEvents.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)
                                                }
                                                </select>	
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label>Sub Category: </label>
                                            <div className="form-group">
                                                <i className="flaticon-filter" />
                                                <select 
                                                autoComplete="off"
                                                className="form-control"
                                                name="sub"
                                                value={eventsForm.sub}
                                                onChange={e=>{ 
                                                    setEventsForm(prev=>({ ...prev, 'sub': e.target.value })); 
                                                }}
                                                >
                                                <option value="">All Sub Category</option>
                                                { subcategoryEvents?.length && subcategoryEvents.map((e) => (
                                                    <option key={e.subcategoryName} value={e.subcategoryID}>{e.subcategoryName}</option>
                                                    ))
                                                }
                                                </select>	
                                            </div>
                                        </div>


                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button onClick={()=>handleEventsSubmit()} ref={buttonElementEvents} type="button" className="default-btn">Apply Filter</button>
                                        </div>
                                    </div>
                                </form>
                                }

                            </div> 
                        </div>
                    </div>

                </div>
                     
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default Explore