import {useState, useEffect} from "react";
import AuthorizedAreas from '../../../utils/AuthorizedAreas';
import { Link } from "react-router-dom";
import useAxios from "../../../utils/hooks/useAxios";
import moment from 'moment';

const MyFollows = () => {
    const [allData, setAllData] = useState([]);
	const {postOrGet}  = useAxios();

	const loadMyFollows = async () => { 
		postOrGet(`business/myfollows`,'get').then((data) =>{
			if(data?.results){
				setAllData(data.results);
			}
		});
	}


	useEffect(()=>{
		//import('../../../assets/admin/js/material-dashboard-inside').then((mod) => {}).catch(err => {});
		loadMyFollows();
		window.scrollTo({top: 0, left: 0});
	},[]);

	return <div className="row mt-4 p-4">  
				<div className="col-12">

					<div className="card mb-4">
						
						<div className="card-header pb-0">
							<h6>Favourite Businesses</h6>
						</div>
						

						<div className="card-body px-0 pt-0 pb-2">
							<AuthorizedAreas permission="CAN_MENU_MY_FOLLOWS">
							{
							allData.length > 0 ? 
								<div className="table-responsive p-0">
									<table className="table align-items-center mb-0">
										<thead>
											<tr>
												<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													S/N
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Business
												</th>
												<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
													Date Followed
												</th>
												
											</tr>
										</thead>
										<tbody>
											{
												allData.map((s, index) => (
													<tr key={`${s.followID}`}>
														<td className="text-center">
															<p className="text-sm font-weight-normal mb-0">
																{index + 1}
															</p>
														</td>
														<td className="text-left">
															<div className="d-flex px-3 py-1">
																<div className="d-flex flex-column justify-content-center">
																	<p className="mb-0 text-sm">
																		<h6>
																			<Link to={`/business/${s.businessURLName}`} state={{ id: s.businessId }}>
																			{s.businessFullName }
																			</Link>
																		</h6>
																	</p>
																</div>
															</div>
														</td>
														<td className="text-center">
																<p className="mb-0 text-sm ">
																	{moment(s.followDate).format("LL HH:mm") }
																</p>
														</td>
														
									
									
													</tr>
												))
											}
										</tbody>
									</table>
								</div> :
							<p className="p-3">No Businesses Followed</p>
							}
							</AuthorizedAreas>
						</div>
					</div>
				</div>
		   </div>
}

export default MyFollows