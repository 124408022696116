import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  name:"",
  userType:"",
  designation: null,
  permissions:[],
  menu:[],
  coordinates:{lat: process.env.REACT_APP_DEFAULT_LAT, lng: process.env.REACT_APP_DEFAULT_LNG},
  address:"Nottingham City Centre",
  accessToken:null,
  isAuthenticated: false,
  isLoading:false,
  triggerAlert:false,
  triggerAlertType:'',//error or success
  triggerAlertMessage:'',
  categories:[],
  subcategories:[],
  categoriesPlaces:[],
  subcategoriesPlaces:[],
  categoriesCommunity:[],
  subcategoriesCommunity:[],
  categoriesEvents:[],
  subcategoriesEvents:[],
}

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		SET_ACTIVE_USER: (state, { payload }) => {
			//return { ...action.payload, isLoading: false };
			state.name = payload.name;
			state.userType = payload.userType;
			state.designation = payload.designation;
			state.permissions = payload.permissions;
			state.menu = payload.menu;
			state.accessToken = payload.accessToken;
			state.isAuthenticated = payload.isAuthenticated;
		},
		SET_ACCESS_TOKEN: (state, action) => {
			state.accessToken = action.payload;
		},
		CHANGE_LOADING_STATE: (state, action) => {
			state.isLoading = action.payload;
		},
		SET_TRIGGER_ALERT: (state, action) => {
			state.triggerAlert = action.payload;
		},
		SET_TRIGGER_ALERT_TYPE: (state, action) => {
			state.triggerAlertType = action.payload;
		},
		SET_TRIGGER_ALERT_MESSAGE: (state, action) => {
			state.triggerAlertMessage = action.payload;
		},
		SET_COORDINATES: (state, action) => {
			state.coordinates = action.payload;
		},
		SET_ADDRESS: (state, action) => {
			state.address = action.payload;
		},
		SET_CATEGORIES: (state, action) => {
			state.categories = action.payload;
		},
		SET_SUBCATEGORIES: (state, action) => {
			state.subcategories = action.payload;
		},
		SET_CATEGORIES_PLACES: (state, action) => {
			state.categoriesPlaces = action.payload;
		},
		SET_SUBCATEGORIES_PLACES: (state, action) => {
			state.subcategoriesPlaces = action.payload;
		},
		SET_CATEGORIES_COMMUNITY: (state, action) => {
			state.categoriesCommunity = action.payload;
		},
		SET_SUBCATEGORIES_COMMUNITY: (state, action) => {
			state.subcategoriesCommunity = action.payload;
		},
		SET_CATEGORIES_EVENTS: (state, action) => {
			state.categoriesEvents = action.payload;
		},
		SET_SUBCATEGORIES_EVENTS: (state, action) => {
			state.subcategoriesEvents = action.payload;
		},
		LOGOUT: (state) => {
			//return initialState;  -->preserving lat and lng coordinates
			state.name = "";
			state.userType = "";
			state.designation = null;
			state.permissions = [];
			state.menu = [];
			state.accessToken = null;
			state.isAuthenticated = false;
			state.isLoading = false;
			state.triggerAlert = false;
			state.triggerAlertType = '';
			state.triggerAlertMessage = '';
			state.categories = [];
			state.categoriesPlaces = [];
			state.categoriesCommunity = [];
			state.categoriesEvents = [];
		}
	},
});

export const {  SET_ADDRESS, SET_COORDINATES, SET_ACTIVE_USER , SET_TRIGGER_ALERT, 
	            SET_TRIGGER_ALERT_TYPE, SET_TRIGGER_ALERT_MESSAGE, CHANGE_LOADING_STATE, 
				SET_CATEGORIES,SET_SUBCATEGORIES,SET_CATEGORIES_PLACES,SET_SUBCATEGORIES_PLACES,
				SET_CATEGORIES_COMMUNITY,SET_SUBCATEGORIES_COMMUNITY,SET_CATEGORIES_EVENTS,SET_SUBCATEGORIES_EVENTS,
				LOGOUT, SET_ACCESS_TOKEN } = authSlice.actions;

export default authSlice.reducer;