import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { EventsEditValidation } from "../../../utils/validations/EventsEditValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import {useJsApiLoader,Autocomplete} from "@react-google-maps/api";
import Geocode from "react-geocode";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
const imagePath = `${serverPath}/api/frontend/images/`;
const documentPath = `${serverPath}/api/docs/docs/`;

const  modules  = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script:  "sub" }, { script:  "super" }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        [/* "link", */ "image", /* "video" */],
        ["clean"],
    ],
};



  const EditEvent = () => {
      const defaultForm = {
        eventsFullName: "",
        eventsDescription: "",
        eventsPhone: "",
        eventsWebsite: "",
        eventStartDate: "",
        eventEndDate: "",
        eventStartTime: "",
        eventEndTime: "",
        eventsAddress: "",
        eventsLatitude: "",
        eventsLongitude: "",
        eventsCategory: "",
        eventsSubcategory: "",
        bannerMainPicture: "",
        bannerOthersPicture:"",
        removeMainBanner: false,
        removeMainBannerName: "",
        removeOtherImages:[]
      };
      const {userType} = useSelector(state=>state.auth);
      const location = useLocation();
      const id = location?.state?.id;
      const name = location?.state?.name;
      const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;

      const [form, setForm] = useState(defaultForm);
      const [categories,setCategories] = useState([]);
      const [subcategories,setSubcategories] = useState([]);
      const [status,setStatus] = useState('');

      const [mainImage,setMainImage] = useState("");
      const [additionalImages,setAdditionalImages] = useState([]);

      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY);
      const [autocomplete, setAutocomplete] = useState(/** @type google.maps.Map.Autocomplete */ (null));
      const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: ["places"],
      region: "uk",
      });

      const placeHasChanged = () => {
          const res = autocomplete.getPlace();
          const lat = res?.geometry?.location?.lat?.();
          const lng = res?.geometry?.location?.lng?.();
          Geocode.fromLatLng(lat, lng).then(
              (response) => {
                 setForm(prev=>({ 
                    ...prev, 
                    'eventsAddress': response.results[0].formatted_address ,
                    'eventsLatitude': lat,
                    'eventsLongitude': lng,
                  })); 
              },
              (error) => {
                  dispatchNotification('error',error);
              }
          ); 
      }

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();
      const maxSize = 600;//KB

      const getInitializationData = async () =>{
        await postOrGet(`newevents/edit?id=${id}`,'get')
            .then((data) =>{
                    if(data){
                        setCategories(data.categories);
                        setSubcategories(data.subcategories);
                        let biz = data.events;
                        
                        setStatus(biz.eventsApprovedStatus);
                        delete biz.eventsApprovedStatus;

                        if(biz.bannerMainPicture){
                          setMainImage(biz.bannerMainPicture);
                        }
                        delete biz.bannerMainPicture;

                        if(biz.bannerOthersPicture){
                          setAdditionalImages(biz.bannerOthersPicture.split(","));
                        }
                        delete biz.bannerOthersPicture;
                        
                        setForm(prev=>({ ...prev,...biz}));
                    }
            }); 
      }

      useEffect(() => {
        getInitializationData();
      },[]);


      const handleChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setForm(prev=>({ ...prev, [name]: value })); 
      }

      const handleCategoryChange = async (e) =>{
        if (e.target.value=="") return;
        handleChange(e);
        dispatchNotification('success','Fetching associated subcategories...');
            await postOrGet(`newevents/fetch/subcategories?eventCategory=${e.target.value}`,'get',{})
                .then((data) =>{
                    if(data){
                      setSubcategories(data.subcategories);
                    }
            });
      }
      
      const handleSubmit = async (e) => {
            e.preventDefault();
            //validation
            EventsEditValidation.validate(form, { abortEarly: false })
            .then(async (values)=>{
                const form_data = new FormData();
                form_data.append('additionalImages', JSON.stringify(additionalImages));
                
                for ( var key in form ) {
                      if(key=="bannerOthersPicture"  && form.bannerOthersPicture?.length){
                        let a=1;
                          Object.keys(form.bannerOthersPicture).forEach((key)=>{
                            form_data.append(`otherPictures${a}`, form.bannerOthersPicture.item(key))
                            a++;
                          }); 
                      }
                    else{
                        form_data.append(key, form[key]);
                      }
                }

                await postOrGet('newevents/save/edit','post',form_data,{"Content-Type": "multipart/form-data" })
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Edits saved successfully."); 
                       // setForm(defaultForm);
                       // setTimeout(() => navigate(-1),2000); 
                    }
                });   
            })
            .catch(err=>{
                let message = "<p>You have the following errors:</p>";
                message  += err.errors.join("<br/>");
                dispatchNotification('error',message);
            });
        }
      
      const handleBannerMainPictureUpload = (e) =>{
        /* if(e.target?.files[0]?.size/1024 > maxSize){
          e.target.value = null;
          dispatchNotification('error',`Banner Image should be less than ${maxSize}KB`);
        }
        else */
            setForm(prev=>({ ...prev, 'bannerMainPicture': e.target.files[0] })) 
      }

      const handleMultipleImages = (e) =>{
        /* for(let key in e.target.files){
            if(e.target.files[key]?.size/1024 > maxSize){
              e.target.value = null;
              dispatchNotification('error',`Images cannot be more than ${maxSize}KB`);
              break;
            }
        } */
        setForm(prev=>({ ...prev, 'bannerOthersPicture': e.target.files }))
      }

      const approveEvent = async () =>{
        await postOrGet('newevents/approve','post',{eventsID : id})
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Event has been approved successfully"); 
                        setStatus(1);
                    }
                }); 
      }

      const disapproveEvent = async () =>{
        await postOrGet('newevents/disapprove','post',{eventsID : id})
                .then((data) =>{
                    if(data){
                        dispatchNotification('success',"Event is now disapproved"); 
                        setStatus(0);
                    }
                }); 
      }
	
	
	return <AuthorizedAreas permission="CAN_EVENTS_EDIT">
	      <div className="card-body px-0 pt-0 pb-2">
					
          <form onSubmit={(e)=>handleSubmit(e)}>
            <div style={{ padding: 20 }}>
              
               <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                  <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                  <span className="btn-inner--text">GO BACK</span>
                </button>
                
              <div className="card mt-4" id="basic-info">
                <div className="card-header">

              {status==0 && userType == 3 &&
                <button type="button" onClick={()=>{approveEvent()}} className="mt-5 btn btn-icon btn-success btn-lg w-100">
                  <span className="btn-inner--icon"> <i className="material-icons">verified</i></span>
                  <span className="btn-inner--text">Approve Event</span>
                </button>
                }

                {status==1 && userType == 3 &&
                <button type="button" onClick={()=>{disapproveEvent()}} className="mt-5 btn btn-icon btn-warning btn-lg w-100">
                  <span className="btn-inner--icon"> <i className="material-icons">block</i></span>
                  <span className="btn-inner--text">Disapprove Event</span>
                </button>
                }

                  <h6 className="text-primary">Event Information for <span className="text-bold text-primary">{name}</span></h6></div>
                <div className="card-body pt-0">

                 
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="input-group input-group-static">
                          <label htmlFor="eventsFullName">Event Name</label>
                          <input
                            autoComplete="off"
                            type="text"
                            name="eventsFullName"
                            id="eventsFullName"
                            className="form-control"
                            placeholder="Enter Event Full Name"
                            value={form.eventsFullName}
                            onChange={(e)=>{
                              const name = e.target.name;
                              const value = e.target.value;
                              setForm(prev=>({ ...prev, [name]: value})); 
                            }}						
                          />
                        </div>
                      </div>
                    </div>

                 
                  </>
                  

                  {isLoaded && (
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsAddress">Event Address</label>
                        <Autocomplete
                            onLoad={(a) => setAutocomplete(a)}
                            onPlaceChanged={(e) => placeHasChanged(e)}
                             className="form-control"
                                >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="eventsAddress"
                                  id="eventsAddress"
                                  className="form-control"
                                  placeholder="Search Event Address"
                                  value={form.eventsAddress}
                                  onChange={(e)=>handleChange(e)}						
                                />
                        </Autocomplete>
                      </div>
                    </div>
                  </div>
                  )}

                  <div className="row mt-4">
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsPhone">Phone</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="eventsPhone"
                          id="eventsPhone"
                          className="form-control"
                          placeholder="Enter Phone Number"
                          value={form.eventsPhone}
						              onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsWebsite">Website</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="eventsWebsite"
                          id="eventsWebsite"
                          className="form-control"
                          placeholder="e.g www.business.com"
                          value={form.eventsWebsite}
						              onChange={(e)=>handleChange(e)}						
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">      
                            <h6 className="font-weight-bold text-primary">Dates</h6>
                            <div className="col-12">
                                <div className="row">

                                    <div className="col-6">
                                        <div className="input-group input-group-static">
                                            <label htmlFor="eventStartDatey">Start Date</label>
                                            <Datetime
                                                className="input-group input-group-static"
                                                closeOnSelect={true}
                                                dateFormat={"YYYY-MM-DD"}
                                                timeFormat={false}
                                                inputProps={{
                                                name: "eventStartDate",
                                                value: form.eventStartDate,
                                                }}

                                                onChange={(e) => {
                                                const newDay = moment(new Date(e._d)).format(
                                                    "YYYY-MM-DD"
                                                );
                                                setForm(prev=>({ ...prev, 'eventStartDate':newDay})); 
                                                }}
                                                isValidDate={(current) =>
                                                    current.isAfter(moment().subtract(1, "day"))
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="input-group input-group-static">
                                            <label htmlFor="eventEndDate">End Date</label>
                                            <Datetime
                                                
                                                className="input-group input-group-static"
                                                closeOnSelect={true}
                                                dateFormat={"YYYY-MM-DD"}
                                                timeFormat={false}
                                                inputProps={{
                                                name: "eventEndDate",
                                                value: form.eventEndDate,
                                                }}

                                                onChange={(e) => {
                                                const newDay2 = moment(new Date(e._d)).format(
                                                    "YYYY-MM-DD"
                                                );
                                                setForm(prev=>({ ...prev, 'eventEndDate':newDay2})); 
                                                }}
                                                isValidDate={(current) =>
                                                    current.isAfter(moment(form.eventStartDate))
                                                }
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                  </div>  

                  <div className="row mt-4">      
                      <h6 className="font-weight-bold text-primary">Times</h6>
                      <div className="col-12">
                          <div className="row">

                              <div className="col-6">
                                  <div className="input-group input-group-static">
                                  <label htmlFor="eventStartTime">Start Time</label>
                                  <Datetime
                                      
                                      className="input-group input-group-static"
                                      inputProps={{
                                      name: "eventStartTime",
                                      value: form.eventStartTime,
                                      }}
                                      onChange={(e) => {
                                      const newTime = moment(new Date(e._d)).format("HH:mm");
                                      setForm(prev=>({ ...prev, "eventStartTime":newTime})); 
                                      }}
                                      dateFormat={false}
                                      timeFormat={"HH:mm"}
                                  />
                                  </div>
                              </div>

                              <div className="col-6">
                                  <div className="input-group input-group-static">
                                  <label htmlFor="eventEndTime">End Time</label>
                                  <Datetime
                                      
                                      className="input-group input-group-static"
                                      inputProps={{
                                      name: "eventEndTime",
                                      value: form.eventEndTime,
                                      }}
                                      onChange={(e) => {
                                        const newTime = moment(new Date(e._d)).format("HH:mm");
                                        setForm(prev=>({ ...prev, "eventEndTime":newTime})); 
                                      }}
                                      dateFormat={false}
                                      timeFormat={"HH:mm"}
                                  />
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div> 

                 
                  <div className="row mt-4">
                    {mainImage && 
                     <div className="col-12 text-center">
                          <img src={`${imagePath}${mainImage}`} alt="img-blur-shadow" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                          <br/>
                          <button onClick={()=>{
                            setMainImage("");
                            setForm(prev=>({ ...prev, 'removeMainBanner': true , 'removeMainBannerName' : mainImage }))
                          }} className="btn btn btn-primary mt-2">Remove</button>
                     </div>}

                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="bannerMainPicture">Banner Picture <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                        <input
                          autoComplete="off"
                          type="file"
                          name="bannerMainPicture"
                          id="bannerMainPicture"
                          className="form-control"
                          onChange={(e)=>handleBannerMainPictureUpload(e)}	
                          accept=".jpg, .jpeg, .png"			
                        />
                      </div>
                    </div>
                  </div>


                   <div className="row mt-4">

                    <div className="row">
                        {additionalImages.length > 0 && additionalImages.map(img=>
                          (
                          <div key={img} className="col-3 text-center">
                            <img src={`${imagePath}${img}`} alt="Additional Place Images" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                            <button onClick={()=>{
                                          const newIms = additionalImages.filter(im=>im!=img);
                                          setAdditionalImages(newIms)
                                          setForm(prev=>({ ...prev, 'removeOtherImages': [...prev.removeOtherImages,img] }))
                                        }} 
                          className="btn btn btn-primary mt-2">Remove</button>
                          </div>
                          ))}
                    </div>

                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="multipleImages">Additional Images <span className="text-secondary text-sm">(Recommended size is {maxSize}KB)</span></label>
                        <input
                          autoComplete="off"
                          type="file"
                          id="multipleImages"
                          className="form-control"
                          multiple={true}
                          onChange={(e)=>handleMultipleImages(e)}		
                          accept=".jpg, .jpeg, .png"		
                        />
                      </div>
                    </div>
                  </div>
          
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsCategory">Event Category</label>
                        <select
                          className="form-control"
                          name="eventsCategory"
                          id="eventsCategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.eventsCategory}
                          onChange={e=>handleCategoryChange(e)}
                        >
                          <option value=""> --Select Events Category--</option>
                          { categories?.length && categories.map((e) => (
                              <option value={e.categoryID}>{e.categoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsSubcategory">Event Sub Category</label>
                        <select
                          className="form-control"
                          name="eventsSubcategory"
                          id="eventsSubcategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.eventsSubcategory}
                          onChange={(e)=>handleChange(e)}	
                        >
                          <option value=""> --Select Sub Category--</option>
                          { subcategories?.length && subcategories.map((e) => (
                              <option value={e.subcategoryID}>{e.subcategoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 mb-5">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="eventsDescription">Event Description</label>
                       

                          <ReactQuill
                          modules={modules}
                          style={{ width: '100%', height: '200px', marginBottom: '10px'}} 
                          theme="snow" name="eventsDescription" value={form.eventsDescription} 
                          onChange={(e)=>{
                            setForm(prev=>({ ...prev, 'eventsDescription': e }))
                          }}	 
                          />

                      </div>
                    </div>
                  </div>
   
                  
                 <button type="submit" className="mt-5 btn btn-icon btn-primary btn-lg w-100">
                  <span className="btn-inner--icon"> <i className="material-icons">save</i></span>
                  <span className="btn-inner--text">SAVE EDITS</span>
                </button>
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default EditEvent