import { NavLink, Link } from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import { LOGOUT } from "../../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";
import useAxios from "../../utils/hooks/useAxios";

const SideNav = () => {
    const {name,menu} = useSelector((state)=>state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {postOrGet}  = useAxios();

	const logOut = async () =>{
		await postOrGet('/auth/logout','post',{})
		.then((data) =>{
			navigate('/',{ state: { status: 'loggedOut'} }); 	
		});
	}

  return (
		<aside
		onClick={()=>window.toggleSidenav()}
			className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
			id="sidenav-main"
		>
			<div className="sidenav-header">
				<i
					className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
					aria-hidden="true"
					id="iconSidenav"
				/>
				<NavLink to="/admin/dashboard" className="navbar-brand m-0">
					<img
						src={require("../../assets/admin/img/logo-ct.png")}
						className="navbar-brand-img h-100"
						alt="main logo"
					/>
					<span className="ms-1 font-weight-bold text-white">
						Business Manager
					</span>
				</NavLink>
			</div>
			<hr className="horizontal light mt-0 mb-2" />
			<div className="collapse navbar-collapse w-auto h-auto" id="sidenav-collapse-main">
				<ul className="navbar-nav">
					<>
						<li className="nav-item mb-2 mt-0">
							<a
								data-bs-toggle="collapse"
								href="#ProfileNav"
								className="nav-link text-white"
								aria-controls="ProfileNav"
								role="button"
								aria-expanded="false"
							>
								{/* <img src={require("../../assets/admin/img/team-3.jpg")} className="avatar" alt="" /> */}
								<span className="nav-link-text ms-2 ps-1">Welcome {name}</span>
							</a>
							{/* <div className="collapse" id="ProfileNav" style={{}}>
								<ul className="nav ">
									<li className="nav-item">
										<a
											className="nav-link text-white"
											href="#j"
										>
											<span className="sidenav-mini-icon"> MP </span>
											<span className="sidenav-normal  ms-3  ps-1">
												{" "}
												My Profile{" "}
											</span>
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link text-white "
											href="#h"
										>
											<span className="sidenav-mini-icon"> S </span>
											<span className="sidenav-normal  ms-3  ps-1">
												{" "}
												Settings{" "}
											</span>
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link text-white "
											href="#h"
										>
											<span className="sidenav-mini-icon"> L </span>
											<span className="sidenav-normal  ms-3  ps-1">
												Logout{" "}
											</span>
										</a>
									</li>
								</ul>
							</div> */}
						</li>
						<hr className="horizontal light mt-0"/>
					</>

					<li className="nav-item">
						<h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder text-white">
							MODULES
						</h6>
					</li>
					<li className="nav-item">
						<NavLink
							end
							to="/"
							className={({ isActive }) =>
								isActive ? `nav-link active` : 'nav-link'
							}
						>
							<i className='material-icons-round'>arrow_back</i>
							<span className="nav-link-text ms-2 ps-1">Front End</span>
						</NavLink>
					</li>
					<li className={`nav-item active`}>
						<NavLink
							end
							to="/admin/dashboard"
							className={({ isActive }) =>
								isActive ? `nav-link active` : 'nav-link'
							}
						>
							<i className='material-icons-round'>dashboard</i>
							<span className="nav-link-text ms-2 ps-1">Dashboard</span>
						</NavLink>
					</li>
					
					{menu.map((m) => {
						return (
							<li key={m.navID} className="nav-item">
								<NavLink
									className={({ isActive }) =>
										isActive ? `nav-link active` : 'nav-link'
									}
									to={m.navRoute}
								>
									<i className='material-icons-round'>{m.navIcon}</i>
									<span className='nav-link-text ms-2 ps-1'>{m.navName}</span>
								</NavLink>
							</li>
						);
					})}
					<li className='nav-item'>
						<Link 
						to="#logOut" 
						onClick={()=>logOut()}
						className='nav-link'
						>
							<i className='material-icons-round'>logout</i>
							<span className='nav-link-text ms-2 ps-1'>Log Out</span>
						</Link>
					</li>
				</ul>
			</div>
		</aside>
	);
}

export default SideNav