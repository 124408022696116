import * as yup from "yup";

export const EventsEditValidation = yup.object().shape({
	eventsID: yup.string().required('Community ID is required'),
	eventsFullName: yup.string().max(100,'Community Name should not exceed 100 characters'),
	eventsDescription: yup.string().required('Community Description is required'),
	eventsAddress: yup.string().max(200,'Community Address cannot exceed 200 characters').required('Community Address field is required'),
	eventsLatitude: yup.string().max(100).required('Community Latitude field is required'),
	eventsLongitude: yup.string().max(100).required('Community Longitude field is required'),
	eventsCategory: yup.string().max(2).required('Community Category field is required'),
	eventsWebsite: yup.string().nullable().notRequired().when('eventsWebsite', {
		is: (value) => value?.length,
		then: (rule) => rule.matches(/^www.(\w+)\.(\w+).(\w+)?$/,'Website is not in acceptable format'),
	}),
	eventsSubcategory: yup.string().max(2).nullable().notRequired(),
	removeMainBanner: yup.boolean().required('Required field')
},
    [
        // Add Cyclic deps here because when in businessWebsite require itself
        ['eventsWebsite', 'eventsWebsite'],
    ]);