import {useEffect, useState, useRef} from 'react';
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {SET_ADDRESS, SET_COORDINATES, CHANGE_LOADING_STATE, LOGOUT} from '../../redux/slice/authSlice';
import Modal from 'react-bootstrap/Modal';
import useGlobalNotification from '../../utils/hooks/useGlobalNotification';
import {
	GoogleMap,
	useJsApiLoader,
	MarkerF,
	Autocomplete
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import NavBar from '../../components/frontend/NavBar';
import useAxios from "../../utils/hooks/useAxios";
import {weekdays} from '../../utils/weekdays_months';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating';
import { useReactPWAInstall } from "react-pwa-install";
import "./Home.scss";
import logo from "../../assets/frontend/apple-touch-icon.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';


const libraries = ['places'];
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const Home = () => {
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
    const [map, setMap] = useState(/** @type google.maps.Map */ (null)); //This is JSDocs-->allows you access to code completion
	const [autocomplete, setAutocomplete] = useState((null));
	const [autocomplete2, setAutocomplete2] = useState((null));
    const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries,
		region: "uk",
	});
    const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
    const imagePath = `${serverPath}/api/frontend/images/`;
    const eventPath = `${serverPath}/api/frontend/events/`;
    const listingPath = `${serverPath}/api/frontend/listings/`;

    const {postOrGet}  = useAxios();

    const {isAuthenticated, address, coordinates, categories, categoriesPlaces, categoriesCommunity, categoriesEvents} = useSelector(state=> state.auth);
    const location = useLocation();
    const navigate = useNavigate();
    const status = location?.state?.status;
    const {dispatchNotification} = useGlobalNotification();
    const dispatch = useDispatch();

    const defaultForm = {
        address,
		searchKeyword: "",
		categoryId: "",
        type: "default", //business(default),properties(listings),events,places,community
        propertyPrice:500,
        timeFrame:"anytime"
    };

    const [form, setForm] = useState(defaultForm);
    const [showModal,setShowModal] = useState(false);
    const [latest,setLatest] = useState([]);
    const [reviews, setReviews] = useState({});
    const [latestEvents,setLatestEvents] = useState([]);
    const [latestListings,setLatestListings] = useState([]);
    const [latestNewEvents,setLatestNewEvents] = useState([]);
    const [latestPlaces,setLatestPlaces] = useState([]);
    const [latestCommunity,setLatestCommunity] = useState([]);
    const [followedBiz,setFollowedBiz] = useState([]);
    const [openClose, setOpenClose] = useState({});

    const handleClick = () =>{
        navigate( '/explore',
            {
                state: {
                    ...form
                }
            }
        );
    }

	const placeHasChanged2 = () => {
        const res = autocomplete2.getPlace();
		const lat = res?.geometry?.location?.lat?.();
		const lng = res?.geometry?.location?.lng?.();
		const newPosition = { lat, lng };
        dispatch(SET_COORDINATES(newPosition));
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                setForm(prev=>({ ...prev, 'address': response.results[0].formatted_address })); 
                sessionStorage.setItem('location', JSON.stringify({ lat, lng, address:response.results[0].formatted_address })); 
                dispatch(SET_ADDRESS(response.results[0].formatted_address)); 
            },
            (error) => {
                dispatchNotification('error',error);
            }
        ); 
	};
	const placeHasChanged = () => {
        const res = autocomplete.getPlace();
		const lat = res?.geometry?.location?.lat?.();
		const lng = res?.geometry?.location?.lng?.();
		const newPosition = { lat, lng };
        dispatch(SET_COORDINATES(newPosition));
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                setForm(prev=>({ ...prev, 'address': response.results[0].formatted_address }));
                sessionStorage.setItem('location', JSON.stringify({ lat, lng, address:response.results[0].formatted_address })); 
                dispatch(SET_ADDRESS(response.results[0].formatted_address)); 
            },
            (error) => {
             
                dispatchNotification('error',error);
            }
        ); 
		map.panTo(newPosition);
	};
	const mapIsClicked = (e) => {
		const lat = e?.latLng?.lat?.();
		const lng = e?.latLng?.lng?.();
		const newPosition = { lat, lng };
        dispatch(SET_COORDINATES(newPosition));
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                setForm(prev=>({ ...prev, 'address': response.results[0].formatted_address }));
                sessionStorage.setItem('location', JSON.stringify({ lat, lng, address:response.results[0].formatted_address }));  
                dispatch(SET_ADDRESS(response.results[0].formatted_address)); 
            },
            (error) => {
                dispatchNotification('error',error);
            }
        ); 
		map.panTo(newPosition);
	};
    const getLocation = async () => {
         dispatch(CHANGE_LOADING_STATE(true));
        if (!navigator.geolocation) {
            //launch modal and autocomplete
            dispatchNotification('error','Kindly enter a location to get a customized experience');
            setShowModal(true);
        } else { 
                navigator.geolocation.getCurrentPosition((position) => {
                dispatch(SET_COORDINATES({lat:position.coords.latitude, lng:position.coords.longitude}));
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                        (response) => {
                           dispatch(SET_ADDRESS(response.results[0].formatted_address)); 
                           setForm(prev=>({ ...prev, 'address': response.results[0].formatted_address })); 
                           sessionStorage.setItem('location', JSON.stringify({ lat:position.coords.latitude, lng:position.coords.longitude, address:response.results[0].formatted_address })); 
                           getLatest(position.coords.latitude,position.coords.longitude);
                        },
                        (error) => {
                            dispatchNotification('error',error);
                        }
                );   
            }, () => {
                dispatchNotification('error','Kindly enter a location to get a customized experience');
                setShowModal(true);
            });
        }
        dispatch(CHANGE_LOADING_STATE(false));
    }
    const handleLikeClick = async (e,bizId) =>{
       e.preventDefault();

       let action = followedBiz.includes(bizId) ? "unfollow" : "follow";

       postOrGet('frontend/follow/unfollow','post',{bizId,action })
        .then((data) =>{
            if(data){
                if(action == "follow"){
                  setFollowedBiz(prev=>([ ...prev, bizId ])); 
                }else{
                    let newAr = followedBiz.filter(id=>id != bizId );
                    setFollowedBiz(newAr);
                }
            }
        });

       
    }

    const getLatest = async (lat,lng) =>{

        postOrGet('frontend/latest','post',{lat,lng })
            .then((data) =>{
                if(data){
                    const dayN = new Date().getDay();
                    const day = weekdays[dayN];
                    const openOrClose = {};
                    data?.results.forEach(biz=>{
                            if( !(day in biz.businessOperatingTimes)){
                                    openOrClose[biz.businessID] = "closed";
                            }
                            else if(biz.businessOperatingTimes[day]['startTime']=="" || biz.businessOperatingTimes[day]['endTime']==""){
                                openOrClose[biz.businessID] = "Closed";
                            }else{
                                const format = 'HH:mm';
                                const currentTime = moment(moment(),format), startTime = moment(biz.businessOperatingTimes[day]["startTime"], format),endTime = moment(biz.businessOperatingTimes[day]["endTime"], format);
                                openOrClose[biz.businessID] = currentTime.isBetween(startTime, endTime) ? "Open" : "Closed";  
                            }
                    });
                   setOpenClose(openOrClose);
                   if(data.resultReview.length>0){
                        let resultReview={};
                        for(let el of data.resultReview){
                            resultReview[el.businessId]=el.ratings;
                        }
                        setReviews(resultReview);
                    }
                   setLatest(data.results);
                   setLatestEvents(data.resultsEvents);
                   setLatestListings(data.resultsListings); 
                   setLatestNewEvents(data.resultsNewEvents);
                   setLatestPlaces(data.resultsPlaces);
                   setLatestCommunity(data.resultsCommunity);
                   if(data?.followedBiz){
                    setFollowedBiz(data.followedBiz);
                   }
                }
            });
    }
      
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setForm(prev=>({ ...prev, [name]: value })); 
   };

   const handlePWA = () => {
    pwaInstall({
      title: "Install Esipsi or Add to Home Screen?",
      logo: logo,
      description: "Advanced Business Listing",
    })
      .then(() => {})
      .catch(() => {});
  };

    useEffect(() => {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOLOCATION_API_KEY);
       if(status && status == 'loggedOut'){
            dispatch(LOGOUT());
			//dispatchNotification('success','You have logout successfully');
		}
        if(sessionStorage.getItem('location')){
            let location = JSON.parse(sessionStorage.getItem('location'));
            setForm(prev=>({ ...prev, 'address': location.address })); 
            getLatest(location.lat,location.lng);
        }else{
            getLocation();
        }
	}, []);


  return (
    <>
        {/* {supported() && !isInstalled() && handlePWA()} */}
        
        <Modal 
        show={showModal} 
        /* fullscreen={true} */
        size="xl"
        scrollable={false}
        centered
        backdrop="static"
        keyboard={false} 
        onHide={()=>setShowModal(false)}>
            <Modal.Header closeButton={false}> 
                <Modal.Title>Specify Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {
               isLoaded?
               <>
               <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label>Search Location</label>
                            {isLoaded && (
                                <Autocomplete
                                    onLoad={(a) => setAutocomplete(a)}
                                    onPlaceChanged={(e) => placeHasChanged(e)}
                                    /* className="form-control form-group" */
                                >
                                    <input
                                    style={{ color: 'white', backgroundColor: '#fc5c65'}}
                                        /* ref={inputRef} */
                                        autoComplete="off"
                                        value={form.address}
                                        onChange={(e)=>{setForm(prev=>({ ...prev, 'address': e.target.value }))}}
                                        type="text"
                                        id="search"
                                        name="mapAddress"
                                        className="form-control"
                                        placeholder="Type your location"
                                    />
                                </Autocomplete>
                            )}
                        </div>
                    </div>
                </div>
               <GoogleMap
                    mapContainerStyle={{
                        width: "100%",
                        height: "400px",
                        marginTop: "5px",
                    }}
                    center={coordinates}
                    zoom={18}
                    onLoad={(map) => setMap(map)}
                    onClick={(e) => mapIsClicked(e)}
                >
                    <MarkerF position={coordinates} />
                </GoogleMap>  
                <button onClick={() =>setShowModal(false)}type="button" className="btn btn-icon btn-danger btn-lg w-100 mt-3">
                    <i className="flaticon-place" /> <span className="btn-inner--text">Use Location</span>
                </button>
                </>                  
               :
               null}
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="danger" onClick={()=>setShowModal(false)}>Close</Button> */}
            </Modal.Footer>
        </Modal>

        {/* Banner Area */}
        <div className="banner-area-two">

			<NavBar/>

            {/* Search Area */}
            <div className="d-table">
                <div className="d-table-cell">
                <div className="container">
                    <div className="banner-content banner-content-ptb text-center">
                    <p>Explore Your Suburb</p>
                    <h1 className="margin-auto">Discover awesome places in your area</h1>


                    <div className="banner-form">
                        <div className="row" style={{padding: "10px", maxWidth: "1140px"}}>
                            <Navbar bg="primary" variant="dark" className="responsiveNoPadding" style={{borderRadius:"5px"}}>
                                    <Navbar.Brand className="serachingFor" href="#" style={{ color:"white", fontSize:"17px"}}>Searching for?</Navbar.Brand>

                                    <Nav className="me-auto explore_area">
                                        <Link to="#" onClick={()=>setForm(prev=>({ ...prev, "type": "default" }))} className={`nav-link responsiveLink ${form.type === "default" && 'exploreActive'}`}><i className="fa fa-briefcase"></i> Businesses</Link>
                                            <Link to="#" onClick={()=>setForm(prev=>({ ...prev, "type": "properties" }))} className={`nav-link responsiveLink ${form.type === "properties" && 'exploreActive'}`}><i className="fa fa-house"></i> Properties</Link>
                                            <Link to="#" onClick={()=>setForm(prev=>({ ...prev, "type": "places" }))} className={`nav-link responsiveLink ${form.type === "places" && 'exploreActive'}`}><i className="fa fa-location-dot"></i> Places</Link>
                                            <Link to="#" onClick={()=>setForm(prev=>({ ...prev, "type": "community" }))} className={`nav-link responsiveLink ${form.type === "community" && 'exploreActive'}`}><i className="fa fa-users"></i> Community</Link>
                                            <Link to="#" onClick={()=>setForm(prev=>({ ...prev, "type": "events" }))} className={`nav-link responsiveLink ${form.type === "events" && 'exploreActive'}`}><i className="fa fa-calendar"></i> Events</Link>
                                            <Link to="#" onClick={()=>setForm(prev=>({ ...prev, "type": "deals" }))} className={`nav-link responsiveLink ${form.type === "deals" && 'exploreActive'}`}><i className="fa fa-gift"></i> Deals/Offers</Link>
                                    </Nav>
                            </Navbar>
                        </div>
                        <form>
                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                                <i className="flaticon-place" />
                                {isLoaded && (
                                <Autocomplete
                                onLoad={(a) => setAutocomplete2(a)}
                                onPlaceChanged={(e) => placeHasChanged2(e)}
                                /* className="form-control form-group" */
                                >
                                <input name="address" value={form.address} onChange={e=>handleChange(e)}  autoComplete="off" className="form-control" type="text" placeholder="Search Location" />
                                </Autocomplete>
                                    
                                )}
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-4 p-1">
                            {
                                form.type === "properties" ?
                                    <>
                                    <label className="form-label">Price Range <span className={'text-bold'}>(&pound;300 - &pound;5000)</span></label>
                                    <Slider
                                        value={form.propertyPrice}
                                        min={300}
                                        max={5000}
                                        step={100}
                                        labels={{ 300: '300', 2500: '2500', 5000: '5000'}}
                                        tooltip={true}
                                        onChange={(v)=>setForm(prev=>({ ...prev, "propertyPrice": v }))}
                                    />
                                    </>:null
                            }
                           {form.type === "default" || form.type === "places" ||  form.type === "community" ?
                            <div className="form-group">
                                <i className="flaticon-vision" />
                                <input autoComplete="off" 
                                name="searchKeyword" 
                                value={form.searchKeyword} 
                                onChange={e=>handleChange(e)} 
                                className="form-control" 
                                type="text" 
                                placeholder="What are looking for?" />
                            </div>:null}
                                {form.type === "deals" || form.type === "events"   ?
                                    <div className="form-group">
                                        <i className="flaticon-calendar" />
                                        <select
                                            className="form-control"
                                            autoComplete="off"
                                            name="timeFrame"
                                            value={form.timeFrame}
                                            onChange={e=>setForm(prev=>({ ...prev, "timeFrame": e.target.value }))}
                                        >
                                            <option value="anytime">Anytime</option>
                                            <option value="today">Today</option>
                                            <option value="this week">This Week</option>
                                            <option value="this weekend">This Weekend</option>
                                            <option value="next week">Next Week</option>
                                        </select>
                                    </div>
                                    :null}
                            </div>



                            {form.type === "default" || form.type === "deals" || form.type === "places" ||  form.type === "community" || form.type === "events"   ?
                            <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                                <i className="flaticon-category" />
                                <select 
                                    className="form-control"
                                    autoComplete="off"
                                    /* defaultValue={""} */
                                    name="categoryId"
						            value={form.categoryId}
                                    onChange={e=>handleChange(e)}	
                                >
                                <option value="">All Categories</option>
                                { (form.type === "default" || form.type === "deals") && categories.length > 0 && categories.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)}
                                { form.type === "places" && categoriesPlaces.length > 0 && categoriesPlaces.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)}
                                { form.type === "community" && categoriesCommunity.length > 0 && categoriesCommunity.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)}
                                { form.type === "events" && categoriesEvents.length > 0 && categoriesEvents.map(category=> <option key={category.categoryID} value={category.categoryID}>{category.categoryName}</option>)}
                                </select>
                            </div>
                            </div>:null}

                            <div className="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0">
                            <button onClick={(e)=>handleClick(e)} type="button" className="default-btn">
                                Search
                                <i className="flaticon-loupe" />
                            </button>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* End Search Area */}

        </div>
        {/* End Banner Area */}
       

        {/* Latest Businesses */}
           {
            latest.length>0 &&

            <section className="place-list-two pb-50" style={{ paddingTop: 30 }}>
                <div className="container-fluid">
                    <div className="section-title text-center">
                        <span>Business</span>
                        <h2>Businesses Around You</h2>
                    </div>
                        <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        >
                        {latest.map(d=>
                                (
                                <div key={d.businessURLName} className="place-list-item" style={{ padding: '15px' }}>
                                    <div className="images">
                                    <Link to={`/business/${d.businessURLName}`} state={{ id: d.businessID }} className="images-list">
                                        <img style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${imagePath}${d.bannerMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                                    </Link>

                                    <div className={`place-status ${openClose[d.businessID]=='Closed'?"bg-dark-orange":"bg-color-green"}`}>
                                        <a href="#"><h3>{ openClose[d.businessID]}</h3></a>
                                    </div>
                                    <div className="place-tag">
                                        {
                                        isAuthenticated &&
                                        <ul>
                                            <li><a onClick={(e)=>{handleLikeClick(e,d.businessID)}} className={followedBiz.includes(d.businessID) ? "iFollow animated heartBeat" : ""} href="#" ><i className="flaticon-like" /></a></li>
                                        </ul>
                                        }

                                    <h3 className="title"><Link to="#">{d.categoryName}</Link></h3>
                                    <span style={{ float: 'right', color : 'white' }}>{parseFloat(d.distance).toFixed(2)} miles</span>
                                    </div>
                                    </div>
                                    <div className="content">
                                    <Link to={`/business/${d.businessURLName}`} state={{ id: d.businessID }}>
                                        <h5 className="oneLine">{d.businessFullName}</h5>
                                    </Link>
                                    <p>
                                        <i className="flaticon-cursor" />
                                        <span className="twoLines">{d.businessAddress}</span>
                                    </p>
                                    {
                                                    d.businessID in reviews ?
                                                    <ul className="place-rating">
                                                        <li>
                                                        <Link to="#">{reviews[d.businessID]}</Link>
                                                        </li>
                                                        <li>
                                                        <Rating
                                                        initialValue={reviews[d.businessID]}
                                                            readonly={true}
                                                            allowFraction={true}
                                                            size={20}
                                                            allowHover={false}
                                                            disableFillHover={true}
                                                        />
                                                        </li>
                                                    </ul> :
                                                    <ul className="place-rating">
                                                        <li>
                                                        {/* <Link to="#">&nbsp;</Link> */}
                                                        No Reviews
                                                        </li>
                                                        <li>

                                                        </li>
                                                    </ul>
                                                }
                                    </div>
                                </div>)
                                )
                                }
                        </Carousel>
                </div>
            </section>
            }
        {/* End Latest Businesses*/}


        {/* Business Events */}
        {
         latestEvents.length>0 &&
         <section className="place-list-two pb-50" style={{ paddingTop: 30 }}>
            <div className="container-fluid">
                <div className="section-title text-center">
                    <span>Deals</span>
                    <h2>Offers Happening Around You?</h2>
                </div>
               <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    >
               {latestEvents.map(d=> 
                (
                <div key={d.eventName} className="place-list-item" style={{ padding: '15px' }}>
                    <div className="images">
                        
                        <Link to={"/business/event"} state={{ id: d.eventID }} className="images-list">
                            <img className="imageHover" style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${eventPath}${d.eventMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                        </Link>
                        <div className="eventOverlayHomePage"></div>

                        <div className="headerHome">
                            <div style={{ position:'relative' }}>
                                <div className="date">
                                <i className="fa-regular fa-calendar"></i> { d.eventStartDate == d.eventEndDate? moment(d.eventStartDate).format('L') : `${moment(d.eventStartDate).format('L')} - ${moment(d.eventEndDate).format('L')}`}
                                </div>

                                <div className="time">
                                    <h6 className="show" style={{ color: 'white', fontWeight:'normal'}}> 
                                    <i className="fa-regular fa-clock"></i>&nbsp;
                                    {moment(d.eventStartTime, "HH:mm").format("HH:mm")} -&nbsp; 
                                        {moment(d.eventEndTime, "HH:mm").format("HH:mm")} 
                                    </h6>
                                </div>
                            </div>
                        </div>
                   
                    <div className="place-tag"> 
                        <p> 
                            {/* <ul>
                            <li><Link to="#"><i className="flaticon-like" /></Link></li>
                            </ul> */}
                            <span className="oneLine" style={{ color: 'white', fontWeight: 'bold', marginLeft:'3px' }}>{d.eventName}</span>
                        </p>
                    </div>
                    </div>
                    <div className="content" style={{ padding: '5px' }}>                   
                        <p style={{ paddingBottom:0 }}><i className="fa-regular fa-compass"></i> by {d.businessFullName}</p>
                    </div>
                </div>) 
                )
                }
                </Carousel>
            </div>
        </section>  
        }
        {/* End Business Events*/}

        {/* Latest Listings */}
        {
         latestListings.length>0 &&
         <section className="place-list-two pb-50" style={{ paddingTop: 30 }}>
            <div className="container-fluid">
                <div className="section-title text-center">
                    <span>Real Estate</span>
                    <h2>Looking for properties to rent?</h2>
                </div>
                <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    >
               {latestListings.map(d=> 
                (
                <div key={d.listingName} className="place-list-item" style={{ padding: '15px' }}>
                    <div className="images">
                        
                        <Link to={"/business/listing"} state={{ id: d.listingID }} className="images-list">
                            <img className="imageHover" style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${listingPath}${d.listingMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                        </Link>
                        <div className="eventOverlayHomePage"></div>

                        <div className="headerHome">
                            <div style={{ position:'relative' }}>
                                <div className="date dateListing">
                                <span style={{ fontWeight:800 }}>&#163;{d.listingAmount}</span> 
                                <span style={{ fontWeight:300 }}> PER MONTH</span>
                                </div>
                            </div>
                        </div>      
                    
                    <div className="place-tag">      
                        <p> 
                            {/* <ul>
                            <li><Link to="#"><i className="flaticon-like" /></Link></li>
                            </ul> */}
                            <Link to={"/business/listing"} state={{ id: d.listingID }}>
                            <span className="oneLine" style={{ color: 'white', fontWeight: 'bold', marginLeft:'3px' , fontSize: '15px'}}>{d.listingName}</span>
                            </Link>
                        </p>
                    </div>
                    </div>
                    <div className="row" style={{ padding: '0px', position: 'relative' }}>  
                        {d.listingSize &&                 
                        <row className="col-4" style={{ padding: '0px' }}>
                            <p><i className="fa fa-box listingIcon"></i><span style={{ fontSize:'13px' }}>{d.listingSize}</span></p>
                        </row>
                        }
                        <row className="col-4" style={{ padding: '0px' }}>
                            <p><i className="fa fa-bed listingIcon"></i>&nbsp;<span style={{ fontSize:'13px' }}>{d.listingBedrooms}</span></p>
                        </row>
                        <row className="col-4" style={{ padding: '0px' }}>
                            <p><i className="fa fa-bath listingIcon"></i>&nbsp;<span style={{ fontSize:'13px' }}>{d.listingBathrooms}</span></p>
                        </row>
                    </div>
                </div>) 
                )
                }
                </Carousel>
            </div>
        </section>  
        }
        {/* End Latest Listings*/}

        {/* New Events */}
        {
            latestNewEvents.length>0 &&
            <section className="place-list-two pb-50" style={{ paddingTop: 30 }}>
                <div className="container-fluid">
                    <div className="section-title text-center">
                        <span>Events</span>
                        <h2>Events near you</h2>
                    </div>
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                    >
                        {latestNewEvents.map(d=>
                            (
                                <div key={d.eventsFullName} className="place-list-item" style={{ padding: '15px' }}>
                                    <div className="images">

                                        <Link to={`/ev/${d.eventsID}`}  className="images-list">
                                            <img className="imageHover" style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${imagePath}${d.bannerMainPicture}`} alt={d.eventsFullName} crossOrigin="Anonymous" />
                                        </Link>
                                        <div className="eventOverlayHomePage"></div>

                                        <div className="headerHome">
                                            <div style={{ position:'relative' }}>
                                                <div className="date">
                                                    <i className="fa-regular fa-calendar"></i> { d.eventStartDate == d.eventEndDate? moment(d.eventStartDate).format('L') : `${moment(d.eventStartDate).format('L')} - ${moment(d.eventEndDate).format('L')}`}
                                                </div>

                                                <div className="time">
                                                    <h6 className="show" style={{ color: 'white', fontWeight:'normal'}}>
                                                        <i className="fa-regular fa-clock"></i>&nbsp;
                                                        {moment(d.eventStartTime, "HH:mm").format("HH:mm")} -&nbsp;
                                                        {moment(d.eventEndTime, "HH:mm").format("HH:mm")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="place-tag">
                                            <p><span className="oneLine" style={{ color: 'white', fontWeight: 'bold', marginLeft:'3px' }}>{d.eventsFullName}</span></p>
                                            <p><span className="oneLine" style={{ color: 'white', marginLeft:'3px' }}>{d.distance} miles</span></p>
                                        </div>
                                    </div>

                                </div>)
                        )
                        }
                    </Carousel>
                </div>
            </section>
        }
        {/* End New Events*/}

        {/* New Places */}
        {
            latestPlaces.length>0 &&
            <section className="place-list-two pb-50" style={{ paddingTop: 30 }}>
                <div className="container-fluid">
                    <div className="section-title text-center">
                        <span>Places</span>
                        <h2>places around you</h2>
                    </div>
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                    >
                        {latestPlaces.map(d=>
                            (
                                <div key={d.placesFullName} className="place-list-item" style={{ padding: '15px' }}>
                                    <div className="images">

                                        <Link to={`/place/${d.placesID}`}  className="images-list">
                                            <img className="imageHover" style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${imagePath}${d.bannerMainPicture}`} alt={d.placesFullName} crossOrigin="Anonymous" />
                                        </Link>
                                        <div className="eventOverlayHomePage"></div>



                                        <div className="place-tag">
                                            <p>
                                                <span className="oneLine" style={{ color: 'white', fontWeight: 'bold', marginLeft:'3px' }}>{d.placesFullName}</span>
                                                <br/>
                                                <span style={{marginLeft: '3px', color: 'white', fontWeight: 300 }}>{d.placesAddress}</span>

                                            </p>
                                            <p><span className="oneLine" style={{ color: 'white', marginLeft:'3px' }}>{d.distance} miles</span></p>
                                        </div>
                                    </div>

                                </div>)
                        )
                        }
                    </Carousel>
                </div>
            </section>
        }
        {/* End New Places */}

        {/* New Community */}
        {
            latestCommunity.length>0 &&
            <section className="place-list-two pb-50" style={{ paddingTop: 30 }}>
                <div className="container-fluid">
                    <div className="section-title text-center">
                        <span>Community</span>
                        <h2>Communities around you</h2>
                    </div>
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                    >
                        {latestCommunity.map(d=>
                            (
                                <div key={d.communityFullName} className="place-list-item" style={{ padding: '15px' }}>
                                    <div className="images">

                                        <Link to={`/comm/${d.communityID}`}  className="images-list">
                                            <img className="imageHover" style={{ width:"510px", height:"340px", objectFit: "cover" }} src={`${imagePath}${d.bannerMainPicture}`} alt={d.communityFullName} crossOrigin="Anonymous" />
                                        </Link>
                                        <div className="eventOverlayHomePage"></div>


                                        <div className="place-tag">
                                            <p>
                                                <span className="oneLine" style={{ color: 'white', fontWeight: 'bold', marginLeft:'3px' }}>{d.communityFullName}</span>
                                                <br/>
                                                <span style={{marginLeft: '3px', color: 'white', fontWeight: 300 }}>{d.communityAddress}</span>
                                            </p>
                                            <p><span className="oneLine" style={{ color: 'white', marginLeft:'3px' }}>{d.distance} miles</span></p>
                                        </div>
                                    </div>

                                </div>)
                        )
                        }
                    </Carousel>
                </div>
            </section>
        }
        {/* End New Community */}

    </>
  )
}

export default Home