import {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import AuthorizedAreas from "../../../utils/AuthorizedAreas";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { BusinessEditValidation } from "../../../utils/validations/BusinessEditValidation";
import useGlobalNotification from "../../../utils/hooks/useGlobalNotification";
import useAxios from "../../../utils/hooks/useAxios";
import Datetime from "react-datetime";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
const imagePath = `${serverPath}/api/frontend/images/`;
const documentPath = `${serverPath}/api/frontend/docs/`;

  const ViewBusiness = () => {
      const defaultForm = {
        businessFullName: "",
        businessURLName: "",
        businessDescription: "",
        businessPhone: "",
        businessWebsite: "",
        businessAddress: "",
        businessLatitude: "",
        businessLongitude: "",
        businessCategory: "",
        businessSubcategory: "",
        businessSocialMediaHandles:{
         'facebook' : '',
          'twitter' :  '',
          'instagram' : ''
        },
        operatingHours: {
          monday : { 
            startTime : '',
            endTime : ''
          },
          tuesday : { 
            startTime : '',
            endTime : ''
          },
          wednesday : { 
            startTime : '',
            endTime : ''
          },
          thursday : { 
            startTime : '',
            endTime : ''
          },
          friday : { 
            startTime : '',
            endTime : ''
          },
          saturday : { 
            startTime : '',
            endTime : ''
          },
          sunday : { 
            startTime : '',
            endTime : ''
          }
        },
        bannerMainPicture: "",
        bannerOthersPicture:"",
        businessRegistrationDocuments:"",
        removeMainBanner: false,
        removeMainBannerName: "",
        removeOtherImages:[],
        removeOtherDocuments:[],
      };

      const location = useLocation();
      const id = location?.state?.id;
      const name = location?.state?.name;
      const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;

      const [form, setForm] = useState(defaultForm);
      const [categories,setCategories] = useState([]);
      const [subcategories,setSubcategories] = useState([]);
      const [status,setStatus] = useState('');

      const [mainImage,setMainImage] = useState("");
      const [additionalImages,setAdditionalImages] = useState([]);
      const [additionalDocuments,setAdditionalDocuments] = useState([]);

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const {dispatchNotification} = useGlobalNotification();
      const {postOrGet}  = useAxios();
      const maxSize = 600;//KB

      const getInitializationData = async () =>{
        await postOrGet(`business/edit?id=${id}`,'get')
            .then((data) =>{
                    if(data){
                        setCategories(data.categories);
                        setSubcategories(data.subcategories);
                        let biz = data.business;
                        biz.operatingHours = biz.businessOperatingTimes;
                        setStatus(biz.businessApprovedStatus);
                        delete biz.businessApprovedStatus;

                        delete biz.businessOperatingTimes;
                        if(biz.bannerMainPicture){
                          setMainImage(biz.bannerMainPicture);
                        }
                        delete biz.bannerMainPicture;

                        if(biz.bannerOthersPicture){
                          setAdditionalImages(biz.bannerOthersPicture.split(","));
                        }
                        delete biz.bannerOthersPicture;

                        if(biz.businessRegistrationDocuments){
                          setAdditionalDocuments(biz.businessRegistrationDocuments.split(","));
                        }
                        delete biz.businessRegistrationDocuments;
                        
                        setForm(prev=>({ ...prev,...biz}));
                    }
            }); 
      }

      useEffect(() => {
        getInitializationData();
      },[]);

      const slugify = (name) => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return name.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\-]+/g, '') // Remove all non-word characters
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
      }
	
	return <AuthorizedAreas permission="CAN_BUSINESS_VIEW">
	      <div className="card-body px-0 pt-0 pb-2">
					
          <form>
            <div style={{ padding: 20 }}>
               <button onClick={() => navigate(-1)} type="button" className="btn btn-icon btn-primary">
                  <span className="btn-inner--icon"><i className="material-icons">arrow_back</i></span>
                  <span className="btn-inner--text">GO BACK</span>
                </button>
              <div className="card mt-4" id="basic-info">
                <div className="card-header">
                  <h6 className="text-primary">Business Information for <span className="text-bold text-primary">{name}</span></h6></div>
                <div className="card-body pt-0">

                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessFullName">Business Name</label>
                        <input
                          readOnly={true}
                          autoComplete="off"
                          type="text"
                          name="businessFullName"
                          id="businessFullName"
                          className="form-control"
                          placeholder="Enter Business Full Name"
                          value={form.businessFullName}            						
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessURLName">Business URL</label>
                        <input
                          style={{ color: '#e67e22' }}
                          readOnly={true}
                          autoComplete="off"
                          type="text"
                          name="businessURLName"
                          id="businessURLName"
                          className="form-control"
                          value={form.businessURLName}					
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessAddress">Business Address</label>
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="businessAddress"
                                  id="businessAddress"
                                  className="form-control"
                                  placeholder="Search Business Address"
                                  value={form.businessAddress}					
                                />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row mt-4">
                    {mainImage && 
                     <div className="col-12 text-center">
                          <img src={`${imagePath}${mainImage}`} alt="img-blur-shadow" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                     </div>}
                  </div>


                   <div className="row mt-4">

                    <div className="row">
                        {additionalImages.length > 0 && additionalImages.map(img=>
                          (
                          <div key={img} className="col-3 text-center">
                            <img src={`${imagePath}${img}`} alt="Additional Business Images" className="img-fluid shadow border-radius-lg" crossOrigin="Anonymous" />
                          </div>
                          ))}
                    </div>

                  </div>

                  <div className="row mt-4">
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessFullName">Business Phone</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="businessPhone"
                          id="businessPhone"
                          className="form-control"
                          placeholder="Enter Business Phone Number"
                          value={form.businessPhone}
						  readOnly={true}					
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessWebsite">Business Website</label>
                        <input
                          autoComplete="off"
                          type="text"
                          name="businessWebsite"
                          id="businessWebsite"
                          className="form-control"
                          placeholder="Enter Business Website"
                          value={form.businessWebsite}
						 readOnly={true}						
                        />
                      </div>
                    </div>
                  </div>
          
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessCategory">Business Category</label>
                        <select
                          className="form-control"
                          name="businessCategory"
                          id="businessCategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.businessCategory}
                          readOnly={true}
                        >
                          { categories?.length && categories.map((e) => (
                              <option value={e.categoryID}>{e.categoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessSubcategory">Business Sub Category</label>
                        <select
                          className="form-control"
                          name="businessSubcategory"
                          id="businessSubcategory"
                          autoComplete="off"
                          defaultValue={""}
                          value={form.businessSubcategory}
                          readOnly={true}	
                        >
                          <option value=""> --Select Sub Category--</option>
                          { subcategories?.length && subcategories.map((e) => (
                              <option value={e.subcategoryID}>{e.subcategoryName}</option>
                              ))
                          }
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 mb-5">
                    <div className="col-12">
                      <div className="input-group input-group-static">
                        <label htmlFor="businessDescription">Business Description</label>
                        
                          <ReactQuill
                          readOnly={true}
                          style={{ width: '100%', height: '200px', marginBottom: '10px'}} 
                          theme="snow" name="businessDescription" value={form.businessDescription}  
                          />

                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 dynamic_operating_times">      
                      <h6 className="font-weight-bold text-primary">Operating Days and Hours</h6>

                      <div className="col-6">
                        <h6 className="text-sm">Mondays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.monday.startTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.monday.endTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Tuesdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.tuesday.startTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.tuesday.endTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Wednesdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.wednesday.startTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.wednesday.endTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Thursdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.thursday.startTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.thursday.endTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Fridays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.friday.startTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.friday.endTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Saturdays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.saturday.startTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.saturday.endTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <h6 className="text-sm">Sundays</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">Start Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.sunday.startTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                              
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="input-group input-group-static">
                              <label htmlFor="businessSubcategory">End Time</label>
                              <Datetime
                                className="input-group input-group-static"
                                inputProps={{
                                  name: "",
                                  value: form.operatingHours.sunday.endTime,
                                }}
                                readOnly={true}
                                dateFormat={false}
                                timeFormat={"HH:mm"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                  </div>  

                  <div className="row mt-4">      
                     
                          <h5 className="font-weight-bolder text-primary">Socials</h5>
                          
                          <div className="col-4"> 
                            <div className="input-group input-group-static">
                              <label>Facebook Account</label>
                              <input 
                              name="businessFacebook"
                              type="text" 
                              className="form-control" 
                              value={form.businessSocialMediaHandles.facebook}
                              readOnly={true}	
                              />
                            </div>
                          </div>

                          <div className="col-4"> 
                            <div className="input-group input-group-static">
                              <label>Instagram Account</label>
                              <input 
                              name="businessInstagram"
                              type="text" 
                              className="form-control" 
                              value={form.businessSocialMediaHandles.instagram}
                              readOnly={true}	
                              />
                            </div> 
                          </div>

                          <div className="col-4"> 
                            <div className="input-group input-group-static">
                              <label>Twitter Account</label>
                              <input 
                              name="businessTwitter"
                              type="text" 
                              className="form-control" 
                              value={form.businessSocialMediaHandles.twitter}
                              readOnly={true}	
                              />
                            </div>
                          </div>
                    
                  </div>  


                  {
                  status != 1 && 
                  <div className="row mt-4">      
                          <h5 className="font-weight-bolder text-primary">Supporting Documents</h5>
                         
                         {additionalDocuments.length > 0 &&
                            <div className="table-responsive">
                              <table className="table align-items-center mb-0">
                                <thead>
                                  <tr>
                                    <th style={{ width: '10px' }} className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">S/N</th>
                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Document</th>
                                  </tr>
                                </thead>
                                <tbody>

                                {
                                additionalDocuments.map((document,index)=>
                                  (
                                  <tr key={document}>
                                    <td style={{ width: '10px' }} className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                      {index+1}
                                    </td>
                                    <td>
                                      <div className="text-center">
                                        <div className="my-auto">
                                          <a href={`${documentPath}${document}`} 
                                          rel="noreferrer"
                                          target="_blank"><h6 className="mb-0 text-xs text-decoration-underline">Document {index+1}</h6></a>
                                        </div>
                                      </div>
                                    </td>                                    
                                  </tr>)
                                  )}

                                </tbody>
                              </table>
                            </div>}
                  
                    
                  </div> 
                  }      
                </div>
              </div>
            </div>
          </form>
        </div> 
		</AuthorizedAreas>;
}

export default ViewBusiness