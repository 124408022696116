import {useState,useEffect} from 'react';
import useAxios from "../../utils/hooks/useAxios";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation,useNavigate,useSearchParams } from "react-router-dom";
import useGlobalNotification from '../../utils/hooks/useGlobalNotification';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Player } from "@lottiefiles/react-lottie-player";
import successLottie from "../../assets/lottie/success.json";
import errorLottie from "../../assets/lottie/restricted.json";
import ImageModal from '../../components/ImageModal';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import list from '../../assets/frontend/img/business/list.png';
import gallery from '../../assets/frontend/img/business/gallery.png';
import contact from '../../assets/frontend/img/business/contact.png';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import {
	GoogleMap,
	useJsApiLoader,
	MarkerF,
} from "@react-google-maps/api";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";

const libraries = ['places'];

const NewFrontendEvent = () => {
  const shareURLPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_CLIENT_PRODUCTION : process.env.REACT_APP_LOCAL_SERVER;
  const serverPath = process.env.NODE_ENV == 'production'? process.env.REACT_APP_LIVE_SERVER : process.env.REACT_APP_LOCAL_SERVER;
  const imagePath = `${serverPath}/api/frontend/images/`;
  const dispatch = useDispatch();
  const id = useLocation()?.pathname.split('/ev/')[1].substring(0);
  

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [position, setPosition] = useState({});
  const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries,
		region: "uk",
	});

  const defaultForm = {
        eventId: id,
        ticketsAdultQty: 0,
        ticketsKidsQty: 0,
      };

  const [userTicketPurchaseOptions, setUserTicketPurchaseOptions] = useState(defaultForm);

  const [data, setData] = useState({});
  const [stripeFeedback, setStripeFeedback] = useState("");
  const [totalSold, setTotalSold] = useState(0);
  const {postOrGet}  = useAxios();
  const {dispatchNotification} = useGlobalNotification();
  const [showPurchaseTicketModal, setShowPurchaseTicketModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [showShareModal,setShowShareModal] = useState(false);
  const [stripeURL,setStripeURL] = useState("");
  const [searchParams] = useSearchParams();
  const message = searchParams.get('success');


  const {isAuthenticated,userType} = useSelector(state=> state.auth);
  

  const getData = async () => {
    await postOrGet("frontend/event",'post',{id})
    .then((data) =>{
        if(data){ 

            //set map position
            const newPosition = { lat: parseFloat(data?.result?.eventsLatitude), lng : parseFloat(data?.result?.eventsLongitude) };
            setPosition(newPosition); 


            if(data?.result?.bannerOthersPicture){
                setImageArray(data.result.bannerOthersPicture.split(','));
            }
            setData(data.result); 
            if(data?.resultTickets[0]?.totalSold){
               setTotalSold(data?.resultTickets[0]?.totalSold)
            }
        }
    });  
  }

  const moveSlideShowRight = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex + 1 >= totalLength ? 0 : currentModalIndex+1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${imagePath}${imageArray[nextIndex]}`);
  }

  const moveSlideShowLeft = async () =>{
    const totalLength = imageArray.length;
    const nextIndex = currentModalIndex - 1 < 0 ? totalLength-1  : currentModalIndex-1;
    setCurrentModalIndex(nextIndex);
    setCurrentModalImage(`${imagePath}${imageArray[nextIndex]}`);
  }

  const handleImage = (img,index) =>{
    setCurrentModalImage(img);
    setCurrentModalIndex(index);
    handleCloseSlideShow(true);
  }

  const handleCloseSlideShow = (action) =>{
    setShowImageModal(action);
  }

  const handleSubmit = async () =>{
    await postOrGet("frontend/ticket/purchase",'post',userTicketPurchaseOptions)
    .then((data) =>{
        if(data){ 
            //console.log(data);
            window.location.href=data.url;
        }
    }); 
  }

  useEffect(()=>{ 
      getData(); 
        if(message=="yes" || message=="no"){
            setStripeFeedback(message);
        }
    },[]);

  return (
    <>
        <div>
            {/* Inner Banner */}
            <div style={{ position : 'relative'}} className="inner-banner">
                <img style={{ position: 'absolute', width:"100%", height:"600px", objectFit: "cover", zIndex : -2  }} src={`${imagePath}${data.bannerMainPicture}`} alt="Images" crossOrigin="Anonymous" />
                <div className="container myResponsiveContainer" style={{ maxWidth:"99%" }}>
                <div className="inner-content">

                    
                      {data.ticketEnabled && moment().diff(moment(data.eventEndDate),"days") <=0 ?
                         <div style={{ float: "right", marginTop: '-140px'}}>
      
                                <div className="row">

                                    <div className="col-lg-12 col-md-6 mb-4">
                                        <div className="card text-center">
                                            <div className="card-body">
                                            <p className="text-uppercase small mb-2">
                                                <strong>TICKET AMOUNT <br/>(ADULT)</strong>
                                            </p>
                                            <h5 className="mb-0">
                                                <strong className="ms-2">£{data.ticketAmount}</strong>
                                            </h5>
                                            <hr />

                                            {data.kidsAmount > 0 ?
                                            <>
                                                <p className="text-uppercase small mb-2">
                                                    <strong>TICKET AMOUNT <br/>(KIDS)</strong>
                                                </p>
                                                <h5 className="mb-0">
                                                    <strong className="ms-2">£{data.kidsAmount}</strong>
                                                </h5>
                                            </> : null
                                            }
                                            </div>
                                            
                                            { 
                                            isAuthenticated ?
                                            <button onClick={() => setShowPurchaseTicketModal(true)} type="button" className="btn btn-icon btn-primary m-1">
                                                <span className="btn-inner--icon"><i className="material-icons">confirmation_number</i></span>
                                                <span className="btn-inner--text">PURCHASE TICKETS</span>
                                            </button>: 
                                            <button type="button" className="btn btn-icon btn-success m-1">
                                                <span className="btn-inner--icon"><i className="material-icons">login</i></span>
                                                <Link to="/authwall"><span className="btn-inner--text">LOGIN TO PURCHASE TICKETS</span></Link>
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </div>  
                         </div> 
                         : null}

                    <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="inner-banner-text">
                        <h3 style={{ marginBottom:"1px" }}>{data.eventsFullName}</h3>
                        <ul style={{ paddingBottom:"5px" }}>

                            <li>
                            <i className="fa-regular fa-calendar"></i>
                            {data.eventStartDate == data.eventEndDate ? moment(data.eventStartDate).format('LL') : `${moment(data.eventStartDate).format('LL')} - ${moment(data.eventEndDate).format('LL')}`} 
                            </li>

                            <li>
                            <i className="fa-regular fa-clock"></i>&nbsp;
                            {moment(data.eventStartTime, "HH:mm").format("HH:mm")} -&nbsp; 
                                {moment(data.eventEndTime, "HH:mm").format("HH:mm")} 
                            </li>

                            {
                            data?.eventsAddress?
                            <>
                            <br/>
                            <li>
                            <a target="_blank" rel="noreferrer" href={`https://maps.google.com?q=${data.eventsLatitude},${data.eventsLongitude}`}>   
                            <i className="flaticon-cursor" />
                            {data.eventsAddress}
                            </a> 
                            </li>
                            </>:null
                            }

                            {data.eventsWebsite? 
                            <>
                            <br/>
                            <li>  
                            <i className="flaticon-email-1" />
                            <a target="_blank" rel="noreferrer" href={`https://${data.businessWebsite}`} >
                                {data.eventsWebsite}
                            </a>
                            </li>
                            </>
                            :null}

                            {data.eventsPhone?
                            <>
                            <br/><br/>
                            <li>
                            <button type="button" className="btn btn-outline-primary">
                            <i style={{ color:'white' }} className="flaticon-telephone" />&nbsp;&nbsp;
                            <a style={{ color:'white' }} href={`tel:${data.eventsPhone}`}>
                            {data.eventsPhone}
                            </a>
                            </button>
                            </li>
                            </>: null
                            }

                            <li>
                                <button onClick={()=>setShowShareModal(true)} type="button" className="btn btn-outline-danger">
                                    <i className="fa fa-share-nodes"></i>&nbsp;&nbsp;
                                    Share
                                </button>
                            </li>

                      

                        </ul>
                        
                        </div>
                    </div>
                    </div>
                </div>
               
                <div className="banner-list">

                <div className="row align-items-center">
                    {/* Profile Area */}
                                <div className="listing-details-area pb-70 p-5">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="listing-details-wrap">
                                                
                                                    <div className="listing-widget listing-widget-desc">
                                                    <h3 className="title"> <img style={{ width: "20px", height: "20px" }} src={list} /> Description</h3>
                                                    <div style={{ padding: "10px",textAlign:"justify" }}> 
                                                        {parse(DOMPurify.sanitize(data.eventsDescription ))}
                                                    </div>
                                                    
                                                    </div>
                                                    {imageArray.length > 0 ?
                                                    <div className="listing-widget gallery-slider p-2">
                                                        <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={gallery} /> Gallery / Photos</h3>

                                                        <ResponsiveMasonry
                                                            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                                                        >
                                                            <Masonry gutter = "10px">
                                                            {imageArray.map((image,index)=>(
                                                                
                                                                <img className="gallery" style={{ borderRadius: '10px' }} key={index} onClick={()=>handleImage(`${imagePath}${image}`,index)} src={`${imagePath}${image}`} alt="Images" crossOrigin="Anonymous"  />
                                                                
                                                            ))}
                                                            </Masonry>
                                                        </ResponsiveMasonry>
                                        
                                                    </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="listing-widget-side">
                                                        <h3 className="title"><img style={{ width: "20px", height: "20px" }} src={contact} /> Contact</h3>
                                                        <ul className="listing-widget-list">
                                                            <li>Address : <span>{data?.eventsAddress}</span></li>
                                                            <li>Phone : <span><a href={`tel:${data?.eventsPhone}`}>{data?.eventsPhone}</a></span></li>
                                                        </ul>         
                                                </div>

                                                <div className="listing-widget gallery-slider p-2">
                                                    <h3 className="title"><i className="fa fa-map-location-dot"></i> Map View <a style={{ float: "right" }} className="btn btn-sm btn-primary" target="_blank" rel="noreferrer" href={`https://maps.google.com?q=${position.lat},${position.lng}`}>directions</a>  </h3>

                                                {isLoaded && position?.lat ? (
                                                    <GoogleMap
                                                        mapContainerStyle={{
                                                            width: "100%",
                                                            height: "500px",
                                                            marginTop: "10px",
                                                        }}
                                                        center={position}
                                                        zoom={18}
                                                        onLoad={(map) => setMap(map)}
                                                    >
                                                        <MarkerF position={position} />
                                                    </GoogleMap>
                                                ) : null}
                                    
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/* Profile Area End */}
                </div>    

                </div>
                </div>
            </div>
            {/* Inner Banner End */}
        </div>

        {
        showImageModal ?
        <ImageModal img={currentModalImage} close={handleCloseSlideShow} left={moveSlideShowLeft} right={moveSlideShowRight} />
        :
        null
        }

        {
        isAuthenticated && data.ticketEnabled  ?
        <Modal 
                show={showPurchaseTicketModal} 
                style={{ zIndex: "9997" }}
                /* fullscreen={FontFaceSetLoadEvent} */
                size="xl"
                scrollable={true}
                centered
                backdrop="static"
                keyboard={false} 
                onHide={()=>setShowPurchaseTicketModal(false)}>
            <Modal.Body>

                    <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowPurchaseTicketModal(false)} className="fa fa-circle-xmark"></i>

                    <div className="row">
                        <div className="col-12">
                            <h5 className="title text-center" style={{ marginBottom: 0 }}> <i className="flaticon-filter" />&nbsp;Purchase Tickets for {data.eventsFullName}</h5>

                            <br/>
                            <div className="center text-center">
                                <span style={{ width:'60px', height:'60px', fontSize:'25px' }} 
                                className="badge badge-circle badge-danger animated pulse infinite slow">{parseInt(data.ticketAvailableQty) - parseInt(totalSold)}</span><br/>
                                Tickets Remaining
                            </div>


                            {
                                data.ticketDiscountPercentage > 0 ? 
                                <div className="alert alert-success d-flex align-items-center m-3 p-1" role="alert">
                                    <i className="material-icons text-white">discount</i>
                                    <div className="text-white">
                                        &nbsp;&nbsp;Get {data.ticketDiscountPercentage}% discount when you purchase {data.ticketDiscountQuantity} ticket or more
                                    </div>
                                </div>
                                :
                                null
                            }


                                { 
                                parseInt(data.ticketAvailableQty) - parseInt(totalSold) - (parseInt(userTicketPurchaseOptions.ticketsAdultQty) + parseInt(userTicketPurchaseOptions.ticketsKidsQty)) > -1 ? null 
                                 :
                                <div className="alert alert-warning d-flex align-items-center m-3 p-1">
                                    <i className="material-icons text-white">pan_tool</i>
                                    <div className="text-white">
                                    &nbsp;&nbsp;Total Available Ticket Quantity Exceeded. Kindly reduce quantity
                                    </div>
                                </div>
                                }

                                <form>
                                    <div className="row">

                                        <div className="row p-5 col-8">
                                            <div className="col-12">
                                                <div className="input-group input-group-static">
                                                    <label htmlFor="ticketsAdultQty">Adult Quantity</label>
                                                    <input
                                                    autoComplete="off"
                                                    type="number"
                                                    name="ticketsAdultQty"
                                                    id="ticketsAdultQty"
                                                    min = "0"
                                                    className="form-control"
                                                    placeholder="How many tickets for Adults"
                                                    value={userTicketPurchaseOptions.ticketsAdultQty}
                                                            onChange={(e)=>{
                                                            const name = e.target.name;
                                                            const value = e.target.value;
                                                            
                                                            if(/^[0-9\b]+$/.test(e.target.value))
                                                            setUserTicketPurchaseOptions(prev=>({ ...prev, [name]: parseInt(value)})); 
                                                    }}						
                                                    />
                                                </div>
                                            </div>

                                            {data.kidsAmount > 0?
                                                <div className="col-12">
                                                    <div className="input-group input-group-static">
                                                        <label htmlFor="ticketsKidsQty">Kids Quantity</label>
                                                        <input
                                                        autoComplete="off"
                                                        type="number"
                                                        min = "0"
                                                        name="ticketsKidsQty"
                                                        id="ticketsKidsQty"
                                                        className="form-control"
                                                        placeholder="How many tickets for Kids"
                                                        value={userTicketPurchaseOptions.ticketsKidsQty}
                                                                    onChange={(e)=>{
                                                                    const name = e.target.name;
                                                                    const value = e.target.value;
                                                                    if(/^[0-9\b]+$/.test(value))
                                                                    setUserTicketPurchaseOptions(prev=>({ ...prev, [name]: parseInt(value)})); 
                                                            }}						
                                                        />
                                                    </div>
                                                </div>
                                            :
                                            null}
                                        </div>


                                        <div className="col-4 p-5 ps-lg-4">
                                            <div className="row m-0">
                                                <div className="col-12 px-4">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <p className="textmuted">Qty</p>
                                                        <p className="fs-14 fw-bold">{parseInt(userTicketPurchaseOptions.ticketsAdultQty) + parseInt(userTicketPurchaseOptions.ticketsKidsQty)}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <p className="textmuted">Subtotal</p>
                                                        <p className="fs-14 fw-bold">- £&nbsp;{ (userTicketPurchaseOptions.ticketsAdultQty * data.ticketAmount) + (userTicketPurchaseOptions.ticketsKidsQty * data.kidsAmount)}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <p className="textmuted">Discount</p>
                                                        <p className="fs-14 fw-bold text-danger">- £&nbsp;{data.ticketDiscountPercentage > 0 && ( parseInt(userTicketPurchaseOptions.ticketsAdultQty) + parseInt(userTicketPurchaseOptions.ticketsKidsQty)) >= parseInt(data.ticketDiscountQuantity) ? `(${ (((userTicketPurchaseOptions.ticketsAdultQty * data.ticketAmount) + (userTicketPurchaseOptions.ticketsKidsQty * data.kidsAmount)) * (data.ticketDiscountPercentage/100)).toFixed(2)})` : 0 }</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-3">
                                                        <p className="textmuted fw-bold">Total</p>
                                                        <div className="d-flex align-text-top">
                                                        £&nbsp;<span className="h4 text-success">
                                                            {
                                                            data.ticketDiscountPercentage > 0 && ( parseInt(userTicketPurchaseOptions.ticketsAdultQty) + parseInt(userTicketPurchaseOptions.ticketsKidsQty)) >= parseInt(data.ticketDiscountQuantity) ? 
                                                            ((userTicketPurchaseOptions.ticketsAdultQty * data.ticketAmount) + (userTicketPurchaseOptions.ticketsKidsQty * data.kidsAmount) - (((userTicketPurchaseOptions.ticketsAdultQty * data.ticketAmount) + (userTicketPurchaseOptions.ticketsKidsQty * data.kidsAmount)) * (data.ticketDiscountPercentage/100))).toFixed(2) : 
                                                            (userTicketPurchaseOptions.ticketsAdultQty * data.ticketAmount) + (userTicketPurchaseOptions.ticketsKidsQty * data.kidsAmount)
                                                            }
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    { 
                                    parseInt(data.ticketAvailableQty) - parseInt(totalSold) - (parseInt(userTicketPurchaseOptions.ticketsAdultQty) + parseInt(userTicketPurchaseOptions.ticketsKidsQty)) > -1 
                                    && parseInt(userTicketPurchaseOptions.ticketsAdultQty) + parseInt(userTicketPurchaseOptions.ticketsKidsQty)
                                    ? 
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 text-center">
                                            <div className="col-12  mb-4 p-0">
                                                    <div onClick={()=>handleSubmit()} className="btn btn-primary btn-lg">PROCEED TO PAYMENT<span className="fas fa-arrow-right ps-2" />
                                                    </div>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    null
                                    }

                                    
                                </form>
                                    
                            </div>

                    </div>    
            </Modal.Body>
        </Modal>
        :
        null}

        {/* Event Share Modal */}
        <Modal 
        show={showShareModal} 
        /* fullscreen={true} */
        size="lg"
        scrollable={false}
        centered
        backdrop="static"
        keyboard={false} 
        onHide={()=>setShowShareModal(false)}>
            <Modal.Body>
            <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setShowShareModal(false)} className="fa fa-circle-xmark"></i>

            <div style={{ padding: '5px' }} className="row">

                        <div className="col-4 text-center p-5">
                            <EmailShareButton 
                                url={`${shareURLPath}/ev/${id}`}
                                title={`${data.eventsFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px" }} className="fa fa-envelope"></i>
                            </EmailShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <LinkedinShareButton 
                                url={`${shareURLPath}/ev/${id}`}
                                title={`${data.eventsFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#0072b1" }} className="fa-brands fa-linkedin"></i>
                            </LinkedinShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <FacebookShareButton 
                                url={`${shareURLPath}/ev/${id}`}
                                title={`${data.eventsFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#3b5998" }} className="fa-brands fa-facebook"></i>
                            </FacebookShareButton>
                        </div>


                        <div className="col-4 text-center p-5">
                            <PinterestShareButton 
                                url={`${shareURLPath}/ev/${id}`}
                                title={`${data.eventsFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#c8232c" }} className="fa-brands fa-pinterest"></i>
                            </PinterestShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <RedditShareButton 
                                url={`${shareURLPath}/ev/${id}`}
                                title={`${data.eventsFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#FF4500" }} className="fa-brands fa-reddit"></i>
                            </RedditShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <TelegramShareButton 
                                url={`${shareURLPath}/ev/${id}`}
                                title={`${data.eventsFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#4995be" }} className="fa-brands fa-telegram"></i>
                            </TelegramShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <TwitterShareButton
                                url={`${shareURLPath}/ev/${id}`}
                                title={`${data.eventsFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color: "#1DA1F2" }} className="fa-brands fa-twitter"></i>
                            </TwitterShareButton>
                        </div>

                        <div className="col-4 text-center p-5">
                            <WhatsappShareButton
                                url={`${shareURLPath}/ev/${id}`}
                                title={`${data.eventsFullName} on Esipsi`}
                            >
                                <i style={{ fontSize: "35px", color:"#128C7E" }} className="fa-brands fa-whatsapp"></i>
                            </WhatsappShareButton>
                        </div>      
            </div>
            </Modal.Body>
        </Modal>

        {/* Payment Status Notification */}
        <Modal 
        show={stripeFeedback!=""} 
        /* fullscreen={true} */
        size="lg"
        scrollable={false}
        centered
        backdrop="static"
        keyboard={false} 
        onHide={()=>setStripeFeedback("")}>
            <Modal.Body>
            <i style={{ position:'absolute', right:'5px', top: '5px', cursor:"pointer", fontSize:"24px", color:"#f14300" }}  onClick={()=>setStripeFeedback("")} className="fa fa-circle-xmark"></i>

                <div style={{ padding: '5px' }} className="row">

                            <div className="col-12 text-center p-5">
                                        {
                                        stripeFeedback == "yes" ? 
                                        <div className="text-center">
                                            <div>
                                                        <Player
                                                        autoplay
                                                        loop = {true}
                                                        style={{ height: "300px", width: "100%" }}
                                                        src={successLottie}
                                                    />
                                            </div>
                                            <p> Your ticket purchase was successful, please check your email</p>
                                        </div>: 
                                        null
                                        }

                                        {
                                        stripeFeedback == "no" ? 
                                        <div className="text-center">
                                            <div>
                                                        <Player
                                                        autoplay
                                                        loop = {true}
                                                        style={{ height: "300px", width: "100%" }}
                                                        src={errorLottie}
                                                    />
                                            </div>
                                            <p>An error occurred during ticket purchase, please try again</p>
                                        </div>: 
                                        null
                                        }
                            </div>            
                </div>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default NewFrontendEvent