import React, {useEffect} from 'react';
import { Outlet } from "react-router-dom";
import {useLocation, NavLink, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import "../../assets/frontend/css/bootstrap.min.css";
import "../../assets/frontend/css/owl.carousel.min.css";
import "../../assets/frontend/css/owl.theme.default.min.css";
import "../../assets/frontend/fonts/flaticon.css";
import "../../assets/frontend/css/boxicons.min.css";
import "../../assets/frontend/css/magnific-popup.css";
import "../../assets/frontend/css/animate.min.css";
import "../../assets/frontend/css/meanmenu.css";
import "../../assets/frontend/css/jquery-ui.css";
import "../../assets/frontend/css/nice-select.min.css";
import "../../assets/frontend/css/style.css";
import "../../assets/frontend/css/responsive.css";
import NavBar from '../../components/frontend/NavBar';
//import $ from 'jquery';

const FrontendLayout = () => {
	//window.jQuery = window.$ = $;
	const location = useLocation();
	const {isAuthenticated} = useSelector(state=> state.auth);
	useEffect(() => {
		import('./../../assets/frontend/custom.js').then((mod)=>{}).catch(err => {}); 		 
	}, []);
  return (
		<>
			<div id="anchorScrollToElement"></div>
			{location?.pathname != "/" && <NavBar/>}
			
			{/* Search Overlay */}
			<div className="search-overlay">
				<div className="d-table">
					<div className="d-table-cell">
					<div className="search-layer" />
					<div className="search-layer" />
					<div className="search-layer" />
					<div className="search-close">
						<span className="search-close-line" />
						<span className="search-close-line" />
					</div>
					<div className="search-form">
						<form>
						<input type="text" className="input-search" placeholder="Search here..." />
						<button type="submit"><i className="flaticon-loupe" /></button>
						</form>
					</div>
					</div>
				</div>
			</div>
			{/* End Search Overlay */}


			<Outlet />

			{/* Footer Area */}
			<footer className="footer-area footer-bg2">
				<div style={{ paddingTop:10 }} className="footer-middle pb-70">
					<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
						<div className="footer-widget">
							<Link to="/" className="logo">
							<img src={require("../../assets/frontend/img/logo/logo1.png")} alt="Logo" />
							</Link>
							<p>
							KadTech Solutions Limited
							</p>
							<ul className="footer-contact-list">
							{/* <li>
								25 Ravansdale Road, Hounslow West, <br/>TW4 7EU, United Kingdom
							</li> */}
							<li>
								<span>Phone :</span> <a href="tel:07963492269"> 0796 349 2269</a>
							</li> 
							<li>
								<span>Email :</span> <a href="mailto:info@kadtechsolutions.com"> info@kadtechsolutions.com</a>
							</li> 
							</ul>
							{/* <ul className="social-link">
							<li>
								<a href="#" target="_blank"><i className="bx bxl-facebook" /></a>
							</li> 
							<li>
								<a href="#" target="_blank"><i className="bx bxl-twitter" /></a>
							</li> 
							<li>
								<a href="#" target="_blank"><i className="bx bxl-instagram" /></a>
							</li> 
							<li>
								<a href="#" target="_blank"><i className="bx bxl-pinterest-alt" /></a>
							</li> 
							<li>
								<a href="#" target="_blank"><i className="bx bxl-youtube" /></a>
							</li> 
							</ul> */}
						</div>
						</div>
						
						{/* <div className="col-lg-6 col-md-6">
							<div className="footer-widget pl-5">
								<h3  style={{ fontSize: '20px' }}>QUICK LINKS</h3>
								<ul className="footer-list">
									<li>
										<Link to="/">Home</Link>
									</li>
								
									<li>
										<Link to="/explore">Explore</Link>
									</li>
								</ul>
							</div>
						</div> */}
					</div>
					</div>
				</div>
			</footer>
			{/* Footer Area End */}
		
				
			{/* Copy Right */}
			<div className="copy-right-area copy-right-area-two">
				<div className="container">
					<div className="row">
				{/* 	<div className="col-lg-4 col-md-4">
						<div className="copy-right-list">
						<ul>
							<li>
							<a href="#" target="_blank">
								Terms of Use
							</a>
							</li> 
							<li>
							<a href="#" target="_blank">
								Privacy Policy
							</a>
							</li> 
							<li>
							</li> 
						</ul>
						</div>
					</div> */}
					<div className="col-lg-12 col-md-12">
						<div className="copy-right-text text-right">
						<p>
							Copyright ©2022 KadTech Solutions. All Rights Reserved by 
						</p>
						</div>
					</div>
					</div>
				</div>
			</div>
			{/* Copy Right End */}


		</>
	);
}
export default FrontendLayout;