import * as yup from "yup";

export const ListingEditValidation = yup.object().shape({
	listingId: yup.number().required('Listing must belong to a business'),
	listingName: yup.string().required('Listing Name is required'),
	listingAmount: yup.number().required('Listing Name is required'),
	listingDetails: yup.string().required('Listing details are required'),
	listingAddress: yup.string().required('Listing Address is required'),
	listingLat: yup.string().required('Listing Latitude is required'),
	listingLng: yup.string().required('Listing Longitude is required'),
	listingBedrooms: yup.number().required('Listing Bedrooms is required'),
	listingBathrooms: yup.number().required('Listing Bathrooms is required'),
	listingEPC: yup.string().required('Listing EPC is required'),
	listingSize: yup.string().required('Listing Size is required'),
	removeMainBanner: yup.boolean().required('Required field'),
}
);